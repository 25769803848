/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseTag } from '@app/components/common/BaseTag/BaseTag';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { Colors } from '@app/infrastructure/enums/colors';
import RankCheckBox from '@app/components/ranks/check-box';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import BillSelectBox from '@app/components/bills/select-box';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

export const OptionsStep: React.FC = () => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const { t } = useTranslation();
  const { rerender } = useRerender();

  useEffect(() => {
    rerender();
  }, []);

  const onRanksSelected = (checkedValue: CheckboxValueType[]) => {
    setCheckedList(checkedValue);
  };

  return (
    <>
      <S.BaseFormItem name="bill" label={t('labels.room')} rules={[{ required: true }]}>
        <BillSelectBox type={[BILL_TYPES.ROOM]} placeholder={undefined} />
      </S.BaseFormItem>
      <S.BaseFormItem name="name" label={t('labels.contentName')} rules={[{ required: true }]}>
        <BaseInput />
      </S.BaseFormItem>
      <BaseRow gutter={25}>
        <BaseCol>
          <S.BaseFormItem name="buyIn" label={t('labels.bi')} rules={[{ required: true }]}>
            <InputNumber />
          </S.BaseFormItem>
        </BaseCol>
        <BaseCol>
          <S.BaseFormItem
            name="type"
            label={t('labels.type')}
            rules={[{ required: true }, { type: 'integer', min: 0, message: t('messages.enterInt') }]}
          >
            <InputNumber />
          </S.BaseFormItem>
        </BaseCol>
      </BaseRow>
      <BaseForm.Item name="ranks" label={t('labels.ranks')} rules={[{ required: true }]}>
        <RankCheckBox
          onChange={(checkedValue: CheckboxValueType[]) => onRanksSelected(checkedValue)}
          value={checkedList}
        />
      </BaseForm.Item>

      <S.BaseFormItem name="color" label={t('labels.color')} rules={[{ required: true }]}>
        <BaseRadio.Group>
          <BaseRadio value={Colors.Blue}>
            <BaseTag color="blue">{t('labels.blue')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Green}>
            <BaseTag color="green">{t('labels.green')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Yellow}>
            <BaseTag color="gold">{t('labels.orange')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Red}>
            <BaseTag color="red">{t('labels.red')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Pink}>
            <BaseTag color="magenta">{t('labels.pink')}</BaseTag>
          </BaseRadio>
        </BaseRadio.Group>
      </S.BaseFormItem>
    </>
  );
};

export default OptionsStep;
