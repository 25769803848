import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { ICreatePlayer } from '@app/infrastructure/interfaces/i-player';
import { Player } from '@app/infrastructure/classes/player';

interface IPlayersCreateFormProps {
  onClose?: () => void;
  onSave?: (player: Player) => void;
}

const PlayersCreateForm: React.FC<IPlayersCreateFormProps> = (props) => {
  const { onClose, onSave } = props;
  const { t } = useTranslation();

  const { createPlayer, loading } = usePlayers();

  const onFinish = async (values: ICreatePlayer) => {
    if (values) {
      const player = await createPlayer(values);
      if (onSave && player) onSave(player);
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item name="login" label={t('labels.login')} rules={[{ required: true, min: 2 }]}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item name="password" label={t('labels.password')} rules={[{ required: true, min: 2 }]}>
        <BaseInput />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default PlayersCreateForm;
