import { ILimit } from '../interfaces/i-limit';
import { Bill } from './bill';
import { Rank } from './rank';

export class Limit {
  id: number;
  bill: Bill;
  rank: Rank;
  limits: { [key: string]: string };
  constructor(data: ILimit) {
    this.id = data.id;
    this.bill = new Bill(data.bill);
    this.rank = new Rank(data.rank);
    this.limits = data.limits;
  }
}
