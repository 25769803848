import { IBillSelectBoxItemProps } from '@app/components/bills/select-box';
import { ITournamentFilters } from '../interfaces/i-tournament';
import { IFlcraftFilters, IPlayerFlcraftFilters } from '../interfaces/i-flcraft';
import { IInternalTransferFilters } from '../interfaces/i-internal-transfer';
import { IPersonalTransferFilters } from '../interfaces/i-personal-transfer';
import { IPlayersBillSelectBoxItemProps } from '@app/components/players/bills/select-box';
import { IPlayerFilters } from '../interfaces/i-player';

const tournamentFiltersKey = 'tournament-filters';
const flcraftFiltersKey = 'flcraft-filters';
const playerFlcraftFiltersKey = 'player-flcraft-filters';
const internalTransfersFiltersKey = 'internal-transfers-filters';
const internalTransfersPlayerFiltersKey = 'internal-transfers-player-filters';
const personalTransfersFiltersKey = 'personal-transfers-filters';
const personalTransfersPlayerFiltersKey = 'personal-transfers-player-filters';
const playerFiltersKey = 'player-filters';

interface ITournamentStorageFilters extends Omit<ITournamentFilters, 'bills'> {
  bills?: IBillSelectBoxItemProps[];
}
interface IInternalTransferStorageFilters extends Omit<IInternalTransferFilters, 'billId'> {
  bill?: IBillSelectBoxItemProps;
}

interface IInternalTransferPlayerStorageFilters extends Omit<IInternalTransferFilters, 'billId'> {
  bill?: IPlayersBillSelectBoxItemProps;
}

interface IPersonalTransferStorageFilters extends Omit<IPersonalTransferFilters, 'billId'> {
  bill?: IBillSelectBoxItemProps;
}

interface IPersonalTransferPlayerStorageFilters extends Omit<IPersonalTransferFilters, 'billId'> {
  bill?: IPlayersBillSelectBoxItemProps;
}

export const getTournamentFilters = (): ITournamentStorageFilters | null => {
  const filter = localStorage.getItem(tournamentFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return null;
};

export const setTournamentFilters = (filters: ITournamentStorageFilters) => {
  localStorage.setItem(tournamentFiltersKey, JSON.stringify(filters));
};

export const getFlcraftFilters = (): IFlcraftFilters => {
  const filter = localStorage.getItem(flcraftFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return {};
};

export const setFlcraftFilters = (filters: IFlcraftFilters) => {
  localStorage.setItem(flcraftFiltersKey, JSON.stringify(filters));
};

export const getPlayerFlcraftFilters = (): IPlayerFlcraftFilters => {
  const filter = localStorage.getItem(playerFlcraftFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return {};
};

export const setPlayerFlcraftFilters = (filters: IPlayerFlcraftFilters) => {
  localStorage.setItem(playerFlcraftFiltersKey, JSON.stringify(filters));
};

export const getInternalTransferFilters = (): IInternalTransferStorageFilters | null => {
  const filter = localStorage.getItem(internalTransfersFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return null;
};

export const setInternalTransferPlayerFilters = (filters: IInternalTransferPlayerStorageFilters) => {
  localStorage.setItem(internalTransfersPlayerFiltersKey, JSON.stringify(filters));
};

export const getInternalTransferPlayerFilters = (): IInternalTransferPlayerStorageFilters | null => {
  const filter = localStorage.getItem(internalTransfersPlayerFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return null;
};

export const setInternalTransferFilters = (filters: IInternalTransferStorageFilters) => {
  localStorage.setItem(internalTransfersFiltersKey, JSON.stringify(filters));
};

export const getPersonalTransferFilters = (): IPersonalTransferStorageFilters | null => {
  const filter = localStorage.getItem(personalTransfersFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return null;
};

export const setPersonalTransferFilters = (filters: IPersonalTransferStorageFilters) => {
  localStorage.setItem(personalTransfersFiltersKey, JSON.stringify(filters));
};

export const getPersonalTransferPlayerFilters = (): IPersonalTransferPlayerStorageFilters | null => {
  const filter = localStorage.getItem(personalTransfersPlayerFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return null;
};

export const setPersonalTransferPlayerFilters = (filters: IPersonalTransferPlayerStorageFilters) => {
  localStorage.setItem(personalTransfersPlayerFiltersKey, JSON.stringify(filters));
};

export const getPlayerFilters = (): IPlayerFilters | null => {
  const filter = localStorage.getItem(playerFiltersKey);
  if (filter) {
    const parsedFilters = JSON.parse(filter);
    if (parsedFilters) return parsedFilters;
  }
  return null;
};

export const setPlayerFilters = (filters: IPlayerFilters) => {
  localStorage.setItem(playerFiltersKey, JSON.stringify(filters));
};

export const deleteFilters = (): void => {
  localStorage.removeItem(internalTransfersFiltersKey);
  localStorage.removeItem(internalTransfersPlayerFiltersKey);
  localStorage.removeItem(playerFlcraftFiltersKey);
  localStorage.removeItem(personalTransfersFiltersKey);
  localStorage.removeItem(personalTransfersPlayerFiltersKey);
  localStorage.removeItem(flcraftFiltersKey);
  localStorage.removeItem(tournamentFiltersKey);
  localStorage.removeItem(playerFiltersKey);
};
