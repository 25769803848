import { CURRENCIES } from '../consts/currencies';
import { IPlayer, IPlayersStatistic } from '../interfaces/i-player';
import { PlayerBill } from './player-bill';
import { ProfitPackage } from './profit-package';
import { Rank } from './rank';
import { User } from './user';

export class Player {
  id: number;
  user?: User;
  overMoney: number;
  personalFunds: number;
  needFromFund: number;
  needFromPlayer: number;
  availableForWithdrawal: number;
  fundMoney: number;
  onTheWayDollar: number;
  onTheWayEuro: number;
  analyticsDescription: string;
  rank?: Rank;
  bills?: PlayerBill[];
  profitPackages?: ProfitPackage[];

  constructor(data: IPlayer) {
    this.id = data.id;
    this.user = data.user ? new User(data.user) : undefined;
    this.overMoney = data.over_money;
    this.personalFunds = data.personal_funds;
    this.needFromFund = data.need_from_fund;
    this.needFromPlayer = data.need_from_player;
    this.fundMoney = data.fund_money;
    this.onTheWayDollar = data.on_the_way_dollar;
    this.onTheWayEuro = data.on_the_way_euro;
    this.analyticsDescription = data.analytics_description;
    this.rank = data.rank ? new Rank(data.rank) : undefined;
    this.bills = data.bills?.length
      ? data.bills.map((item) => {
          return new PlayerBill(item);
        })
      : undefined;
    this.profitPackages = data.profit_packages?.length
      ? data.profit_packages.map((item) => {
          return new ProfitPackage(item);
        })
      : undefined;
    this.availableForWithdrawal = Number((data.available_for_withdrawal + this.getPlayerPart()).toFixed(2));
  }

  updateInfo(data: IPlayer): void {
    this.user = new User(data.user);
    this.overMoney = data.over_money;
    this.personalFunds = data.personal_funds;
    this.needFromFund = data.need_from_fund;
    this.needFromPlayer = data.need_from_player;
    this.fundMoney = data.fund_money;
    this.onTheWayDollar = data.on_the_way_dollar;
    this.onTheWayEuro = data.on_the_way_euro;
    this.analyticsDescription = data.analytics_description;
    this.rank = data.rank ? new Rank(data.rank) : undefined;
    this.bills = data.bills
      ? data.bills.map((item) => {
          return new PlayerBill(item);
        })
      : undefined;
    this.profitPackages = data.profit_packages?.length
      ? data.profit_packages.map((item) => {
          return new ProfitPackage(item);
        })
      : undefined;
    this.availableForWithdrawal = Number((data.available_for_withdrawal + this.getPlayerPart()).toFixed(2));
  }

  getBillsSumm(): number {
    let summ = 0;
    let rate = 1;
    this.bills
      ? (summ = this.bills.reduce((previousValue, playerBill) => {
          const summInDollar = Number((playerBill.summ * playerBill.bill.currency.rate).toFixed(2));
          if (playerBill.bill.currency.name === CURRENCIES.EURO) rate = playerBill.bill.currency.rate;
          return previousValue + summInDollar;
        }, 0))
      : (summ = 0);
    summ = Math.round(summ + this.onTheWayDollar + this.onTheWayEuro * rate);
    return summ;
  }

  getPlayerPart(): number {
    const playerPart =
      this.profitPackages && this.profitPackages[this.profitPackages.length - 1].playersPart
        ? this.profitPackages[this.profitPackages.length - 1].playersPart
        : 0;
    return playerPart || 0;
  }
}

export class PlayersStatistic {
  overMoneySumm: number;
  fundMoneySumm: number;
  fundsPartSumm: number;

  constructor(data: IPlayersStatistic) {
    this.overMoneySumm = Number(data.over_money_summ.toFixed(2));
    this.fundMoneySumm = Number(data.fund_money_summ.toFixed(2));
    this.fundsPartSumm = Number(data.funds_part_summ.toFixed(2));
  }
}
