import React from 'react';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as S from './styles';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import { STATUS_INTERNAL_TRANSFER } from '@app/infrastructure/enums/status-internal-transfer.enum';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';

interface CloseCellProps {
  data: InternalTransferRequest;
  onSave: () => void;
}

const CloseCell: React.FC<CloseCellProps> = (props) => {
  const { data, onSave } = props;

  const { updateInternalTransferRequest, loading } = useInternalTransferRequests({ player: undefined });

  const { t } = useTranslation();

  const onFinish = async (status: STATUS_INTERNAL_TRANSFER) => {
    updateInternalTransferRequest(data.id, { status }, data).then(() => {
      onSave();
    });
  };

  return (
    <BaseRow justify="center" align="middle">
      <BaseButton size="small" type="text" onClick={() => onFinish(STATUS_INTERNAL_TRANSFER.CLOSED)} loading={loading}>
        <S.CloseButton>{t('controls.close')}</S.CloseButton>
      </BaseButton>
    </BaseRow>
  );
};

export default CloseCell;
