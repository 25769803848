import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { Analytics } from '@app/infrastructure/classes/analytics';

interface IAnalyticsColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: Analytics) => void;
  onDelete: (data: Analytics) => void;
}

export const analyticsTableColumns = ({
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}: IAnalyticsColumnsProps): IModifiedTableColumn<Analytics>[] => {
  const columns: IModifiedTableColumn<Analytics>[] = [
    {
      title: `${i18next.t('labels.stage')}`,
      dataIndex: 'stage',
      render: (_, data: Analytics) => `${i18next.t(`analyticsStages.${data.stage}`)}`,
    },
    {
      title: `${i18next.t('labels.numberOfHands')}`,
      dataIndex: 'numberOfHands',
    },
    {
      title: `${i18next.t('labels.abi')}`,
      dataIndex: 'abi',
    },
    {
      title: `${i18next.t('labels.ev')}`,
      dataIndex: 'ev',
    },
    {
      title: `${i18next.t('labels.net')}`,
      dataIndex: 'net',
    },
    {
      title: `${i18next.t('labels.period')}`,
      dataIndex: 'period',
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '150px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          canEdit={canEdit}
          canDelete={canDelete}
          onDelete={() => {
            onDelete(record);
          }}
          onEdit={() => {
            onEdit(record);
          }}
          size="small"
        />
      ),
    });
  }

  return columns;
};
