export const ROUTES = {
  HOME: '/',
  LOGIN: '/auth/login',
  ACCESS_RIGHTS: '/access-rights',
  ADMINS: '/admins',
  FLCRAFT: '/gg-statistics',
  FLCRAFT_DETAIL: '/gg-statistics/:playerId',
  PLAYERS: '/players',
  PLAYERS_DETAIL: '/players/:playerId',
  BILLS: '/bills',
  CURRENCIES: '/currencies',
  RANKS: '/ranks',
  PERSONAL_TRANSFERS: '/personal-transfers',
  INTERNAL_TRANSFERS: '/internal-transfers',
  TOURNAMENTS: '/tournaments',
  INTERNAL_TRANSFER_REQUESTS: '/internal-transfer-requests',
  INTERNAL_TRANSFER_REQUESTS_ME: '/internal-transfer-requests/me',
  PLAYER_GAMES: '/player-games',
  ANALYTICS: '/analytics',
  PROFIT_PACKAGES: '/profit-packages',
  ERRORS: {
    NOT_FOUND: '/errors/404',
    FORBIDDEN: '/errors/403',
    SERVER_ERROR: '/errors/500',
  },
};
