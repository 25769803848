import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import styled from 'styled-components';

export const CaculateFormButton = styled(BaseButton)`
  margin-left: auto;
`;

export const PackageInfo = styled(BaseTypography)`
  margin-bottom: 15px;
`;

export const PackageDateInfo = styled(BaseTypography)`
  margin-top: 15px;
  margin-bottom: 15px;
`;
