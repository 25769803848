import moment from 'moment';
import { PURPOSE_PERSONAL_TRANSFER } from '../enums/purpose-personal-transfer.enum';
import { IPersonalTransfer } from '../interfaces/i-personal-transfer';
import { Image } from './image';
import { PlayerBill } from './player-bill';

export class PersonalTransfer {
  id: number;
  amount: number;
  description: string;
  purpose: PURPOSE_PERSONAL_TRANSFER;
  createdAt: moment.Moment;
  deleted_at: Date;
  image: Image;
  playersBill: PlayerBill;

  constructor(data: IPersonalTransfer) {
    this.id = data.id;
    this.amount = data.amount;
    this.description = data.description;
    this.purpose = data.purpose;
    this.createdAt = moment(data.created_at);
    this.deleted_at = data.deleted_at;
    this.image = new Image(data.image);
    this.playersBill = new PlayerBill(data.players_bill);
  }
}
