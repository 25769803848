import { Bill } from '@app/infrastructure/classes/bill';
import { billTypesDataSource } from '@app/infrastructure/data-sources/bill-types';
import React from 'react';

interface TypeCellProps {
  data: Bill;
}

const TypeCell: React.FC<TypeCellProps> = ({ data }) => {
  let translatedType;
  billTypesDataSource().map((billType) => {
    if (data.type === billType.id) {
      translatedType = billType.label;
    }
  });
  return <span>{translatedType}</span>;
};

export default TypeCell;
