import { Permissions } from '../enums/permissions';

export const checkPermission = (requiredPermission: Permissions, userPermission?: Permissions): boolean => {
  if (!userPermission) return false;

  if (userPermission === Permissions.FullAccess) {
    return true;
  }

  if (requiredPermission === Permissions.Read && userPermission >= Permissions.Read) {
    return true;
  }

  if (requiredPermission === Permissions.Create && userPermission >= Permissions.Create) {
    return true;
  }

  if (requiredPermission === Permissions.Update && userPermission >= Permissions.Update) {
    return true;
  }

  return false;
};
