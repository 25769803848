import moment from 'moment-timezone';
import { RangePickerProps } from 'antd/lib/date-picker';

export const prepareDateRange = (fromDate?: string, toDate?: string): RangePickerProps['value'] => {
  if (fromDate && toDate) {
    return [moment(fromDate), moment(toDate)];
  }

  return undefined;
};
