import { LoadingOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import { Player } from '@app/infrastructure/classes/player';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SendCellProps {
  data: InternalTransferRequest;
  player: Player;
  onSend?: () => void;
}

const SendCell: React.FC<SendCellProps> = (props) => {
  const { data, player, onSend } = props;
  const { t } = useTranslation();

  const userId = useAppSelector((state) => state.user.user?.id);

  const { getTransferInfo, startTransfer, loading } = useInternalTransferRequests({ userId });

  let hasBill = false;
  if (player.bills && player.bills.find((playerBill) => playerBill.bill.id === data.playersBill?.bill.id)) {
    hasBill = true;
  }

  let isDisabled = false;
  if (data.playersBill?.player) {
    isDisabled = data.playersBill.player.id === player.id || !hasBill;
  }

  const getInfo = async () => {
    const res = await getTransferInfo(data.id);
    if (res && res.canTransfer && onSend) {
      onSend();
      if (res.time === 0) await startTransfer(data.id);
    }
  };

  return (
    <BaseRow align="middle" justify="center">
      <BaseButton
        icon={loading ? <LoadingOutlined /> : undefined}
        size="small"
        severity="info"
        disabled={isDisabled || loading}
        onClick={getInfo}
      >
        {t('controls.send')}
      </BaseButton>
    </BaseRow>
  );
};

export default SendCell;
