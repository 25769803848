import { httpApi } from '@app/api/http.api';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { ICreateUser, IUpdateUser, IUser } from '@app/infrastructure/interfaces/i-user';

const baseRoute = 'users';

export const getUsers = async (params: IPaginationMeta, loadAdmins?: boolean): Promise<IPaginatedData<IUser>> => {
  let route = baseRoute;
  if (loadAdmins) route += '/admins';
  return await httpApi.get<IPaginatedData<IUser>>(route, { params }).then(({ data }) => data);
};

export const createUsers = async (data: ICreateUser): Promise<IUser> => {
  return await httpApi.post<IUser>(baseRoute, data).then(({ data }) => data);
};

export const updateUsers = async (id: number, data: IUpdateUser): Promise<IUser> => {
  return await httpApi.put<IUser>(`${baseRoute}/${id}`, data).then(({ data }) => data);
};

export const deleteUsers = async (id: number): Promise<IUser> => {
  return await httpApi.delete<IUser>(`${baseRoute}/${id}`).then(({ data }) => data);
};
