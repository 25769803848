/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { Player } from '@app/infrastructure/classes/player';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';
import useInternalTransfers from '@app/infrastructure/hooks/use-internal-transfers';
import { internalTransferTableColumns } from './cols';
import { IInternalTransferFilters } from '@app/infrastructure/interfaces/i-internal-transfer';
import InternalTransfersUpdateForm from '@app/components/internal-transfers/update-form';
import BaseTableTitle from '@app/components/common/base-table-title';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { getInternalTransferPlayerFilters } from '@app/infrastructure/services/filters-storage.service';
import InternalTransferTablePlayerFilters from '../filters';

interface InternalTransfersTableProps {
  player: Player;
}

const InternalTransfersPlayerTable: React.FC<InternalTransfersTableProps> = (props) => {
  const { player } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedInternalTransfer, setSelectedInternalTransfer] = useState<InternalTransfer | null>(null);
  const [filters, setFilters] = useState<IInternalTransferFilters | undefined>();

  const { internalTransfers, meta, loading, loadInternalTransfersByPlayer, deleteInternalTransfer } =
    useInternalTransfers({
      player,
    });
  const { updatePlayerInfoById } = usePlayers();
  const { t } = useTranslation();
  const { key, rerender } = useRerender();
  const modalTitle = selectedInternalTransfer && t('labels.updateInternalTransfer');

  const hasPagination = meta.total > meta.limit;

  const user = useAppSelector((state) => state.user?.user);

  const onReload = async () => {
    await loadInternalTransfersByPlayer({ filters, reload: true });
  };

  const onEdit = async (record: InternalTransfer) => {
    setSelectedInternalTransfer(record);
    setModalOpened(true);
  };

  const onDelete = async (record: InternalTransfer) => {
    await deleteInternalTransfer(record.id);
    await updatePlayerInfoById(player.id, player);
    rerender();
    await onReload();
  };

  const onClose = () => {
    setSelectedInternalTransfer(null);
    setModalOpened(false);
  };

  const onSave = async () => {
    setSelectedInternalTransfer(null);
    setModalOpened(false);
    await updatePlayerInfoById(player.id, player);
    rerender();
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<InternalTransfer> | SorterResult<InternalTransfer>[],
  ) => {
    await loadInternalTransfersByPlayer({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<InternalTransfer>(sorter as IModifiedSorterResult<InternalTransfer>, 'created_at', 'DESC'),
      reload: true,
      filters,
    });
  };

  useEffect(() => {
    const storageFilters = getInternalTransferPlayerFilters();
    if (storageFilters && filters) {
      onReload();
    }
    if (!storageFilters) {
      onReload();
    }
  }, [filters, key]);

  return (
    <>
      <BaseTable
        rowKey="id"
        key={key}
        title={() => {
          return (
            <>
              <BaseTableTitle onReload={onReload} size="small" />
              <InternalTransferTablePlayerFilters player={player} onFilterChange={(filters) => setFilters(filters)} />
            </>
          );
        }}
        columns={internalTransferTableColumns({
          user,
          onDelete,
          onEdit,
        })}
        dataSource={internalTransfers}
        pagination={
          !loading &&
          hasPagination && {
            current: meta.page,
            pageSize: meta.limit,
            total: meta.total,
            showSizeChanger: true,
          }
        }
        loading={loading}
        onChange={handleTableChange}
        size="small"
        scroll={{ x: 1000 }}
        bordered
      />
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} destroyOnClose footer={null}>
        {selectedInternalTransfer && (
          <InternalTransfersUpdateForm data={selectedInternalTransfer} onClose={onClose} onSave={onSave} />
        )}
      </BaseModal>
    </>
  );
};

export default InternalTransfersPlayerTable;
