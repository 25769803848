/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { RangePickerProps } from 'antd/lib/date-picker';
import { IFlcraftFilters } from '@app/infrastructure/interfaces/i-flcraft';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import { setFlcraftFilters } from '@app/infrastructure/services/filters-storage.service';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { prepareDateRange } from '@app/infrastructure/antd/functions/prepare-date-range';
import BaseRangeNumberInput, { rangeValue } from '@app/components/common/base-range-number-input';
import { debouncedFunction } from '@app/infrastructure/functions/debounce-function';

interface IFilterData {
  dateRange: RangePickerProps['value'];
  name: string | undefined;
  buyinRange: rangeValue;
}

interface IFlcraftTableFiltersProps {
  filters: IFlcraftFilters;
  onFilterChange: (filters: IFlcraftFilters) => void;
}

const FlcraftTableFilters: React.FC<IFlcraftTableFiltersProps> = (props) => {
  const { filters, onFilterChange } = props;
  const { t } = useTranslation();

  const [data, setData] = useState<IFilterData>({
    dateRange: prepareDateRange(filters.fromDate, filters.toDate),
    name: filters.tournamentName,
    buyinRange: [filters.fromBuyin, filters.toBuyin],
  });

  const debouncedInputChange = debouncedFunction((inputValue: rangeValue) => {
    const [val1, val2] = inputValue;
    setData({ ...data, buyinRange: inputValue });
    changeFilters({ ...filters, fromBuyin: val1, toBuyin: val2 });
  });

  const onDateChange: RangePickerProps['onChange'] = (dateRange) => {
    const [fromDate, toDate] = dateRange || [];
    setData({ ...data, dateRange });

    changeFilters({
      ...filters,
      fromDate: fromDate?.format(MomentFormats['MM.DD.YYYY']),
      toDate: toDate?.format(MomentFormats['MM.DD.YYYY']),
    });
  };

  const debouncedNameChange = useCallback(
    debouncedFunction((value: string | undefined) => {
      changeFilters({ ...filters, tournamentName: value });
    }),
    [],
  );

  const handleNameChange = (value: string | undefined) => {
    setData({ ...data, name: value });
    debouncedNameChange(value);
  };

  const changeFilters = (filters: IFlcraftFilters) => {
    setFlcraftFilters(filters);
    onFilterChange(filters);
  };

  return (
    <BaseRow gutter={30}>
      <BaseCol>
        <DatePicker.RangePicker size="small" value={data.dateRange} onChange={onDateChange} />
      </BaseCol>
      <BaseCol>
        <BaseInput
          size="small"
          value={data.name}
          placeholder={t('labels.tournament')}
          onChange={(e) => handleNameChange(e.target.value)}
        />
      </BaseCol>
      <BaseCol>
        <BaseRangeNumberInput
          value={data.buyinRange}
          placeholder={t('labels.bi')}
          onChange={(inputValue) => debouncedInputChange(inputValue)}
        />
      </BaseCol>
    </BaseRow>
  );
};

export default FlcraftTableFilters;
