import { httpApi } from '@app/api/http.api';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { ICreateTournament, ITournament } from '@app/infrastructure/interfaces/i-tournament';

const route = 'tournaments';

export const getTournaments = async (params: IPaginationMeta): Promise<IPaginatedData<ITournament>> => {
  return await httpApi.get<IPaginatedData<ITournament>>(route, { params }).then(({ data }) => data);
};

export const createTournaments = async (data: ICreateTournament): Promise<ITournament> => {
  return await httpApi.post<ITournament>(route, data).then(({ data }) => data);
};

export const deleteTournaments = async (groupId: string): Promise<ITournament> => {
  return await httpApi.delete<ITournament>(`${route}/group/${groupId}`).then(({ data }) => data);
};
