import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import useAnalytics from '@app/infrastructure/hooks/use-analytics';
import { ICreateAnalytics } from '@app/infrastructure/interfaces/i-analytics';
import StageSelect from '../stage-select-box';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { Player } from '@app/infrastructure/classes/player';
import { Colors } from '@app/infrastructure/enums/colors';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseTag } from '@app/components/common/BaseTag/BaseTag';

interface IAnalyticsCreateFormProps {
  player: Player;
  onClose?: () => void;
  onSave?: () => void;
}

const AnalyticsCreateForm: React.FC<IAnalyticsCreateFormProps> = (props) => {
  const { player, onClose, onSave } = props;
  const { t } = useTranslation();

  const { createAnalytics, loading } = useAnalytics({ player });

  const onFinish = async (values: ICreateAnalytics) => {
    if (values) {
      await createAnalytics(values);
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item
        name="stage"
        label={t('labels.stage')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <StageSelect />
      </BaseButtonsForm.Item>

      <BaseRow justify={'space-between'}>
        <S.NumberInput
          name="number_of_hands"
          label={t('labels.numberOfHands')}
          rules={[{ type: 'integer', min: 0, message: t('messages.enterInt') }]}
        >
          <InputNumber min={0} />
        </S.NumberInput>
        <S.NumberInput name="abi" label={t('labels.abi')} rules={[{ type: 'number', min: 0 }]}>
          <InputNumber min={0} />
        </S.NumberInput>
      </BaseRow>

      <BaseRow justify={'space-between'}>
        <S.NumberInput name="ev" label={t('labels.ev')} rules={[{ type: 'number' }]}>
          <InputNumber />
        </S.NumberInput>
        <S.NumberInput name="net" label={t('labels.net')} rules={[{ type: 'number' }]}>
          <InputNumber />
        </S.NumberInput>
      </BaseRow>
      <BaseButtonsForm.Item name="period" label={t('labels.period')}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item name="color" label={t('labels.color')} rules={[{ required: true }]}>
        <BaseRadio.Group>
          <BaseRadio value={Colors.Blue}>
            <BaseTag color="blue">{t('labels.blue')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Green}>
            <BaseTag color="green">{t('labels.green')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Yellow}>
            <BaseTag color="gold">{t('labels.orange')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Red}>
            <BaseTag color="red">{t('labels.red')}</BaseTag>
          </BaseRadio>
        </BaseRadio.Group>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default AnalyticsCreateForm;
