/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { Analytics } from '@app/infrastructure/classes/analytics';
import useAnalytics from '@app/infrastructure/hooks/use-analytics';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { analyticsTableColumns } from './cols';
import { Player } from '@app/infrastructure/classes/player';
import AnalyticsCreateForm from '../create-form';
import AnalyticsUpdateForm from '../update-form';

interface AnalyticsTableProps {
  player: Player;
}

const AnalyticsTable: React.FC<AnalyticsTableProps> = (props) => {
  const { player } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedAnalytics, setSelectedAnalytics] = useState<Analytics | null>(null);
  const { analytics, meta, loading, loadAnalyticsByPlayer, deleteAnalytics } = useAnalytics({ player });
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user?.user);

  const modalTitle = selectedAnalytics
    ? `${t('labels.update')} ${t(`analyticsStages.${selectedAnalytics.stage}`)}`
    : t('labels.createAnalytics');

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadAnalyticsByPlayer({ reload: true });
  };

  const onCreate = () => {
    setSelectedAnalytics(null);
    setModalOpened(true);
  };

  const onEdit = async (record: Analytics) => {
    setSelectedAnalytics(record);
    setModalOpened(true);
  };

  const onDelete = async (record: Analytics) => {
    await deleteAnalytics(record.id);
    await onReload();
  };

  const onClose = () => {
    setSelectedAnalytics(null);
    setModalOpened(false);
  };

  const onSave = async () => {
    setSelectedAnalytics(null);
    setModalOpened(false);
    await onReload();
  };

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    await loadAnalyticsByPlayer({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      reload: true,
    });
  };

  useEffect(() => {
    loadAnalyticsByPlayer();
  }, []);

  return (
    <>
      <div>
        <S.StyledBaseTable
          rowKey="id"
          rowClassName={(record: Analytics) => record.color.replace('#', 'color-')}
          title={() => {
            return (
              <BaseTableTitle
                canCreate={user?.canCreate('analyticsPermission')}
                onCreate={onCreate}
                onReload={onReload}
                size="small"
              />
            );
          }}
          columns={analyticsTableColumns({
            canEdit: user?.canUpdate('analyticsPermission'),
            canDelete: user?.fullAccess('analyticsPermission'),
            onDelete,
            onEdit,
          })}
          dataSource={analytics}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          size="small"
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} destroyOnClose footer={null}>
        {selectedAnalytics ? (
          <AnalyticsUpdateForm player={player} data={selectedAnalytics} onClose={onClose} onSave={onSave} />
        ) : (
          <AnalyticsCreateForm player={player} onClose={onClose} onSave={onSave} />
        )}
      </BaseModal>
    </>
  );
};

export default AnalyticsTable;
