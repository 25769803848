import { useState } from 'react';
import { ILoadProps } from './interfaces/i-load-props';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HTTP_STATUSES } from '../consts/http-statuses';
import { Rank } from '../classes/rank';
import { ICreateRank, IUpdateRank } from '../interfaces/i-rank';
import { createRanks, deleteRanks, getRanks, updateRanks } from '@app/api/ranks';

type UseRanks = {
  ranks: Rank[];
  meta: IPaginationMeta;
  loadRanks: (props?: ILoadProps) => Promise<Rank[] | undefined>;
  createRank: (data: ICreateRank) => Promise<Rank | undefined>;
  updateRank: (id: number, data: IUpdateRank) => Promise<Rank | undefined>;
  deleteRank: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseRanksProps {
  meta?: IPartialPaginationMeta;
}

const useRanks = (props?: IUseRanksProps): UseRanks => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ranks, setRanks] = useState<Rank[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadRanks = async (props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);

    const { results, meta: metaRes } = await getRanks({ ...meta, ...props });

    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new Rank(el));
      if (props?.reload) setRanks(data);
      else setRanks([...ranks, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getRanks({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new Rank(el));
        if (props?.reload) setRanks(data);
        else setRanks([...ranks, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const createRank = async (data: ICreateRank) => {
    setLoading(true);
    const result = await createRanks(data)
      .then((data) => {
        notificationController.success({
          message: t('messages.successAdd'),
        });
        return data;
      })
      .catch(({ options }) => {
        if (options.statusCode === HTTP_STATUSES.CONFLICT) {
          return notificationController.error({
            message: t('messages.rankUsed'),
          });
        } else {
          notificationController.error({ message: options.message });
        }
        setLoading(false);
      });
    setLoading(false);
    if (result) {
      return new Rank(result);
    }
  };

  const updateRank = async (id: number, data: IUpdateRank) => {
    setLoading(true);
    const result = await updateRanks(id, data).then((data) => {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      setLoading(false);
      return data;
    });
    return new Rank(result);
  };

  const deleteRank = async (id: number) => {
    setLoading(true);
    await deleteRanks(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  return {
    ranks,
    meta,
    loading,
    loadRanks,
    createRank,
    updateRank,
    deleteRank,
  };
};

export default useRanks;
