import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { Bill } from '@app/infrastructure/classes/bill';
import NameCell from './name-cell';
import TypeCell from './type-cell';
import { IUseTableColumnSearch } from '@app/infrastructure/antd/hooks/use-table-column-search';

interface IBillColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: Bill) => void;
  onDelete: (data: Bill) => void;
  columnSearchProps: IUseTableColumnSearch['columnSearchProps'];
}

export const billsTableColumns = ({
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  columnSearchProps,
}: IBillColumnsProps): IModifiedTableColumn<Bill>[] => {
  const columns: IModifiedTableColumn<Bill>[] = [
    {
      title: `${i18next.t('labels.contentName')}`,
      dataIndex: 'name',
      sortField: 'name',
      sorter: true,
      ...columnSearchProps('name'),
      render: (_, data: Bill) => {
        return <NameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.type')}`,
      dataIndex: 'type',
      sortField: 'type',
      sorter: true,
      render: (_, data: Bill) => {
        return <TypeCell data={data} />;
      },
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '150px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          canEdit={canEdit}
          canDelete={canDelete}
          onDelete={() => {
            onDelete(record);
          }}
          onEdit={() => {
            onEdit(record);
          }}
          size="small"
        />
      ),
    });
  }

  return columns;
};
