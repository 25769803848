import React from 'react';
import moment from 'moment-timezone';
import { Tournament } from '@app/infrastructure/classes/tournament';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';

interface IStartDateColProps {
  data: Tournament;
  isPlayer: boolean;
}

const StartDateCol: React.FC<IStartDateColProps> = ({ data, isPlayer }) => {
  const time = data.startTime.clone();

  if (isPlayer) {
    const localTimeZone = moment.tz.guess();
    time.tz(localTimeZone);
  }

  return <div>{time.format(MomentFormats['dd DD.MM.YYYY'])}</div>;
};

export default StartDateCol;
