import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import { Link } from 'react-router-dom';
import { ROUTES } from '@app/infrastructure/consts/routes';

interface ILoginCellProps {
  data: InternalTransferRequest;
}

const LoginCell: React.FC<ILoginCellProps> = (props) => {
  const { data } = props;
  return (
    <div>
      <BaseTypography>
        {data.playersBill?.player?.id ? (
          <Link to={`${ROUTES.PLAYERS}/${data.playersBill?.player?.id}`}>{data.user.login}</Link>
        ) : (
          data.user.login
        )}
      </BaseTypography>
    </div>
  );
};

export default LoginCell;
