/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import useRanks from '@app/infrastructure/hooks/use-ranks';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { ranksTableColumns } from './cols';
import RanksCreateForm from '../create-form';
import { Rank } from '@app/infrastructure/classes/rank';
import RanksUpdateForm from '../update-form';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import LimitsTable from '@app/components/limits/table';

const RanksTable: React.FC = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedRank, setSelectedRank] = useState<Rank | null>(null);
  const [selectLimit, setSelectLimit] = useState<boolean>(false);
  const { ranks, meta, loading, loadRanks, deleteRank } = useRanks();
  const { t } = useTranslation();

  const hasPagination = meta.total > meta.limit;

  const user = useAppSelector((state) => state.user?.user);

  const getModalTitle = () => {
    if (selectedRank && !selectLimit) {
      return `${t('labels.update')} ${selectedRank.name}`;
    }
    if (!selectedRank) {
      return t('labels.createRank');
    }
    if (selectLimit) {
      return `${t('labels.limitForRank')} ${selectedRank.name}`;
    }
  };

  const onReload = async () => {
    await loadRanks({ reload: true });
  };

  const onCreate = () => {
    setSelectedRank(null);
    setModalOpened(true);
  };

  const onEdit = async (record: Rank) => {
    setSelectedRank(record);
    setModalOpened(true);
  };

  const onSelectLimit = async (record: Rank) => {
    setSelectedRank(record);
    setSelectLimit(true);
    setModalOpened(true);
  };

  const onDelete = async (record: Rank) => {
    await deleteRank(record.id);
    await onReload();
  };

  const onClose = () => {
    setSelectedRank(null);
    setSelectLimit(false);
    setModalOpened(false);
  };

  const onSave = async () => {
    setSelectedRank(null);
    setModalOpened(false);
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Rank> | SorterResult<Rank>[],
  ) => {
    let search: [string, string] | undefined = undefined;
    if (filters['name']) {
      search = ['name', `${filters['name'][0]}`];
    }
    await loadRanks({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<Rank>(sorter as IModifiedSorterResult<Rank>),
      reload: true,
      search,
    });
  };

  useEffect(() => {
    loadRanks();
  }, []);

  return (
    <>
      <div>
        <BaseTable
          size="small"
          rowKey="id"
          title={() => {
            return (
              <BaseTableTitle
                title={t('navigation.ranks')}
                canCreate={user?.canCreate('rankPermission')}
                onCreate={onCreate}
                onReload={onReload}
                size="small"
              />
            );
          }}
          columns={ranksTableColumns({
            canEdit: user?.canUpdate('rankPermission'),
            canDelete: user?.fullAccess('rankPermission'),
            onDelete,
            onEdit,
            onSelectLimit,
          })}
          dataSource={ranks}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1000 }}
          bordered
        />
      </div>
      <BaseModal
        width={selectLimit ? 800 : 600}
        title={getModalTitle()}
        open={modalOpened}
        onCancel={onClose}
        destroyOnClose
        footer={null}
      >
        {selectedRank && !selectLimit && <RanksUpdateForm data={selectedRank} onClose={onClose} onSave={onSave} />}
        {!selectedRank && <RanksCreateForm onClose={onClose} onSave={onSave} />}
        {selectLimit && selectedRank && <LimitsTable rank={selectedRank} />}
      </BaseModal>
    </>
  );
};

export default RanksTable;
