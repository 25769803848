import React from 'react';
import * as S from '../styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Analytics } from '@app/infrastructure/classes/analytics';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import StageSelect from '../stage-select-box';
import { IUpdateAnalytics } from '@app/infrastructure/interfaces/i-analytics';
import useAnalytics from '@app/infrastructure/hooks/use-analytics';
import { Player } from '@app/infrastructure/classes/player';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { Colors } from '@app/infrastructure/enums/colors';
import { BaseTag } from '@app/components/common/BaseTag/BaseTag';

interface IAnalyticsUpdateFormProps {
  player: Player;
  data: Analytics;
  onClose?: () => void;
  onSave?: () => void;
}

const AnalyticsUpdateForm: React.FC<IAnalyticsUpdateFormProps> = (props) => {
  const { player, data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { id, stage, numberOfHands, abi, ev, net, period, color } = data;

  const { updateAnalytics, loading } = useAnalytics({ player });

  const onFinish = async (values: IUpdateAnalytics) => {
    if (values) {
      await updateAnalytics(id, values);
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item name="stage" label={t('labels.stage')} initialValue={stage}>
        <StageSelect />
      </BaseButtonsForm.Item>

      <BaseRow justify={'space-between'}>
        <S.NumberInput
          name="number_of_hands"
          label={t('labels.numberOfHands')}
          initialValue={numberOfHands}
          rules={[{ type: 'integer', min: 0, message: t('messages.enterInt') }]}
        >
          <InputNumber min={0} />
        </S.NumberInput>
        <S.NumberInput name="abi" label={t('labels.abi')} initialValue={abi} rules={[{ type: 'number', min: 0 }]}>
          <InputNumber min={0} />
        </S.NumberInput>
      </BaseRow>

      <BaseRow justify={'space-between'}>
        <S.NumberInput name="ev" label={t('labels.ev')} initialValue={ev} rules={[{ type: 'number' }]}>
          <InputNumber />
        </S.NumberInput>
        <S.NumberInput name="net" label={t('labels.net')} initialValue={net} rules={[{ type: 'number' }]}>
          <InputNumber />
        </S.NumberInput>
      </BaseRow>
      <BaseButtonsForm.Item name="period" label={t('labels.period')} initialValue={period}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item name="color" label={t('labels.color')} rules={[{ required: true }]} initialValue={color}>
        <BaseRadio.Group>
          <BaseRadio value={Colors.Blue}>
            <BaseTag color="blue">{t('labels.blue')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Green}>
            <BaseTag color="green">{t('labels.green')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Yellow}>
            <BaseTag color="gold">{t('labels.orange')}</BaseTag>
          </BaseRadio>
          <BaseRadio value={Colors.Red}>
            <BaseTag color="red">{t('labels.red')}</BaseTag>
          </BaseRadio>
        </BaseRadio.Group>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default AnalyticsUpdateForm;
