import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import styled from 'styled-components';

export const CaculateFormButton = styled(BaseButton)`
  margin-left: auto;
`;

export const CaculateFormCheckbox = styled(BaseCheckbox)`
  margin-bottom: 20px;
`;
