import { useTranslation } from 'react-i18next';
import * as S from './personal-info.styles';
import { Player } from '@app/infrastructure/classes/player';
import AppBaseCard from '@app/components/common/base-card';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import SaveButton from '@app/components/common/buttons/save-button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import RanksSelectBox, { IRankSelectBoxItemProps } from '@app/components/ranks/select-box';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import DeletePlayer from '../delete';
import { ROUTES } from '@app/infrastructure/consts/routes';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/infrastructure/hooks/redux';

interface PersonalInfoProps {
  player: Player;
}

interface IFormValues {
  rank: IRankSelectBoxItemProps;
  login: string;
  password: string;
}

const PersonalInfo: React.FC<PersonalInfoProps> = (props) => {
  const { player } = props;

  const { t } = useTranslation();
  const { updatePlayer, updatePlayerInfoById, loading } = usePlayers();
  const [form] = BaseButtonsForm.useForm();
  const { rerender } = useRerender();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user?.user);

  const canDelete = user?.fullAccess('playerPermission');
  const canEdit = user?.canUpdate('playerPermission');

  const onFinish = async (values: IFormValues) => {
    await updatePlayer(
      player.id,
      {
        rank_id: values.rank?.record?.id,
        login: values.login,
        password: values.password,
      },
      player,
    );
    await updatePlayerInfoById(player.id, player);
    rerender();
  };

  const onConfirm = () => form.submit();

  const onDelete = () => {
    navigate(ROUTES.PLAYERS);
  };

  return (
    <AppBaseCard
      title={t('labels.personalInformation')}
      extra={canDelete && <DeletePlayer player={player} onDelete={onDelete} />}
    >
      <S.PersonalForm
        form={form}
        isFieldsChanged={true}
        layout="vertical"
        name="updatePlayerForm"
        onFinish={onFinish}
        autoComplete="off"
        footer={<SaveButton onCorfim={onConfirm} />}
        loading={loading}
        disabled={!canEdit}
      >
        <S.PersonalInfoSpace>
          <S.PersonalInfoFormItem
            name="login"
            label={t('labels.login')}
            initialValue={player?.user?.login}
            rules={[{ min: 2, message: t('messages.minCharacters') }]}
          >
            <BaseInput />
          </S.PersonalInfoFormItem>
          <S.PersonalInfoFormItem
            name="password"
            label={t('labels.password')}
            rules={[{ min: 2, message: t('messages.minCharacters') }]}
          >
            <BaseInput />
          </S.PersonalInfoFormItem>
        </S.PersonalInfoSpace>
        <S.PersonalInfoFormItem name="rank" label={t('labels.rank')} initialValue={player.rank?.name}>
          <RanksSelectBox />
        </S.PersonalInfoFormItem>
      </S.PersonalForm>
    </AppBaseCard>
  );
};

export default PersonalInfo;
