import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './button.styles';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { StopOutlined } from '@ant-design/icons';

interface CancelIconButtonProps {
  cancel: () => void;
}

const CancelIconButton: React.FC<CancelIconButtonProps> = ({ cancel }) => {
  const { t } = useTranslation();

  return (
    <BaseTooltip title={t('controls.cancel')}>
      <S.ActivityButton size="small" type="default" onClick={cancel}>
        <StopOutlined />
      </S.ActivityButton>
    </BaseTooltip>
  );
};

export default CancelIconButton;
