import i18next from 'i18next';
import { STATUS_GAME_REQUEST } from '../enums/status-game-request.enum';

export const statusGameRequestDataSource = (): {
  id: STATUS_GAME_REQUEST;
  label: string;
}[] => [
  {
    id: STATUS_GAME_REQUEST.IN_REVIEW,
    label: i18next.t('labels.inReview'),
  },
  {
    id: STATUS_GAME_REQUEST.IN_PACKAGE,
    label: i18next.t('labels.inPackage'),
  },
  {
    id: STATUS_GAME_REQUEST.PLAYER_GAME,
    label: i18next.t('labels.byYourself'),
  },
];
