import { useState } from 'react';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ILoadProps } from './interfaces/i-load-props';
import { Bill } from '../classes/bill';
import { ICreateBill, IUpdateBill } from '../interfaces/i-bill';
import { createBills, deleteBills, getBills, getBillById, updateBills } from '@app/api/bills';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HttpStatus } from '../enums/http-status';
import { HTTP_STATUSES } from '../consts/http-statuses';

type UseBills = {
  bills: Bill[];
  meta: IPaginationMeta;
  loadBills: (props?: ILoadProps) => Promise<Bill[] | undefined>;
  createBill: (data: ICreateBill) => Promise<Bill | void>;
  loadBillById: (id: number) => Promise<Bill | void>;
  updateBill: (id: number, data: IUpdateBill, bill: Bill) => Promise<Bill | void>;
  deleteBill: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseBillsProps {
  meta?: IPartialPaginationMeta;
}

const useBills = (props?: IUseBillsProps): UseBills => {
  const [loading, setLoading] = useState<boolean>(false);
  const [bills, setBills] = useState<Bill[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadBills = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getBills({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new Bill(el));
      if (props?.reload) setBills(data);
      else setBills([...bills, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getBills({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new Bill(el));
        if (props?.reload) setBills(data);
        else setBills([...bills, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadBillById = async (id: number) => {
    setLoading(true);
    const data = await getBillById(id).catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    setLoading(false);
    if (data) return new Bill(data);
  };

  const createBill = async (data: ICreateBill) => {
    setLoading(true);
    const result = await createBills(data).catch((error) => {
      if (error.options.statusCode === HttpStatus.CONFLICT) {
        return notificationController.error({
          message: t('messages.billUsed'),
        });
      }
    });

    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successAdd'),
      });
      return new Bill(result);
    }
  };

  const updateBill = async (id: number, data: IUpdateBill, bill: Bill) => {
    setLoading(true);
    const result = await updateBills(id, data)
      .then((data) => {
        notificationController.success({
          message: t('messages.successUpdate'),
        });
        setLoading(false);
        return data;
      })
      .catch(({ options }) => {
        if (options.statusCode === HTTP_STATUSES.BAD_REQUEST) {
          return notificationController.error({
            message: t('messages.notCorrectTimeShift'),
          });
        }
      });

    if (result) {
      bill.updateInfo(result);
      return new Bill(result);
    }
  };

  const deleteBill = async (id: number) => {
    setLoading(true);
    await deleteBills(id)
      .then(() => {
        notificationController.success({
          message: t('messages.successDelete'),
        });
        setLoading(false);
      })
      .catch((error) => {
        if (error.options.statusCode === HttpStatus.CONFLICT) {
          return notificationController.error({
            message: t('messages.billUsedPlayersOrTournaments'),
          });
        } else {
          notificationController.error({ message: error.message });
        }
      });
  };

  return {
    bills,
    meta,
    loading,
    loadBills,
    loadBillById,
    createBill,
    updateBill,
    deleteBill,
  };
};

export default useBills;
