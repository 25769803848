import styled from 'styled-components';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';

export const BaseFormItem = styled(BaseForm.Item)`
  margin: 0;

  .ant-col.ant-form-item-label {
    text-align: left;
    .ant-form-item-required::before {
      display: none;
    }
  }
  .ant-form-item-explain {
    display: none;
  }

  .ant-select .ant-select-selector {
    border-radius: 7px !important;
  }
`;
