import i18next from 'i18next';
import { BILL_TYPES } from '../enums/bill-types.enum';

export const billTypesDataSource = (): {
  id: BILL_TYPES;
  label: string;
}[] => [
  {
    id: BILL_TYPES.BILL,
    label: i18next.t('labels.bill'),
  },
  {
    id: BILL_TYPES.ROOM,
    label: i18next.t('labels.room'),
  },
];
