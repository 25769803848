import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import LimitCreateRow from './create-row';
import { Rank } from '@app/infrastructure/classes/rank';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import LimitCreateCol from './create-col';

interface ILimitsTableHeaderProps {
  colNames: { [key: string]: string };
  rank: Rank;
  onSave: () => void;
}

const LimitsTableHeader: React.FC<ILimitsTableHeaderProps> = (props) => {
  const { onSave, colNames, rank } = props;
  const { t } = useTranslation();
  const [isCreatingLimit, setIsCreatingLimit] = useState<boolean>(false);
  const [isCreatingCol, setIsCreatingCol] = useState<boolean>(false);
  const [key, setKey] = useState<number>(Date.now());

  const onClose = () => {
    setIsCreatingLimit(false);
    setIsCreatingCol(false);
    setKey(Date.now());
  };
  const onSaved = () => {
    onClose();
    onSave();
  };

  return (
    <BaseRow justify="space-between">
      <BaseCol span={24}>
        {isCreatingLimit ? (
          <LimitCreateRow key={key} colNames={colNames} rank={rank} onClose={onClose} onSaved={onSaved} />
        ) : (
          !isCreatingCol && (
            <BaseButton size="small" type="text" icon={<PlusCircleOutlined />} onClick={() => setIsCreatingLimit(true)}>
              {t('controls.addRoom')}
            </BaseButton>
          )
        )}
      </BaseCol>
      <BaseCol span={24}>
        {isCreatingCol ? (
          <LimitCreateCol key={key} colNames={colNames} onClose={onClose} onSaved={onSaved} />
        ) : (
          !isCreatingLimit && (
            <BaseButton size="small" type="text" icon={<PlusCircleOutlined />} onClick={() => setIsCreatingCol(true)}>
              {t('controls.addCol')}
            </BaseButton>
          )
        )}
      </BaseCol>
    </BaseRow>
  );
};

export default LimitsTableHeader;
