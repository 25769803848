/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { RangePickerProps } from 'antd/lib/date-picker';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import { IPersonalTransferFilters } from '@app/infrastructure/interfaces/i-personal-transfer';
import * as S from './styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import PlayersBillSelectBox, { IPlayersBillSelectBoxItemProps } from '../../bills/select-box';
import { Player } from '@app/infrastructure/classes/player';
import {
  getPersonalTransferPlayerFilters,
  setPersonalTransferPlayerFilters,
} from '@app/infrastructure/services/filters-storage.service';
import BaseRangeNumberInput, { rangeValue } from '@app/components/common/base-range-number-input';

interface IPersonalTransferTableFiltersProps {
  player: Player;
  onFilterChange: (filters: IPersonalTransferFilters) => void;
}

const PersonalTransferForPlayerFilters: React.FC<IPersonalTransferTableFiltersProps> = (props) => {
  const { player, onFilterChange } = props;
  const { t } = useTranslation();

  const [bill, setBill] = useState<IPlayersBillSelectBoxItemProps | undefined>(undefined);
  const [dateRange, setDateRange] = useState<RangePickerProps['value']>(undefined);
  const [amountRange, setAmountRange] = useState<rangeValue>([undefined, undefined]);
  const [filters, setFilters] = useState<IPersonalTransferFilters>({});

  const changeFilters = useCallback(
    (filters: IPersonalTransferFilters) => {
      setPersonalTransferPlayerFilters({
        ...filters,
        bill,
      });
      setFilters(filters);
      onFilterChange(filters);
    },
    [amountRange, dateRange, bill?.key],
  );

  const handleInputChange = (inputValue: rangeValue) => {
    const [val1, val2] = inputValue;
    setAmountRange(inputValue);
    changeFilters({
      ...filters,
      fromAmount: val1,
      toAmount: val2,
    });
  };
  const debouncedInputChange = debounce(handleInputChange, 1000);

  const onDateChange: RangePickerProps['onChange'] = (values) => {
    setDateRange(values);
    let newFilters = {
      ...filters,
    };
    if (values) {
      const [fromDate, toDate] = values;
      newFilters = {
        ...filters,
        fromDate: fromDate?.format(MomentFormats['MM.DD.YYYY']),
        toDate: toDate?.format(MomentFormats['MM.DD.YYYY']),
      };
    } else {
      newFilters = {
        ...filters,
        fromDate: undefined,
        toDate: undefined,
      };
    }
    changeFilters(newFilters);
  };

  const onBillChange = (bill: IPlayersBillSelectBoxItemProps) => {
    setBill(bill);
    changeFilters({
      ...filters,
      billId: bill?.record?.bill.id ?? undefined,
    });
    setPersonalTransferPlayerFilters({
      ...filters,
      bill,
    });
  };

  useEffect(() => {
    const storageFilters = getPersonalTransferPlayerFilters();
    if (storageFilters) {
      const { fromDate, toDate, fromAmount, toAmount, bill } = storageFilters;

      if (bill) {
        setBill(bill);
      }

      const localFilters = { ...storageFilters, billId: bill?.record ? bill.record.bill.id : undefined };
      setPersonalTransferPlayerFilters({
        ...storageFilters,
        bill,
      });

      setFilters(localFilters);
      onFilterChange(localFilters);

      if (fromDate && toDate) {
        setDateRange([moment(fromDate), moment(toDate)]);
      }
      if (fromAmount || toAmount) {
        setAmountRange([fromAmount, toAmount]);
      }
    }
  }, []);

  return (
    <S.FilterRow gutter={[30, 20]}>
      <BaseCol>
        <PlayersBillSelectBox
          player={player}
          withDeleted
          width="200px"
          size="small"
          placeholder={t('labels.check')}
          onChange={onBillChange}
          value={bill}
        />
      </BaseCol>
      <BaseCol>
        <BaseRow gutter={10}>
          <BaseCol>
            <BaseRangeNumberInput
              value={amountRange}
              placeholder={t('labels.summ')}
              onChange={(inputValue) => debouncedInputChange(inputValue)}
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol>
        <DatePicker.RangePicker size="small" value={dateRange} onChange={onDateChange} />
      </BaseCol>
    </S.FilterRow>
  );
};

export default PersonalTransferForPlayerFilters;
