import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './button.styles';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { CheckCircleOutlined } from '@ant-design/icons';

interface SaveIconButtonProps {
  save: () => void;
}

const SaveIconButton: React.FC<SaveIconButtonProps> = ({ save }) => {
  const { t } = useTranslation();

  return (
    <BaseTooltip title={t('controls.save')}>
      <S.ActivityButton size="small" type="default" onClick={() => save()} id="save">
        <CheckCircleOutlined />
      </S.ActivityButton>
    </BaseTooltip>
  );
};

export default SaveIconButton;
