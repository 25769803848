import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import { IUpdateInternalTransferRequest } from '@app/infrastructure/interfaces/i-internal-transfer-request';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import StatusInternalTransferRequestSelect from '../status-select';
import { STATUS_INTERNAL_TRANSFER } from '@app/infrastructure/enums/status-internal-transfer.enum';

interface IInternalTransferRequestsUpdateProps {
  data: InternalTransferRequest;
  onClose?: () => void;
  onSave?: () => void;
}

const InternalTransferRequestsUpdateForm: React.FC<IInternalTransferRequestsUpdateProps> = (props) => {
  const { data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { id } = data;

  const { updateInternalTransferRequest, loading } = useInternalTransferRequests({ player: undefined });

  const onFinish = async (value: IUpdateInternalTransferRequest) => {
    updateInternalTransferRequest(id, value, data).then(() => {
      if (onSave) onSave();
    });
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item name="status" initialValue={data.status} label={t('labels.status')}>
        <StatusInternalTransferRequestSelect disabledValues={[STATUS_INTERNAL_TRANSFER.IN_REVIEW]} />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default InternalTransferRequestsUpdateForm;
