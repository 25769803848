import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { IUseTableColumnSearch } from '@app/infrastructure/antd/hooks/use-table-column-search';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import BillNameCell from '@app/components/internal-transfer-requests/table/cols/bill-name-cell';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import { STATUS_INTERNAL_TRANSFER } from '@app/infrastructure/enums/status-internal-transfer.enum';
import StatusCell from '@app/components/internal-transfer-requests/table/cols/status-cell';
import BillDeletedCell from '@app/components/internal-transfer-requests/table/cols/bill-deleted-cell';
import CloseCell from '@app/components/internal-transfer-requests/table/cols/close-cell';

interface IInternalTransferAllPlayerRequestColumnsProps {
  canDelete?: boolean;
  onDelete: (data: InternalTransferRequest) => void;
  onSave: () => void;
  columnSearchProps?: IUseTableColumnSearch['columnSearchProps'];
}

export const internalTransferAllPlayerRequestTableColumns = ({
  canDelete,
  onDelete,
  onSave,
}: IInternalTransferAllPlayerRequestColumnsProps): IModifiedTableColumn<InternalTransferRequest>[] => {
  const columns: IModifiedTableColumn<InternalTransferRequest>[] = [
    {
      title: `${i18next.t('labels.check')}`,
      dataIndex: 'name',
      sortField: 'players_bill.bill.name',
      sorter: true,
      render: (_, data: InternalTransferRequest) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.requestedAmount')}`,
      dataIndex: 'requestedAmount',
    },
    {
      title: `${i18next.t('labels.remainingAmount')}`,
      dataIndex: 'leftOver',
    },
    {
      title: `${i18next.t('labels.createDate')}`,
      dataIndex: 'createdAt',
      sortField: 'created_at',
      sorter: true,
      render: (_, record) => record.createdAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.updateDate')}`,
      dataIndex: 'updatedAt',
      sortField: 'updated_at',
      sorter: true,
      render: (_, record) => record.updatedAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.status')}`,
      dataIndex: 'status',
      sortField: 'status',
      sorter: true,
      render: (_, data: InternalTransferRequest) => {
        return <StatusCell data={data} />;
      },
    },
  ];

  if (canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '130px',
      align: 'center',
      render: (_, data: InternalTransferRequest) =>
        data.playersBill && data.playersBill.deleted_at === null ? (
          (data.status === STATUS_INTERNAL_TRANSFER.IN_REVIEW && (
            <BaseActionsCell
              canDelete={canDelete}
              onDelete={() => {
                onDelete(data);
              }}
              size="small"
            />
          )) ||
          (data.status === STATUS_INTERNAL_TRANSFER.APPROVED && <CloseCell data={data} onSave={onSave} />)
        ) : (
          <BillDeletedCell />
        ),
    });
  }

  return columns;
};
