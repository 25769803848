import { Permissions } from '../enums/permissions';
import { IAccessRight } from '../interfaces/i-access-right';

export class AccessRight {
  id: number;
  name: string;
  userPermission?: Permissions;
  playerPermission?: Permissions;
  billPermission?: Permissions;
  rankPermission?: Permissions;
  accessPermission?: Permissions;
  flcraftPermission?: Permissions;
  limitPermission?: Permissions;
  tournamentPermission?: Permissions;
  personalTransferPermission?: Permissions;
  internalTransferPermission?: Permissions;
  analyticsPermission?: Permissions;
  playerGamePermission?: Permissions;
  profitPackagePermission?: Permissions;
  overMoneyPermission?: Permissions;

  constructor(data: IAccessRight) {
    this.id = data.id;
    this.name = data.name;
    this.userPermission = data.user_permission;
    this.playerPermission = data.player_permission;
    this.billPermission = data.bill_permission;
    this.rankPermission = data.rank_permission;
    this.accessPermission = data.access_permission;
    this.flcraftPermission = data.flcraft_permission;
    this.tournamentPermission = data.tournament_permission;
    this.limitPermission = data.limit_permission;
    this.personalTransferPermission = data.personal_transfer_permission;
    this.internalTransferPermission = data.internal_transfer_permission;
    this.analyticsPermission = data.analytics_permission;
    this.playerGamePermission = data.player_game_permission;
    this.profitPackagePermission = data.profit_package_permission;
    this.overMoneyPermission = data.over_money_permission;
  }
}
