import moment from 'moment-timezone';
import { IFlcraft, IFlcraftPlayerStatistic } from '../interfaces/i-flcraft';
import { Player } from './player';

export class Flcraft {
  id: number;
  tournamentNumber: string;
  tournamentName: string;
  tournamentBuyin: number;
  prizeFund: number;
  playersCount: number;
  startDate: moment.Moment;
  playerName: string;
  playerPosition: number;
  playerPrizeAmount: number;
  reentry: number;
  profit: number;

  constructor(data: IFlcraft) {
    const [start_date] = data.start_date.split('T');

    this.id = data.id;
    this.tournamentNumber = data.tournament_number;
    this.tournamentName = data.tournament_name;
    this.tournamentBuyin = data.tournament_buyin;
    this.prizeFund = data.prize_fund;
    this.playersCount = data.players_count;
    this.startDate = moment(start_date);
    this.playerName = data.player_name;
    this.playerPosition = data.player_position;
    this.playerPrizeAmount = data.player_prize_amount;
    this.reentry = data.reentry;
    this.profit = +data.profit.toFixed(2);
  }
}

export class FlcraftPlayerStatistic {
  totalEntries: number;
  uniqueEntries: number;
  totalReentry: number;
  totalBuyin: number;
  abi: number;
  roi: number;
  reentryPercent: number;
  profit: number;
  player?: Player;
  playerId?: number;

  constructor({ statistic, player }: IFlcraftPlayerStatistic) {
    this.totalEntries = Number(statistic.total_entries);
    this.uniqueEntries = Number(statistic.unique_entries);
    this.totalReentry = Number(statistic.total_reentry);
    this.totalBuyin = +Number(statistic.total_buyin).toFixed(2) ?? 0;
    this.abi = +Number(statistic.abi).toFixed(2) ?? 0;
    this.roi = +Number(statistic.roi).toFixed(2) ?? 0;
    this.reentryPercent = +Number(statistic.reentry_percent).toFixed(2) ?? 0;
    this.profit = +Number(statistic.profit).toFixed(2) ?? 0;
    this.player = player ? new Player(player) : undefined;
    this.playerId = player ? player.id : undefined;
  }
}
