import i18next from 'i18next';
import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import { FlcraftPlayerStatistic } from '@app/infrastructure/classes/flcraft';
import PlayerLoginCell from './login';
import PlayerRankCell from './rank';

export const flcraftPlayerStatisticTableColumns = (): IModifiedTableColumn<FlcraftPlayerStatistic>[] => [
  {
    title: `${i18next.t('labels.playerLogin')}`,
    dataIndex: 'userPermission',
    sortField: 'user_permission',
    sorter: false,
    render: (_, record) => <PlayerLoginCell data={record} />,
  },
  {
    title: `${i18next.t('labels.rank')}`,
    dataIndex: 'userPermission',
    sortField: 'user_permission',
    sorter: false,
    render: (_, record) => <PlayerRankCell data={record} />,
  },
  {
    title: `${i18next.t('labels.totalEntries')}`,
    dataIndex: 'totalEntries',
    sortField: ':total_entries',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.uniqueEntries')}`,
    dataIndex: 'uniqueEntries',
    sortField: ':unique_entries',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.profit')}`,
    dataIndex: 'profit',
    sortField: ':profit',
    sorter: true,
    render: (data) => `${data} $`,
  },
  {
    title: `${i18next.t('labels.abi')}`,
    dataIndex: 'abi',
    sortField: ':abi',
    sorter: true,
    render: (data) => `${data} $`,
  },
  {
    title: `${i18next.t('labels.roi')}`,
    dataIndex: 'roi',
    sortField: ':roi',
    sorter: true,
    render: (data) => `${data} %`,
  },
  {
    title: `${i18next.t('labels.totalBuyin')}`,
    dataIndex: 'totalBuyin',
    sortField: ':total_buyin',
    sorter: true,
    render: (data) => `${data} $`,
  },
  {
    title: `${i18next.t('labels.reentry')}`,
    dataIndex: 'totalReentry',
    sortField: ':total_reentry',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.reentry')} %`,
    dataIndex: 'reentryPercent',
    sortField: ':reentry_percent',
    sorter: true,
    render: (data) => `${data} %`,
  },
];
