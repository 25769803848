import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';
import React from 'react';

const { Text } = BaseTypography;

interface INameCellProps {
  data: PlayerBill;
}

const NickNameCell: React.FC<INameCellProps> = (props) => {
  const { data } = props;
  return (
    <Text style={{ width: 74 }} ellipsis={{ tooltip: `${data.playerNickname}` }}>
      {data.playerNickname}
    </Text>
  );
};

export default NickNameCell;
