import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { IUseTableColumnSearch } from '@app/infrastructure/antd/hooks/use-table-column-search';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import ImageWithPreview from '@app/components/common/image';
import { PlayerGame } from '@app/infrastructure/classes/player-game';
import TournamentNameCell from '../../cells/tournament-name-cell';
import BillNameCell from '../../cells/bill-name-cell';
import TournamentBiCell from '../../cells/tournament-bi-cell';
import LoginCell from '../../cells/login-cell';
import TypeOfEntryCell from '../../cells/type-of-entry-cell';
import StatusCell from './status-cell';

interface IPlayerGameAdminInReviewColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  onSave: () => void;
  onDelete: (data: PlayerGame) => void;
  columnSearchProps?: IUseTableColumnSearch['columnSearchProps'];
}

export const playerGameAdminInReviewTableColumns = ({
  canDelete,
  onSave,
  onDelete,
}: IPlayerGameAdminInReviewColumnsProps): IModifiedTableColumn<PlayerGame>[] => {
  const columns: IModifiedTableColumn<PlayerGame>[] = [
    {
      title: `${i18next.t('labels.login')}`,
      dataIndex: 'login',
      sortField: 'player.user.login',
      sorter: true,
      width: '120px',
      render: (_, data: PlayerGame) => {
        return <LoginCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.date')}`,
      dataIndex: 'startDate',
      sortField: 'start_date',
      sorter: true,
      render: (_, record) => record.startDate.format(MomentFormats['dd DD.MM.YYYY']),
    },
    {
      title: `${i18next.t('labels.room')}`,
      dataIndex: 'name',
      sortField: 'bill.name',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.tournamentName')}`,
      dataIndex: 'tournamentName',
      sortField: 'tournament_name',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <TournamentNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.bi')}`,
      dataIndex: 'buyIn',
      sortField: 'buy_in',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <TournamentBiCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.bbQuantity')}`,
      dataIndex: 'bbQuantity',
      sortField: 'bb_quantity',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.entry')}`,
      dataIndex: 'typeOfEntry',
      sortField: 'type_of_entry',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <TypeOfEntryCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.comment')}`,
      dataIndex: 'description',
    },
    {
      title: `${i18next.t('labels.screen')}`,
      dataIndex: 'image',
      align: 'center',
      render: (_, data: PlayerGame) => {
        return <ImageWithPreview data={data.image} />;
      },
    },
    {
      title: `${i18next.t('labels.selectStatus')}`,
      dataIndex: 'status',
      width: '90px',
      render: (_, data: PlayerGame) => {
        return <StatusCell data={data} onSave={onSave} />;
      },
    },
  ];

  if (canDelete) {
    columns.push({
      title: `${i18next.t('controls.delete')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '120px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          canDelete={canDelete}
          onDelete={() => {
            onDelete(record);
          }}
          size="small"
        />
      ),
    });
  }

  return columns;
};
