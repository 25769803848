import React from 'react';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';

interface BillNameCellProps {
  data: InternalTransferRequest;
}

const BillNameCell: React.FC<BillNameCellProps> = ({ data }) => {
  return <span>{data.playersBill && `${data.playersBill.bill.name} ${data.playersBill.bill.currency.name}`}</span>;
};

export default BillNameCell;
