import styled from 'styled-components';

export const StatusLabel = styled.div`
  width: 80px;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
