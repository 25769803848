import React from 'react';
import { statusGameRequestDataSource } from '@app/infrastructure/data-sources/status-game-request';
import * as S from './styles';
import {
  backgroundStatusColor,
  borderStatusColor,
  fontStatusColor,
} from '@app/infrastructure/consts/status-player-game-colors';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { STATUS_GAME_REQUEST } from '@app/infrastructure/enums/status-game-request.enum';

interface StatusButtonProps {
  statusGameRequest: STATUS_GAME_REQUEST;
  onChangeStatus: (statusGameRequest: STATUS_GAME_REQUEST) => void;
  loading?: boolean;
}

const StatusButton: React.FC<StatusButtonProps> = (props) => {
  const { statusGameRequest, onChangeStatus, loading = false } = props;
  const status = statusGameRequestDataSource().find((item) => item.id === statusGameRequest);
  return (
    <BaseButton size="small" type="text" onClick={() => onChangeStatus(statusGameRequest)} loading={loading}>
      <S.StatusLabel
        style={
          status && {
            color: fontStatusColor[status.id],
            backgroundColor: backgroundStatusColor[status.id],
            borderColor: borderStatusColor[status.id],
          }
        }
      >
        {status?.label}
      </S.StatusLabel>
    </BaseButton>
  );
};

export default StatusButton;
