import React from 'react';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';

interface BillNameCellProps {
  data: InternalTransfer;
}

const BillNameCell: React.FC<BillNameCellProps> = ({ data }) => {
  return data.internalTransferRequest.playersBill ? (
    <span>
      {data.internalTransferRequest.playersBill.bill.name} {data.internalTransferRequest.playersBill.bill.currency.name}
    </span>
  ) : (
    <span>
      {data.playersBill?.bill.name} {data.playersBill?.bill.currency.name}
    </span>
  );
};

export default BillNameCell;
