import { useState } from 'react';
import { ILoadProps } from './interfaces/i-load-props';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { Player } from '../classes/player';
import {
  ICreateInternalTransfer,
  ICreateInternalTransferFromAdmin,
  ICreateInternalTransferToAdmin,
  IUpdateInternalTransfer,
} from '../interfaces/i-internal-transfer';
import {
  createInternalTransfers,
  createInternalTransfersAdmin,
  createInternalTransfersToAdmin,
  deleteInternalTransferById,
  getAllInternalTransfers,
  getInternalTransferById,
  getInternalTransfersByPlayer,
  updateInternalTransfers,
  updateInternalTransfersAdmin,
} from '@app/api/internal-transfers';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { InternalTransfer } from '../classes/internal-transfer';
import { HTTP_STATUSES } from '../consts/http-statuses';

type UseInternalTransfers = {
  internalTransfers: InternalTransfer[];
  meta: IPaginationMeta;
  loadAllInternalTransfers: (props?: ILoadProps) => Promise<InternalTransfer[] | undefined>;
  loadInternalTransfersByPlayer: (props?: ILoadProps) => Promise<InternalTransfer[] | undefined>;
  createInternalTransfer: (data: ICreateInternalTransfer) => Promise<InternalTransfer | void>;
  createInternalTransferAdmin: (data: ICreateInternalTransferFromAdmin) => Promise<InternalTransfer | void>;
  createInternalTransferToAdmin: (data: ICreateInternalTransferToAdmin) => Promise<InternalTransfer | void>;
  loadInternalTransferById: (id: number) => Promise<InternalTransfer | void>;
  updateInternalTransfer: (
    id: number,
    data: IUpdateInternalTransfer,
    InternalTransfer: InternalTransfer,
  ) => Promise<InternalTransfer | void>;
  updateInternalTransferAdmin: (
    id: number,
    data: IUpdateInternalTransfer,
    InternalTransfer: InternalTransfer,
  ) => Promise<InternalTransfer | void>;
  deleteInternalTransfer: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseInternalTransfersProps {
  player?: Player | undefined;
  userId?: number | undefined;
  meta?: IPartialPaginationMeta;
}

const useInternalTransfers = (props: IUseInternalTransfersProps): UseInternalTransfers => {
  const { player, userId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [internalTransfers, setInternalTransfers] = useState<InternalTransfer[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 5,
    sort: ['created_at', 'DESC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadAllInternalTransfers = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getAllInternalTransfers({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new InternalTransfer(el));
      if (props?.reload) setInternalTransfers(data);
      else setInternalTransfers([...internalTransfers, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getAllInternalTransfers({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new InternalTransfer(el));
        if (props?.reload) setInternalTransfers(data);
        else setInternalTransfers([...internalTransfers, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadInternalTransfersByPlayer = async (props?: ILoadProps) => {
    if (player) {
      setLoading(true);
      const { results, meta: metaRes } = await getInternalTransfersByPlayer(player.id, { ...meta, ...props });
      let data = undefined;
      if (results.length || metaRes.total === 0) {
        data = results.map((el) => new InternalTransfer(el));
        if (props?.reload) setInternalTransfers(data);
        else setInternalTransfers([...internalTransfers, ...data]);
        setMeta({
          ...meta,
          ...metaRes,
        });
      } else {
        let page = metaRes.page;
        if (metaRes.totalPages < page) page = metaRes.totalPages;
        const { results, meta: metaSecondReq } = await getInternalTransfersByPlayer(player.id, {
          ...meta,
          ...metaRes,
          page,
          ...props,
        });
        if (results) {
          data = results.map((el) => new InternalTransfer(el));
          if (props?.reload) setInternalTransfers(data);
          else setInternalTransfers([...internalTransfers, ...data]);
          setMeta({
            ...meta,
            ...metaSecondReq,
          });
        }
      }

      setLoading(false);
      return data;
    }
  };

  const loadInternalTransferById = async (id: number) => {
    if (player) {
      setLoading(true);
      const data = await getInternalTransferById(id).catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
      setLoading(false);
      if (data) return new InternalTransfer(data);
    }
  };

  const createInternalTransfer = async (data: ICreateInternalTransfer) => {
    if (player) {
      setLoading(true);
      const result = await createInternalTransfers(data).catch((err) => {
        if (err.options.statusCode === HTTP_STATUSES.CONFLICT) {
          return notificationController.error({
            message: t('messages.notEnoughMoney'),
          });
        } else {
          notificationController.error({ message: err.message });
        }
      });
      setLoading(false);
      if (result) {
        notificationController.success({
          message: t('messages.successAddInternalTransfer'),
        });
        return new InternalTransfer(result);
      }
    }
  };

  const createInternalTransferAdmin = async (data: ICreateInternalTransferFromAdmin) => {
    if (userId) {
      setLoading(true);
      const result = await createInternalTransfersAdmin(data).catch((err) => {
        notificationController.error({ message: err.message });
      });
      setLoading(false);
      if (result) {
        notificationController.success({
          message: t('messages.successAddInternalTransfer'),
        });
        return new InternalTransfer(result);
      }
    }
  };

  const createInternalTransferToAdmin = async (data: ICreateInternalTransferToAdmin) => {
    if (player) {
      setLoading(true);
      const result = await createInternalTransfersToAdmin(data).catch((err) => {
        if (err.options.statusCode === HTTP_STATUSES.CONFLICT) {
          return notificationController.error({
            message: t('messages.notEnoughMoney'),
          });
        } else {
          notificationController.error({ message: err.message });
        }
      });
      setLoading(false);
      if (result) {
        notificationController.success({
          message: t('messages.successAddInternalTransfer'),
        });
        return new InternalTransfer(result);
      }
    }
  };

  const updateInternalTransfer = async (id: number, data: IUpdateInternalTransfer) => {
    setLoading(true);
    const result = await updateInternalTransfers(id, data).catch((err) => {
      if (err.options.statusCode === HTTP_STATUSES.CONFLICT) {
        return notificationController.error({
          message: t('messages.notEnoughMoney'),
        });
      } else {
        notificationController.error({ message: err.message });
      }
    });
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return new InternalTransfer(result);
    }
  };

  const updateInternalTransferAdmin = async (id: number, data: IUpdateInternalTransfer) => {
    setLoading(true);
    const result = await updateInternalTransfersAdmin(id, data).catch((err) => {
      notificationController.error({ message: err.message });
    });
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return new InternalTransfer(result);
    }
  };

  const deleteInternalTransfer = async (id: number) => {
    setLoading(true);
    await deleteInternalTransferById(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  return {
    internalTransfers,
    meta,
    loading,
    loadAllInternalTransfers,
    loadInternalTransfersByPlayer,
    loadInternalTransferById,
    createInternalTransfer,
    createInternalTransferAdmin,
    createInternalTransferToAdmin,
    updateInternalTransfer,
    updateInternalTransferAdmin,
    deleteInternalTransfer,
  };
};

export default useInternalTransfers;
