import i18next from 'i18next';
import { TYPE_OF_ENTRY } from '../enums/type-of-entry.enum';

export const typeOfEntryDataSource = (): {
  id: TYPE_OF_ENTRY;
  label: string;
}[] => [
  {
    id: TYPE_OF_ENTRY.FIRST_ENTRY,
    label: i18next.t('labels.firstEntry'),
  },
  {
    id: TYPE_OF_ENTRY.REENTRY,
    label: i18next.t('labels.reentry'),
  },
];
