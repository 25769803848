import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { WithChildrenProps } from '@app/types/general-types';
import { accessFields } from '@app/infrastructure/classes/user';
import { ROUTES } from '@app/infrastructure/consts/routes';

interface ICanReadRoute extends WithChildrenProps {
  accessField: accessFields;
}

const CanReadRoute: React.FC<ICanReadRoute> = ({ children, accessField }) => {
  const user = useAppSelector((state) => state.user.user);

  return user?.canRead(accessField) ? <>{children}</> : <Navigate to={ROUTES.HOME} replace />;
};

export default CanReadRoute;
