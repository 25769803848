import { useState } from 'react';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ILoadProps } from './interfaces/i-load-props';
import {
  getPlayerGames,
  getPlayerGamesByPlayer,
  getPlayerGameById,
  createPlayerGames,
  updatePlayerGameById,
  deletePlayerGameById,
} from '@app/api/player-games';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { PlayerGame } from '../classes/player-game';
import { ICreatePlayerGame, IUpdatePlayerGame } from '../interfaces/i-player-game';

type UsePlayerGames = {
  playerGames: PlayerGame[];
  meta: IPaginationMeta;
  loadPlayerGames: (props?: ILoadProps) => Promise<PlayerGame[] | undefined>;
  loadPlayerGamesByPlayer: (props?: ILoadProps) => Promise<PlayerGame[] | undefined>;
  createPlayerGame: (data: ICreatePlayerGame) => Promise<PlayerGame | void>;
  loadPlayerGameById: (id: number) => Promise<PlayerGame | void>;
  updatePlayerGame: (id: number, data: IUpdatePlayerGame) => Promise<PlayerGame | void>;
  deletePlayerGame: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUsePlayerGamesProps {
  meta?: IPartialPaginationMeta;
}

const usePlayerGames = (props?: IUsePlayerGamesProps): UsePlayerGames => {
  const [loading, setLoading] = useState<boolean>(false);
  const [playerGames, setPlayerGames] = useState<PlayerGame[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 5,
    sort: ['updated_at', 'DESC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadPlayerGames = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getPlayerGames({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new PlayerGame(el));
      if (props?.reload) setPlayerGames(data);
      else setPlayerGames([...playerGames, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getPlayerGames({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new PlayerGame(el));
        if (props?.reload) setPlayerGames(data);
        else setPlayerGames([...playerGames, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadPlayerGamesByPlayer = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getPlayerGamesByPlayer({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new PlayerGame(el));
      if (props?.reload) setPlayerGames(data);
      else setPlayerGames([...playerGames, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getPlayerGamesByPlayer({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new PlayerGame(el));
        if (props?.reload) setPlayerGames(data);
        else setPlayerGames([...playerGames, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadPlayerGameById = async (id: number) => {
    setLoading(true);
    const data = await getPlayerGameById(id).catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    setLoading(false);
    if (data) return new PlayerGame(data);
  };

  const createPlayerGame = async (data: ICreatePlayerGame) => {
    setLoading(true);
    const result = await createPlayerGames(data).catch((err) => {
      notificationController.error({ message: err.message });
    });
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successAdd'),
      });
      return new PlayerGame(result);
    }
  };

  const updatePlayerGame = async (id: number, data: IUpdatePlayerGame) => {
    setLoading(true);
    const result = await updatePlayerGameById(id, data).then((data) => {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      setLoading(false);
      return data;
    });
    return new PlayerGame(result);
  };

  const deletePlayerGame = async (id: number) => {
    setLoading(true);
    await deletePlayerGameById(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  return {
    playerGames,
    meta,
    loading,
    loadPlayerGames,
    loadPlayerGamesByPlayer,
    loadPlayerGameById,
    createPlayerGame,
    updatePlayerGame,
    deletePlayerGame,
  };
};

export default usePlayerGames;
