import React, { useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { ImageUpload } from '@app/components/images/image-uploud-input';
import useImages from '@app/infrastructure/hooks/use-images';
import { UploadFile } from 'antd/es/upload/interface';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as S from './styles';
import useInternalTransfers from '@app/infrastructure/hooks/use-internal-transfers';
import { ICreateInternalTransferFromAdmin } from '@app/infrastructure/interfaces/i-internal-transfer';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import { BaseStatistic } from '@app/components/common/BaseStatistic';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import PlayersBillSelectBox, { IPlayersBillSelectBoxItemProps } from '@app/components/players/bills/select-box';

const { Text } = S.Description;
const { Countdown } = BaseStatistic;

interface IInternalTransferAdminFormProps {
  internalTransferRequest: InternalTransferRequest;
  onClose?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  image: UploadFile;
  amount: number;
  commission: number;
  description: string;
  playersBill: IPlayersBillSelectBoxItemProps;
}

const InternalTransferSendAdminForm: React.FC<IInternalTransferAdminFormProps> = (props) => {
  const { internalTransferRequest, onClose, onSave } = props;
  const { t } = useTranslation();

  const userId = useAppSelector((state) => state.user.user?.id);
  const { createInternalTransferAdmin, loading } = useInternalTransfers({ userId });
  const { getTransferInfo, stopTransfer, loading: loadingTransferInfo } = useInternalTransferRequests({ userId });
  const { createImage, loading: loadingImage } = useImages();
  const { key } = useRerender();
  const [deadline, setDeadline] = useState<number>(Date.now() + 10 * 60000);

  useEffect(() => {
    getTransferInfo(internalTransferRequest.id).then((info) =>
      info ? setDeadline(Date.now() - info.time + 10 * 60000) : setDeadline(Date.now() + 10 * 60000),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalTransferRequest.id]);

  const onFinish = async (values: IFormValues) => {
    const imageData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    imageData.append('image', values.image as any);
    createImage(imageData).then((data) => {
      if (data) {
        const createInternalTransferValues: ICreateInternalTransferFromAdmin = {
          amount: values.amount,
          commission: values.commission ? values.commission : 0,
          description: values.description,
          internal_transfer_request_id: internalTransferRequest.id,
          image_id: data?.id,
        };
        createInternalTransferAdmin(createInternalTransferValues).then(async () => {
          if (onSave) onSave();
          await stopTransfer(internalTransferRequest.id);
        });
      }
    });
  };

  const onCancel = async () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading || loadingImage || loadingTransferInfo}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
      key={key}
    >
      <S.Description>{t('messages.sendTransferNeedFillFields')}</S.Description>
      <BaseRow justify="center">
        <Countdown value={deadline} format="mm:ss" onFinish={onCancel} />
      </BaseRow>
      {internalTransferRequest.playersBill?.player && (
        <BaseButtonsForm.Item
          name="playersBill"
          label={t('labels.check')}
          initialValue={`${internalTransferRequest.playersBill?.bill.name} ${internalTransferRequest.playersBill?.bill.currency.name}`}
          rules={[{ required: true, message: t('messages.requiredField') }]}
        >
          {internalTransferRequest.playersBill && (
            <PlayersBillSelectBox player={internalTransferRequest.playersBill.player} disabled />
          )}
        </BaseButtonsForm.Item>
      )}

      <S.Description>
        {t('labels.detailsForTransfers')}
        <Text copyable>
          {internalTransferRequest.playersBill && internalTransferRequest.playersBill.playerNickname}
        </Text>
      </S.Description>
      <S.Description>{`${t('labels.remainingAmount')}:  ${internalTransferRequest.leftOver}`}</S.Description>
      <BaseRow justify="space-between">
        <S.NumberInput name="amount" label={t('labels.summ')} rules={[{ type: 'number', min: 0, required: true }]}>
          <InputNumber min={0} />
        </S.NumberInput>
        <S.NumberInput name="commission" label={t('labels.commission')} rules={[{ type: 'number', min: 0 }]}>
          <InputNumber min={0} />
        </S.NumberInput>
      </BaseRow>
      <BaseButtonsForm.Item label={t('labels.screen')} required>
        <ImageUpload rules={[{ required: true, message: t('messages.requiredField') }]} />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default InternalTransferSendAdminForm;
