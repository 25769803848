import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { ImageUpload } from '@app/components/images/image-uploud-input';
import useImages from '@app/infrastructure/hooks/use-images';
import { UploadFile } from 'antd/es/upload/interface';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as S from './styles';
import { STATUS_GAME_REQUEST } from '@app/infrastructure/enums/status-game-request.enum';
import { TYPE_OF_ENTRY } from '@app/infrastructure/enums/type-of-entry.enum';
import { ITournamentFilters } from '@app/infrastructure/interfaces/i-tournament';
import usePlayerGames from '@app/infrastructure/hooks/use-player-games';
import { ICreatePlayerGame } from '@app/infrastructure/interfaces/i-player-game';
import TypeOfEntrySelect from '../type-of-entry-select';
import BillSelectBox, { IBillSelectBoxItemProps } from '@app/components/bills/select-box';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';
import { valueType } from 'antd/lib/statistic/utils';
import { DatePicker, DatePickerProps } from 'antd';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import TournamentAutocompleteInput from '@app/components/tournaments/autocomplete-input';

interface IPlayerGameFormProps {
  onClose?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  image: UploadFile;
  bill: IBillSelectBoxItemProps;
  bbQuantity: number;
  buyin: number;
  date: string;
  description: string;
  status: STATUS_GAME_REQUEST;
  typeOfEntry: TYPE_OF_ENTRY;
  tournamentName: string;
}

const PlayerGameCreateForm: React.FC<IPlayerGameFormProps> = (props) => {
  const { onClose, onSave } = props;
  const { t } = useTranslation();
  const [filters, setFilters] = useState<ITournamentFilters | undefined>();
  const [billValue, setBillValue] = useState<string>();
  const [biValue, setBiValue] = useState<valueType | undefined>();

  const { createPlayerGame, loading } = usePlayerGames();
  const { createImage, loading: loadingImage } = useImages();

  const onFinish = async (values: IFormValues) => {
    const imageData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    imageData.append('image', values.image as any);
    createImage(imageData).then((data) => {
      if (data) {
        const createPlayerGameValues: ICreatePlayerGame = {
          bb_quantity: values.bbQuantity,
          description: values.description,
          type_of_entry: values.typeOfEntry,
          tournament_name: values.tournamentName,
          start_date: values.date,
          buy_in: values.buyin,
          bill_id: values.bill.record.id,
          image_id: data?.id,
        };
        createPlayerGame(createPlayerGameValues).then(() => {
          if (onSave) onSave();
        });
      }
    });
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleInputChange = (inputValue: valueType | null) => {
    setBiValue(inputValue || 0);
    changeFilters({
      ...filters,
      buyin: inputValue || 0,
    });
  };
  const debouncedInputChange = debounce(handleInputChange, 1000);

  const onDateChange: DatePickerProps['onChange'] = (value) => {
    let newFilters = {
      ...filters,
    };
    if (value) {
      newFilters = {
        ...filters,
        date: value?.format(MomentFormats['MM.DD.YYYY']),
      };
    } else {
      newFilters = {
        ...filters,
        date: undefined,
      };
    }
    changeFilters(newFilters);
  };

  const onBillChange = (value: IBillSelectBoxItemProps) => {
    if (value) {
      setBillValue(`${value.record.name} ${value.record.currency.name}`);

      changeFilters({
        ...filters,
        bills: [value.record.id],
      });
    } else {
      setBillValue(undefined);

      changeFilters({
        ...filters,
        bills: undefined,
      });
    }
  };

  const changeFilters = (filters: ITournamentFilters) => {
    setFilters(filters);
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    return current && current < moment().subtract(1, 'day');
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading || loadingImage}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <S.HeaderDescription>{t('messages.createPlayerGameDescription')}</S.HeaderDescription>

      <BaseButtonsForm.Item
        name="bill"
        label={t('labels.room')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <BillSelectBox
          type={[BILL_TYPES.ROOM]}
          onChange={onBillChange}
          value={billValue}
          placeholder={t('labels.selectRoom')}
          width="100%"
        />
      </BaseButtonsForm.Item>
      <BaseRow justify="space-between">
        <S.NumberInput
          name="buyin"
          label={t('labels.bi')}
          rules={[{ required: true, message: t('messages.requiredField') }]}
        >
          <InputNumber
            min={0}
            value={biValue}
            onChange={(inputValue) => debouncedInputChange(inputValue)}
            placeholder={t('labels.pointBuyin')}
          />
        </S.NumberInput>
        <BaseButtonsForm.Item
          name="date"
          label={t('labels.startDate')}
          rules={[{ required: true, message: t('messages.requiredField') }]}
        >
          <DatePicker disabledDate={disabledDate} style={{ width: '200px' }} onChange={onDateChange} />
        </BaseButtonsForm.Item>
      </BaseRow>
      <BaseButtonsForm.Item
        name="tournamentName"
        label={t('labels.tournament')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <TournamentAutocompleteInput filters={filters} />
      </BaseButtonsForm.Item>
      <BaseRow justify="space-between">
        <S.TypeOfEntryItem
          name="typeOfEntry"
          label={t('labels.entry')}
          rules={[{ required: true, message: t('messages.requiredField') }]}
        >
          <TypeOfEntrySelect />
        </S.TypeOfEntryItem>
        <S.NumberInput
          name="bbQuantity"
          label={t('labels.bbQuantity')}
          rules={[{ type: 'number', min: 0, required: true }]}
        >
          <InputNumber min={0} />
        </S.NumberInput>
      </BaseRow>

      <BaseButtonsForm.Item name="description" label={t('labels.comment')}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item label={t('labels.screen')} required>
        <ImageUpload rules={[{ required: true, message: t('messages.requiredField') }]} />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default PlayerGameCreateForm;
