import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { ProfitPackage } from '@app/infrastructure/classes/profit-package';
import LoginCell from './login-cell';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import StatusCell from './status-cell';
import { CANCELED_PACKAGE_STATUS } from '@app/infrastructure/enums/canceled-package-status';

interface IProfitPackagesColumnsProps {
  canEdit?: boolean;
  onEdit: (data: ProfitPackage) => void;
  onInfo: (data: ProfitPackage) => void;
}

export const profitPackagesTableColumns = ({
  canEdit,
  onEdit,
  onInfo,
}: IProfitPackagesColumnsProps): IModifiedTableColumn<ProfitPackage>[] => {
  const columns: IModifiedTableColumn<ProfitPackage>[] = [
    {
      title: `${i18next.t('labels.updateDate')}`,
      dataIndex: 'updatedAt',
      sortField: 'updated_at',
      sorter: true,
      render: (_, record) => record.updatedAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.login')}`,
      dataIndex: 'login',
      sortField: 'player.user.login',
      sorter: true,
      render: (_, data: ProfitPackage) => {
        return <LoginCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.startDate')}`,
      dataIndex: 'startDate',
      sortField: 'start_date',
      sorter: true,
      render: (_, record) => record.startDate.format(MomentFormats['DD.MM.YYYY']),
    },
    {
      title: `${i18next.t('labels.status')}`,
      dataIndex: 'canceledPackageStatus',
      sortField: 'canceled_package_status',
      sorter: true,
      render: (_, data: ProfitPackage) => {
        return <StatusCell data={data} />;
      },
    },
  ];

  if (canEdit) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '100px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          canEdit={canEdit && record.canceledPackageStatus === CANCELED_PACKAGE_STATUS.IN_REVIEW}
          canRead={record.canceledPackageStatus === CANCELED_PACKAGE_STATUS.APPROVED}
          onEdit={() => {
            onEdit(record);
          }}
          onInfo={() => {
            onInfo(record);
          }}
          size="small"
        />
      ),
    });
  }

  return columns;
};
