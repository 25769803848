import styled from 'styled-components';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export const BaseButtonsFormItem = styled(BaseButtonsForm.Item)`
  .ant-col.ant-form-item-label {
    min-width: 145px;
    text-align: left;
    .ant-form-item-required::before {
      display: none;
    }
  }
  label {
    color: var(--text-main-color) !important;
    font-size: 1rem;
  }

  .ant-input-number-affix-wrapper-sm {
    width: 100%;
  }
`;
