import React from 'react';
import { Player } from '@app/infrastructure/classes/player';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';

interface IOverMoneyInfoProps {
  player: Player;
}

const OverMoneyInfo: React.FC<IOverMoneyInfoProps> = ({ player }) => {
  const { t } = useTranslation();
  return (
    <BaseTypography>
      {t('labels.overMoney')}: {CURRENCIES.DOLLAR} {player.overMoney}
    </BaseTypography>
  );
};

export default OverMoneyInfo;
