import { Permissions } from '../enums/permissions';
import { ROLES } from '../enums/roles';
import { checkPermission } from '../functions/check-permission';
import { IUser } from '../interfaces/i-user';
import { AccessRight } from './access-right';
import { Player } from './player';

export type accessFields = keyof Omit<AccessRight, 'id' | 'name'>;

export class User {
  id: number;
  login: string;
  role: ROLES;
  accessRight?: AccessRight;
  player?: Player;
  constructor(data: IUser) {
    this.id = data.id;
    this.login = data.login;
    this.role = data.role;
    this.accessRight = data.access_right ? new AccessRight(data.access_right) : undefined;
    this.player = data.player ? new Player(data.player) : undefined;
  }

  isPlayer(): boolean {
    return this.role === ROLES.PLAYER;
  }

  isAdmin(): boolean {
    return this.role === ROLES.ADMIN;
  }

  isSuperadmin(): boolean {
    return this.role === ROLES.SUPERADMIN;
  }

  canRead(field: accessFields): boolean {
    if (this.isSuperadmin()) return true;
    if (!this.accessRight) return false;
    return checkPermission(Permissions.Read, this.accessRight[field]);
  }
  canCreate(field: accessFields): boolean {
    if (this.isSuperadmin()) return true;
    if (!this.accessRight) return false;
    return checkPermission(Permissions.Create, this.accessRight[field]);
  }
  canUpdate(field: accessFields): boolean {
    if (this.isSuperadmin()) return true;
    if (!this.accessRight) return false;
    return checkPermission(Permissions.Update, this.accessRight[field]);
  }
  fullAccess(field: accessFields): boolean {
    if (this.isSuperadmin()) return true;
    if (!this.accessRight) return false;
    return checkPermission(Permissions.FullAccess, this.accessRight[field]);
  }
}
