import { useState } from 'react';
import { ILoadProps } from './interfaces/i-load-props';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { Player } from '../classes/player';
import {
  ICreateInternalTransferRequest,
  IInternalTransferRequestInfo,
  IUpdateInternalTransferRequest,
} from '../interfaces/i-internal-transfer-request';
import {
  createInternalTransferRequests,
  deleteInternalTransferRequestById,
  getAllInternalTransferRequests,
  getApprovedInternalTransferRequests,
  getInternalTransferRequestById,
  getInternalTransferRequestInfo,
  getInternalTransferRequestsByPlayerForAdmin,
  getInternalTransferRequestsForPlayer,
  startTransfers,
  stopTransfers,
  updateInternalTransferRequests,
} from '@app/api/internal-transfer-requests';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { InternalTransferRequest } from '../classes/internal-transfer-request';

type UseInternalTransferRequests = {
  internalTransferRequests: InternalTransferRequest[];
  meta: IPaginationMeta;
  loadAllInternalTransferRequests: (props?: ILoadProps) => Promise<InternalTransferRequest[] | undefined>;
  loadInternalTransferRequestsForPlayer: (props?: ILoadProps) => Promise<InternalTransferRequest[] | undefined>;
  loadInternalTransferRequestsByPlayerForAdmin: (props?: ILoadProps) => Promise<InternalTransferRequest[] | undefined>;
  loadApprovedInternalTransferRequests: (props?: ILoadProps) => Promise<InternalTransferRequest[] | undefined>;
  createInternalTransferRequest: (data: ICreateInternalTransferRequest) => Promise<InternalTransferRequest | void>;
  loadInternalTransferRequestById: (id: number) => Promise<InternalTransferRequest | void>;
  updateInternalTransferRequest: (
    id: number,
    data: IUpdateInternalTransferRequest,
    InternalTransferRequest: InternalTransferRequest,
  ) => Promise<InternalTransferRequest | void>;
  deleteInternalTransferRequest: (id: number) => Promise<void>;
  getTransferInfo: (id: number) => Promise<IInternalTransferRequestInfo | undefined>;
  startTransfer: (id: number) => Promise<void>;
  stopTransfer: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseInternalTransferRequestsProps {
  player?: Player | undefined;
  userId?: number | undefined;
  meta?: IPartialPaginationMeta;
}

const useInternalTransferRequests = (props: IUseInternalTransferRequestsProps): UseInternalTransferRequests => {
  const { player, userId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [internalTransferRequests, setInternalTransferRequests] = useState<InternalTransferRequest[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 5,
    sort: ['updated_at', 'DESC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadAllInternalTransferRequests = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getAllInternalTransferRequests({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new InternalTransferRequest(el));
      if (props?.reload) setInternalTransferRequests(data);
      else setInternalTransferRequests([...internalTransferRequests, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getAllInternalTransferRequests({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new InternalTransferRequest(el));
        if (props?.reload) setInternalTransferRequests(data);
        else setInternalTransferRequests([...internalTransferRequests, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadInternalTransferRequestsForPlayer = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getInternalTransferRequestsForPlayer({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new InternalTransferRequest(el));
      if (props?.reload) setInternalTransferRequests(data);
      else setInternalTransferRequests([...internalTransferRequests, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getInternalTransferRequestsForPlayer({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new InternalTransferRequest(el));
        if (props?.reload) setInternalTransferRequests(data);
        else setInternalTransferRequests([...internalTransferRequests, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadInternalTransferRequestsByPlayerForAdmin = async (props?: ILoadProps) => {
    if (player) {
      setLoading(true);
      const { results, meta: metaRes } = await getInternalTransferRequestsByPlayerForAdmin(player.id, {
        ...meta,
        ...props,
      });
      let data = undefined;
      if (results.length || metaRes.total === 0) {
        data = results.map((el) => new InternalTransferRequest(el));
        if (props?.reload) setInternalTransferRequests(data);
        else setInternalTransferRequests([...internalTransferRequests, ...data]);
        setMeta({
          ...meta,
          ...metaRes,
        });
      } else {
        let page = metaRes.page;
        if (metaRes.totalPages < page) page = metaRes.totalPages;
        const { results, meta: metaSecondReq } = await getInternalTransferRequestsByPlayerForAdmin(player.id, {
          ...meta,
          ...metaRes,
          page,
          ...props,
        });
        if (results) {
          data = results.map((el) => new InternalTransferRequest(el));
          if (props?.reload) setInternalTransferRequests(data);
          else setInternalTransferRequests([...internalTransferRequests, ...data]);
          setMeta({
            ...meta,
            ...metaSecondReq,
          });
        }
      }

      setLoading(false);
      return data;
    }
  };

  const loadApprovedInternalTransferRequests = async (props?: ILoadProps) => {
    if (player) {
      setLoading(true);
      const { results, meta: metaRes } = await getApprovedInternalTransferRequests({ ...meta, ...props });
      let data = undefined;
      if (results.length || metaRes.total === 0) {
        data = results.map((el) => new InternalTransferRequest(el));
        if (props?.reload) setInternalTransferRequests(data);
        else setInternalTransferRequests([...internalTransferRequests, ...data]);
        setMeta({
          ...meta,
          ...metaRes,
        });
      } else {
        let page = metaRes.page;
        if (metaRes.totalPages < page) page = metaRes.totalPages;
        const { results, meta: metaSecondReq } = await getApprovedInternalTransferRequests({
          ...meta,
          ...metaRes,
          page,
          ...props,
        });
        if (results) {
          data = results.map((el) => new InternalTransferRequest(el));
          if (props?.reload) setInternalTransferRequests(data);
          else setInternalTransferRequests([...internalTransferRequests, ...data]);
          setMeta({
            ...meta,
            ...metaSecondReq,
          });
        }
      }

      setLoading(false);
      return data;
    }
  };

  const loadInternalTransferRequestById = async (id: number) => {
    if (player) {
      setLoading(true);
      const data = await getInternalTransferRequestById(id).catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
      setLoading(false);
      if (data) return new InternalTransferRequest(data);
    }
  };

  const createInternalTransferRequest = async (data: ICreateInternalTransferRequest) => {
    if (player) {
      setLoading(true);
      const result = await createInternalTransferRequests(data).catch((err) => {
        notificationController.error({ message: err.message });
      });
      setLoading(false);
      if (result) {
        notificationController.success({
          message: t('messages.successAddInternalTransferRequest'),
        });
        return new InternalTransferRequest(result);
      }
    }
  };

  const updateInternalTransferRequest = async (id: number, data: IUpdateInternalTransferRequest) => {
    setLoading(true);
    const result = await updateInternalTransferRequests(id, data);
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return new InternalTransferRequest(result);
    }
  };

  const deleteInternalTransferRequest = async (id: number) => {
    setLoading(true);
    await deleteInternalTransferRequestById(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  const getTransferInfo = async (id: number) => {
    if (userId) {
      setLoading(true);
      const res = await getInternalTransferRequestInfo(id, userId);
      setLoading(false);

      if (!res.canTransfer) {
        notificationController.info({
          message: t('messages.sendingInProccess'),
        });
      }
      return res;
    }
  };

  const startTransfer = async (id: number) => {
    if (userId) {
      setLoading(true);
      await startTransfers(id, userId);
      setLoading(false);
    }
  };

  const stopTransfer = async (id: number) => {
    setLoading(true);
    await stopTransfers(id);
    setLoading(false);
  };

  return {
    internalTransferRequests,
    meta,
    loading,
    loadAllInternalTransferRequests,
    loadInternalTransferRequestsForPlayer,
    loadInternalTransferRequestsByPlayerForAdmin,
    loadApprovedInternalTransferRequests,
    loadInternalTransferRequestById,
    createInternalTransferRequest,
    updateInternalTransferRequest,
    deleteInternalTransferRequest,
    getTransferInfo,
    startTransfer,
    stopTransfer,
  };
};

export default useInternalTransferRequests;
