import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseDeleteBtn from '@app/components/common/buttons/delete';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { Player } from '@app/infrastructure/classes/player';

interface DeletePlayerProps {
  player: Player;
  onDelete: () => void;
}

const DeletePlayer: React.FC<DeletePlayerProps> = ({ player, onDelete }) => {
  const { t } = useTranslation();
  const { deletePlayer } = usePlayers();

  const onConfirm = async (player: Player) => {
    await deletePlayer(player.id);
    onDelete();
  };

  return <BaseDeleteBtn text={t('labels.deletePlayer')} onConfirm={() => onConfirm(player)} />;
};

export default DeletePlayer;
