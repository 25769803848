import React from 'react';
import { BaseSelect, BaseSelectProps, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';
import { billTypesDataSource } from '@app/infrastructure/data-sources/bill-types';

interface IBaseSelectProps extends BaseSelectProps {
  disabledValues?: BILL_TYPES[];
}

const BillTypeSelect: React.FC<IBaseSelectProps> = (props) => {
  const { disabledValues = [], ...args } = props;
  return (
    <BaseSelect {...args}>
      {billTypesDataSource().map((billType) => (
        <Option key={billType.id} value={billType.id} disabled={disabledValues.some((el) => el === billType.id)}>
          {billType.label}
        </Option>
      ))}
    </BaseSelect>
  );
};

export default BillTypeSelect;
