import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import styled from 'styled-components';

export const StyledBaseTable = styled(BaseTable)`
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f5f5f5 !important;
    color: rgb(64, 64, 64) !important;
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: rgb(189, 205, 213) !important;
  }
`;
