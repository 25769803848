import React from 'react';
import { Player } from '@app/infrastructure/classes/player';
import * as S from './styles';
import SummInfo from '../funds/summ-info';
import PlayerBillsTable from '../bills/table';
import PlayerFundsInfo from '../funds/info';
import PlayerProfitPackage from '../profit-package';

interface PlayerDashboardProps {
  player: Player;
  canEdit?: boolean;
}

const PlayerDashboard: React.FC<PlayerDashboardProps> = (props) => {
  const { player, canEdit = false } = props;
  return (
    <S.Flex>
      <S.BillsSpace>
        <SummInfo player={player} canEdit={canEdit} />
        <PlayerBillsTable player={player} />
      </S.BillsSpace>
      <S.PlayerFundsSpace>
        <S.FoundSpace>
          <PlayerFundsInfo player={player} />
        </S.FoundSpace>
        <S.FoundSpace>
          <PlayerProfitPackage player={player} />
        </S.FoundSpace>
      </S.PlayerFundsSpace>
    </S.Flex>
  );
};

export default PlayerDashboard;
