import React from 'react';
import { statusProfitPackageDataSource } from '@app/infrastructure/data-sources/status-profit-package';
import * as S from './styles';
import {
  backgroundStatusColor,
  borderStatusColor,
  fontStatusColor,
} from '@app/infrastructure/consts/status-profit-package';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { ProfitPackage } from '@app/infrastructure/classes/profit-package';

interface StatusCellProps {
  data: ProfitPackage;
}

const StatusCell: React.FC<StatusCellProps> = ({ data }) => {
  const status = statusProfitPackageDataSource().find((item) => item.id === data.canceledPackageStatus);

  return (
    <BaseRow justify="center" align="middle">
      <S.StatusLabel
        style={
          status && {
            color: fontStatusColor[status.id],
            backgroundColor: backgroundStatusColor[status.id],
            borderColor: borderStatusColor[status.id],
          }
        }
      >
        {status?.label}
      </S.StatusLabel>
    </BaseRow>
  );
};

export default StatusCell;
