/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ShoppingOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';
import useFlcrafts from '@app/infrastructure/hooks/use-flcraft';

interface IFlcraftUploaderProps {
  onUploaded?(): void;
}

const FlcraftUploader: React.FC<IFlcraftUploaderProps> = (props) => {
  const { onUploaded } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const { uploadFlcraft } = useFlcrafts();

  const uploadFile = async (file: File) => {
    if (file) {
      await uploadFlcraft(file)
        .then(() => {
          notificationController.success({ message: t('messages.fileUploaded') });
          setLoading(false);
          if (onUploaded) onUploaded();
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  return (
    <>
      <BaseButton
        disabled={loading}
        icon={loading ? <ShoppingOutlined /> : <UploadOutlined />}
        size="small"
        onClick={() => {
          if (fileInput.current) {
            fileInput.current.click();
          }
        }}
      >
        <input
          ref={fileInput}
          accept=".zip,.rar,.7zip"
          type="file"
          hidden
          onChange={(e) => {
            if (e.target.files) {
              uploadFile(e.target.files[0]);
              e.target.value = null as any;
            }
          }}
        />
        {t('controls.upload')}
      </BaseButton>
    </>
  );
};

export default FlcraftUploader;
