import moment from 'moment';
import { IInternalTransfer } from '../interfaces/i-internal-transfer';
import { Image } from './image';
import { PlayerBill } from './player-bill';
import { InternalTransferRequest } from './internal-transfer-request';
import { User } from './user';

export class InternalTransfer {
  id: number;
  amount: number;
  commission: number;
  description: string;
  createdAt: moment.Moment;
  image: Image;
  playersBill?: PlayerBill;
  user: User;
  internalTransferRequest: InternalTransferRequest;

  constructor(data: IInternalTransfer) {
    this.id = data.id;
    this.amount = data.amount;
    this.commission = data.commission;
    this.description = data.description;
    this.createdAt = moment(data.created_at);
    this.image = new Image(data.image);
    this.playersBill = data.players_bill ? new PlayerBill(data.players_bill) : undefined;
    this.user = new User(data.user);
    this.internalTransferRequest = new InternalTransferRequest(data.internal_transfer_request);
  }
}
