import { Tournament } from '@app/infrastructure/classes/tournament';
import React from 'react';

interface IBillColProps {
  data: Tournament;
}

const BillCol: React.FC<IBillColProps> = ({ data }) => {
  return (
    <div>
      {data.bill?.name} {data.bill?.currency.name}
    </div>
  );
};

export default BillCol;
