/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { Player } from '@app/infrastructure/classes/player';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import { internalTransferAllPlayerRequestTableColumns } from './cols';
import InternalTransferRequestsCreateForm from '../create-form';
import OverMoneyInfo from '../over-money-info';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';

interface InternalTransferRequestsTableProps {
  player: Player;
  canCreate?: boolean;
  canDelete?: boolean;
}

const InternalTransferRequestsForPlayerTable: React.FC<InternalTransferRequestsTableProps> = (props) => {
  const { player, canCreate = false, canDelete = false } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const {
    internalTransferRequests,
    meta,
    loading,
    loadInternalTransferRequestsForPlayer,
    deleteInternalTransferRequest,
  } = useInternalTransferRequests({
    player,
  });
  const { t } = useTranslation();

  const modalTitle = t('labels.createInternalTransferRequest');

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadInternalTransferRequestsForPlayer({ reload: true, limit: 10 });
  };

  const onCreate = () => {
    player.rank?.name
      ? setModalOpened(true)
      : notificationController.error({
          message: t('messages.rankNotDefined'),
        });
  };

  const onDelete = async (record: InternalTransferRequest) => {
    await deleteInternalTransferRequest(record.id);
    await onReload();
  };

  const onClose = () => {
    setModalOpened(false);
  };

  const onSave = async () => {
    setModalOpened(false);
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InternalTransferRequest> | SorterResult<InternalTransferRequest>[],
  ) => {
    await loadInternalTransferRequestsForPlayer({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || 10,
      sort: createSortOrder<InternalTransferRequest>(
        sorter as IModifiedSorterResult<InternalTransferRequest>,
        'updated_at',
        'DESC',
      ),
      reload: true,
    });
  };

  useEffect(() => {
    loadInternalTransferRequestsForPlayer({ limit: 10 });
  }, []);

  return (
    <>
      <div>
        <BaseTable
          rowKey="id"
          title={() => {
            return (
              <BaseTableTitle
                canCreate={canCreate}
                onReload={onReload}
                onCreate={onCreate}
                extra={<OverMoneyInfo player={player} />}
                size="small"
              />
            );
          }}
          columns={internalTransferAllPlayerRequestTableColumns({
            canDelete,
            onDelete,
            onSave,
          })}
          dataSource={internalTransferRequests}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          size="small"
          scroll={{ x: 1000 }}
          bordered
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} destroyOnClose footer={null}>
        <InternalTransferRequestsCreateForm player={player} onClose={onClose} onSave={onSave} />
      </BaseModal>
    </>
  );
};

export default InternalTransferRequestsForPlayerTable;
