import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import styled from 'styled-components';

export const FilterRow = styled(BaseRow)`
  .ant-select-selector {
    border-radius: 7px !important;
  }

  .ant-input-number-affix-wrapper {
    width: 150px;
  }

  .ant-picker {
    width: 350px;
  }
`;

export const StyledBaseTable = styled(BaseTable)`
  .color-389e0d {
    background-color: #f6ffed;
  }
  .color-cf1322 {
    background-color: #fff1f0;
  }
  tbody .ant-table-cell {
    color: black !important;
  }
`;
