import styled from 'styled-components';
import { BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '../BaseButton/BaseButton';

export const Card = styled(CommonCard)`
  width: 100%;
  margin-bottom: 1.25rem;
  .ant-card-head-wrapper {
    width: 100%;
  }
  .ant-card-head {
    border-bottom: 1px solid #f0f0f0;
    padding: 15px 25px;
    min-height: 67px;
    display: flex;
    align-items: center;
  }
  .ant-card-head-title {
    padding: 0 !important;
    font-size: 1rem;
  }
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: start;
    padding: 20px;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

export const IconBtn = styled(BaseButton)`
  padding: 0;
`;
