import { IImage } from '@app/infrastructure/interfaces/i-image';
import { httpApi } from './http.api';

const baseRoute = '/file-manager';

export async function createImages(image: FormData): Promise<IImage> {
  const { data } = await httpApi.post<IImage>(baseRoute, image, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}
