import { httpApi } from '@app/api/http.api';
import { IUser } from '@app/infrastructure/interfaces/i-user';

export interface LoginRequest {
  login: string;
  password: string;
}

export interface LoginResponse {
  user: IUser;
}

export const login = async (loginPayload: LoginRequest): Promise<LoginResponse> => {
  return await httpApi.post<LoginResponse>('/auth/login', { ...loginPayload }).then(({ data }) => data);
};
export const refreshAccessToken = async (refreshToken: string): Promise<LoginResponse> => {
  return await httpApi.post<LoginResponse>('/auth/refresh', { refresh_token: refreshToken }).then(({ data }) => data);
};

export const getMe = async () => {
  return await httpApi.get('/auth/me').then(({ data }) => data);
};
