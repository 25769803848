import React from 'react';
import { BaseSelect, BaseSelectProps, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';

interface ITournamentTypeSelectProps extends BaseSelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  width?: string;
}

const TournamentTypeSelect: React.FC<ITournamentTypeSelectProps> = (props) => {
  const { width = '100%' } = props;
  const types = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <BaseSelect width={width} allowClear {...props}>
      {types.map((el) => (
        <Option key={el} value={el}>
          {el}
        </Option>
      ))}
    </BaseSelect>
  );
};

export default TournamentTypeSelect;
