import { PersonalTransfer } from '@app/infrastructure/classes/personal-transfer';
import React from 'react';

interface BillNameCellProps {
  data: PersonalTransfer;
}

const BillNameCell: React.FC<BillNameCellProps> = ({ data }) => {
  return (
    <span>
      {data.playersBill.bill.name} {data.playersBill.bill.currency.name}
    </span>
  );
};

export default BillNameCell;
