import { STATUS_INTERNAL_TRANSFER } from '../enums/status-internal-transfer.enum';

export const fontStatusColor = {
  [STATUS_INTERNAL_TRANSFER.IN_REVIEW]: '#082799',
  [STATUS_INTERNAL_TRANSFER.APPROVED]: '#06802b',
  [STATUS_INTERNAL_TRANSFER.REJECTED]: '#ed4049',
  [STATUS_INTERNAL_TRANSFER.CLOSED]: '#2e2a2a',
};

export const backgroundStatusColor = {
  [STATUS_INTERNAL_TRANSFER.IN_REVIEW]: '#E6F7FF',
  [STATUS_INTERNAL_TRANSFER.APPROVED]: '#F6FFED',
  [STATUS_INTERNAL_TRANSFER.REJECTED]: '#FFF1F0',
  [STATUS_INTERNAL_TRANSFER.CLOSED]: '#FAFAFA',
};

export const borderStatusColor = {
  [STATUS_INTERNAL_TRANSFER.IN_REVIEW]: '#082799',
  [STATUS_INTERNAL_TRANSFER.APPROVED]: '#06802b',
  [STATUS_INTERNAL_TRANSFER.REJECTED]: '#ed4049',
  [STATUS_INTERNAL_TRANSFER.CLOSED]: '#2e2a2a',
};
