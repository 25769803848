import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import BillsTable from '@app/components/bills/table';

const BillsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('navigation.bills')}</PageTitle>
      <BaseCol>
        <BillsTable />
      </BaseCol>
    </>
  );
};

export default BillsPage;
