import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/infrastructure/hooks/redux';
import { doLogin } from '@app/store/slices/auth-slice';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';
import * as Auth from '@app/components/layouts/auth-layout/styles';
import { ROUTES } from '@app/infrastructure/consts/routes';
import { ROLES } from '@app/infrastructure/enums/roles';
import { IUser } from '@app/infrastructure/interfaces/i-user';

interface LoginFormData {
  login: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: LoginFormData) => {
    dispatch(doLogin(values))
      .unwrap()
      .then((data: IUser) => {
        data.role === ROLES.PLAYER ? navigate(ROUTES.HOME) : navigate(ROUTES.PLAYERS);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <Auth.FormItem
          name="login"
          label={t('common.login')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.login')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
