import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import AdminsTable from '@app/components/admins/table';

const AdminsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('navigation.admins')}</PageTitle>
      <BaseCol>
        <AdminsTable />
      </BaseCol>
    </>
  );
};

export default AdminsPage;
