import { useState } from 'react';
import { getTournaments, createTournaments, deleteTournaments } from '@app/api/tournament';
import { Tournament } from '../classes/tournament';
import { ILoadProps } from './interfaces/i-load-props';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ICreateTournament } from '../interfaces/i-tournament';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';

type UseTournaments = {
  tournaments: Tournament[];
  meta: IPaginationMeta;
  loadTournaments: (props?: ILoadProps) => Promise<Tournament[] | undefined>;
  createTournament: (data: ICreateTournament) => Promise<void>;
  deleteTournament: (groupId: string) => Promise<void>;
  loading: boolean;
};

interface IUseTournamentsProps {
  meta?: IPartialPaginationMeta;
}

const useTournaments = (props?: IUseTournamentsProps): UseTournaments => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 5,
    sort: ['start_date', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadTournaments = async (props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);

    const { results, meta: metaRes } = await getTournaments({ ...meta, ...props });

    const data = results.map((el) => new Tournament(el));
    setTournaments(data);
    setMeta({
      ...meta,
      ...metaRes,
    });

    setLoading(false);
    return data;
  };

  const createTournament = async (data: ICreateTournament) => {
    setLoading(true);
    await createTournaments(data).then((data) => {
      notificationController.success({
        message: t('messages.successAdd'),
      });
      return data;
    });
    setLoading(false);
  };

  const deleteTournament = async (groupId: string) => {
    setLoading(true);
    await deleteTournaments(groupId).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  return {
    tournaments,
    meta,
    loading,
    loadTournaments,
    createTournament,
    deleteTournament,
  };
};

export default useTournaments;
