/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { EditableCell } from './cols/editable-cell';
import { getPlayerBillsTableColumns } from './cols';
import { useMounted } from '@app/infrastructure/hooks/use-mounted';
import usePlayersBills from '@app/infrastructure/hooks/use-players-bills';
import { Player } from '@app/infrastructure/classes/player';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';
import PlayerBillsTableTitle from './title';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { CANCELED_PACKAGE_STATUS } from '@app/infrastructure/enums/canceled-package-status';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { ROLES } from '@app/infrastructure/enums/roles';
import { useOnClickOutside } from '@app/infrastructure/hooks/use-on-click-outside';
import { TablePaginationConfig } from 'antd';

interface PlayerBillsTableProps {
  player: Player;
}

const PlayerBillsTable: React.FC<PlayerBillsTableProps> = (props) => {
  const { player } = props;

  const { playersBills, updatePlayersBill, deletePlayersBill, loading, loadPlayersBills, meta } = usePlayersBills({
    player,
  });
  const { updatePlayerInfoById, loading: loadingPlayer } = usePlayers();
  const [editingId, setEditingId] = useState(0);
  const [isProfitPackageInReview, setIsProfitPackageInReview] = useState<boolean>(false);
  const [inputSumm, setInputSumm] = useState<HTMLElement | null>();
  const [inputPlayerNickname, setInputPlayerNickname] = useState<HTMLElement | null>();

  const [form] = BaseForm.useForm();
  const { isMounted } = useMounted();
  const { t } = useTranslation();
  const { key, rerender } = useRerender();
  const rowRef = useRef(null);

  const user = useAppSelector((state) => state.user?.user);
  const role = user?.role;

  const canCreate = role === ROLES.PLAYER ? false : user?.canCreate('playerPermission');
  const canDelete = role === ROLES.PLAYER ? false : user?.fullAccess('playerPermission');
  const canEdit = role === ROLES.PLAYER ? true : user?.canUpdate('playerPermission');

  const hasPagination = meta.total > meta.limit;

  const fetch = useCallback(async () => {
    await loadPlayersBills({ reload: true });
  }, [isMounted, player]);

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    await loadPlayersBills({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      reload: true,
    });
    cancel();
  };

  const isEditing = (data: PlayerBill) => data.id === editingId;

  const edit = (data: PlayerBill) => {
    form.setFieldsValue(data);
    setEditingId(data.id);
  };

  const cancel = () => {
    setEditingId(0);
  };

  const save = () => {
    form.submit();
  };

  const onFinish = async (values: PlayerBill) => {
    const playerBill = playersBills.find((el) => el.id === editingId);
    if (playerBill) {
      await updatePlayersBill(
        editingId,
        { summ: values.summ, player_nickname: values.playerNickname },
        playerBill,
      ).then(async () => {
        notificationController.success({
          message: t('messages.successUpdate'),
        });
        cancel();
        await updatePlayerInfoById(player.id, player);
        await loadPlayersBills({
          ...meta,
          page: meta.page,
          limit: meta.limit,
          reload: true,
        });
        rerender();
      });
    }
  };

  const handleDeleteBill = async (rowId: number) => {
    await deletePlayersBill(rowId).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
    });
    await updatePlayerInfoById(player.id, player);
    fetch();
    rerender();
  };

  const onBillSaved = async () => {
    await updatePlayerInfoById(player.id, player);
    fetch();
    rerender();
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const profitPackageInReview = player.profitPackages?.find(
      (profitPackage) =>
        profitPackage.canceledDate === null &&
        profitPackage.canceledPackageStatus === CANCELED_PACKAGE_STATUS.IN_REVIEW,
    );

    setIsProfitPackageInReview(profitPackageInReview ? true : false);
  }, [player, key]);

  useEffect(() => {
    if (editingId) {
      setInputSumm(document.getElementById('summ'));
      setInputPlayerNickname(document.getElementById('playerNickname'));
    }
  }, [editingId]);

  if (inputSumm) {
    inputSumm.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        form.submit();
      }
    });
  }

  if (inputPlayerNickname) {
    inputPlayerNickname.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        form.submit();
      }
    });
  }

  useOnClickOutside(rowRef, save);

  return (
    <BaseForm size="small" form={form} component={false} onFinish={onFinish} autoComplete="off" key={key}>
      <S.PlayerBillsTable
        rowKey={'id'}
        title={() => (
          <PlayerBillsTableTitle
            player={player}
            onSaved={onBillSaved}
            canCreate={canCreate && !isProfitPackageInReview}
          />
        )}
        size="middle"
        showHeader={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={getPlayerBillsTableColumns({
          isEditing,
          save,
          cancel,
          handleDeleteBill,
          canEdit,
          canDelete: canDelete && !isProfitPackageInReview,
          role,
        })}
        dataSource={playersBills}
        loading={loading || loadingPlayer}
        onChange={handleTableChange}
        pagination={
          !loading &&
          hasPagination && {
            current: meta.page,
            pageSize: meta.limit,
            total: meta.total,
            showSizeChanger: true,
          }
        }
        onRow={(data) => {
          const options: any = {
            onClick: () => {
              if (!isEditing(data) && canEdit && !isProfitPackageInReview) {
                edit(data);
              }
            },
          };
          if (isEditing(data)) {
            options.ref = rowRef;
          }
          return options;
        }}
      />
    </BaseForm>
  );
};

export default PlayerBillsTable;
