import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface LimitsCellProps {
  onClick?: () => void;
}

const LimitsCell: React.FC<LimitsCellProps> = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <BaseRow align="middle" justify="center">
      <BaseButton size="small" onClick={onClick}>
        {t('labels.limits')}
      </BaseButton>
    </BaseRow>
  );
};

export default LimitsCell;
