import React from 'react';
import * as S from './styles';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import BillSelectBox from '@app/components/bills/select-box';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import useLimits from '@app/infrastructure/hooks/use-limits';
import { Rank } from '@app/infrastructure/classes/rank';
import { useTranslation } from 'react-i18next';
import { ICreateLimit } from '@app/infrastructure/interfaces/i-limit';

interface ILimitCreateRowProps {
  colNames: { [key: string]: string };
  rank: Rank;
  onClose: () => void;
  onSaved: () => void;
}

const LimitCreateRow: React.FC<ILimitCreateRowProps> = (props) => {
  const { colNames, rank, onClose, onSaved } = props;
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const { createLimit } = useLimits();

  const onSave = async () => {
    form.submit();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (values: any) => {
    const newLimit: ICreateLimit = { bill_id: values.bill.record.id, rank_id: rank.id, limits: {} };
    Object.keys(colNames).map((colName) => {
      newLimit.limits[colName] = values[colName];
    });
    await createLimit(newLimit);
    onSaved();
  };

  return (
    <BaseForm name="add-limit-form" autoComplete="off" form={form} onFinish={onFinish} size="small">
      <BaseRow gutter={[10, 10]} align="middle" justify={'space-between'}>
        <BaseCol span={4}>
          <S.BaseFormItem name="bill" rules={[{ required: true, message: '' }]}>
            <BillSelectBox type={[BILL_TYPES.ROOM]} placeholder={t('labels.room')} />
          </S.BaseFormItem>
        </BaseCol>
        {colNames &&
          Object.keys(colNames)
            .sort()
            .map((colName) => {
              return (
                <BaseCol span={3} key={colName}>
                  <S.BaseFormItem name={colName}>
                    <InputNumber placeholder={colName} />
                  </S.BaseFormItem>
                </BaseCol>
              );
            })}

        <BaseCol span={4}>
          <BaseRow gutter={2} align="middle">
            <BaseCol>
              <BaseButton size="small" type="text" icon={<CheckOutlined />} onClick={onSave} />
            </BaseCol>
            <BaseCol>
              <BaseButton size="small" type="text" icon={<CloseOutlined />} onClick={onClose} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  );
};

export default LimitCreateRow;
