import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import AnalyticsTable from '@app/components/players/analytics/table';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import AnalyticsDescription from '@app/components/players/analytics/description-card';

const AnalyticsPage: React.FC = () => {
  const { t } = useTranslation();
  const player = useAppSelector((state) => state.user.user?.player);

  return (
    <>
      <PageTitle>{t('navigation.analytics')}</PageTitle>
      {player && (
        <BaseRow gutter={[10, 10]}>
          <BaseCol span={24} xl={14}>
            <AnalyticsTable player={player} />
          </BaseCol>

          <BaseCol span={24} xl={10}>
            <AnalyticsDescription player={player} />
          </BaseCol>
        </BaseRow>
      )}
    </>
  );
};

export default AnalyticsPage;
