import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import ProfitPackagesTable from '@app/components/profit-packages/table';
import { useBadge } from '@app/infrastructure/providers/badge-provider';

const ProfitPackagesPage: React.FC = () => {
  const { t } = useTranslation();
  const { rerenderBadge } = useBadge();

  useEffect(() => {
    rerenderBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PageTitle>{t('navigation.profitPackages')}</PageTitle>
      <BaseCol>
        <ProfitPackagesTable />
      </BaseCol>
    </>
  );
};

export default ProfitPackagesPage;
