import React from 'react';
import moment from 'moment-timezone';
import { Tournament } from '@app/infrastructure/classes/tournament';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';

interface IEndTimeColProps {
  data: Tournament;
  isPlayer: boolean;
}

const EndTimeCol: React.FC<IEndTimeColProps> = ({ data, isPlayer }) => {
  const time = data.endTime.clone();
  if (isPlayer) {
    const localTimeZone = moment.tz.guess();
    time.tz(localTimeZone);
  }
  return <div>{time.format(MomentFormats['HH:mm'])}</div>;
};

export default EndTimeCol;
