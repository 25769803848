/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { CloseOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/lib/date-picker';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import BillSelectBox, { IBillSelectBoxItemProps } from '@app/components/bills/select-box';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { ITournamentFilters } from '@app/infrastructure/interfaces/i-tournament';
import { getTournamentFilters, setTournamentFilters } from '@app/infrastructure/services/filters-storage.service';
import { BaseTag } from '@app/components/common/BaseTag/BaseTag';
import * as S from './styles';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import BaseRangeNumberInput, { rangeValue } from '@app/components/common/base-range-number-input';
import TournamentTypeSelect from '../type-select-box';

interface ITournamentsTableFiltersProps {
  onFilterChange: (filters: ITournamentFilters) => void;
}

const TournamentsTableFilters: React.FC<ITournamentsTableFiltersProps> = (props) => {
  const { onFilterChange } = props;
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user.user);

  const [buyinRange, setBuyinRange] = useState<rangeValue>([undefined, undefined]);
  const [dateRange, setDateRange] = useState<RangePickerProps['value']>(undefined);
  const [rooms, setRooms] = useState<IBillSelectBoxItemProps[]>([]);
  const [types, setTypes] = useState<number[]>([]);
  const [name, setName] = useState<string | undefined>();
  const [filters, setFilters] = useState<ITournamentFilters>({});

  const changeFilters = useCallback(
    (filters: ITournamentFilters) => {
      setTournamentFilters({
        ...filters,
        bills: rooms,
      });
      setFilters(filters);
      onFilterChange(filters);
    },
    [buyinRange, dateRange, rooms.length, types, name],
  );

  const handleBuyinChange = (inputValue: rangeValue) => {
    const [val1, val2] = inputValue;
    setBuyinRange(inputValue);
    changeFilters({
      ...filters,
      fromBuyin: val1,
      toBuyin: val2,
    });
  };

  const debouncedBuyinChange = debounce(handleBuyinChange, 1000);

  const handleTypeChange = (types: number[] | []) => {
    setTypes(types);
    changeFilters({
      ...filters,
      types,
    });
  };

  const debouncedTypeChange = debounce(handleTypeChange, 500);

  const debouncedNameChange = useCallback(
    debounce((value: string | undefined) => {
      changeFilters({
        ...filters,
        name: value as string,
      });
    }, 500),
    [buyinRange, dateRange, rooms.length, types],
  );

  const handleNameChange = (value: string | undefined) => {
    setName(value);
    debouncedNameChange(value);
  };

  const onDateChange: RangePickerProps['onChange'] = (values) => {
    setDateRange(values);
    let newFilters = {
      ...filters,
    };
    if (values) {
      const [fromDate, toDate] = values;
      const fromTime = moment(fromDate);
      const toTime = moment(toDate);

      if (user && user.isPlayer()) {
        fromTime.utc();
        toTime.utc();
      }

      newFilters = {
        ...filters,
        fromDate: fromTime?.format(MomentFormats['MM.DD.YYYY']),
        toDate: toTime?.format(MomentFormats['MM.DD.YYYY']),
        fromTime: fromTime?.format(MomentFormats['HH:mm']),
        toTime: toTime?.format(MomentFormats['HH:mm']),
      };
    } else {
      newFilters = {
        ...filters,
        fromDate: undefined,
        toDate: undefined,
        fromTime: undefined,
        toTime: undefined,
      };
    }
    changeFilters(newFilters);
  };

  const onBillsSelected = (bills: IBillSelectBoxItemProps[]) => {
    setRooms(bills);
    changeFilters({
      ...filters,
      bills: bills.map((bill) => bill.record.id),
    });
    setTournamentFilters({
      ...filters,
      bills: bills,
    });
  };

  const removeRooms = (tag: IBillSelectBoxItemProps) => {
    const filteredRooms = rooms.filter((room) => room.key !== tag.key);
    setRooms(filteredRooms);
    changeFilters({
      ...filters,
      bills: filteredRooms.map((room) => room.record.id),
    });
    setTournamentFilters({
      ...filters,
      bills: filteredRooms,
    });
  };

  const removeTypes = (tag: number) => {
    const filteredTypes = types.filter((type) => type !== tag);
    setTypes(filteredTypes);
    changeFilters({ ...filters, types: filteredTypes });
    setTournamentFilters({ ...filters, bills: rooms, types: filteredTypes });
  };

  useEffect(() => {
    const storageFilters = getTournamentFilters();
    if (storageFilters) {
      const { fromDate, toDate, fromTime, toTime, name, fromBuyin, toBuyin, types, bills } = storageFilters;
      const localFilters = {
        ...storageFilters,
        bills: bills?.length ? bills.map((bill) => bill.record.id) : undefined,
      };
      setTournamentFilters({
        ...storageFilters,
        bills,
      });
      setFilters(localFilters);
      onFilterChange(localFilters);

      if (fromDate && toDate) {
        setDateRange([moment(fromDate), moment(toDate)]);
        if (fromTime && toTime) {
          const from = moment.utc(`${fromDate} ${fromTime}`, MomentFormats['MM.DD.YYYY HH:mm']);
          const to = moment.utc(`${toDate} ${toTime}`, MomentFormats['MM.DD.YYYY HH:mm']);

          if (user && user.isPlayer()) {
            const localTimeZone = moment.tz.guess();
            from.tz(localTimeZone);
            to.tz(localTimeZone);
          }

          setDateRange([from, to]);
        }
      }
      if (name) {
        setName(name);
      }
      if (fromBuyin || toBuyin) {
        setBuyinRange([fromBuyin, toBuyin]);
      }
      if (types) {
        setTypes(types);
      }
      if (bills) {
        setRooms(bills);
      }
    }
  }, []);

  return (
    <div>
      <BaseRow gutter={[10, 10]} align="top">
        <BaseCol>
          <DatePicker.RangePicker
            format={MomentFormats['DD.MM.YYYY HH:mm']}
            showTime
            showSecond={false}
            value={dateRange}
            size="small"
            onChange={onDateChange}
          />
        </BaseCol>
        <BaseCol>
          <S.labeledDiv>
            <label className="label">{t('labels.room')}</label>
            <BillSelectBox
              placeholder=""
              size="small"
              width="150px"
              mode="multiple"
              type={[BILL_TYPES.ROOM]}
              onChange={(bills: IBillSelectBoxItemProps[]) => onBillsSelected(bills)}
              value={rooms}
              tagRender={() => <></>}
            />
          </S.labeledDiv>
        </BaseCol>
        <BaseCol>
          <BaseInput
            size="small"
            value={name}
            placeholder={t('labels.tournament')}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </BaseCol>
        <BaseCol>
          <BaseRangeNumberInput
            value={buyinRange}
            placeholder={t('labels.bi')}
            onChange={(inputValue) => debouncedBuyinChange(inputValue)}
          />
        </BaseCol>
        <BaseCol>
          <S.labeledDiv>
            <label className="label">{t('labels.type')}</label>
            <TournamentTypeSelect
              size="small"
              width="85px"
              mode="multiple"
              value={types}
              onChange={(types: number[]) => debouncedTypeChange(types)}
              tagRender={() => <></>}
            />
          </S.labeledDiv>
        </BaseCol>
      </BaseRow>
      <BaseRow style={{ margin: '10px 0 0 5px' }}>
        <BaseRow gutter={5}>
          {rooms.map((room) => (
            <BaseCol key={room.key}>
              <BaseTag
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  removeRooms(room);
                }}
                icon={<CloseOutlined />}
              >
                {room.label}
              </BaseTag>
            </BaseCol>
          ))}
        </BaseRow>
        <BaseRow gutter={5}>
          {types.map((type) => (
            <BaseCol key={type}>
              <BaseTag
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  removeTypes(type);
                }}
                icon={<CloseOutlined />}
              >
                {type}
              </BaseTag>
            </BaseCol>
          ))}
        </BaseRow>
      </BaseRow>
    </div>
  );
};

export default TournamentsTableFilters;
