import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Player } from '@app/infrastructure/classes/player';
import { Link } from 'react-router-dom';
import { ROUTES } from '@app/infrastructure/consts/routes';

interface ILoginCellProps {
  data: Player;
}

const LoginCell: React.FC<ILoginCellProps> = (props) => {
  const { data } = props;
  return (
    <div>
      <BaseTypography>{data.user && <Link to={`${ROUTES.PLAYERS}/${data.id}`}>{data.user.login}</Link>}</BaseTypography>
    </div>
  );
};

export default LoginCell;
