import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import useBills from '@app/infrastructure/hooks/use-bills';
import { IUpdateBill } from '@app/infrastructure/interfaces/i-bill';
import { Bill } from '@app/infrastructure/classes/bill';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';
import { timeReg } from '@app/infrastructure/regex/time-reg';

interface IBillsUpdateFormProps {
  data: Bill;
  onClose?: () => void;
  onSave?: () => void;
}

const BillsUpdateForm: React.FC<IBillsUpdateFormProps> = (props) => {
  const { data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { id, name, timeShift, type } = data;

  const { updateBill, loading } = useBills();

  const onFinish = async (values: IUpdateBill) => {
    if (values) {
      await updateBill(id, values, data);
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item
        name="name"
        label={t('labels.contentName')}
        initialValue={name}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <BaseInput />
      </BaseButtonsForm.Item>
      {type === BILL_TYPES.ROOM && (
        <BaseButtonsForm.Item
          name="time_shift"
          label={t('labels.timeShift')}
          initialValue={timeShift}
          rules={[
            { required: true, message: t('messages.requiredField') },
            {
              pattern: timeReg,
              message: t('messages.notCorrectTimeShift'),
            },
          ]}
        >
          <BaseInput />
        </BaseButtonsForm.Item>
      )}
    </BaseButtonsForm>
  );
};

export default BillsUpdateForm;
