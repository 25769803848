import React from 'react';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useTranslation } from 'react-i18next';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: PlayerBill;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  inputType,
  children,
  ...restProps
}) => {
  const isNumberInputType = inputType === 'number';
  const inputNode = isNumberInputType ? <InputNumber size="small" min={0} /> : <BaseInput size="small" />;
  const { t } = useTranslation();
  return (
    <td {...restProps}>
      {editing ? (
        <BaseForm.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={
            isNumberInputType
              ? [
                  {
                    required: true,
                    message: t('messages.requiredField'),
                  },
                  { type: 'number', min: 0 },
                ]
              : [
                  {
                    min: 2,
                    message: t('messages.minCharacters'),
                  },
                ]
          }
        >
          {inputNode}
        </BaseForm.Item>
      ) : (
        children
      )}
    </td>
  );
};
