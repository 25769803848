import { IBill, ICreateBill, IUpdateBill } from '@app/infrastructure/interfaces/i-bill';
import { httpApi } from './http.api';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const baseRoute = '/bills';

export const getBills = async (params: IPaginationMeta): Promise<IPaginatedData<IBill>> => {
  return await httpApi.get<IPaginatedData<IBill>>(baseRoute, { params }).then(({ data }) => data);
};

export async function getBillById(id: number): Promise<IBill> {
  const { data } = await httpApi.get(`${baseRoute}/${id}`);
  return data;
}

export async function createBills(bill: ICreateBill): Promise<IBill> {
  const { data } = await httpApi.post(baseRoute, bill);
  return data;
}

export async function updateBills(id: number, bill: IUpdateBill): Promise<IBill> {
  const { data } = await httpApi.put(`${baseRoute}/${id}`, bill);
  return data;
}

export async function deleteBills(id: number): Promise<IBill> {
  const { data } = await httpApi.delete(`${baseRoute}/${id}`);
  return data;
}
