import styled from 'styled-components';
import { BaseButton } from '../BaseButton/BaseButton';

export const ActivityButton = styled(BaseButton)`
  border: none;
  box-shadow: none;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 5px;
  }

  .ant-btn: {
    padding: 0;
  }

  span {
    width: 15px;
    height: 15px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;
