import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';

interface ILoginSenderCellProps {
  data: InternalTransfer;
}

const LoginSenderCell: React.FC<ILoginSenderCellProps> = (props) => {
  const { data } = props;
  return (
    <>
      <BaseTypography>{data.user.login}</BaseTypography>
    </>
  );
};

export default LoginSenderCell;
