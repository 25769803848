/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { STATUS_GAME_REQUEST } from '@app/infrastructure/enums/status-game-request.enum';
import usePlayerGames from '@app/infrastructure/hooks/use-player-games';
import { PlayerGame } from '@app/infrastructure/classes/player-game';
import { playerGameAdminInReviewTableColumns } from './cols';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { useBadge } from '@app/infrastructure/providers/badge-provider';

interface PlayerGamesAdminInReviewTableProps {
  canEdit?: boolean;
  canDelete?: boolean;
}

const PlayerGamesAdminInReviewTable: React.FC<PlayerGamesAdminInReviewTableProps> = (props) => {
  const { canEdit = false, canDelete = false } = props;
  const { playerGames, meta, loading, loadPlayerGames, deletePlayerGame } = usePlayerGames();

  const { key, rerender } = useRerender();
  const { rerenderBadge } = useBadge();

  const filterStatus = { statuses: [STATUS_GAME_REQUEST.IN_REVIEW] };

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadPlayerGames({ filters: filterStatus, reload: true });
  };

  const onDelete = async (record: PlayerGame) => {
    await deletePlayerGame(record.id);
    await onReload();
  };

  const onSave = async () => {
    rerenderBadge();
    rerender();
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PlayerGame> | SorterResult<PlayerGame>[],
  ) => {
    await loadPlayerGames({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<PlayerGame>(sorter as IModifiedSorterResult<PlayerGame>, 'updated_at', 'DESC'),
      reload: true,
      filters: filterStatus,
    });
  };

  useEffect(() => {
    onReload();
  }, []);

  return (
    <BaseTable
      rowKey="id"
      key={key}
      title={() => {
        return <BaseTableTitle onReload={onReload} size="small" />;
      }}
      columns={playerGameAdminInReviewTableColumns({
        canEdit,
        canDelete,
        onSave,
        onDelete,
      })}
      dataSource={playerGames}
      pagination={
        !loading &&
        hasPagination && {
          current: meta.page,
          pageSize: meta.limit,
          total: meta.total,
          showSizeChanger: true,
        }
      }
      loading={loading}
      onChange={handleTableChange}
      size="small"
      scroll={{ x: 1000 }}
      style={{ marginBottom: '20px' }}
      bordered
    />
  );
};

export default PlayerGamesAdminInReviewTable;
