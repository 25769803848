/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import FlcraftTable from '@app/components/flcraft/table';
import { useParams } from 'react-router-dom';
import { useMounted } from '@app/infrastructure/hooks/use-mounted';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { Player } from '@app/infrastructure/classes/player';
import BreadcrumbFlcraft from '@app/components/flcraft/breadcrumb';

const FlcraftDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const { playerId } = useParams();
  const { loadPlayerById } = usePlayers();
  const [player, setPlayer] = useState<Player | null>(null);
  const { isMounted } = useMounted();

  const fetch = useCallback(async () => {
    if (playerId) {
      const player = await loadPlayerById(Number(playerId));
      if (player) {
        setPlayer(player);
      }
    }
  }, [playerId, isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <PageTitle>{t('navigation.flcraft')}</PageTitle>
      {player && player.user && (
        <>
          <BreadcrumbFlcraft name={player.user.login} rank={player.rank?.name} />
          <div style={{ margin: '20px 0 0 0' }}>
            <FlcraftTable ids={[Number(playerId)]} showStatistic={true} canUpload={false} />
          </div>
        </>
      )}
    </>
  );
};

export default FlcraftDetailPage;
