import { useState } from 'react';
import { ILoadProps } from './interfaces/i-load-props';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { Player } from '../classes/player';
import { PersonalTransfer } from '../classes/personal-transfer';
import { ICreatePersonalTransfer, IUpdatePersonalTransfer } from '../interfaces/i-personal-transfer';
import {
  createPersonalTransfers,
  deletePersonalTransferById,
  getAllPersonalTransfers,
  getPersonalTransferById,
  getPersonalTransfersByPlayer,
  updatePersonalTransfers,
} from '@app/api/personal-transfers';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { HTTP_STATUSES } from '../consts/http-statuses';

type UsePersonalTransfers = {
  personalTransfers: PersonalTransfer[];
  meta: IPaginationMeta;
  loadAllPersonalTransfers: (props?: ILoadProps) => Promise<PersonalTransfer[] | undefined>;
  loadPersonalTransfersByPlayer: (props?: ILoadProps) => Promise<PersonalTransfer[] | undefined>;
  createPersonalTransfer: (data: ICreatePersonalTransfer) => Promise<PersonalTransfer | void>;
  loadPersonalTransferById: (id: number) => Promise<PersonalTransfer | void>;
  updatePersonalTransfer: (
    id: number,
    data: IUpdatePersonalTransfer,
    personalTransfer: PersonalTransfer,
  ) => Promise<PersonalTransfer | void>;
  deletePersonalTransfer: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUsePersonalTransfersProps {
  player?: Player | undefined;
  meta?: IPartialPaginationMeta;
}

const usePersonalTransfers = (props: IUsePersonalTransfersProps): UsePersonalTransfers => {
  const { player } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [personalTransfers, setPersonalTransfers] = useState<PersonalTransfer[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    sort: ['id', 'DESC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadAllPersonalTransfers = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getAllPersonalTransfers({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new PersonalTransfer(el));
      if (props?.reload) setPersonalTransfers(data);
      else setPersonalTransfers([...personalTransfers, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getAllPersonalTransfers({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new PersonalTransfer(el));
        if (props?.reload) setPersonalTransfers(data);
        else setPersonalTransfers([...personalTransfers, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadPersonalTransfersByPlayer = async (props?: ILoadProps) => {
    if (player) {
      setLoading(true);
      const { results, meta: metaRes } = await getPersonalTransfersByPlayer(player.id, { ...meta, ...props });
      let data = undefined;
      if (results.length || metaRes.total === 0) {
        data = results.map((el) => new PersonalTransfer(el));
        if (props?.reload) setPersonalTransfers(data);
        else setPersonalTransfers([...personalTransfers, ...data]);
        setMeta({
          ...meta,
          ...metaRes,
        });
      } else {
        let page = metaRes.page;
        if (metaRes.totalPages < page) page = metaRes.totalPages;
        const { results, meta: metaSecondReq } = await getPersonalTransfersByPlayer(player.id, {
          ...meta,
          ...metaRes,
          page,
          ...props,
        });
        if (results) {
          data = results.map((el) => new PersonalTransfer(el));
          if (props?.reload) setPersonalTransfers(data);
          else setPersonalTransfers([...personalTransfers, ...data]);
          setMeta({
            ...meta,
            ...metaSecondReq,
          });
        }
      }

      setLoading(false);
      return data;
    }
  };

  const loadPersonalTransferById = async (id: number) => {
    if (player) {
      setLoading(true);
      const data = await getPersonalTransferById(id).catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
      setLoading(false);
      if (data) return new PersonalTransfer(data);
    }
  };

  const createPersonalTransfer = async (data: ICreatePersonalTransfer) => {
    if (player) {
      setLoading(true);
      const result = await createPersonalTransfers(player.id, data).catch((err) => {
        if (err.options.statusCode === HTTP_STATUSES.CONFLICT) {
          return notificationController.error({
            message: t('messages.notEnoughMoney'),
          });
        } else {
          notificationController.error({ message: err.message });
        }
      });
      setLoading(false);
      if (result) {
        notificationController.success({
          message: t('messages.successAddPersonalTransfer'),
        });
        return new PersonalTransfer(result);
      }
    }
  };

  const updatePersonalTransfer = async (id: number, data: IUpdatePersonalTransfer) => {
    setLoading(true);
    const result = await updatePersonalTransfers(id, data);
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return new PersonalTransfer(result);
    }
  };

  const deletePersonalTransfer = async (id: number) => {
    setLoading(true);
    await deletePersonalTransferById(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  return {
    personalTransfers,
    meta,
    loading,
    loadAllPersonalTransfers,
    loadPersonalTransfersByPlayer,
    loadPersonalTransferById,
    createPersonalTransfer,
    updatePersonalTransfer,
    deletePersonalTransfer,
  };
};

export default usePersonalTransfers;
