import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import InternalTransferRequestsAdminTable from '@app/components/internal-transfer-requests/table';
import InternalTransfersAdminTable from '@app/components/internal-transfers/table';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import InternalTransfersForPlayerTable from '@app/components/players/internal-transfers/player-table';
import { ROLES } from '@app/infrastructure/enums/roles';
import { Player } from '@app/infrastructure/classes/player';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { Loading } from '@app/components/common/Loading/Loading';
import ApprovedInternalTransferRequestsTable from '@app/components/players/internal-transfer-requests/approved-player-table';
import { useBadge } from '@app/infrastructure/providers/badge-provider';

const PlayerView: React.FC = () => {
  const playerId = useAppSelector((state) => state.user.user?.player?.id);
  const [player, setPlayer] = useState<Player>();
  const { loadPlayerById, loading } = usePlayers();

  useEffect(() => {
    if (playerId) {
      loadPlayerById(playerId).then((player) => {
        setPlayer(player);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  return (
    <BaseCol>
      {(loading && <Loading />) ||
        (player && (
          <>
            <ApprovedInternalTransferRequestsTable player={player} canCreate />
            <InternalTransfersForPlayerTable player={player} />
          </>
        ))}
    </BaseCol>
  );
};

const AdminView: React.FC = () => {
  const { rerenderBadge } = useBadge();

  useEffect(() => {
    rerenderBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BaseCol>
      <InternalTransferRequestsAdminTable />
      <InternalTransfersAdminTable />
    </BaseCol>
  );
};

const InternalTransfersPage: React.FC = () => {
  const { t } = useTranslation();
  const role = useAppSelector((state) => state.user.user?.role);
  return (
    <>
      <PageTitle>{t('navigation.internalTransfers')}</PageTitle>
      {role === ROLES.PLAYER ? <PlayerView /> : <AdminView />}
    </>
  );
};

export default InternalTransfersPage;
