import { httpApi } from '@app/api/http.api';
import { ILimit, ICreateLimit, IUpdateLimit, IDynamicLimit } from '@app/infrastructure/interfaces/i-limit';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const route = 'limits';

export const getLimits = async (params: IPaginationMeta): Promise<IPaginatedData<ILimit>> => {
  return await httpApi.get<IPaginatedData<ILimit>>(route, { params }).then(({ data }) => data);
};

export const getDynamicLimitsForTable = async (): Promise<IDynamicLimit> => {
  return await httpApi.get<IDynamicLimit>('dynamic-limits/1').then(({ data }) => data);
};

export const getLimitsByRanks = async (rankId: number, params: IPaginationMeta): Promise<IPaginatedData<ILimit>> => {
  return await httpApi.get<IPaginatedData<ILimit>>(`ranks/${rankId}/${route}`, { params }).then(({ data }) => data);
};

export const createLimits = async (data: ICreateLimit): Promise<ILimit> => {
  return await httpApi.post<ILimit>(route, data).then(({ data }) => data);
};

export const updateLimits = async (id: number, data: IUpdateLimit): Promise<ILimit> => {
  return await httpApi.put<ILimit>(`${route}/${id}`, data).then(({ data }) => data);
};

export const updateDynamicLimitsForTable = async (data: IDynamicLimit): Promise<IDynamicLimit> => {
  return await httpApi.put<IDynamicLimit>('dynamic-limits/1', data).then(({ data }) => data);
};

export const deleteLimits = async (id: number): Promise<ILimit> => {
  return await httpApi.delete<ILimit>(`${route}/${id}`).then(({ data }) => data);
};
