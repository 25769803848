import i18next from 'i18next';
import { CANCELED_PACKAGE_STATUS } from '../enums/canceled-package-status';

export const statusProfitPackageDataSource = (): {
  id: CANCELED_PACKAGE_STATUS;
  label: string;
}[] => [
  {
    id: CANCELED_PACKAGE_STATUS.IN_REVIEW,
    label: i18next.t('labels.inReview'),
  },
  {
    id: CANCELED_PACKAGE_STATUS.APPROVED,
    label: i18next.t('labels.approved'),
  },
];
