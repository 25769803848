import { useState } from 'react';
import { ILoadProps } from './interfaces/i-load-props';
import { PlayerBill } from '../classes/player-bill';
import { ICreatePlayerBill, IUpdatePlayerBill } from '../interfaces/i-player-bill';
import {
  createPlayerBill,
  deletePlayerBillById,
  getPlayerBills,
  getPlayerBillById,
  updatePlayerBill,
  getPlayerBillsWithDeleted,
} from '@app/api/players-biils.api';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { Player } from '../classes/player';
import { HTTP_STATUSES } from '../consts/http-statuses';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';

type UsePlayersBills = {
  playersBills: PlayerBill[];
  meta: IPaginationMeta;
  loadPlayersBills: (props?: ILoadProps) => Promise<PlayerBill[] | undefined>;
  loadPlayersBillsWithDeleted: (props?: ILoadProps) => Promise<PlayerBill[] | undefined>;
  createPlayersBill: (data: ICreatePlayerBill) => Promise<PlayerBill | void>;
  loadPlayersBillById: (id: number) => Promise<PlayerBill | void>;
  updatePlayersBill: (id: number, data: IUpdatePlayerBill, playersBill: PlayerBill) => Promise<PlayerBill | void>;
  deletePlayersBill: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUsePlayersBillsProps {
  player: Player;
  meta?: IPartialPaginationMeta;
}

const usePlayersBills = (props: IUsePlayersBillsProps): UsePlayersBills => {
  const { player } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [playersBills, setPlayersBills] = useState<PlayerBill[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });
  const { t } = useTranslation();

  const loadPlayersBills = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getPlayerBills(player.id, { ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new PlayerBill(el));
      if (props?.reload) setPlayersBills(data);
      else setPlayersBills([...playersBills, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getPlayerBills(player.id, {
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new PlayerBill(el));
        if (props?.reload) setPlayersBills(data);
        else setPlayersBills([...playersBills, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadPlayersBillsWithDeleted = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getPlayerBillsWithDeleted(player.id, { ...meta, ...props });
    const data = results.map((el) => new PlayerBill(el));
    if (props?.reload) setPlayersBills(data);
    else setPlayersBills([...playersBills, ...data]);
    setMeta({
      ...meta,
      ...metaRes,
    });
    setLoading(false);
    return data;
  };

  const loadPlayersBillById = async (id: number) => {
    setLoading(true);
    const data = await getPlayerBillById(player.id, id).catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    setLoading(false);
    if (data) return new PlayerBill(data);
  };

  const createPlayersBill = async (data: ICreatePlayerBill) => {
    setLoading(true);
    const result = await createPlayerBill(player.id, data).catch((err) => {
      if (err.options.statusCode === HTTP_STATUSES.CONFLICT) {
        return notificationController.error({
          message: t('messages.billUsed'),
        });
      } else {
        notificationController.error({ message: err.message });
      }
    });
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successAddPlayerBill'),
      });
      return new PlayerBill(result);
    }
  };

  const updatePlayersBill = async (id: number, data: IUpdatePlayerBill, playersBill: PlayerBill) => {
    setLoading(true);
    const result = await updatePlayerBill(player.id, id, data);
    setLoading(false);
    playersBill.updateInfo(result);
    return new PlayerBill(result);
  };

  const deletePlayersBill = async (billId: number) => {
    setLoading(true);
    await deletePlayerBillById(player.id, billId);
    setLoading(false);
  };

  return {
    playersBills,
    meta,
    loading,
    loadPlayersBills,
    loadPlayersBillsWithDeleted,
    loadPlayersBillById,
    createPlayersBill,
    updatePlayersBill,
    deletePlayersBill,
  };
};

export default usePlayersBills;
