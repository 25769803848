import React from 'react';
import { Player } from '@app/infrastructure/classes/player';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';

interface IAvailableForWithdrawalInfoProps {
  player: Player;
}

const AvailableForWithdrawalInfo: React.FC<IAvailableForWithdrawalInfoProps> = ({ player }) => {
  const { t } = useTranslation();
  return (
    <BaseTypography>
      {t('labels.availableForWithdrawal')}: {CURRENCIES.DOLLAR} {player.availableForWithdrawal}
    </BaseTypography>
  );
};

export default AvailableForWithdrawalInfo;
