import React from 'react';
import moment from 'moment-timezone';
import { Tournament } from '@app/infrastructure/classes/tournament';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';

interface IStartTimeColProps {
  data: Tournament;
  isPlayer: boolean;
}

const StartTimeCol: React.FC<IStartTimeColProps> = ({ data, isPlayer }) => {
  const time = data.startTime.clone();

  if (isPlayer) {
    const localTimeZone = moment.tz.guess();
    time.tz(localTimeZone);
  }
  return <div>{time.format(MomentFormats['HH:mm'])}</div>;
};

export default StartTimeCol;
