import userReducer from '@app/store/slices/user-slice';
import authReducer from '@app/store/slices/auth-slice';
import nightModeReducer from '@app/store/slices/night-mode-slice';
import themeReducer from '@app/store/slices/theme-slice';
import pwaReducer from '@app/store/slices/pwa-slice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
};
