import React from 'react';
import { PlayerGame } from '@app/infrastructure/classes/player-game';

interface TournamentNameCellProps {
  data: PlayerGame;
}

const TournamentNameCell: React.FC<TournamentNameCellProps> = ({ data }) => {
  return <span>{data.tournamentName}</span>;
};

export default TournamentNameCell;
