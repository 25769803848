import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import useRanks from '@app/infrastructure/hooks/use-ranks';
import { ICreateRank } from '@app/infrastructure/interfaces/i-rank';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface IRanksCreateFormProps {
  onClose?: () => void;
  onSave?: () => void;
}

const RanksCreateForm: React.FC<IRanksCreateFormProps> = (props) => {
  const { onClose, onSave } = props;
  const { t } = useTranslation();

  const { createRank, loading } = useRanks();

  const onFinish = async (values: ICreateRank) => {
    if (values) {
      await createRank(values);
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <S.RankNameInput
        name="name"
        label={t('labels.contentName')}
        rules={[
          { required: true, message: t('messages.requiredField') },
          { type: 'integer', min: 0, message: t('messages.enterInt') },
        ]}
      >
        <InputNumber min={0} />
      </S.RankNameInput>

      <BaseRow justify={'space-between'}>
        <BaseButtonsForm.Item
          name="fund_limit"
          label={t('labels.fundLimit')}
          rules={[{ type: 'number', min: 0, required: true }]}
        >
          <InputNumber addonAfter="$" min={0} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="req_limit"
          label={t('labels.reqLimit')}
          rules={[{ type: 'number', min: 0, required: true }]}
        >
          <InputNumber addonAfter="$" min={0} />
        </BaseButtonsForm.Item>
      </BaseRow>
    </BaseButtonsForm>
  );
};

export default RanksCreateForm;
