import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { Player } from '@app/infrastructure/classes/player';
import PlayersBillSelectBox, { IPlayersBillSelectBoxItemProps } from '../../bills/select-box';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import * as S from './styles';

interface IInternalTransferRequestFormProps {
  player: Player;
  onClose?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  requested_amount: number;
  playersBill: IPlayersBillSelectBoxItemProps;
}

const InternalTransferRequestsCreateForm: React.FC<IInternalTransferRequestFormProps> = (props) => {
  const { player, onClose, onSave } = props;
  const { t } = useTranslation();

  const { createInternalTransferRequest, loading } = useInternalTransferRequests({ player });

  const onFinish = async (values: IFormValues) => {
    createInternalTransferRequest({
      requested_amount: values.requested_amount,
      players_bill_id: values.playersBill.record.id,
    }).then(() => {
      if (onSave) onSave();
    });
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item
        name="playersBill"
        label={t('labels.check')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <PlayersBillSelectBox player={player} withSumm />
      </BaseButtonsForm.Item>
      <S.SummInput
        name="requested_amount"
        label={t('labels.summ')}
        rules={[{ type: 'number', min: 0, required: true }]}
      >
        <InputNumber min={0} width="100%" />
      </S.SummInput>
    </BaseButtonsForm>
  );
};

export default InternalTransferRequestsCreateForm;
