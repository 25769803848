import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { httpApi } from './http.api';
import {
  ICreateInternalTransferRequest,
  IInternalTransferRequest,
  IInternalTransferRequestInfo,
  IUpdateInternalTransferRequest,
} from '@app/infrastructure/interfaces/i-internal-transfer-request';

const route = '/internal-transfer-requests';
const routeByPlayer = (id: number) => `players/${id}${route}`;
const routeApproved = 'internal-transfer-requests/approved';
const routeForPlayer = '/internal-transfer-requests/me';

export async function getInternalTransferBadges(): Promise<number> {
  return await httpApi.get(`${route}/badge`).then(({ data }) => data);
}

export async function getAllInternalTransferRequests(
  params: IPaginationMeta,
): Promise<IPaginatedData<IInternalTransferRequest>> {
  return await httpApi.get<IPaginatedData<IInternalTransferRequest>>(route, { params }).then(({ data }) => data);
}

export async function getInternalTransferRequestsByPlayerForAdmin(
  playerId: number,
  params: IPaginationMeta,
): Promise<IPaginatedData<IInternalTransferRequest>> {
  return await httpApi
    .get<IPaginatedData<IInternalTransferRequest>>(routeByPlayer(playerId), { params })
    .then(({ data }) => data);
}

export async function getInternalTransferRequestsForPlayer(
  params: IPaginationMeta,
): Promise<IPaginatedData<IInternalTransferRequest>> {
  return await httpApi
    .get<IPaginatedData<IInternalTransferRequest>>(routeForPlayer, { params })
    .then(({ data }) => data);
}

export async function getApprovedInternalTransferRequests(
  params: IPaginationMeta,
): Promise<IPaginatedData<IInternalTransferRequest>> {
  return await httpApi
    .get<IPaginatedData<IInternalTransferRequest>>(routeApproved, { params })
    .then(({ data }) => data);
}

export async function getInternalTransferRequestById(id: number): Promise<IInternalTransferRequest> {
  return await httpApi.get<IInternalTransferRequest>(`${route}/${id}`).then(({ data }) => data);
}

export async function createInternalTransferRequests(
  data: ICreateInternalTransferRequest,
): Promise<IInternalTransferRequest> {
  return await httpApi.post<IInternalTransferRequest>(route, data).then(({ data }) => data);
}

export async function updateInternalTransferRequests(
  id: number,
  data: IUpdateInternalTransferRequest,
): Promise<IInternalTransferRequest> {
  return await httpApi.put<IInternalTransferRequest>(`${route}/${id}`, data).then(({ data }) => data);
}

export async function deleteInternalTransferRequestById(id: number): Promise<IInternalTransferRequest> {
  return await httpApi.delete<IInternalTransferRequest>(`${route}/${id}`).then(({ data }) => data);
}

export async function getInternalTransferRequestInfo(
  id: number,
  userId: number,
): Promise<IInternalTransferRequestInfo> {
  return await httpApi
    .get<IInternalTransferRequestInfo>(`${route}/can-transfer/${id}/user/${userId}`)
    .then(({ data }) => data);
}

export async function startTransfers(id: number, userId: number): Promise<void> {
  return await httpApi.post(`${route}/start-transfer/${id}/user/${userId}`);
}

export async function stopTransfers(id: number): Promise<void> {
  return await httpApi.post(`${route}/stop-transfer/${id}`);
}
