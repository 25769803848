import i18next from 'i18next';
import { PURPOSE_PERSONAL_TRANSFER } from '../enums/purpose-personal-transfer.enum';

export const purposePersonalTransferDataSource = (): {
  id: PURPOSE_PERSONAL_TRANSFER;
  label: string;
}[] => [
  {
    id: PURPOSE_PERSONAL_TRANSFER.DEPOSIT_WITHDRAWAL,
    label: i18next.t('labels.depositOrWithdrawal'),
  },
  {
    id: PURPOSE_PERSONAL_TRANSFER.PERSONAL_TRANSFER,
    label: i18next.t('labels.personalTransfer'),
  },
  {
    id: PURPOSE_PERSONAL_TRANSFER.PLAYERS_GAME,
    label: i18next.t('labels.playersGame'),
  },
  {
    id: PURPOSE_PERSONAL_TRANSFER.OTHER,
    label: i18next.t('labels.other'),
  },
];
