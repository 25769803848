import React from 'react';
import * as S from './styles';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { valueType } from 'antd/lib/statistic/utils';

type modifiedType = valueType | null | undefined;

export type rangeValue = [modifiedType, modifiedType];

interface IBaseRangeNumberInputProps {
  value: rangeValue;
  placeholder?: string;
  onChange?: (value: rangeValue) => void;
}

const BaseRangeNumberInput: React.FC<IBaseRangeNumberInputProps> = (props) => {
  const { value, placeholder, onChange } = props;

  const handleChange = (inputValue: valueType | null, index: number) => {
    const newValue: rangeValue = [...value];
    newValue[index] = inputValue;
    if (onChange) onChange(newValue);
  };

  return (
    <S.inputWrapper tabIndex={-1}>
      <S.StyledInputNumber
        size="small"
        prefix={<RightOutlined />}
        placeholder={placeholder}
        value={value[0]}
        onChange={(inputValue) => handleChange(inputValue, 0)}
        bordered={false}
      />
      <S.StyledInputNumber
        size="small"
        prefix={<LeftOutlined />}
        placeholder={placeholder}
        value={value[1]}
        onChange={(inputValue) => handleChange(inputValue, 1)}
        bordered={false}
      />
    </S.inputWrapper>
  );
};

export default BaseRangeNumberInput;
