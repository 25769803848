/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { getPlayerFilters, setPlayerFilters } from '@app/infrastructure/services/filters-storage.service';
import { IPlayerFilters } from '@app/infrastructure/interfaces/i-player';

interface IPlayerTableFiltersProps {
  onFilterChange: (filters: IPlayerFilters) => void;
}

const PlayerTableFilters: React.FC<IPlayerTableFiltersProps> = (props) => {
  const { onFilterChange } = props;
  const { t } = useTranslation();

  const [name, setName] = useState<string | undefined>();
  const [filters, setFilters] = useState<IPlayerFilters>({});

  const changeFilters = useCallback(
    (filters: IPlayerFilters) => {
      setPlayerFilters(filters);
      setFilters(filters);
      onFilterChange(filters);
    },
    [name],
  );

  const debouncedNameChange = useCallback(
    debounce((value: string | undefined) => {
      changeFilters({
        ...filters,
        name: value as string,
      });
    }, 500),
    [],
  );

  const handleNameChange = (value: string | undefined) => {
    setName(value);
    debouncedNameChange(value);
  };

  useEffect(() => {
    const storageFilters = getPlayerFilters();
    if (storageFilters) {
      const { name } = storageFilters;
      changeFilters(storageFilters);

      if (name) {
        setName(name);
      }
    }
  }, []);

  return (
    <BaseInput
      size="small"
      placeholder={t('labels.enterPlayerLogin')}
      value={name}
      onChange={(e) => handleNameChange(e.target.value)}
      style={{ width: '300px' }}
    />
  );
};

export default PlayerTableFilters;
