/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import DebounceSelect from '../../../common/debounce-select';
import { useTranslation } from 'react-i18next';
import { IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { AutoCompleteProps } from 'antd/es/auto-complete';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';
import usePlayersBills from '@app/infrastructure/hooks/use-players-bills';
import { Player } from '@app/infrastructure/classes/player';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';

export interface IPlayersBillSelectBoxItemProps {
  key: any;
  label: React.ReactNode;
  value: any;
  record: PlayerBill;
}

interface IPlayersBillSelectBoxProps extends AutoCompleteProps {
  player: Player;
  withDeleted?: boolean;
  withSumm?: boolean;
  type?: BILL_TYPES[];
  width?: string;
  saveState?: boolean;
  placeholder?: string;
  onSelect?: (items: PlayerBill[]) => void;
}

const PlayersBillSelectBox: React.FC<IPlayersBillSelectBoxProps> = (props) => {
  const { t } = useTranslation();

  const {
    player,
    withDeleted = false,
    withSumm = false,
    width = '100%',
    placeholder = t('labels.selectFromList'),
    type = [BILL_TYPES.BILL, BILL_TYPES.ROOM],
    ...args
  } = props;

  const { loadPlayersBills, loadPlayersBillsWithDeleted, meta } = usePlayersBills({
    player,
    meta: {
      limit: 50,
      filters: {
        type,
      },
    },
  });

  const searchPlayerBills = async (searchValue: string, meta: IPaginationMeta) => {
    const playersBills = withDeleted
      ? await loadPlayersBillsWithDeleted({
          ...meta,
          search: ['bill.name', searchValue],
          reload: true,
          filters: {
            type,
          },
        })
      : await loadPlayersBills({
          ...meta,
          search: ['bill.name', searchValue],
          reload: true,
          filters: {
            type,
          },
        });
    if (playersBills)
      return playersBills.map((el) => {
        if (withSumm) {
          return {
            key: el.id,
            label: `${el.bill.name} - ${el.summ}${el.bill.currency.name}`,
            value: `${el.bill.name} ${el.bill.currency.name}`,
            record: el,
          };
        } else {
          return {
            key: el.id,
            label: `${el.bill.name} ${el.bill.currency.name}`,
            value: `${el.bill.name} ${el.bill.currency.name}`,
            record: el,
          };
        }
      });

    return [];
  };

  return (
    <DebounceSelect
      showSearch
      allowClear
      placeholder={placeholder}
      fetchOptions={searchPlayerBills}
      meta={meta}
      style={{ width }}
      {...args}
    />
  );
};

export default PlayersBillSelectBox;
