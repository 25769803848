import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login, LoginRequest } from '@app/api/auth.api';
import { setUser } from '@app/store/slices/user-slice';
import {
  deleteToken,
  deleteRefreshToken,
  deleteUser,
  persistToken,
  persistRefreshToken,
  readToken,
} from '@app/infrastructure/services/local-storage.service';
import { deleteFilters } from '@app/infrastructure/services/filters-storage.service';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) => {
  return login(loginPayload).then((res) => {
    persistToken(res.user.access_token);
    persistRefreshToken(res.user.refresh_token);
    dispatch(setUser(res.user));
    return res.user;
  });
});

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteRefreshToken();
  deleteUser();
  deleteFilters();
  dispatch(setUser(null));
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload.access_token;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
