import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PlayersTable } from '@app/components/players/table';

const PlayersPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('navigation.players')}</PageTitle>
      <BaseCol>
        <PlayersTable />
      </BaseCol>
    </>
  );
};

export default PlayersPage;
