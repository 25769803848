import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTournaments from '@app/infrastructure/hooks/use-tournaments';
import * as S from './styles';
import EventTimeStep from './steps/event-time';
import OptionsStep from './steps/options';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { mergeBy } from '@app/utils/utils';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Steps } from '@app/components/common/BaseSteps/BaseSteps.styles';
import { TOURNAMENT_PERIOD } from '@app/infrastructure/enums/tournament-period';
import { FrequencyType } from '@app/components/tournaments/calendar/frequency-select';
import { MonthDaysType } from '@app/infrastructure/enums/month-days-type';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import { IBillSelectBoxItemProps } from '@app/components/bills/select-box';
import { Colors } from '@app/infrastructure/enums/colors';
import { ICreateTournament } from '@app/infrastructure/interfaces/i-tournament';
import moment from 'moment-timezone';
import { Bill } from '@app/infrastructure/classes/bill';

interface IFormValues {
  name: string;
  type: number;
  buyIn: number;
  color: Colors;
  ranks: number[];
  bill?: IBillSelectBoxItemProps;
  periodType: TOURNAMENT_PERIOD;
  startDate: moment.Moment;
  startEndDate: moment.Moment[];
  startTime: moment.Moment;
  endTime: moment.Moment;
  repeatEvery: number;
  frequencyType: number;
  weekDays: number[];
  monthDays: number;
  groupId?: string;
}

interface ITournamentsCreateFormProps {
  id?: number;
  data?: ICreateTournament;
  bill?: Bill;
  groupId?: string;
  action?: 'update' | 'copy';
  onSave?: () => void;
}

interface FieldData {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  label?: string;
}

const TournamentsCreateForm: React.FC<ITournamentsCreateFormProps> = (props) => {
  const { id, data, bill, action, groupId, onSave } = props;
  const { t } = useTranslation();

  const { createTournament, loading } = useTournaments();

  const [current, setCurrent] = useState(0);
  const [form] = BaseForm.useForm();

  const [fields, setFields] = useState<FieldData[]>([
    { name: 'periodType', value: data ? data.periodType : TOURNAMENT_PERIOD.EVENT },
    { name: 'startDate' },
    { name: 'startEndDate' },
    { name: 'startTime' },
    { name: 'endTime' },
    { name: 'repeatEvery', value: 1 },
    { name: 'frequencyType', value: FrequencyType.Weekly },
    { name: 'weekDays' },
    { name: 'monthDays', value: MonthDaysType.WeekDay },
    { name: 'name' },
    { name: 'bill' },
    { name: 'buyIn' },
    { name: 'ranks' },
    { name: 'color' },
    { name: 'groupId' },
  ]);

  const next = () => {
    form.validateFields().then(() => {
      setCurrent(current + 1);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = async () => {
    form.validateFields().then(async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formData: any = {
        id,
        action,
      };
      const periodType = fields.find((el) => el.name === 'periodType');
      fields.forEach((field) => {
        if ('value' in field) {
          formData[field.name] = field.value;
          if (field.name === 'startDate' && periodType && periodType.value === TOURNAMENT_PERIOD.EVENT) {
            const startDate = field.value as moment.Moment;
            formData.startDate = startDate.toISOString(true);
          }
          if (field.name === 'startEndDate' && periodType && periodType.value === TOURNAMENT_PERIOD.REGULAR) {
            const [startDate, endDate] = field.value as [moment.Moment, moment.Moment];
            formData.startDate = startDate.toISOString(true);
            formData.endDate = endDate.toISOString(true);
          }
          if (field.name === 'startTime') {
            const startTime = field.value as moment.Moment;
            formData.startTime = startTime.format(MomentFormats['HH:mm']);
          }
          if (field.name === 'endTime') {
            const endTime = field.value as moment.Moment;
            formData.endTime = endTime.format(MomentFormats['HH:mm']);
          }
          if (field.name === 'bill') {
            const bill = field.value as IBillSelectBoxItemProps;
            formData.bill = bill.record;
          }
        }
      });

      await createTournament(formData);
      if (onSave) onSave();
    });
  };

  const steps = [
    {
      title: t('labels.options'),
    },
    {
      title: t('labels.eventTime'),
    },
  ];

  const formFieldsUi = [
    <OptionsStep key="1" />,
    <EventTimeStep form={form} key="2" isTypeTournamentDisabled={data && true} />,
  ];

  const prepareData = (): IFormValues | undefined => {
    if (data) {
      const values: IFormValues = {
        name: data.name,
        type: data.type,
        buyIn: data.buyIn,
        color: data.color,
        ranks: data.ranks,
        bill: bill
          ? {
              key: bill.id,
              label: `${bill.name} ${bill.currency.name}`,
              value: `${bill.name}`,
              record: bill,
            }
          : undefined,
        periodType: data.periodType ?? TOURNAMENT_PERIOD.EVENT,
        startDate: moment(data.startDate),
        startEndDate: [moment(data.startDate), moment(data.endDate)],
        startTime: moment(data.startTime, MomentFormats['HH:mm']),
        endTime: moment(data.endTime, MomentFormats['HH:mm']),
        repeatEvery: data.repeatEvery ?? 1,
        frequencyType: data.frequencyType ?? FrequencyType.Weekly,
        weekDays: data.weekDays,
        monthDays: data.monthDays ?? MonthDaysType.WeekDay,
        groupId: groupId,
      };
      return values;
    }
  };

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const values: any = prepareData();
      if (values) {
        const fieldValues = fields.map((item) => ({
          name: item.name,
          value: item.value || item.value === 0 ? item.value : values[item.name],
        }));
        setFields(fieldValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <BaseForm
      name="stepForm"
      form={form}
      fields={fields}
      initialValues={data && prepareData()}
      onFieldsChange={(_, allFields) => {
        const currentFields = allFields.map((item) => ({
          name: Array.isArray(item.name) ? item.name[0] : '',
          value: item.value,
        }));
        const uniqueData = mergeBy(fields, currentFields, 'name');
        setFields(uniqueData);
      }}
      onFinish={onFinish}
      size="small"
    >
      <Steps size="small" current={current} items={steps} />

      <S.FormContent>{formFieldsUi[current]}</S.FormContent>
      <S.Row>
        {current > 0 && (
          <S.PrevButton type="default" onClick={() => prev()}>
            {t('controls.previous')}
          </S.PrevButton>
        )}
        {current < steps.length - 1 && (
          <BaseButton size="small" type="primary" onClick={() => next()}>
            {t('controls.next')}
          </BaseButton>
        )}
        {current === steps.length - 1 && (
          <BaseButton size="small" type="primary" onClick={onFinish} loading={loading}>
            {t('controls.save')}
          </BaseButton>
        )}
      </S.Row>
    </BaseForm>
  );
};

export default TournamentsCreateForm;
