import moment from 'moment';
import { CANCELED_PACKAGE_STATUS } from '../enums/canceled-package-status';
import { PACKAGE_TERMS_ENUM } from '../enums/package-terms.enum';
import { IProfitPackage } from '../interfaces/i-profit-package';
import { Player } from './player';
import { ProfitPackageImage } from './profit-package-image';

export class ProfitPackage {
  id: number;
  startDate: moment.Moment;
  canceledDate?: string;
  packageTerms: PACKAGE_TERMS_ENUM;
  profitPackage?: number;
  fundsPart?: number;
  playersPart?: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  canceledPackageStatus?: CANCELED_PACKAGE_STATUS;
  nextPackageTerms?: PACKAGE_TERMS_ENUM;
  player?: Player;
  profitPackageImages?: ProfitPackageImage[];

  constructor(data: IProfitPackage) {
    this.id = data.id;
    this.startDate = moment(data.start_date);
    this.canceledDate = data.canceled_date;
    this.packageTerms = data.package_terms;
    this.profitPackage = data.profit_package;
    this.fundsPart = data.funds_part;
    this.playersPart = data.players_part;
    this.createdAt = moment(data.created_at);
    this.updatedAt = moment(data.updated_at);
    this.canceledPackageStatus = data.canceled_package_status;
    this.nextPackageTerms = data.next_package_terms;
    this.player = data.player ? new Player(data.player) : undefined;
    this.profitPackageImages = data.profit_package_images?.length
      ? data.profit_package_images.map((profitPackageImage) => {
          return new ProfitPackageImage(profitPackageImage);
        })
      : undefined;
  }
}
