import React from 'react';
import { ProfitPackageImage } from '@app/infrastructure/classes/profit-package-image';
import ImageWithPreview from '@app/components/common/image';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

interface PlayerBillImageProps {
  profitPackageImage: ProfitPackageImage;
}

export const PlayerBillImage: React.FC<PlayerBillImageProps> = ({ profitPackageImage }) => {
  return (
    <BaseCol>
      <BaseCol>
        {profitPackageImage.playersBill.bill.name} {profitPackageImage.playersBill.bill.currency.name}
      </BaseCol>
      <BaseCol>
        <ImageWithPreview data={profitPackageImage.image} />
      </BaseCol>
    </BaseCol>
  );
};
