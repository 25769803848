import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import FlcraftTable from '@app/components/flcraft/table';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { ROLES } from '@app/infrastructure/enums/roles';
import FlcraftMergedTable from '@app/components/flcraft/merged-table';

const PlayerView: React.FC = () => {
  const player = useAppSelector((state) => state.user.user?.player);

  return <div>{player && <FlcraftTable ids={[player?.id]} />}</div>;
};

const AdminView: React.FC = () => {
  return <FlcraftMergedTable />;
};

const FlcraftPage: React.FC = () => {
  const { t } = useTranslation();

  const role = useAppSelector((state) => state.user.user?.role);

  return (
    <>
      <PageTitle>{t('navigation.flcraft')}</PageTitle>
      {role === ROLES.PLAYER ? <PlayerView /> : <AdminView />}
    </>
  );
};

export default FlcraftPage;
