import { Player } from '@app/infrastructure/classes/player';
import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import LoginCell from './login';
import RankCell from './rank';
import ProfitPackageCell from './profit-package';
import PackageTermsCell from './package-terms';

interface IPlayersTableColumnsProps {
  canRead?: boolean;
  canDelete?: boolean;
  onInfo: (data: Player) => void;
  onDelete: (data: Player) => void;
}

export const playersTableColumns = ({
  canRead,
  canDelete,
  onInfo,
  onDelete,
}: IPlayersTableColumnsProps): IModifiedTableColumn<Player>[] => {
  const columns: IModifiedTableColumn<Player>[] = [
    {
      title: `${i18next.t('labels.login')}`,
      dataIndex: 'user',
      sortField: 'user.login',
      sorter: true,
      render: (_, record) => <LoginCell data={record} />,
    },
    {
      title: `${i18next.t('labels.profitPackage')}`,
      dataIndex: 'profitPackage',
      sortField: 'profit_packages.profit_package',
      sorter: true,
      render: (_, record) => <ProfitPackageCell data={record} />,
    },
    {
      title: `${i18next.t('labels.packageTerms')}`,
      dataIndex: 'profitPackage',
      sortField: 'profit_packages.package_terms',
      sorter: true,
      render: (_, record) => <PackageTermsCell data={record} />,
    },
    {
      title: `${i18next.t('labels.fundMoney')}`,
      dataIndex: 'fundMoney',
      sortField: 'fund_money',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.overMoney')}`,
      dataIndex: 'overMoney',
      sortField: 'over_money',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.rank')}`,
      dataIndex: 'rank',
      sortField: 'rank.name',
      sorter: true,
      render: (_, record) => <RankCell data={record} />,
    },
  ];
  if (canRead || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      align: 'center',
      width: '150px',
      render: (_, record) => (
        <BaseActionsCell
          canRead={canRead}
          canDelete={canDelete}
          onDelete={() => {
            onDelete(record);
          }}
          onInfo={() => {
            onInfo(record);
          }}
          size="small"
        />
      ),
    });
  }
  return columns;
};
