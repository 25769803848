import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { PlayerGame } from '@app/infrastructure/classes/player-game';
import { Link } from 'react-router-dom';
import { ROUTES } from '@app/infrastructure/consts/routes';

interface ILoginCellProps {
  data: PlayerGame;
}

const LoginCell: React.FC<ILoginCellProps> = (props) => {
  const { data } = props;
  return (
    <BaseTypography>
      {<Link to={`${ROUTES.PLAYERS}/${data.player.id}`}>{data.player.user && data.player.user.login}</Link>}
    </BaseTypography>
  );
};

export default LoginCell;
