import React from 'react';
import * as S from '../styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { Rank } from '@app/infrastructure/classes/rank';
import useRanks from '@app/infrastructure/hooks/use-ranks';
import { IUpdateRank } from '@app/infrastructure/interfaces/i-rank';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface IRanksUpdateFormProps {
  data: Rank;
  onClose?: () => void;
  onSave?: () => void;
}

const RanksUpdateForm: React.FC<IRanksUpdateFormProps> = (props) => {
  const { data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { id, name, fundLimit, reqLimit } = data;

  const { updateRank, loading } = useRanks();

  const onFinish = async (values: IUpdateRank) => {
    if (values) {
      await updateRank(id, values);
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <S.RankNameInput
        name="name"
        label={t('labels.contentName')}
        initialValue={name}
        rules={[{ type: 'integer', min: 0, message: t('messages.enterInt') }]}
      >
        <InputNumber min={0} />
      </S.RankNameInput>
      <BaseRow justify={'space-between'}>
        <BaseButtonsForm.Item
          name="fund_limit"
          label={t('labels.fundLimit')}
          initialValue={fundLimit}
          rules={[{ type: 'number', min: 0 }]}
        >
          <InputNumber addonAfter="$" min={0} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="req_limit"
          label={t('labels.reqLimit')}
          initialValue={reqLimit}
          rules={[{ type: 'number', min: 0 }]}
        >
          <InputNumber addonAfter="$" min={0} />
        </BaseButtonsForm.Item>
      </BaseRow>
    </BaseButtonsForm>
  );
};

export default RanksUpdateForm;
