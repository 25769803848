import { ANALYTICS_STAGES } from '../enums/analytics-stages.enum';
import { IAnalytics } from '../interfaces/i-analytics';
import { Player } from './player';

export class Analytics {
  id: number;
  stage: ANALYTICS_STAGES;
  numberOfHands: number;
  abi: number;
  ev: number;
  net: number;
  period: string;
  color: string;
  player: Player;

  constructor(data: IAnalytics) {
    this.id = data.id;
    this.stage = data.stage;
    this.numberOfHands = data.number_of_hands;
    this.abi = data.abi;
    this.ev = data.ev;
    this.net = data.net;
    this.period = data.period;
    this.color = data.color;
    this.player = new Player(data.player);
  }
}
