/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { UploadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Rule } from 'antd/lib/form';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';
import useEventListener from '@app/infrastructure/hooks/use-event-listener';

interface ImageUploadProps {
  rules?: Rule[];
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ rules }) => {
  const { t } = useTranslation();

  const [image, setImage] = useState<UploadFile>();

  const uploadRef = useRef<any>();

  const handlePasteFile = (e: any) => {
    const file = e.clipboardData?.files[0];
    if (file) {
      const dropEvent = new DragEvent('drop', {
        dataTransfer: e.clipboardData,
        bubbles: true,
        cancelable: true,
      });
      uploadRef.current?.upload?.uploader.fileInput.dispatchEvent(dropEvent);
    }
  };

  useEventListener('paste', handlePasteFile);

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'removed') {
      setImage(undefined);
    } else {
      setImage(info.file);
    }
  };

  const normFile = (e: any) => {
    return e.file;
  };

  const uploadButton = (
    <BaseButton size="small" type="dashed" icon={<UploadOutlined />}>
      {t('controls.upload')}
    </BaseButton>
  );

  const beforeUpload = (file: UploadFile) => {
    if (file.type) {
      const fileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const isImage = fileTypes.includes(file.type);
      if (!isImage) {
        notificationController.error({ message: t('messages.notPngJpegFile') });
        file.status = 'error';
      }
    }
    return false;
  };

  return (
    <BaseButtonsForm.Item name="image" valuePropName="file" getValueFromEvent={normFile} noStyle rules={rules}>
      <BaseUpload
        name="file"
        listType="picture"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        maxCount={1}
        ref={uploadRef}
      >
        {!image && uploadButton}
      </BaseUpload>
    </BaseButtonsForm.Item>
  );
};
