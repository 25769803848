import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import styled from 'styled-components';

export const BaseSpace = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlayerBillsTable = styled(BaseTable)`
  .ant-table-tbody > tr > td {
    border-bottom-color: #f0f0f0;
  }
`;
export const AddBtn = styled(BaseButton)`
  padding: 0;
`;
