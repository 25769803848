import { ICreatePlayer, IPlayersStatistic } from '@app/infrastructure/interfaces/i-player';
import { IPlayer } from '@app/infrastructure/interfaces/i-player';
import { IUpdatePlayer } from '@app/infrastructure/interfaces/i-player';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { httpApi } from './http.api';

const baseRoute = '/players';

export const getPlayers = async (params: IPaginationMeta): Promise<IPaginatedData<IPlayer>> => {
  return await httpApi.get<IPaginatedData<IPlayer>>(baseRoute, { params }).then(({ data }) => data);
};

export const getPlayersStatistics = async (): Promise<IPlayersStatistic> => {
  return await httpApi.get<IPlayersStatistic>(`${baseRoute}/statistic`).then(({ data }) => data);
};

export async function getPlayerById(id: number): Promise<IPlayer> {
  const { data } = await httpApi.get(`${baseRoute}/${id}`);
  return data;
}

export async function createPlayers(player: ICreatePlayer): Promise<IPlayer> {
  const { data } = await httpApi.post(baseRoute, player);
  return data;
}

export async function updatePlayers(id: number, player: IUpdatePlayer): Promise<IPlayer> {
  const { data } = await httpApi.put(`${baseRoute}/${id}`, player);
  return data;
}

export async function deletePlayers(id: number): Promise<IPlayer> {
  const { data } = await httpApi.delete(`${baseRoute}/${id}`);
  return data;
}
