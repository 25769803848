/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Player } from '@app/infrastructure/classes/player';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import BaseTableTitle from '@app/components/common/base-table-title';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { playersTableColumns } from './cols';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/infrastructure/consts/routes';
import PlayersCreateForm from '../create-form';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import PlayerTableFilters from './filters';
import { IPlayerFilters } from '@app/infrastructure/interfaces/i-player';
import { getPlayerFilters } from '@app/infrastructure/services/filters-storage.service';
import PlayerStatisticView from './statistic';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

export const PlayersTable: React.FC = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<IPlayerFilters | undefined>();
  const { players, meta, loading, loadPlayers, deletePlayer } = usePlayers({
    meta: {
      limit: 100,
    },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const hasPagination = meta.total > players.length;

  const user = useAppSelector((state) => state.user.user);

  const onReload = async () => {
    await loadPlayers({ filters, reload: true });
  };

  const onCreate = () => {
    setModalOpened(true);
  };

  const onInfo = async (record: Player) => {
    if (record.id) navigate(`${ROUTES.PLAYERS}/${record.id}`);
  };

  const onDelete = async (record: Player) => {
    await deletePlayer(record.id);
    await onReload();
  };

  const onClose = () => {
    setModalOpened(false);
  };

  const onSave = async (player: Player) => {
    setModalOpened(false);
    navigate(`${ROUTES.PLAYERS}/${player.id}`);
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<Player> | SorterResult<Player>[],
  ) => {
    await loadPlayers({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<Player>(sorter as IModifiedSorterResult<Player>),
      reload: true,
      filters,
    });
  };

  useEffect(() => {
    const storageFilters = getPlayerFilters();
    if (storageFilters && filters) {
      onReload();
    }
    if (!storageFilters) {
      onReload();
    }
  }, [filters]);

  useEffect(() => {
    const tableContent = document.querySelector('.ant-table-body');
    let isFetching = false;
    const eventListener = async (event: any) => {
      if (event.target) {
        const maxScroll = Math.ceil(event.target.scrollHeight - event.target.clientHeight) - 1;
        const currentScroll = Math.ceil(event.target.scrollTop);
        if (currentScroll >= maxScroll && hasPagination && !isFetching && !loading) {
          isFetching = true;
          await loadPlayers({ filters, page: meta.page + 1, reload: false });
          isFetching = false;
        }
      }
    };
    if (tableContent) {
      tableContent.addEventListener('scroll', eventListener);
    }
    return () => {
      if (tableContent) {
        tableContent.removeEventListener('scroll', eventListener);
      }
    };
  }, [meta, filters, hasPagination, loading]);

  return (
    <>
      <div>
        <BaseTable
          size="small"
          rowKey="id"
          bordered
          scroll={{ x: 800, y: `calc(100vh - 350px)` }}
          dataSource={players}
          title={() => {
            return (
              <BaseRow gutter={[20, 20]}>
                <BaseCol span={24}>
                  <PlayerStatisticView />
                </BaseCol>
                <BaseCol span={24}>
                  <BaseTableTitle
                    canCreate={user?.canCreate('playerPermission')}
                    onCreate={onCreate}
                    onReload={onReload}
                    size="small"
                    extra={<PlayerTableFilters onFilterChange={(filters) => setFilters(filters)} />}
                  />
                </BaseCol>
              </BaseRow>
            );
          }}
          columns={playersTableColumns({
            canRead: user?.canRead('playerPermission'),
            canDelete: user?.fullAccess('playerPermission'),
            onDelete,
            onInfo,
          })}
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
      <BaseModal title={t('labels.createPlayer')} open={modalOpened} onCancel={onClose} footer={null} destroyOnClose>
        <PlayersCreateForm onClose={onClose} onSave={onSave} />
      </BaseModal>
    </>
  );
};
