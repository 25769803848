import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { httpApi } from './http.api';
import {
  ICreateInternalTransfer,
  ICreateInternalTransferFromAdmin,
  ICreateInternalTransferToAdmin,
  IInternalTransfer,
  IUpdateInternalTransfer,
} from '@app/infrastructure/interfaces/i-internal-transfer';

const route = '/internal-transfers';
const routeByPlayer = (id: number) => `players/${id}${route}`;

export async function getAllInternalTransfers(params: IPaginationMeta): Promise<IPaginatedData<IInternalTransfer>> {
  return await httpApi.get<IPaginatedData<IInternalTransfer>>(route, { params }).then(({ data }) => data);
}

export async function getInternalTransfersByPlayer(
  playerId: number,
  params: IPaginationMeta,
): Promise<IPaginatedData<IInternalTransfer>> {
  return await httpApi
    .get<IPaginatedData<IInternalTransfer>>(routeByPlayer(playerId), { params })
    .then(({ data }) => data);
}

export async function getInternalTransferById(id: number): Promise<IInternalTransfer> {
  return await httpApi.get<IInternalTransfer>(`${route}/${id}`).then(({ data }) => data);
}

export async function createInternalTransfers(data: ICreateInternalTransfer): Promise<IInternalTransfer> {
  return await httpApi.post<IInternalTransfer>(route, data).then(({ data }) => data);
}

export async function createInternalTransfersAdmin(data: ICreateInternalTransferFromAdmin): Promise<IInternalTransfer> {
  return await httpApi.post<IInternalTransfer>(`${route}/admin`, data).then(({ data }) => data);
}

export async function createInternalTransfersToAdmin(data: ICreateInternalTransferToAdmin): Promise<IInternalTransfer> {
  return await httpApi.post<IInternalTransfer>(`${route}/to-admin`, data).then(({ data }) => data);
}

export async function updateInternalTransfers(id: number, data: IUpdateInternalTransfer): Promise<IInternalTransfer> {
  return await httpApi.put<IInternalTransfer>(`${route}/${id}`, data).then(({ data }) => data);
}

export async function updateInternalTransfersAdmin(
  id: number,
  data: IUpdateInternalTransfer,
): Promise<IInternalTransfer> {
  return await httpApi.put<IInternalTransfer>(`${route}/${id}/admin`, data).then(({ data }) => data);
}

export async function deleteInternalTransferById(id: number): Promise<IInternalTransfer> {
  return await httpApi.delete<IInternalTransfer>(`${route}/${id}`).then(({ data }) => data);
}
