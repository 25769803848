/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { Player } from '@app/infrastructure/classes/player';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import { approvedInternalTransferRequestTableColumns } from './cols';
import OverMoneyInfo from '../over-money-info';
import { STATUS_INTERNAL_TRANSFER } from '@app/infrastructure/enums/status-internal-transfer.enum';
import InternalTransferSendForm from '../send-form';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import usePlayers from '@app/infrastructure/hooks/use-players';

interface InternalTransferRequestsTableProps {
  player: Player;
  canCreate?: boolean;
}

const ApprovedInternalTransferRequestsTable: React.FC<InternalTransferRequestsTableProps> = (props) => {
  const { player, canCreate = false } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedInternalTransferRequest, setSelectedInternalTransferRequest] =
    useState<InternalTransferRequest | null>(null);
  const { internalTransferRequests, stopTransfer, meta, loading, loadApprovedInternalTransferRequests } =
    useInternalTransferRequests({
      player,
    });

  const { updatePlayerInfoById } = usePlayers();

  const { t } = useTranslation();
  const { rerender } = useRerender();

  let isInApproved = false;
  if (selectedInternalTransferRequest && selectedInternalTransferRequest.status === STATUS_INTERNAL_TRANSFER.APPROVED) {
    isInApproved = true;
  }

  const modalTitle = selectedInternalTransferRequest && t('controls.send');

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadApprovedInternalTransferRequests({ reload: true });
  };

  const onClose = async () => {
    setModalOpened(false);
    selectedInternalTransferRequest && (await stopTransfer(selectedInternalTransferRequest.id));
    setSelectedInternalTransferRequest(null);
  };

  const onSave = async () => {
    onClose();
    await updatePlayerInfoById(player.id, player);
    rerender();
    await onReload();
  };

  const onSend = async (record: InternalTransferRequest) => {
    setSelectedInternalTransferRequest(record);
    setModalOpened(true);
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InternalTransferRequest> | SorterResult<InternalTransferRequest>[],
  ) => {
    await loadApprovedInternalTransferRequests({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<InternalTransferRequest>(
        sorter as IModifiedSorterResult<InternalTransferRequest>,
        'updated_at',
        'DESC',
      ),
      reload: true,
    });
  };

  useEffect(() => {
    loadApprovedInternalTransferRequests();
  }, []);

  return (
    <>
      <div>
        <BaseTable
          rowKey="id"
          title={() => {
            return (
              <BaseTableTitle
                canCreate={canCreate}
                onReload={onReload}
                extra={<OverMoneyInfo player={player} />}
                size="small"
              />
            );
          }}
          columns={approvedInternalTransferRequestTableColumns({
            onSend,
            player,
          })}
          dataSource={internalTransferRequests}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          style={{ marginBottom: '20px' }}
          size="small"
          scroll={{ x: 1000 }}
          bordered
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} destroyOnClose footer={null}>
        {selectedInternalTransferRequest && isInApproved && (
          <InternalTransferSendForm
            player={player}
            internalTransferRequest={selectedInternalTransferRequest}
            onClose={onClose}
            onSave={onSave}
          />
        )}
      </BaseModal>
    </>
  );
};

export default ApprovedInternalTransferRequestsTable;
