import { useState } from 'react';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HttpStatus } from '../enums/http-status';
import { ProfitPackage } from '../classes/profit-package';
import { ICalculatePackage, ICreateProfitPackage } from '../interfaces/i-profit-package';
import {
  calculateProfitPackages,
  cancelCalculateProfitPackages,
  createProfitPackages,
  getProfitPackageById,
  getProfitPackages,
  updateCalculateProfitPackages,
} from '@app/api/profit-packages';
import { Player } from '../classes/player';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ILoadProps } from './interfaces/i-load-props';

type UseProfitPackages = {
  profitPackages: ProfitPackage[];
  meta: IPaginationMeta;
  loadProfitPackages: (props?: ILoadProps) => Promise<ProfitPackage[] | undefined>;
  loadProfitPackageByPlayer: () => Promise<ProfitPackage | undefined>;
  createProfitPackage: (data: ICreateProfitPackage) => Promise<ProfitPackage | void>;
  calculateProfitPackage: (profitPackageId: number, data: ICalculatePackage) => Promise<ProfitPackage | void>;
  updateCalculateProfitPackage: (profitPackageId: number) => Promise<ProfitPackage | void>;
  cancelCalculateProfitPackage: (profitPackageId: number) => Promise<ProfitPackage | void>;
  loading: boolean;
};

interface IUseProfitPackagesProps {
  player?: Player | undefined;
  meta?: IPartialPaginationMeta;
}

const useProfitPackages = (props: IUseProfitPackagesProps): UseProfitPackages => {
  const { player } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [profitPackages, setProfitPackages] = useState<ProfitPackage[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    sort: ['canceled_package_status', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadProfitPackages = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getProfitPackages({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new ProfitPackage(el));
      if (props?.reload) setProfitPackages(data);
      else setProfitPackages([...profitPackages, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getProfitPackages({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new ProfitPackage(el));
        if (props?.reload) setProfitPackages(data);
        else setProfitPackages([...profitPackages, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadProfitPackageByPlayer = async () => {
    if (player) {
      setLoading(true);
      const data = await getProfitPackageById(player.id).catch((err) => {
        setLoading(false);
        return notificationController.error({ message: err.message });
      });
      setLoading(false);
      if (data) return new ProfitPackage(data);
    }
  };

  const createProfitPackage = async (data: ICreateProfitPackage) => {
    if (player) {
      setLoading(true);
      const result = await createProfitPackages(player.id, data).catch((error) => {
        if (error.options.statusCode === HttpStatus.CONFLICT) {
          return notificationController.error({
            message: t('messages.profitPackageUsed'),
          });
        }
      });

      setLoading(false);
      if (result) {
        notificationController.success({
          message: t('messages.successAdd'),
        });
        return new ProfitPackage(result);
      }
    }
  };

  const calculateProfitPackage = async (profitPackageId: number, data: ICalculatePackage) => {
    if (player) {
      setLoading(true);
      const result = await calculateProfitPackages(player.id, profitPackageId, data);
      setLoading(false);
      if (result) {
        notificationController.success({
          message: t('messages.successReqSend'),
        });
        return new ProfitPackage(result);
      }
    }
  };

  const updateCalculateProfitPackage = async (profitPackageId: number) => {
    setLoading(true);
    const result = await updateCalculateProfitPackages(profitPackageId);
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return new ProfitPackage(result);
    }
  };

  const cancelCalculateProfitPackage = async (profitPackageId: number) => {
    setLoading(true);
    const result = await cancelCalculateProfitPackages(profitPackageId);
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return new ProfitPackage(result);
    }
  };

  return {
    profitPackages,
    loading,
    meta,
    loadProfitPackages,
    loadProfitPackageByPlayer,
    createProfitPackage,
    calculateProfitPackage,
    updateCalculateProfitPackage,
    cancelCalculateProfitPackage,
  };
};

export default useProfitPackages;
