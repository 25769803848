import styled from 'styled-components';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

export const NeedFromRow = styled(BaseRow)`
  .ant-input-number-affix-wrapper {
    width: 120px;
    margin-left: 20px;
  }
`;

export const OverRow = styled(BaseRow)`
  margin-top: 23px;
`;
