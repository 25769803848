import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import BillNameCell from '@app/components/internal-transfers/table/cols/bill-name-cell';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';
import ImageWithPreview from '@app/components/common/image';
import PurposeInternalTransferCell from '@app/components/internal-transfers/table/cols/purpose-cell';
import LoginSenderCell from '@app/components/internal-transfers/table/cols/login-sender-cell';
import LoginReceiverCell from './login-receiver-cell';
import BillDeletedCell from '@app/components/internal-transfer-requests/table/cols/bill-deleted-cell';
import { User } from '@app/infrastructure/classes/user';

interface IInternalTransferColumnsProps {
  user: User | null;
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: InternalTransfer) => void;
  onDelete: (data: InternalTransfer) => void;
}

export const internalTransferTableColumns = ({
  user,
  onEdit,
  onDelete,
}: IInternalTransferColumnsProps): IModifiedTableColumn<InternalTransfer>[] => {
  const isNotDeleted = (record: InternalTransfer) => {
    return (
      (record.playersBill &&
        record.playersBill.deleted_at === null &&
        record.internalTransferRequest.playersBill &&
        record.internalTransferRequest.playersBill.deleted_at === null) ||
      (!record.playersBill &&
        record.internalTransferRequest.playersBill &&
        record.internalTransferRequest.playersBill.deleted_at === null) ||
      (record.playersBill && !record.internalTransferRequest.playersBill && record.playersBill.deleted_at === null)
    );
  };
  const columns: IModifiedTableColumn<InternalTransfer>[] = [
    {
      title: `${i18next.t('labels.date')}`,
      dataIndex: 'createdAt',
      sortField: 'created_at',
      sorter: true,
      render: (_, record) => record.createdAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.sender')}`,
      dataIndex: 'login',
      sortField: 'user.login',
      sorter: true,
      render: (_, data: InternalTransfer) => {
        return <LoginSenderCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.receiver')}`,
      dataIndex: 'receiverLogin',
      sortField: 'internal_transfer_request.user.login',
      sorter: true,
      render: (_, data: InternalTransfer) => {
        return <LoginReceiverCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.check')}`,
      dataIndex: 'name',
      render: (_, data: InternalTransfer) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.summ')}`,
      dataIndex: 'amount',
      sortField: 'amount',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.commission')}`,
      dataIndex: 'commission',
      sortField: 'commission',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.purpose')}`,
      dataIndex: 'purpose',
      render: () => {
        return <PurposeInternalTransferCell />;
      },
    },
    {
      title: `${i18next.t('labels.comment')}`,
      dataIndex: 'description',
    },
    {
      title: `${i18next.t('labels.screen')}`,
      dataIndex: 'image',
      align: 'center',
      render: (_, data: InternalTransfer) => {
        return <ImageWithPreview data={data.image} />;
      },
    },
  ];

  if (
    user?.canUpdate('internalTransferPermission') ||
    user?.canUpdate('overMoneyPermission') ||
    user?.fullAccess('internalTransferPermission') ||
    user?.fullAccess('overMoneyPermission')
  ) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '150px',
      align: 'center',
      render: (_, record) =>
        isNotDeleted(record) ? (
          <BaseActionsCell
            canEdit={
              record.internalTransferRequest.playersBill
                ? user?.canUpdate('internalTransferPermission')
                : user?.canUpdate('overMoneyPermission')
            }
            canDelete={
              record.internalTransferRequest.playersBill
                ? user?.fullAccess('internalTransferPermission')
                : user?.fullAccess('overMoneyPermission')
            }
            onDelete={() => {
              onDelete(record);
            }}
            onEdit={() => {
              onEdit(record);
            }}
            size="small"
          />
        ) : (
          <BillDeletedCell />
        ),
    });
  }

  return columns;
};
