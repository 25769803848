import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import useUsers from '@app/infrastructure/hooks/use-users';
import AccessRightSelectBox, { IAccessRightSelectBoxItemProps } from '@app/components/access-rights/select-box';
import RoleSelectBox from '@app/components/roles/select-box';
import { ROLES } from '@app/infrastructure/enums/roles';
import { User } from '@app/infrastructure/classes/user';
import { useAppSelector } from '@app/infrastructure/hooks/redux';

interface IFormValues {
  login: string;
  password?: string;
  role: ROLES;
  accessRight?: IAccessRightSelectBoxItemProps;
}

interface IAdminsUpdateFormProps {
  data: User;
  onClose?: () => void;
  onSave?: () => void;
}

const AdminsUpdateForm: React.FC<IAdminsUpdateFormProps> = (props) => {
  const { data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { updateUser, loading } = useUsers();

  const user = useAppSelector((state) => state.user.user);

  const disabledRoles = [ROLES.PLAYER];

  if (user?.isAdmin()) {
    disabledRoles.push(ROLES.SUPERADMIN);
  }

  const onFinish = async (values: IFormValues) => {
    if (values) {
      if (!values?.password) delete values.password;
      await updateUser(data.id, { ...values, access_right: values.accessRight?.record });
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  const prepareData = (): IFormValues => {
    const values: IFormValues = { ...data, accessRight: undefined, password: '' };
    if (data.accessRight) {
      const { accessRight } = data;
      values.accessRight = {
        key: accessRight.id,
        label: accessRight.name,
        value: `${accessRight.name}`,
        record: accessRight,
      };
    }
    return values;
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      initialValues={prepareData()}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
    >
      <BaseButtonsForm.Item name="login" label={t('labels.login')} rules={[{ required: true }]}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item name="password" label={t('labels.password')}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item name="role" label={t('labels.role')} rules={[{ required: true }]}>
        <RoleSelectBox disabledValues={disabledRoles} />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item name="accessRight" label={t('labels.accessRight')} rules={[{ required: true }]}>
        <AccessRightSelectBox />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default AdminsUpdateForm;
