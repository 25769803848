import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { ROLES } from '@app/infrastructure/enums/roles';
import PersonalTransfersTable from '@app/components/personal-transfers/table';
import PlayerPersonalTransfersTable from '@app/components/players/personal-transfers/table';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { Player } from '@app/infrastructure/classes/player';

const PlayerView: React.FC = () => {
  const playerId = useAppSelector((state) => state.user.user?.player?.id);
  const [player, setPlayer] = useState<Player>();
  const { loadPlayerById } = usePlayers();

  useEffect(() => {
    if (playerId) {
      loadPlayerById(playerId).then((player) => {
        setPlayer(player);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  return <BaseCol>{player && <PlayerPersonalTransfersTable player={player} canCreate />}</BaseCol>;
};

const AdminView: React.FC = () => {
  return (
    <BaseCol>
      <PersonalTransfersTable />
    </BaseCol>
  );
};

const PersonalTransfersPage: React.FC = () => {
  const { t } = useTranslation();
  const role = useAppSelector((state) => state.user.user?.role);

  return (
    <>
      <PageTitle>{t('navigation.personalTransfers')}</PageTitle>
      {role === ROLES.PLAYER ? <PlayerView /> : <AdminView />}
    </>
  );
};

export default PersonalTransfersPage;
