/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { ProfitPackage } from '@app/infrastructure/classes/profit-package';
import useProfitPackages from '@app/infrastructure/hooks/use-profit-packages';
import { profitPackagesTableColumns } from './cols';
import { CalculateAdmin } from '../calculate-admin-form';
import { useBadge } from '@app/infrastructure/providers/badge-provider';

const ProfitPackagesTable: React.FC = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedProfitPackage, setSelectedProfitPackage] = useState<ProfitPackage | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { profitPackages, meta, loading, loadProfitPackages } = useProfitPackages({ player: undefined });
  const { t } = useTranslation();

  const modalTitle =
    selectedProfitPackage &&
    `${t('labels.calculateProfitPackagePlayer')}: ${selectedProfitPackage.player?.user?.login}`;

  const hasPagination = meta.total > meta.limit;

  const user = useAppSelector((state) => state.user?.user);
  const { rerenderBadge } = useBadge();

  const onReload = async () => {
    await loadProfitPackages({ reload: true });
  };

  const onInfo = async (record: ProfitPackage) => {
    setSelectedProfitPackage(record);
    setModalOpened(true);
  };

  const onEdit = async (record: ProfitPackage) => {
    setIsEdit(true);
    setSelectedProfitPackage(record);
    setModalOpened(true);
  };

  const onClose = () => {
    setIsEdit(false);
    setSelectedProfitPackage(null);
    setModalOpened(false);
    rerenderBadge();
  };

  const onSave = async () => {
    onClose();
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ProfitPackage> | SorterResult<ProfitPackage>[],
  ) => {
    await loadProfitPackages({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<ProfitPackage>(
        sorter as IModifiedSorterResult<ProfitPackage>,
        'canceled_package_status',
        'ASC',
      ),
      reload: true,
    });
  };

  useEffect(() => {
    loadProfitPackages();
  }, []);

  return (
    <>
      <div>
        <BaseTable
          size="small"
          rowKey="id"
          title={() => {
            return <BaseTableTitle title={t('navigation.profitPackages')} onReload={onReload} size="small" />;
          }}
          columns={profitPackagesTableColumns({
            canEdit: user?.canUpdate('profitPackagePermission'),
            onEdit,
            onInfo,
          })}
          dataSource={profitPackages}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1000 }}
          bordered
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} destroyOnClose footer={null}>
        {selectedProfitPackage && (
          <CalculateAdmin profitPackage={selectedProfitPackage} onSave={onSave} isEdit={isEdit} />
        )}
      </BaseModal>
    </>
  );
};

export default ProfitPackagesTable;
