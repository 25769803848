import React from 'react';
import * as S from '../styles';
import { Level } from '@app/infrastructure/classes/level';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  record: Level;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, children, ...restProps }) => {
  return (
    <td {...restProps}>
      {editing && dataIndex ? (
        <S.BaseFormItem name={dataIndex} style={{ margin: 0 }}>
          <BaseInput.TextArea size="small" />
        </S.BaseFormItem>
      ) : (
        children
      )}
    </td>
  );
};
