import styled from 'styled-components';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';

export const inputWrapper = styled.div`
  padding: 0;
  border-radius: 7px;
  background-color: var(--background-color);
  border: 1px solid var(--border-base-color);
  transition: border 0.3s;
  &:hover {
    border: 1px solid var(--ant-primary-5);
  }
  &:focus {
    border: 1px solid var(--ant-primary-5);
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  padding: 0 0 0 5px;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
