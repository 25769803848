import NameCell from './name-cell';
import SummCell from './summ-cell';
import ActionsCell from './actions-cell';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';
import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import { ROLES } from '@app/infrastructure/enums/roles';
import NickNameCell from './nickname-cell';

interface IGetPlayerBillsTableColumnsProps {
  isEditing: (data: PlayerBill) => boolean;
  save: () => void;
  cancel: () => void;
  handleDeleteBill: (id: number) => void;
  canEdit?: boolean;
  canDelete?: boolean;
  role: ROLES | undefined;
}

export const getPlayerBillsTableColumns = ({
  isEditing,
  save,
  cancel,
  handleDeleteBill,
  canEdit = false,
  canDelete = false,
  role,
}: IGetPlayerBillsTableColumnsProps): IModifiedTableColumn<PlayerBill>[] => {
  const columns: IModifiedTableColumn<PlayerBill>[] = [
    {
      title: '',
      dataIndex: 'name',
      editable: false,
      width: '130px',
      render: (_, data) => {
        return <NameCell data={data} />;
      },
    },
    {
      title: '',
      dataIndex: 'summ',
      key: 'summ',
      editable: true,
      width: '133px',
      render: (_, data) => {
        return <SummCell data={data} />;
      },
    },
    {
      title: '',
      editable: role === ROLES.PLAYER ? false : true,
      width: '90px',
      dataIndex: 'playerNickname',
      render: (_, data) => {
        return <NickNameCell data={data} />;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      editable: false,
      width: '20px',
      render: (_, data) => {
        const editable = isEditing(data) && canEdit;
        return (
          <ActionsCell
            data={data}
            editable={editable}
            save={save}
            cancel={cancel}
            handleDeleteBill={handleDeleteBill}
            canDelete={canDelete}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (data: PlayerBill) => ({
        record: data,
        inputType: col.dataIndex === 'summ' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: `${col.title}`,
        editing: isEditing(data),
      }),
    };
  });

  return mergedColumns;
};
