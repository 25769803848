import { useState } from 'react';
import { Flcraft } from '../classes/flcraft';
import { getFlcrafts, uploadFlcrafts } from '@app/api/flcraft';
import { ILoadProps } from './interfaces/i-load-props';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';

type UseFlcrafts = {
  flcrafts: Flcraft[];
  meta: IPaginationMeta;
  loadFlcrafts: (props?: ILoadProps) => Promise<Flcraft[] | undefined>;
  uploadFlcraft: (file: Blob) => Promise<void>;
  loading: boolean;
};

interface IUseFlcraftsProps {
  meta?: IPartialPaginationMeta;
}

const useFlcrafts = (props?: IUseFlcraftsProps): UseFlcrafts => {
  const [loading, setLoading] = useState<boolean>(false);
  const [flcrafts, setFlcrafts] = useState<Flcraft[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 5,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const loadFlcrafts = async (props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { results, meta: metaRes } = await getFlcrafts({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new Flcraft(el));
      if (props?.reload) setFlcrafts(data);
      else setFlcrafts([...flcrafts, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getFlcrafts({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new Flcraft(el));
        if (props?.reload) setFlcrafts(data);
        else setFlcrafts([...flcrafts, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const uploadFlcraft = async (file: Blob) => {
    setLoading(true);
    await uploadFlcrafts(file);
    setLoading(false);
  };

  return {
    flcrafts,
    meta,
    loading,
    loadFlcrafts,
    uploadFlcraft,
  };
};

export default useFlcrafts;
