import React from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/es/upload/interface';

import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import usePersonalTransfers from '@app/infrastructure/hooks/use-personal-transfers';
import { ICreatePersonalTransfer } from '@app/infrastructure/interfaces/i-personal-transfer';
import { Player } from '@app/infrastructure/classes/player';
import PlayersBillSelectBox, { IPlayersBillSelectBoxItemProps } from '../../bills/select-box';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import PurposePersonalTransferSelect from '@app/components/personal-transfers/purpose-select';
import { ImageUpload } from '@app/components/images/image-uploud-input';
import useImages from '@app/infrastructure/hooks/use-images';
import { PURPOSE_PERSONAL_TRANSFER } from '@app/infrastructure/enums/purpose-personal-transfer.enum';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as S from './styles';
import { positiveNumberPattern } from '@app/infrastructure/consts/patterns';

interface IPersonalTransferFormProps {
  player: Player;
  onClose?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  image: UploadFile;
  amount: number;
  description: string;
  purpose: PURPOSE_PERSONAL_TRANSFER;
  playersBill: IPlayersBillSelectBoxItemProps;
}

const PersonalTransfersCreateForm: React.FC<IPersonalTransferFormProps> = (props) => {
  const { player, onClose, onSave } = props;
  const { t } = useTranslation();

  const { createPersonalTransfer, loading } = usePersonalTransfers({ player });
  const { createImage, loading: loadingImage } = useImages();

  const onFinish = async (values: IFormValues) => {
    const imageData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    imageData.append('image', values.image as any);
    createImage(imageData).then((data) => {
      if (data) {
        const createPersonalTransferValues: ICreatePersonalTransfer = {
          amount: values.amount,
          description: values.description,
          purpose: values.purpose,
          players_bill_id: values.playersBill.record.id,
          image_id: data?.id,
        };
        createPersonalTransfer(createPersonalTransferValues).then(() => {
          if (onSave) onSave();
        });
      }
    });
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading || loadingImage}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <S.HeaderDescription>{t('messages.createTransferNeedFillFields')}</S.HeaderDescription>
      <BaseButtonsForm.Item
        name="playersBill"
        label={t('labels.check')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <PlayersBillSelectBox player={player} withSumm />
      </BaseButtonsForm.Item>
      <BaseRow justify="space-between">
        <S.NumberInput
          name="amount"
          label={t('labels.summ')}
          rules={[
            { type: 'number' },
            { required: true, message: t('messages.requiredField') },
            {
              pattern: positiveNumberPattern,
              message: '',
              warningOnly: true,
            },
          ]}
        >
          <InputNumber />
        </S.NumberInput>
        <S.PurposeItem
          name="purpose"
          label={t('labels.purpose')}
          rules={[{ required: true, message: t('messages.requiredField') }]}
        >
          <PurposePersonalTransferSelect />
        </S.PurposeItem>
      </BaseRow>

      <BaseButtonsForm.Item name="description" label={t('labels.comment')}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item label={t('labels.screen')} required>
        <ImageUpload rules={[{ required: true, message: t('messages.requiredField') }]} />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default PersonalTransfersCreateForm;
