import { ILevel } from '../interfaces/i-level';

export class Level {
  id: number;
  name: string;
  description: string;
  constructor(data: ILevel) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
  }
}
