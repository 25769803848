import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import ImageWithPreview from '@app/components/common/image';
import { PlayerGame } from '@app/infrastructure/classes/player-game';
import StatusCell from '@app/components/player-games/with-status-table/cols/status-cell';
import BillNameCell from '@app/components/player-games/cells/bill-name-cell';
import TournamentBiCell from '@app/components/player-games/cells/tournament-bi-cell';
import TournamentNameCell from '@app/components/player-games/cells/tournament-name-cell';
import TypeOfEntryCell from '@app/components/player-games/cells/type-of-entry-cell';

export const playerGameWithStatusesTableColumns = (): IModifiedTableColumn<PlayerGame>[] => {
  const columns: IModifiedTableColumn<PlayerGame>[] = [
    {
      title: `${i18next.t('labels.date')}`,
      dataIndex: 'startDate',
      sortField: 'start_date',
      sorter: true,
      render: (_, record) => record.startDate.format(MomentFormats['dd DD.MM.YYYY']),
    },
    {
      title: `${i18next.t('labels.room')}`,
      dataIndex: 'name',
      sortField: 'bill.name',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.tournamentName')}`,
      dataIndex: 'tournamentName',
      sortField: 'tournament_name',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <TournamentNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.bi')}`,
      dataIndex: 'buyIn',
      sortField: 'buy_in',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <TournamentBiCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.bbQuantity')}`,
      dataIndex: 'bbQuantity',
      sortField: 'bb_quantity',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.entry')}`,
      dataIndex: 'typeOfEntry',
      sortField: 'type_of_entry',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <TypeOfEntryCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.comment')}`,
      dataIndex: 'description',
    },
    {
      title: `${i18next.t('labels.screen')}`,
      dataIndex: 'image',
      align: 'center',
      render: (_, data: PlayerGame) => {
        return <ImageWithPreview data={data.image} />;
      },
    },
    {
      title: `${i18next.t('labels.status')}`,
      dataIndex: 'status',
      sortField: 'status',
      width: '90px',
      sorter: true,
      render: (_, data: PlayerGame) => {
        return <StatusCell data={data} />;
      },
    },
  ];

  return columns;
};
