import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Player } from '@app/infrastructure/classes/player';

interface IRankCellProps {
  data: Player;
}

const RankCell: React.FC<IRankCellProps> = (props) => {
  const { data } = props;
  return (
    <div>
      <BaseTypography>{data.rank?.name}</BaseTypography>
    </div>
  );
};

export default RankCell;
