import React from 'react';
import FlcraftUploader from './file-uploader';
import FlcraftStatisticView from '../statistic';
import { FlcraftPlayerStatistic } from '@app/infrastructure/classes/flcraft';
import FlcraftTableFilters from './filters';
import { IFlcraftFilters } from '@app/infrastructure/interfaces/i-flcraft';

interface IFlcraftsTableHeaderProps {
  filters: IFlcraftFilters;
  statistic?: FlcraftPlayerStatistic;
  loading?: boolean;
  canUpload?: boolean;
  onUploaded?(): void;
  onFilterChange(filters: IFlcraftFilters): void;
}

const FlcraftsTableHeader: React.FC<IFlcraftsTableHeaderProps> = (props) => {
  const { statistic, filters, onUploaded, onFilterChange, canUpload = true, loading = false } = props;
  return (
    <div>
      {canUpload && (
        <div style={{ margin: '0 0 25px 0' }}>
          <FlcraftUploader onUploaded={onUploaded} />
        </div>
      )}
      {statistic && (
        <div style={{ margin: '0 0 25px 0' }}>
          <FlcraftStatisticView data={statistic} loading={loading} />
        </div>
      )}
      <FlcraftTableFilters filters={filters} onFilterChange={onFilterChange} />
    </div>
  );
};

export default FlcraftsTableHeader;
