import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Player } from '@app/infrastructure/classes/player';
import PlayerBillCreateForm from '../create-form';

interface IPlayerBillsTableTitleProps {
  player: Player;
  onSaved?: () => void;
  canCreate?: boolean;
}

const PlayerBillsTableTitle: React.FC<IPlayerBillsTableTitleProps> = (props) => {
  const { player, onSaved, canCreate = false } = props;

  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  const { t } = useTranslation();

  const onSave = () => {
    if (onSaved) onSaved();
  };

  return (
    <div>
      {!isOpenForm && canCreate && (
        <S.AddBtn size="small" type="text" icon={<PlusCircleOutlined />} onClick={() => setIsOpenForm(true)}>
          {t('labels.addBill')}
        </S.AddBtn>
      )}
      {isOpenForm && <PlayerBillCreateForm player={player} onCancel={() => setIsOpenForm(false)} onSave={onSave} />}
    </div>
  );
};

export default PlayerBillsTableTitle;
