import React from 'react';
import { BaseSelect, BaseSelectProps, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { tournamentPeriodsDataSource } from '@app/infrastructure/data-sources/tournament-periods';

interface ITournamentPeriodSelectProps extends BaseSelectProps {
  title?: string;
  disabled?: boolean;
}

const TournamentPeriodSelect: React.FC<ITournamentPeriodSelectProps> = (props) => {
  const { title, disabled = false } = props;
  const periods = tournamentPeriodsDataSource();
  return (
    <div>
      {title && <p style={{ margin: '10px 0 5px 0' }}>{title}</p>}
      <BaseSelect width={`100%`} disabled={disabled} allowClear {...props}>
        {periods.map((el) => (
          <Option key={el.id} value={el.id}>
            {el.label}
          </Option>
        ))}
      </BaseSelect>
    </div>
  );
};

export default TournamentPeriodSelect;
