/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';
import { Player } from '@app/infrastructure/classes/player';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import AppBaseCard from '@app/components/common/base-card';

interface IPlayerFundsInfoProps {
  player: Player;
}

const PlayerFundsInfo: React.FC<IPlayerFundsInfoProps> = (props) => {
  const { player } = props;

  const user = useAppSelector((state) => state.user?.user);
  const canEdit = user?.canUpdate('playerPermission');

  const { t } = useTranslation();
  const [isEditNeedFromFund, setIsEditNeedFromFund] = useState<boolean>(false);
  const [isEditNeedFromPlayer, setIsEditNeedFromPlayer] = useState<boolean>(false);

  const [needFromFund, setNeedFromFund] = useState<number | undefined>(player.needFromFund);
  const [needFromPlayer, setNeedFromPlayer] = useState<number | undefined>(player.needFromPlayer);

  const [inputNeedFromFund, setInputNeedFromFund] = useState<HTMLElement | null>();
  const [inputNeedFromPlayer, setInputNeedFromPlayer] = useState<HTMLElement | null>();

  const { updatePlayer, loading } = usePlayers();

  const { key } = useRerender();

  useEffect(() => {
    if (isEditNeedFromFund) {
      setInputNeedFromFund(document.getElementById('needFromFund'));
    }
  }, [isEditNeedFromFund]);

  useEffect(() => {
    if (isEditNeedFromPlayer) {
      setInputNeedFromPlayer(document.getElementById('needFromPlayer'));
    }
  }, [isEditNeedFromPlayer]);

  const onSaveNeedFromFund = async () => {
    await updatePlayer(player.id, { need_from_fund: needFromFund }, player).then(() => {
      setIsEditNeedFromFund(false);
    });
  };

  const onSaveNeedFromPlayer = async () => {
    await updatePlayer(player.id, { need_from_player: needFromPlayer }, player).then(() => {
      setIsEditNeedFromPlayer(false);
    });
  };

  const changeNeedFromFundCallback = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSaveNeedFromFund();
      }
    },
    [onSaveNeedFromFund],
  );

  const changeNeedFromPlayerCallback = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSaveNeedFromPlayer();
      }
    },
    [onSaveNeedFromPlayer],
  );

  useEffect(() => {
    if (inputNeedFromFund) {
      inputNeedFromFund.addEventListener('keypress', changeNeedFromFundCallback);
    }
    return () =>
      inputNeedFromFund ? inputNeedFromFund.removeEventListener('keypress', changeNeedFromFundCallback) : undefined;
  }, [inputNeedFromFund, changeNeedFromFundCallback]);

  useEffect(() => {
    if (inputNeedFromPlayer) {
      inputNeedFromPlayer.addEventListener('keypress', changeNeedFromPlayerCallback);
    }
    return () =>
      inputNeedFromPlayer
        ? inputNeedFromPlayer.removeEventListener('keypress', changeNeedFromPlayerCallback)
        : undefined;
  }, [inputNeedFromPlayer, changeNeedFromPlayerCallback]);

  return (
    <AppBaseCard title={t('labels.funds')} loading={loading} key={key}>
      <BaseRow gutter={[0, 23]}>
        <BaseRow gutter={20}>
          <BaseCol>{`${t('labels.personalFunds')}:`}</BaseCol>
          <BaseCol>
            {CURRENCIES.DOLLAR} {player.personalFunds}
          </BaseCol>
        </BaseRow>
        <S.NeedFromRow align="middle">
          {`${t('labels.needFromFund')}: `}
          <InputNumber
            id="needFromFund"
            min={0}
            bordered={isEditNeedFromFund}
            readOnly={!isEditNeedFromFund}
            prefix={CURRENCIES.DOLLAR}
            size="small"
            value={needFromFund}
            onClick={() => !isEditNeedFromFund && canEdit && setIsEditNeedFromFund(true)}
            onChange={(value) => setNeedFromFund(Number(value))}
            onBlur={() => isEditNeedFromFund && canEdit && onSaveNeedFromFund()}
          />
        </S.NeedFromRow>
        <S.NeedFromRow align="middle">
          {`${t('labels.needFromPlayer')}: `}
          <InputNumber
            id="needFromPlayer"
            min={0}
            bordered={isEditNeedFromPlayer}
            readOnly={!isEditNeedFromPlayer}
            prefix={CURRENCIES.DOLLAR}
            size="small"
            value={needFromPlayer}
            onClick={() => !isEditNeedFromPlayer && canEdit && setIsEditNeedFromPlayer(true)}
            onChange={(value) => setNeedFromPlayer(Number(value))}
            onBlur={() => isEditNeedFromPlayer && canEdit && onSaveNeedFromPlayer()}
          />
        </S.NeedFromRow>

        <BaseRow gutter={20}>
          <BaseCol>{`${t('labels.availableForWithdrawal')}:`}</BaseCol>
          <BaseCol>
            {CURRENCIES.DOLLAR} {player.availableForWithdrawal}
          </BaseCol>
        </BaseRow>
        <BaseCol>
          <BaseRow gutter={20}>
            <BaseCol>{`${t('labels.fundMoney')}:`}</BaseCol>
            <BaseCol>
              {CURRENCIES.DOLLAR} {player.fundMoney}
            </BaseCol>
          </BaseRow>
          <S.OverRow gutter={20}>
            <BaseCol>{`${t('labels.overMoney')}:`}</BaseCol>
            <BaseCol>
              {CURRENCIES.DOLLAR} {player.overMoney}
            </BaseCol>
          </S.OverRow>
        </BaseCol>
      </BaseRow>
    </AppBaseCard>
  );
};

export default PlayerFundsInfo;
