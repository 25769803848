import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { UploadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';

interface ImageUploadProps {
  playerBill: PlayerBill;
  imagesArray: { image: UploadFile; playerBillId: number }[];
  setImagesArray: (files: { image: UploadFile; playerBillId: number }[]) => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ playerBill, imagesArray, setImagesArray }) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<UploadFile>();

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'removed') {
      setFile(undefined);
      const images = imagesArray.filter((images) => images.playerBillId !== playerBill.id);
      setImagesArray(images);
    } else {
      const images = imagesArray;
      images.push({ image: info.file, playerBillId: playerBill.id });
      setFile(info.file);
      setImagesArray(images);
    }
  };

  const uploadButton = (
    <BaseButton size="small" type="dashed" icon={<UploadOutlined />}>
      {t('controls.upload')}
    </BaseButton>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <BaseButtonsForm.Item label={`${playerBill.bill.name} ${playerBill.bill.currency.name}`} key={playerBill.id}>
      <BaseButtonsForm.Item name="image" valuePropName="image" getValueFromEvent={normFile} noStyle>
        <BaseUpload name="image" listType="picture" beforeUpload={() => false} onChange={handleChange} maxCount={1}>
          {!file && uploadButton}
        </BaseUpload>
      </BaseButtonsForm.Item>
    </BaseButtonsForm.Item>
  );
};
