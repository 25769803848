/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { PersonalTransfer } from '@app/infrastructure/classes/personal-transfer';
import usePersonalTransfers from '@app/infrastructure/hooks/use-personal-transfers';
import { Player } from '@app/infrastructure/classes/player';
import { personalTransfersTableColumns } from './cols';
import PersonalTransfersCreateForm from '../create-form';
import { IPersonalTransferFilters } from '@app/infrastructure/interfaces/i-personal-transfer';
import AvailableForWithdrawalInfo from './available-for-withdrawal-info';
import usePlayers from '@app/infrastructure/hooks/use-players';
import PersonalTransferForPlayerFilters from './filters';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import PersonalTransfersUpdateForm from '@app/components/personal-transfers/update-form';
import { getPersonalTransferPlayerFilters } from '@app/infrastructure/services/filters-storage.service';
import * as S from './styles';

interface PersonalTransfersTableProps {
  player: Player;
  canCreate?: boolean;
}

const PlayerPersonalTransfersTable: React.FC<PersonalTransfersTableProps> = (props) => {
  const { player, canCreate = false } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<IPersonalTransferFilters | undefined>();
  const [selectedPersonalTransfer, setSelectedPersonalTransfer] = useState<PersonalTransfer | null>(null);

  const { personalTransfers, meta, loading, loadPersonalTransfersByPlayer, deletePersonalTransfer } =
    usePersonalTransfers({
      player,
    });
  const { updatePlayerInfoById } = usePlayers();

  const user = useAppSelector((state) => state.user?.user);

  const { t } = useTranslation();

  const modalTitle = selectedPersonalTransfer ? t('labels.updatePersonalTransfer') : t('labels.createPersonalTransfer');

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadPersonalTransfersByPlayer({ filters, reload: true });
  };

  const onEdit = async (record: PersonalTransfer) => {
    setSelectedPersonalTransfer(record);
    setModalOpened(true);
  };

  const onDelete = async (record: PersonalTransfer) => {
    await deletePersonalTransfer(record.id);
    await onReload();
  };

  const onCreate = () => {
    setModalOpened(true);
  };

  const onClose = () => {
    setModalOpened(false);
  };

  const onSave = async () => {
    setModalOpened(false);
    await updatePlayerInfoById(player.id, player);
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<PersonalTransfer> | SorterResult<PersonalTransfer>[],
  ) => {
    await loadPersonalTransfersByPlayer({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<PersonalTransfer>(sorter as IModifiedSorterResult<PersonalTransfer>, 'id', 'DESC'),
      reload: true,
      filters,
    });
  };

  useEffect(() => {
    const storageFilters = getPersonalTransferPlayerFilters();
    if (storageFilters && filters) {
      onReload();
    }
    if (!storageFilters) {
      onReload();
    }
  }, [filters]);

  return (
    <>
      <S.StyledBaseTable
        size="small"
        rowKey="id"
        rowClassName={(record: PersonalTransfer) => (record.amount < 0 ? 'color-cf1322' : 'color-389e0d')}
        title={() => {
          return (
            <>
              <BaseTableTitle
                canCreate={canCreate}
                onCreate={onCreate}
                onReload={onReload}
                extra={<AvailableForWithdrawalInfo player={player} />}
                size="small"
              />
              <PersonalTransferForPlayerFilters player={player} onFilterChange={(filters) => setFilters(filters)} />
            </>
          );
        }}
        columns={personalTransfersTableColumns({
          canEdit: user?.canUpdate('personalTransferPermission'),
          canDelete: user?.fullAccess('personalTransferPermission'),
          onDelete,
          onEdit,
        })}
        dataSource={personalTransfers}
        pagination={
          !loading &&
          hasPagination && {
            current: meta.page,
            pageSize: meta.limit,
            total: meta.total,
            showSizeChanger: true,
          }
        }
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 1000 }}
        bordered
      />
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} destroyOnClose footer={null}>
        {selectedPersonalTransfer ? (
          <PersonalTransfersUpdateForm data={selectedPersonalTransfer} onClose={onClose} onSave={onSave} />
        ) : (
          <PersonalTransfersCreateForm player={player} onClose={onClose} onSave={onSave} />
        )}
      </BaseModal>
    </>
  );
};

export default PlayerPersonalTransfersTable;
