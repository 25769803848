import { BILL_TYPES } from '../enums/bill-types.enum';
import { IBill } from '../interfaces/i-bill';
import { Currency } from './currency';

export class Bill {
  id: number;
  name: string;
  type: BILL_TYPES;
  currency: Currency;
  timeShift: string;

  constructor(data: IBill) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.timeShift = data.time_shift;
    this.currency = new Currency(data.currency);
  }

  updateInfo(data: IBill): void {
    this.name = data.name;
    this.timeShift = data.time_shift;
  }
}
