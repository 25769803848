import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import ruRU from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/app-router';
import { themeObject } from './styles/themes/themeVariables';
import { useAppSelector } from './infrastructure/hooks/redux';
import { useAutoNightMode } from './infrastructure/hooks/use-auto-night-mode';
import { usePWA } from './infrastructure/hooks/use-pwa';
import { useThemeWatcher } from './infrastructure/hooks/use-theme-watcher';
import RerenderProvider from './infrastructure/providers/rerender-provider';
import moment from 'moment';
import BadgeProvider from './infrastructure/providers/badge-provider';
import {
  deleteRefreshToken,
  deleteToken,
  deleteUser,
  readRefreshToken,
} from './infrastructure/services/local-storage.service';
import { getMe } from './api/auth.api';
import { ROUTES } from './infrastructure/consts/routes';

const App: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  moment.locale('ru');

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  useEffect(() => {
    if (window.location.pathname === ROUTES.LOGIN) {
      deleteToken();
      deleteRefreshToken();
      deleteUser();
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname !== ROUTES.LOGIN) {
      const refreshToken = readRefreshToken();
      if (refreshToken) {
        getMe();
      }
    }
  }, []);

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={ruRU}>
          <RerenderProvider>
            <BadgeProvider>
              <AppRouter />
            </BadgeProvider>
          </RerenderProvider>
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
