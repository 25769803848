import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BasePopconfirm } from '@app/components/common/BasePopconfirm/BasePopconfirm';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { DeleteOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface ITitleCellProps {
  value: string;
  onDelete: () => void;
  canDelete?: boolean;
}

const TitleCell: React.FC<ITitleCellProps> = (props) => {
  const { value, onDelete, canDelete = false } = props;
  const { t } = useTranslation();
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>{value}</BaseCol>
      {canDelete && (
        <BaseCol>
          <BasePopconfirm title={t('messages.deleteConfirm')} onConfirm={onDelete}>
            <BasePopover content={t('controls.delete')}>
              <BaseButton size="small" type="text" icon={<DeleteOutlined />} />
            </BasePopover>
          </BasePopconfirm>
        </BaseCol>
      )}
    </BaseRow>
  );
};

export default TitleCell;
