import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';

interface ILoginCellProps {
  data: InternalTransferRequest;
}

const LoginCell: React.FC<ILoginCellProps> = (props) => {
  const { data } = props;
  return (
    <div>
      <BaseTypography>{data.user.login}</BaseTypography>
    </div>
  );
};

export default LoginCell;
