import translation from './translation.json';
import navigation from './navigation.json';
import labels from './labels.json';
import controls from './controls.json';
import permissions from './permissions.json';
import messages from './messages.json';
import roles from './roles.json';
import tournamentPeriods from './tournament-periods.json';
import calendar from './calendar.json';
import analyticsStages from './analytics-stages.json';

export default {
  translation: {
    ...translation,
    calendar,
    tournamentPeriods,
    navigation,
    labels,
    permissions,
    controls,
    messages,
    roles,
    analyticsStages,
  },
};
