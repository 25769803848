import { IProfitPackageImage } from '../interfaces/i-profit-package-image';
import { Image } from './image';
import { PlayerBill } from './player-bill';
import { ProfitPackage } from './profit-package';

export class ProfitPackageImage {
  id: number;
  image: Image;
  playersBill: PlayerBill;
  profitPackage?: ProfitPackage;

  constructor(data: IProfitPackageImage) {
    this.id = data.id;
    this.image = new Image(data.image);
    this.playersBill = new PlayerBill(data.players_bill);
    this.profitPackage = data.profit_package ? new ProfitPackage(data.profit_package) : undefined;
  }
}
