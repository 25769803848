import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import LevelCreateRow from './create-row';

interface ITournamentLevelsTableHeaderProps {
  onSave: () => void;
}

const TournamentLevelsTableHeader: React.FC<ITournamentLevelsTableHeaderProps> = (props) => {
  const { onSave } = props;
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [key, setKey] = useState<number>(Date.now());

  const onClose = () => {
    setIsCreating(false);
    setKey(Date.now());
  };
  const onSaved = () => {
    onClose();
    onSave();
  };

  return (
    <div>
      {isCreating ? (
        <LevelCreateRow key={key} onClose={onClose} onSaved={onSaved} />
      ) : (
        <BaseButton size="small" type="text" icon={<PlusCircleOutlined />} onClick={() => setIsCreating(true)}>
          {t('controls.addType')}
        </BaseButton>
      )}
    </div>
  );
};

export default TournamentLevelsTableHeader;
