import i18next from 'i18next';
import { ROLES } from '../enums/roles';

export const rolesDataSource = (): {
  id: ROLES;
  label: string;
}[] => [
  {
    id: ROLES.ADMIN,
    label: i18next.t(`roles.${ROLES.ADMIN}`),
  },
  {
    id: ROLES.SUPERADMIN,
    label: i18next.t(`roles.${ROLES.SUPERADMIN}`),
  },
  {
    id: ROLES.PLAYER,
    label: i18next.t(`roles.${ROLES.PLAYER}`),
  },
];
