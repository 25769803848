/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import useUsers from '@app/infrastructure/hooks/use-users';
import BaseTableTitle from '@app/components/common/base-table-title';
import { usersTableColumns } from './cols';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { User } from '@app/infrastructure/classes/user';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import AdminsCreateForm from '../create-form';
import AdminsUpdateForm from '../update-form';
import { useAppSelector } from '@app/infrastructure/hooks/redux';

const AdminsTable: React.FC = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { users, meta, loading, loadUsers, deleteUser } = useUsers();
  const { t } = useTranslation();

  const modalTitle = selectedUser ? `${t('labels.update')} ${selectedUser.login}` : t('labels.createAdmin');

  const hasPagination = meta.total > meta.limit;

  const user = useAppSelector((state) => state.user.user);

  const onReload = async () => {
    await loadUsers({ reload: true, admins: true });
  };

  const onCreate = () => {
    setSelectedUser(null);
    setModalOpened(true);
  };

  const onEdit = async (record: User) => {
    setSelectedUser(record);
    setModalOpened(true);
  };

  const onDelete = async (record: User) => {
    await deleteUser(record.id);
    await onReload();
  };

  const onClose = () => {
    setSelectedUser(null);
    setModalOpened(false);
  };

  const onSave = async () => {
    setSelectedUser(null);
    await onReload();
    setModalOpened(false);
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    await loadUsers({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<User>(sorter as IModifiedSorterResult<User>),
      reload: true,
      admins: true,
    });
  };

  useEffect(() => {
    loadUsers({ admins: true });
  }, []);

  return (
    <>
      <div>
        <BaseTable
          size="small"
          title={() => {
            return (
              <BaseTableTitle
                title={t('navigation.admins')}
                canCreate={user?.canCreate('userPermission')}
                onCreate={onCreate}
                onReload={onReload}
                size="small"
              />
            );
          }}
          rowKey={'id'}
          columns={usersTableColumns({
            user: user ? user : undefined,
            canEdit: user?.canUpdate('userPermission'),
            canDelete: user?.fullAccess('userPermission'),
            onEdit,
            onDelete,
          })}
          dataSource={users}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1000 }}
          bordered
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} footer={null} destroyOnClose>
        {selectedUser ? (
          <AdminsUpdateForm data={selectedUser} onClose={onClose} onSave={onSave} />
        ) : (
          <AdminsCreateForm onClose={onClose} onSave={onSave} />
        )}
      </BaseModal>
    </>
  );
};

export default AdminsTable;
