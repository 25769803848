import React from 'react';
import { BaseSelect, BaseSelectProps, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { rolesDataSource } from '@app/infrastructure/data-sources/roles';
import { ROLES } from '@app/infrastructure/enums/roles';

interface IBaseSelectPropsProps extends BaseSelectProps {
  disabledValues?: ROLES[];
}

const RoleSelectBox: React.FC<IBaseSelectPropsProps> = (props) => {
  const { disabledValues = [], ...args } = props;
  return (
    <BaseSelect {...args}>
      {rolesDataSource().map((role) => (
        <Option key={role.id} value={role.id} disabled={disabledValues.some((el) => el === role.id)}>
          {role.label}
        </Option>
      ))}
    </BaseSelect>
  );
};

export default RoleSelectBox;
