import styled from 'styled-components';

export const StatusLabel = styled.div`
  width: 150px;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.div`
  width: 100px;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #2e2a2a;
  border-color: #2e2a2a;
  background-color: #fafafa;
`;
