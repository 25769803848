/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { IPlayerFlcraftFilters } from '@app/infrastructure/interfaces/i-flcraft';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { setPlayerFlcraftFilters } from '@app/infrastructure/services/filters-storage.service';
import BaseRangeNumberInput, { rangeValue } from '@app/components/common/base-range-number-input';
import { debouncedFunction } from '@app/infrastructure/functions/debounce-function';

interface IFilterData {
  name: string | undefined;
  abiRange: rangeValue;
  roiRange: rangeValue;
  rankRange: rangeValue;
}

interface IFlcraftPlayersTablefilters {
  filters: IPlayerFlcraftFilters;
  onFilterChange: (filters: IPlayerFlcraftFilters) => void;
}

const FlcraftPlayersTableFilters: React.FC<IFlcraftPlayersTablefilters> = (props) => {
  const { onFilterChange, filters = {} } = props;
  const { t } = useTranslation();

  const [data, setData] = useState<IFilterData>({
    name: filters.playerName,
    abiRange: [filters.fromAbi, filters.toAbi],
    roiRange: [filters.fromRoi, filters.toRoi],
    rankRange: [filters.fromRank, filters.toRank],
  });

  const debouncedNameChange = useCallback(
    debouncedFunction((playerName: string | undefined) => {
      changeFilters({
        ...filters,
        playerName,
      });
    }),
    [],
  );

  const handleNameChange = (name: string | undefined) => {
    setData({
      ...data,
      name,
    });
    debouncedNameChange(name);
  };

  const debouncedInputChange = debouncedFunction(
    (inputValue: rangeValue, dataField: keyof Omit<IFilterData, 'name'>) => {
      const isAbi = dataField === 'abiRange';
      const isRoi = dataField === 'roiRange';
      const isRank = dataField === 'rankRange';

      const [val1, val2] = inputValue;
      let keys: Array<keyof IPlayerFlcraftFilters> = [];
      if (isAbi) keys = ['fromAbi', 'toAbi'];
      if (isRoi) keys = ['fromRoi', 'toRoi'];
      if (isRank) keys = ['fromRank', 'toRank'];
      const [fromKey, toKey] = keys;
      setData({
        ...data,
        [dataField]: inputValue,
      });
      changeFilters({
        ...filters,
        [fromKey]: val1,
        [toKey]: val2,
      });
    },
  );

  const changeFilters = (filters: IPlayerFlcraftFilters) => {
    setPlayerFlcraftFilters(filters);
    onFilterChange(filters);
  };

  return (
    <BaseRow gutter={30}>
      <BaseCol>
        <BaseInput
          size="small"
          placeholder={t('labels.playerName')}
          value={data.name}
          onChange={(e) => handleNameChange(e.target.value)}
        />
      </BaseCol>
      <BaseCol>
        <BaseRangeNumberInput
          value={data.rankRange}
          placeholder={t('labels.rank')}
          onChange={(inputValue) => debouncedInputChange(inputValue, 'rankRange')}
        />
      </BaseCol>
      <BaseCol>
        <BaseRangeNumberInput
          value={data.abiRange}
          placeholder={t('labels.abi')}
          onChange={(inputValue) => debouncedInputChange(inputValue, 'abiRange')}
        />
      </BaseCol>
      <BaseCol>
        <BaseRangeNumberInput
          value={data.roiRange}
          placeholder={t('labels.roi')}
          onChange={(inputValue) => debouncedInputChange(inputValue, 'roiRange')}
        />
      </BaseCol>
    </BaseRow>
  );
};

export default FlcraftPlayersTableFilters;
