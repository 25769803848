/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { useCallback, useEffect, useState } from 'react';
import { Player } from '@app/infrastructure/classes/player';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';
import AppBaseCard from '@app/components/common/base-card';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';

interface ISummInfoProps {
  player: Player;
  canEdit?: boolean;
}

const SummInfo: React.FC<ISummInfoProps> = (props) => {
  const { player, canEdit = false } = props;

  const { t } = useTranslation();
  const [onTheWayDollar, setOnTheWayDollar] = useState<number | undefined>(player.onTheWayDollar);
  const [onTheWayEuro, setOnTheWayEuro] = useState<number | undefined>(player.onTheWayEuro);
  const [isEditDollar, setIsEditDollar] = useState(false);
  const [isEditEuro, setIsEditEuro] = useState(false);
  const [inputDollar, setInputDollar] = useState<HTMLElement | null>();
  const [inputEuro, setInputEuro] = useState<HTMLElement | null>();
  const { updatePlayer, loading } = usePlayers();
  useRerender();

  useEffect(() => {
    if (isEditDollar) {
      setInputDollar(document.getElementById('onTheWayDollar'));
    }
  }, [isEditDollar]);

  useEffect(() => {
    if (isEditEuro) {
      setInputEuro(document.getElementById('onTheWayEuro'));
    }
  }, [isEditEuro]);

  const onSaveDollar = async () => {
    await updatePlayer(player.id, { on_the_way_dollar: onTheWayDollar }, player).then(() => {
      setIsEditDollar(false);
    });
  };

  const onSaveEuro = async () => {
    await updatePlayer(player.id, { on_the_way_euro: onTheWayEuro }, player).then(() => {
      setIsEditEuro(false);
    });
  };

  const changeDollarCallback = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSaveDollar();
      }
    },
    [onSaveDollar],
  );

  const changeEuroCallback = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSaveEuro();
      }
    },
    [onSaveEuro],
  );

  useEffect(() => {
    if (inputDollar) {
      inputDollar.addEventListener('keypress', changeDollarCallback);
    }
    return () => (inputDollar ? inputDollar.removeEventListener('keypress', changeDollarCallback) : undefined);
  }, [inputDollar, changeDollarCallback]);

  useEffect(() => {
    if (inputEuro) {
      inputEuro.addEventListener('keypress', changeEuroCallback);
    }
    return () => (inputEuro ? inputEuro.removeEventListener('keypress', changeEuroCallback) : undefined);
  }, [inputEuro, changeEuroCallback]);

  return (
    <AppBaseCard title={t('labels.summPlayerBills')} loading={loading}>
      <S.CardSpace>
        <S.TitleBalanceText>{`$ ${player.getBillsSumm()}`}</S.TitleBalanceText>
        <S.OnTheWayRow align="middle">
          {`${t('labels.onTheWay')}: `}
          <InputNumber
            id="onTheWayDollar"
            min={0}
            bordered={isEditDollar}
            readOnly={!isEditDollar}
            prefix={CURRENCIES.DOLLAR}
            size="small"
            value={onTheWayDollar}
            onClick={() => !isEditDollar && canEdit && setIsEditDollar(true)}
            onChange={(value) => setOnTheWayDollar(Number(value))}
            onBlur={() => isEditDollar && canEdit && onSaveDollar()}
          />
        </S.OnTheWayRow>
        <S.OnTheWayRow align="middle">
          {`${t('labels.onTheWay')}: `}
          <InputNumber
            id="onTheWayEuro"
            min={0}
            bordered={isEditEuro}
            readOnly={!isEditEuro}
            prefix={CURRENCIES.EURO}
            size="small"
            value={onTheWayEuro}
            onClick={() => !isEditEuro && canEdit && setIsEditEuro(true)}
            onChange={(value) => setOnTheWayEuro(Number(value))}
            onBlur={() => isEditEuro && canEdit && onSaveEuro()}
          />
        </S.OnTheWayRow>
      </S.CardSpace>
    </AppBaseCard>
  );
};

export default SummInfo;
