import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import useBills from '@app/infrastructure/hooks/use-bills';
import { ICreateBill } from '@app/infrastructure/interfaces/i-bill';
import CurrenciesSelectBox from '@app/components/currencies/select-box';
import BillTypeSelect from '../type-select';

interface IBillsCreateFormProps {
  onClose?: () => void;
  onSave?: () => void;
}

const BillsCreateForm: React.FC<IBillsCreateFormProps> = (props) => {
  const { onClose, onSave } = props;
  const { t } = useTranslation();

  const { createBill, loading } = useBills();

  const onFinish = async (values: ICreateBill) => {
    if (values) {
      await createBill(values);
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item
        name="name"
        label={t('labels.contentName')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item
        name="type"
        label={t('labels.type')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <BillTypeSelect />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item
        name="currency_id"
        label={t('labels.currency')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <CurrenciesSelectBox />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default BillsCreateForm;
