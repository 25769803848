/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { Player } from '@app/infrastructure/classes/player';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';
import useInternalTransfers from '@app/infrastructure/hooks/use-internal-transfers';
import { internalTransferTableColumns } from './cols';
import { IInternalTransferFilters } from '@app/infrastructure/interfaces/i-internal-transfer';
import BaseTableTitle from '@app/components/common/base-table-title';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { getInternalTransferPlayerFilters } from '@app/infrastructure/services/filters-storage.service';
import InternalTransferTablePlayerFilters from '../filters';

interface InternalTransfersTableProps {
  player: Player;
}

const InternalTransfersForPlayerTable: React.FC<InternalTransfersTableProps> = (props) => {
  const { player } = props;
  const { key } = useRerender();
  const [filters, setFilters] = useState<IInternalTransferFilters | undefined>();

  const { internalTransfers, meta, loading, loadInternalTransfersByPlayer } = useInternalTransfers({
    player,
  });

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadInternalTransfersByPlayer({ filters, reload: true });
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<InternalTransfer> | SorterResult<InternalTransfer>[],
  ) => {
    await loadInternalTransfersByPlayer({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<InternalTransfer>(sorter as IModifiedSorterResult<InternalTransfer>, 'created_at', 'DESC'),
      reload: true,
      filters,
    });
  };

  useEffect(() => {
    const storageFilters = getInternalTransferPlayerFilters();
    if (storageFilters && filters) {
      onReload();
    }
    if (!storageFilters) {
      onReload();
    }
  }, [filters, key]);

  return (
    <BaseTable
      rowKey="id"
      title={() => {
        return (
          <>
            <BaseTableTitle onReload={onReload} size="small" />
            <InternalTransferTablePlayerFilters player={player} onFilterChange={(filters) => setFilters(filters)} />
          </>
        );
      }}
      columns={internalTransferTableColumns()}
      dataSource={internalTransfers}
      pagination={
        !loading &&
        hasPagination && {
          current: meta.page,
          pageSize: meta.limit,
          total: meta.total,
          showSizeChanger: true,
        }
      }
      loading={loading}
      onChange={handleTableChange}
      size="small"
      scroll={{ x: 1000 }}
      bordered
    />
  );
};

export default InternalTransfersForPlayerTable;
