import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import InternalTransferRequestsForPlayerTable from '@app/components/players/internal-transfer-requests/all-status-player-table';
import { Player } from '@app/infrastructure/classes/player';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { Loading } from '@app/components/common/Loading/Loading';

const InternalTransferRequestsForPlayerPage: React.FC = () => {
  const { t } = useTranslation();
  const playerId = useAppSelector((state) => state.user.user?.player?.id);
  const [player, setPlayer] = useState<Player>();
  const { loadPlayerById, loading } = usePlayers();

  useEffect(() => {
    if (playerId) {
      loadPlayerById(playerId).then((player) => {
        setPlayer(player);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  return (
    <>
      <PageTitle>{t('navigation.myRequests')}</PageTitle>
      <BaseCol>
        {(loading && <Loading />) ||
          (player && <InternalTransferRequestsForPlayerTable player={player} canCreate canDelete />)}
      </BaseCol>
    </>
  );
};

export default InternalTransferRequestsForPlayerPage;
