import { httpApi } from '@app/api/http.api';
import {
  IFlcraft,
  IFlcraftPlayerStatistic,
  IFlcraftStatistic,
  IFlcraftStatisticPaginationMeta,
} from '@app/infrastructure/interfaces/i-flcraft';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const route = 'flcraft';

export const getFlcrafts = async (params: IPaginationMeta): Promise<IPaginatedData<IFlcraft>> => {
  return await httpApi
    .get<IPaginatedData<IFlcraft>>(route, {
      params,
    })
    .then(({ data }) => data);
};

export const getFlcraftStatistics = async (
  params: IFlcraftStatisticPaginationMeta,
): Promise<IPaginatedData<IFlcraftPlayerStatistic>> => {
  return await httpApi
    .get<IPaginatedData<IFlcraftPlayerStatistic>>(`${route}/players/statistic`, {
      params,
    })
    .then(({ data }) => data);
};

export const getFlcraftsAverageStatistic = async (
  params: IFlcraftStatisticPaginationMeta,
): Promise<IFlcraftStatistic> => {
  return await httpApi
    .get<IFlcraftStatistic>(`${route}/players/average-statistic`, {
      params,
    })
    .then(({ data }) => data);
};

export const uploadFlcrafts = async (file: Blob): Promise<IFlcraft> => {
  const formData = new FormData();
  formData.append('flcraft', file);
  return await httpApi.post<IFlcraft>(`${route}/file`, formData).then(({ data }) => data);
};
