import moment from 'moment-timezone';
import { TOURNAMENT_PERIOD } from '../enums/tournament-period';
import { ICreateTournament, ITournament } from '../interfaces/i-tournament';
import { Bill } from './bill';
import { Rank } from './rank';
import { Colors } from '../enums/colors';
import { makeid } from '@app/utils/makeid';

const prepareTime = (date: moment.Moment, timeShift: string) => {
  const localDate = date.clone();
  const isSubtract = timeShift.includes('-');
  const [hh, mm] = timeShift.split(':');

  if (isSubtract) {
    localDate.subtract(hh.replace('-', ''), 'hours').subtract(mm, 'minutes');
  } else {
    localDate.add(hh.replace('+', ''), 'hours').add(mm, 'minutes');
  }

  return localDate;
};

export class Tournament {
  key: string;
  id: number;
  groupId: string;
  name: string;
  type: number;
  startDate: moment.Moment;
  startTime: moment.Moment;
  endTime: moment.Moment;
  buyIn: number;
  periodType: TOURNAMENT_PERIOD;
  color: Colors;
  ranks?: Rank[];
  bill?: Bill;
  meta: ICreateTournament;

  constructor(data: ITournament) {
    const [date] = data.start_date.split('T');

    let startDate = moment.utc(date + ' ' + data.start_time);
    let endDate = moment.utc(date + ' ' + data.end_time);

    if (data.bill) {
      startDate = prepareTime(startDate, data.bill.time_shift);
      endDate = prepareTime(endDate, data.bill.time_shift);
    }

    this.key = makeid(5);
    this.id = data.id;
    this.groupId = data.group_id;
    this.name = data.name;
    this.type = data.type;
    this.startDate = startDate;
    this.startTime = startDate;
    this.endTime = endDate;
    this.buyIn = data.buy_in;
    this.periodType = data.period_type;
    this.color = data.color;
    this.ranks = data.ranks?.length ? data.ranks.map((rank) => new Rank(rank)) : undefined;
    this.bill = data.bill ? new Bill(data.bill) : undefined;
    this.meta = data.meta ? JSON.parse(data.meta) : {};
  }
}
