import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasePopconfirm } from '../BasePopconfirm/BasePopconfirm';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { BaseButton } from '../BaseButton/BaseButton';

interface IBaseDeleteBtnProps {
  text?: string;
  confirmTitle?: string;
  onConfirm?: () => void;
}

export const BaseDeleteBtn: React.FC<IBaseDeleteBtnProps> = (props) => {
  const { t } = useTranslation();
  const { onConfirm, text = t('controls.delete'), confirmTitle = t('messages.deleteConfirm') } = props;

  return (
    <BasePopconfirm
      title={confirmTitle}
      onConfirm={() => {
        if (onConfirm) onConfirm();
      }}
    >
      <BaseTooltip title={text}>
        <BaseButton type="primary" size="small" danger>
          {text}
        </BaseButton>
      </BaseTooltip>
    </BasePopconfirm>
  );
};

export default BaseDeleteBtn;
