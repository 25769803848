import i18next from 'i18next';

export interface RecurrenceDay {
  key: number;
  title: string;
  symbol: string;
}

export const weekDays = (): RecurrenceDay[] => [
  {
    key: 1,
    title: i18next.t('calendar.monday'),
    symbol: i18next.t('calendar.mondayShort'),
  },
  {
    key: 2,
    title: i18next.t('calendar.tuesday'),
    symbol: i18next.t('calendar.tuesdayShort'),
  },

  {
    key: 3,
    title: i18next.t('calendar.wednesday'),
    symbol: i18next.t('calendar.wednesdayShort'),
  },
  {
    key: 4,
    title: i18next.t('calendar.thursday'),
    symbol: i18next.t('calendar.thursdayShort'),
  },
  {
    key: 5,
    title: i18next.t('calendar.friday'),
    symbol: i18next.t('calendar.fridayShort'),
  },
  {
    key: 6,
    title: i18next.t('calendar.saturday'),
    symbol: i18next.t('calendar.saturdayShort'),
  },
  {
    key: 0,
    title: i18next.t('calendar.sunday'),
    symbol: i18next.t('calendar.sundayShort'),
  },
];
