import { IPlayerBill } from '../interfaces/i-player-bill';
import { Bill } from './bill';
import { Player } from './player';

export class PlayerBill {
  id: number;
  summ: number;
  playerNickname: string;
  bill: Bill;
  player?: Player;
  deleted_at: Date;

  constructor(data: IPlayerBill) {
    this.id = data.id;
    this.summ = data.summ;
    this.playerNickname = data.player_nickname;
    this.bill = new Bill(data.bill);
    this.player = data.player ? new Player(data.player) : undefined;
    this.deleted_at = data.deleted_at;
  }

  updateInfo(data: IPlayerBill): void {
    this.summ = data.summ;
    this.playerNickname = data.player_nickname;
    this.bill = new Bill(data.bill);
  }
}
