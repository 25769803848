import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import styled from 'styled-components';

export const PersonalForm = styled(BaseButtonsForm)`
  width: 100%;

  .ant-btn {
    margin-top: 20px;
    margin-left: auto;
  }
`;

export const PersonalInfoFormItem = styled(BaseForm.Item)`
  width: 48%;
`;

export const BaseSpace = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonalInfoSpace = styled(BaseSpace)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BaseTypography = styled.div`
  margin-bottom: 20px;
`;
