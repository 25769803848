import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import MergedTournamentTable from '@app/components/tournaments/table/merged-tables';
import { useAppSelector } from '@app/infrastructure/hooks/redux';

const TournamentsPage: React.FC = () => {
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user.user);

  const ranks = user?.player?.rank ? [user?.player?.rank.id] : undefined;

  return (
    <>
      <PageTitle>{t('navigation.tournaments')}</PageTitle>
      <BaseCol>
        <MergedTournamentTable ranks={ranks} />
      </BaseCol>
    </>
  );
};

export default TournamentsPage;
