import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import styled from 'styled-components';

export const AddPlayerBillSpace = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 156px 90px 90px 20px 20px;
  align-items: center;
  gap: 10px;
`;

export const AddPlayerBillFormItem = styled(BaseForm.Item)`
  margin-bottom: 0;
  border-radius: 0 !important;

  .ant-form-item-explain-error {
    display: none;
  }

  .ant-select-selector {
    border-radius: 7px !important;
  }
`;
