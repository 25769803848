import { useState } from 'react';
import { User } from '../classes/user';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ILoadProps } from './interfaces/i-load-props';
import { createUsers, deleteUsers, getUsers, updateUsers } from '@app/api/users';
import { ICreateUser, IUpdateUser } from '../interfaces/i-user';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HTTP_STATUSES } from '../consts/http-statuses';

type loadProps = ILoadProps & {
  admins?: boolean;
};

type UseUsers = {
  users: User[];
  meta: IPaginationMeta;
  loadUsers: (props?: loadProps) => Promise<User[] | undefined>;
  createUser: (data: ICreateUser) => Promise<User | void>;
  updateUser: (id: number, data: IUpdateUser) => Promise<User | void>;
  deleteUser: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseUsersProps {
  meta?: IPartialPaginationMeta;
}

const useUsers = (props?: IUseUsersProps): UseUsers => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 5,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadUsers = async (props?: loadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getUsers({ ...meta, ...props }, props?.admins);
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new User(el));
      if (props?.reload) setUsers(data);
      else setUsers([...users, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getUsers(
        {
          ...meta,
          ...metaRes,
          page,
          ...props,
        },
        props?.admins,
      );
      if (results) {
        data = results.map((el) => new User(el));
        if (props?.reload) setUsers(data);
        else setUsers([...users, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const createUser = async (data: ICreateUser) => {
    setLoading(true);
    const result = await createUsers(data)
      .then((data) => {
        notificationController.success({
          message: t('messages.successAdd'),
        });
        return data;
      })
      .catch((err) => {
        if (err.options.statusCode === HTTP_STATUSES.CONFLICT) {
          return notificationController.error({
            message: t('messages.loginUsed'),
          });
        } else {
          notificationController.error({ message: err.message });
        }
        setLoading(false);
      });

    setLoading(false);
    if (result) {
      return new User(result);
    }
  };

  const updateUser = async (id: number, data: IUpdateUser) => {
    setLoading(true);
    const result = await updateUsers(id, data).then((data) => {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return data;
    });
    setLoading(false);
    return new User(result);
  };

  const deleteUser = async (id: number) => {
    setLoading(true);
    await deleteUsers(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
    });
    setLoading(false);
  };

  return {
    users,
    meta,
    loading,
    loadUsers,
    createUser,
    updateUser,
    deleteUser,
  };
};

export default useUsers;
