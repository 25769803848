import React from 'react';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import { statusInternalTransferDataSource } from '@app/infrastructure/data-sources/status-internal-transfer';
import * as S from './styles';
import {
  backgroundStatusColor,
  borderStatusColor,
  fontStatusColor,
} from '@app/infrastructure/consts/status-internal-transfer-colors';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface StatusCellProps {
  data: InternalTransferRequest;
}

const StatusCell: React.FC<StatusCellProps> = ({ data }) => {
  const status = statusInternalTransferDataSource().find((item) => item.id === data.status);

  return (
    <BaseRow justify="center" align="middle">
      <S.StatusLabel
        style={
          status && {
            color: fontStatusColor[status.id],
            backgroundColor: backgroundStatusColor[status.id],
            borderColor: borderStatusColor[status.id],
          }
        }
      >
        {status?.label}
      </S.StatusLabel>
    </BaseRow>
  );
};

export default StatusCell;
