import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';

interface SummCellProps {
  data: PlayerBill;
}

const SummCell: React.FC<SummCellProps> = (props) => {
  const { data } = props;
  const isEuro = data.bill.currency.name === CURRENCIES.EURO;
  return (
    <>
      <BaseTypography>{`${data.bill.currency.name}${data.summ}`}</BaseTypography>
      {isEuro && <div>{`(${CURRENCIES.DOLLAR} ${(data.summ * data.bill.currency.rate).toFixed(2)})`}</div>}
    </>
  );
};

export default SummCell;
