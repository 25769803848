import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';
import React from 'react';

const { Text } = BaseTypography;
interface INameCellProps {
  data: PlayerBill;
}

const NameCell: React.FC<INameCellProps> = (props) => {
  const { data } = props;
  return (
    <Text style={{ width: 114 }} ellipsis={{ tooltip: `${data.bill.name}` }}>
      {data.bill.name}
    </Text>
  );
};

export default NameCell;
