import { IUseTableColumnSearch } from '@app/infrastructure/antd/hooks/use-table-column-search';
import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import { Flcraft } from '@app/infrastructure/classes/flcraft';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import i18next from 'i18next';

interface IFlcraftTableColumnsProps {
  columnSearchProps: IUseTableColumnSearch['columnSearchProps'];
}

export const flcraftTableColumns = ({}: IFlcraftTableColumnsProps): IModifiedTableColumn<Flcraft>[] => [
  {
    title: `${i18next.t('labels.startDate')}`,
    dataIndex: 'startDate',
    sortField: 'start_date',
    sorter: true,
    render: (_, record) => record.startDate.format(MomentFormats['DD.MM.YYYY']),
  },
  {
    title: `${i18next.t('labels.tournamentName')}`,
    dataIndex: 'tournamentName',
    sortField: 'tournament_name',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.reentry')}`,
    dataIndex: 'reentry',
    sortField: 'reentry',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.tournamentBuyin')}`,
    dataIndex: 'tournamentBuyin',
    sortField: 'tournament_buyin',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.playersCount')}`,
    dataIndex: 'playersCount',
    sortField: 'players_count',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.prizeFund')}`,
    dataIndex: 'prizeFund',
    sortField: 'prize_fund',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.playerName')}`,
    dataIndex: 'playerName',
    sortField: 'player_name',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.playerPosition')}`,
    dataIndex: 'playerPosition',
    sortField: 'player_position',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.playerPrizeAmount')}`,
    dataIndex: 'playerPrizeAmount',
    sortField: 'player_prize_amount',
    sorter: true,
  },
  {
    title: `${i18next.t('labels.result')}`,
    dataIndex: 'profit',
    sortField: 'profit',
    sorter: true,
  },
];
