import React from 'react';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { useTranslation } from 'react-i18next';
import { typeOfEntryDataSource } from '@app/infrastructure/data-sources/type-of-entry';

interface TypeOfEntrySelectProps {
  placeholder?: string;
}

const TypeOfEntrySelect: React.FC<TypeOfEntrySelectProps> = (props) => {
  const { t } = useTranslation();
  const { placeholder = t('labels.selectFromList'), ...args } = props;

  return (
    <BaseSelect placeholder={placeholder} {...args}>
      {typeOfEntryDataSource().map((item) => {
        return (
          <Option value={item.id} key={item.id}>
            {item.label}
          </Option>
        );
      })}
    </BaseSelect>
  );
};

export default TypeOfEntrySelect;
