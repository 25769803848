import { User } from '@app/infrastructure/classes/user';
import { Permissions } from '@app/infrastructure/enums/permissions';
import { ROLES } from '@app/infrastructure/enums/roles';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IAccessRightCellProps {
  data: User;
}

const AccessRightCell: React.FC<IAccessRightCellProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  let message = data.accessRight ? data.accessRight?.name : t('labels.noAccessRight');
  if (data.role === ROLES.SUPERADMIN) {
    message = t(`permissions.${Permissions.FullAccess}`);
  }
  return <div>{message}</div>;
};

export default AccessRightCell;
