import { User } from '@app/infrastructure/classes/user';
import { IUser } from '@app/infrastructure/interfaces/i-user';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const persistRefreshToken = (token: string): void => {
  localStorage.setItem('refreshToken', token);
};

export const readToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

export const readRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken');
};

export const persistUser = (user: IUser): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): User | null => {
  const userStr = localStorage.getItem('user');
  if (userStr) {
    const parsedUser = JSON.parse(userStr);
    if (parsedUser) return new User(parsedUser);
  }
  return null;
};

export const deleteRefreshToken = (): void => localStorage.removeItem('refreshToken');
export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
