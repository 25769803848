import React from 'react';
import * as S from './styles';
import { BaseRow } from '../BaseRow/BaseRow';
import { BaseCol } from '../BaseCol/BaseCol';
import { BaseButton } from '../BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface IBaseTableTitleProps {
  title?: string;
  extra?: React.ReactNode;
  canCreate?: boolean;
  onCreate?: () => void;
  onReload?: () => void;
  size?: SizeType;
}

const BaseTableTitle: React.FC<IBaseTableTitleProps> = (props) => {
  const { title, extra, onCreate, canCreate, onReload, size = 'middle' } = props;
  const { t } = useTranslation();

  return (
    <BaseRow justify="space-between" align="middle">
      {extra && <BaseCol>{extra}</BaseCol>}
      <BaseCol>
        <S.TableTitle>{title}</S.TableTitle>
      </BaseCol>

      <BaseCol>
        <BaseRow justify="space-between" align="middle" gutter={10}>
          <BaseCol>
            {onCreate && canCreate && (
              <BaseButton type="primary" onClick={onCreate} size={size}>
                {t('controls.create')}
              </BaseButton>
            )}
          </BaseCol>
          <BaseCol>
            {onReload && <BaseButton type="primary" icon={<ReloadOutlined />} onClick={onReload} size={size} />}
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>
  );
};

export default BaseTableTitle;
