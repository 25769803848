import moment from 'moment';
import { STATUS_INTERNAL_TRANSFER } from '../enums/status-internal-transfer.enum';
import { IInternalTransferRequest } from '../interfaces/i-internal-transfer-request';
import { InternalTransfer } from './internal-transfer';
import { PlayerBill } from './player-bill';
import { User } from './user';

export class InternalTransferRequest {
  id: number;
  requestedAmount: number;
  receivedAmount: number;
  leftOver: number;
  status: STATUS_INTERNAL_TRANSFER;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  user: User;
  playersBill?: PlayerBill;
  internalTransfers?: InternalTransfer[];

  constructor(data: IInternalTransferRequest) {
    this.id = data.id;
    this.requestedAmount = data.requested_amount;
    this.receivedAmount = data.received_amount;
    this.leftOver = Number((data.requested_amount - data.received_amount).toFixed(2));
    this.status = data.status;
    this.createdAt = moment(data.created_at);
    this.updatedAt = moment(data.updated_at);
    this.user = new User(data.user);
    this.playersBill = data.players_bill ? new PlayerBill(data.players_bill) : undefined;
    this.internalTransfers = data.internal_transfers
      ? data.internal_transfers.map((item) => {
          return new InternalTransfer(item);
        })
      : undefined;
  }
}
