import { httpApi } from '@app/api/http.api';
import { IAccessRight, ICreateAccessRight } from '@app/infrastructure/interfaces/i-access-right';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const route = 'access-rights';

export const getAccessRights = async (params: IPaginationMeta): Promise<IPaginatedData<IAccessRight>> => {
  return await httpApi.get<IPaginatedData<IAccessRight>>(route, { params }).then(({ data }) => data);
};

export const createAccessRights = async (data: ICreateAccessRight): Promise<IAccessRight> => {
  return await httpApi.post<IAccessRight>(route, data).then(({ data }) => data);
};

export const updateAccessRights = async (id: number, data: ICreateAccessRight): Promise<IAccessRight> => {
  return await httpApi.put<IAccessRight>(`${route}/${id}`, data).then(({ data }) => data);
};

export const deleteAccessRights = async (id: number): Promise<IAccessRight> => {
  return await httpApi.delete<IAccessRight>(`${route}/${id}`).then(({ data }) => data);
};
