import { useState } from 'react';
import { Limit } from '../classes/limit';
import {
  getLimits,
  createLimits,
  deleteLimits,
  updateLimits,
  getLimitsByRanks,
  getDynamicLimitsForTable,
  updateDynamicLimitsForTable,
} from '@app/api/limits';
import { ILoadProps } from './interfaces/i-load-props';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ICreateLimit, IDynamicLimit, IUpdateLimit } from '../interfaces/i-limit';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HttpStatus } from '../enums/http-status';

type UseLimits = {
  limits: Limit[];
  dynamicLimits: IDynamicLimit | undefined;
  meta: IPaginationMeta;
  loadLimits: (props?: ILoadProps) => Promise<Limit[] | undefined>;
  loadLimitsByRank: (rankId: number, props?: ILoadProps) => Promise<Limit[] | undefined>;
  loadDynamicLimitsForTable: () => Promise<IDynamicLimit | undefined>;
  createLimit: (data: ICreateLimit) => Promise<Limit | void>;
  updateLimit: (id: number, data: IUpdateLimit) => Promise<Limit | undefined>;
  updateDynamicLimit: (data: IDynamicLimit) => Promise<IDynamicLimit | undefined>;
  deleteLimit: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseLimitsProps {
  meta?: IPartialPaginationMeta;
}

const useLimits = (props?: IUseLimitsProps): UseLimits => {
  const [loading, setLoading] = useState<boolean>(false);
  const [limits, setLimits] = useState<Limit[]>([]);
  const [dynamicLimits, setDynamicLimits] = useState<IDynamicLimit>();
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 0,
    limit: 0,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadLimits = async (props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { results, meta: metaRes } = await getLimits({ ...meta, ...props });
    const data = results.map((el) => new Limit(el));
    if (props?.reload) setLimits(data);
    else setLimits([...limits, ...data]);
    setMeta({
      ...meta,
      ...metaRes,
    });
    setLoading(false);
    return data;
  };

  const loadLimitsByRank = async (rankId: number, props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { results, meta: metaRes } = await getLimitsByRanks(rankId, { ...meta, ...props });
    const data = results.map((el) => new Limit(el));
    if (props?.reload) setLimits(data);
    else setLimits([...limits, ...data]);
    setMeta({
      ...meta,
      ...metaRes,
    });
    setLoading(false);
    return data;
  };

  const loadDynamicLimitsForTable = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const data = await getDynamicLimitsForTable();
    if (data) {
      setDynamicLimits(data);
    }
    setLoading(false);
    return data;
  };

  const createLimit = async (data: ICreateLimit) => {
    setLoading(true);
    const result = await createLimits(data).catch((error) => {
      if (error.options.statusCode === HttpStatus.CONFLICT) {
        notificationController.error({
          message: t('messages.roomUsed'),
        });
      }
    });
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successAdd'),
      });
      return new Limit(result);
    }
  };

  const updateLimit = async (id: number, data: IUpdateLimit) => {
    setLoading(true);
    const result = await updateLimits(id, data);
    setLoading(false);
    notificationController.success({
      message: t('messages.successUpdate'),
    });
    return new Limit(result);
  };

  const updateDynamicLimit = async (data: IDynamicLimit) => {
    setLoading(true);
    const result = await updateDynamicLimitsForTable(data);
    setLoading(false);
    notificationController.success({
      message: t('messages.successUpdate'),
    });
    return result;
  };

  const deleteLimit = async (id: number) => {
    setLoading(true);
    await deleteLimits(id);
    setLoading(false);
    notificationController.success({
      message: t('messages.successDelete'),
    });
  };

  return {
    limits,
    dynamicLimits,
    meta,
    loading,
    loadLimits,
    loadLimitsByRank,
    loadDynamicLimitsForTable,
    createLimit,
    updateLimit,
    updateDynamicLimit,
    deleteLimit,
  };
};

export default useLimits;
