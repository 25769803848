import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { TabsSpace } from './styles';
import { Player } from '@app/infrastructure/classes/player';
import PlayerDashboard from '../dashboard';
import PersonalInfo from '../personal-info';
import PersonalTransfersTable from '../personal-transfers/table';
import InternalTransferRequestsPlayerTable from '../internal-transfer-requests/admin-table';
import InternalTransfersPlayerTable from '../internal-transfers/admin-table';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import AnalyticsTable from '../analytics/table';
import AnalyticsDescription from '../analytics/description-card';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

interface IAdminPlayerTabsProps {
  player: Player;
}

const AdminPlayerTabs: React.FC<IAdminPlayerTabsProps> = (props) => {
  const { player } = props;
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user?.user);
  const canEdit = user?.canUpdate('playerPermission');

  const tabs = useMemo(
    () => [
      {
        key: '1',
        label: t('labels.dashboard'),
        children: <PlayerDashboard player={player} canEdit={canEdit} />,
      },
      {
        key: '2',
        label: t('labels.internalTransfers'),
        children: (
          <>
            <InternalTransferRequestsPlayerTable player={player} /> <InternalTransfersPlayerTable player={player} />
          </>
        ),
      },
      {
        key: '3',
        label: t('labels.personalTransfers'),
        children: <PersonalTransfersTable player={player} />,
      },
      {
        key: '4',
        label: t('labels.personalInformation'),
        children: <PersonalInfo player={player} />,
      },
      {
        key: '5',
        label: t('labels.analytics'),
        children: (
          <BaseRow gutter={[10, 10]}>
            <BaseCol span={24} xl={14}>
              <AnalyticsTable player={player} />
            </BaseCol>

            <BaseCol span={24} xl={10}>
              <AnalyticsDescription player={player} canEdit={canEdit} />
            </BaseCol>
          </BaseRow>
        ),
      },
    ],
    [player, t, canEdit],
  );

  return (
    <TabsSpace>
      <BaseTabs defaultActiveKey="1" items={tabs} destroyInactiveTabPane />
    </TabsSpace>
  );
};

export default AdminPlayerTabs;
