import { PlayerGame } from '@app/infrastructure/classes/player-game';
import { typeOfEntryDataSource } from '@app/infrastructure/data-sources/type-of-entry';
import React from 'react';

interface TypeOfEntryCellProps {
  data: PlayerGame;
}

const TypeOfEntryCell: React.FC<TypeOfEntryCellProps> = ({ data }) => {
  const name = typeOfEntryDataSource().find((item) => item.id === data.typeOfEntry);
  return <span>{name?.label}</span>;
};

export default TypeOfEntryCell;
