import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../BaseButton/BaseButton';

interface SaveButtonProps {
  onCorfim: () => void;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onCorfim }) => {
  const { t } = useTranslation();

  return (
    <BaseButton size="small" type="primary" onClick={onCorfim} id="save">
      {t('controls.save')}
    </BaseButton>
  );
};

export default SaveButton;
