import styled from 'styled-components';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { media } from '@app/styles/themes/constants';

export const StyledBaseTable = styled(BaseTable)`
  .color-096dd9 {
    background-color: #e6f7ff;
  }
  .color-389e0d {
    background-color: #f6ffed;
  }
  .color-d48806 {
    background-color: #fdfce6;
  }
  .color-cf1322 {
    background-color: #fff1f0;
  }
  .color-f3e1fa {
    background-color: #f3e1fa;
  }
  tbody .ant-table-cell {
    color: black !important;
  }
`;

export const StyledHeader = styled.div`
  margin: 0 0 20px 0;
`;

export const labeledDiv = styled.div`
  position: relative;
  display: flex;
  align-items: top;
  .label {
    position: absolute;
    z-index: 10;
    top: 4px;
    left: 10px;
  }
`;

export const StyledRow = styled(BaseRow)`
  margin-top: 220px;
  @media only screen and ${media.md} {
    margin-top: 160px;
  }
  @media only screen and ${media.xl} {
    margin-top: 150px;
  }
  @media only screen and ${media.middle} {
    margin-top: 130px;
  }
`;
