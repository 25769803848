import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { InputNumber } from 'antd';
import * as S from './styles';
import { ImageUpload } from '@app/components/images/image-uploud-input';
import useImages from '@app/infrastructure/hooks/use-images';
import { UploadFile } from 'antd/es/upload/interface';
import useInternalTransfers from '@app/infrastructure/hooks/use-internal-transfers';
import { IUpdateInternalTransfer } from '@app/infrastructure/interfaces/i-internal-transfer';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';

interface IInternalTransfersUpdateFormProps {
  data: InternalTransfer;
  onClose?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  image: UploadFile;
  amount: number;
  commission: number;
  description: string;
}

const InternalTransfersUpdateForm: React.FC<IInternalTransfersUpdateFormProps> = (props) => {
  const { data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { id } = data;
  const { key } = useRerender();
  const { updateInternalTransfer, updateInternalTransferAdmin, loading } = useInternalTransfers({ player: undefined });
  const { createImage, loading: loadingImage } = useImages();

  const onFinish = async (values: IFormValues) => {
    if (values.image) {
      const imageData = new FormData();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      imageData.append('image', values.image as any);
      createImage(imageData).then((image) => {
        if (image) {
          const updateInternalTransferValues: IUpdateInternalTransfer = {
            amount: values.amount,
            description: values.description,
            commission: values.commission,
            image_id: image?.id,
          };
          if (data.playersBill) {
            updateInternalTransfer(id, updateInternalTransferValues, data).then(() => {
              if (onSave) onSave();
            });
          } else {
            updateInternalTransferAdmin(id, updateInternalTransferValues, data).then(() => {
              if (onSave) onSave();
            });
          }
        }
      });
    } else {
      const updateInternalTransferValues: IUpdateInternalTransfer = {
        amount: values.amount,
        commission: values.commission,
        description: values.description,
      };
      if (data.playersBill) {
        updateInternalTransfer(id, updateInternalTransferValues, data).then(() => {
          if (onSave) onSave();
        });
      } else {
        updateInternalTransferAdmin(id, updateInternalTransferValues, data).then(() => {
          if (onSave) onSave();
        });
      }
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading || loadingImage}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
      key={key}
    >
      <BaseRow justify="space-between">
        <S.NumberInput
          name="amount"
          initialValue={data.amount}
          label={t('labels.summ')}
          rules={[{ type: 'number', min: 0 }]}
        >
          <InputNumber min={0} />
        </S.NumberInput>
        <S.NumberInput
          name="commission"
          initialValue={data.commission}
          label={t('labels.commission')}
          rules={[{ type: 'number', min: 0 }]}
        >
          <InputNumber min={0} />
        </S.NumberInput>
      </BaseRow>
      <BaseButtonsForm.Item name="description" initialValue={data.description} label={t('labels.comment')}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item label={t('labels.screen')}>
        <ImageUpload />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default InternalTransfersUpdateForm;
