import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { PersonalTransfer } from '@app/infrastructure/classes/personal-transfer';
import { ROUTES } from '@app/infrastructure/consts/routes';
import React from 'react';
import { Link } from 'react-router-dom';

interface LoginCellProps {
  data: PersonalTransfer;
}

const LoginCell: React.FC<LoginCellProps> = ({ data }) => {
  return (
    <>
      {data.playersBill.player && (
        <BaseTypography>
          {<Link to={`${ROUTES.PLAYERS}/${data.playersBill.player.id}`}>{data.playersBill.player.user?.login}</Link>}
        </BaseTypography>
      )}
    </>
  );
};

export default LoginCell;
