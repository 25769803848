import React, { useState } from 'react';
import * as S from './styles';
import TournamentsTableFilters from './filters';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import TournamentsCreateForm from '../create-form';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ITournamentFilters } from '@app/infrastructure/interfaces/i-tournament';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import TournamentLimits from '../limits';

interface ITournamentTableHeaderProps {
  onFilterChange: (filters: ITournamentFilters) => void;
  onCreated: () => void;
}

const TournamentTableHeader: React.FC<ITournamentTableHeaderProps> = (props) => {
  const { onCreated, onFilterChange } = props;
  const { t } = useTranslation();
  const [key, setKey] = useState<number>(Date.now());
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user.user);

  const onSave = async () => {
    setModalOpened(false);
    onCreated();
    setKey(Date.now());
  };

  const onClose = () => {
    setModalOpened(false);
  };

  return (
    <>
      <S.StyledHeader>
        <BaseRow gutter={10} style={{ margin: '0 0 20px 0' }}>
          {user?.canCreate('tournamentPermission') && (
            <BaseCol>
              <BaseButton size="small" type="primary" onClick={() => setModalOpened(true)}>
                {t('controls.addTournament')}
              </BaseButton>
            </BaseCol>
          )}
          <BaseCol>
            <TournamentLimits />
          </BaseCol>
        </BaseRow>
        <TournamentsTableFilters onFilterChange={onFilterChange} />
      </S.StyledHeader>
      <BaseModal title={t('labels.addTournament')} open={modalOpened} onCancel={onClose} footer={null} destroyOnClose>
        <TournamentsCreateForm key={key} onSave={onSave} />
      </BaseModal>
    </>
  );
};

export default TournamentTableHeader;
