import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import styled from 'styled-components';

export const StyledBaseTable = styled(BaseTable)`
  .color-096dd9 {
    background-color: #e6f7ff;
  }
  .color-389e0d {
    background-color: #f6ffed;
  }
  .color-d48806 {
    background-color: #fdfce6;
  }
  .color-cf1322 {
    background-color: #fff1f0;
  }
  tbody .ant-table-cell {
    color: black !important;
  }
`;
