import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { IUseTableColumnSearch } from '@app/infrastructure/antd/hooks/use-table-column-search';
import { PersonalTransfer } from '@app/infrastructure/classes/personal-transfer';
import BillNameCell from './bill-name-cell';
import PurposeCell from './purpose-cell';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import ImageWithPreview from '@app/components/common/image';
import LoginCell from './login-cell';
import BillDeletedCell from './bill-deleted-cell';

interface IPersonalTransferColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: PersonalTransfer) => void;
  onDelete: (data: PersonalTransfer) => void;
  columnSearchProps?: IUseTableColumnSearch['columnSearchProps'];
}

export const personalTransfersTableColumns = ({
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}: IPersonalTransferColumnsProps): IModifiedTableColumn<PersonalTransfer>[] => {
  const columns: IModifiedTableColumn<PersonalTransfer>[] = [
    {
      title: `${i18next.t('labels.login')}`,
      dataIndex: 'login',
      sortField: 'players_bill.player.user.login',
      sorter: true,
      width: '120px',
      render: (_, data: PersonalTransfer) => {
        return <LoginCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.check')}`,
      dataIndex: 'name',
      sortField: 'players_bill.bill.name',
      sorter: true,
      width: '100px',
      render: (_, data: PersonalTransfer) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.summ')}`,
      dataIndex: 'amount',
      sortField: 'amount',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.date')}`,
      dataIndex: 'createdAt',
      sortField: 'created_at',
      sorter: true,
      render: (_, record) => record.createdAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.purpose')}`,
      dataIndex: 'purpose',
      sortField: 'purpose',
      sorter: true,
      render: (_, data: PersonalTransfer) => {
        return <PurposeCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.comment')}`,
      dataIndex: 'description',
    },
    {
      title: `${i18next.t('labels.screen')}`,
      dataIndex: 'image',
      align: 'center',
      render: (_, data: PersonalTransfer) => {
        return <ImageWithPreview data={data.image} />;
      },
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '120px',
      align: 'center',
      render: (_, record) =>
        record.playersBill.deleted_at === null ? (
          <BaseActionsCell
            canEdit={canEdit}
            canDelete={canDelete}
            onDelete={() => {
              onDelete(record);
            }}
            onEdit={() => {
              onEdit(record);
            }}
            size="small"
          />
        ) : (
          <BillDeletedCell />
        ),
    });
  }

  return columns;
};
