import { httpApi } from '@app/api/http.api';
import { ILevel, ICreateLevel } from '@app/infrastructure/interfaces/i-level';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const route = 'levels';

export const getLevels = async (params: IPaginationMeta): Promise<IPaginatedData<ILevel>> => {
  return await httpApi.get<IPaginatedData<ILevel>>(route, { params }).then(({ data }) => data);
};

export const createLevels = async (data: ICreateLevel): Promise<ILevel> => {
  return await httpApi.post<ILevel>(route, data).then(({ data }) => data);
};

export const updateLevels = async (id: number, data: ICreateLevel): Promise<ILevel> => {
  return await httpApi.put<ILevel>(`${route}/${id}`, data).then(({ data }) => data);
};

export const deleteLevels = async (id: number): Promise<ILevel> => {
  return await httpApi.delete<ILevel>(`${route}/${id}`).then(({ data }) => data);
};
