import { useState } from 'react';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ILoadProps } from './interfaces/i-load-props';
import {
  getAnalyticsByPlayer,
  getAnalyticsById,
  addAnalytics,
  updateAnalyticsById,
  deleteAnalyticsById,
} from '@app/api/analytics';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HttpStatus } from '../enums/http-status';
import { Analytics } from '../classes/analytics';
import { ICreateAnalytics, IUpdateAnalytics } from '../interfaces/i-analytics';
import { Player } from '../classes/player';

type UseAnalytics = {
  analytics: Analytics[];
  meta: IPaginationMeta;
  loadAnalyticsByPlayer: (props?: ILoadProps) => Promise<Analytics[] | undefined>;
  createAnalytics: (data: ICreateAnalytics) => Promise<Analytics | void>;
  loadAnalyticsById: (id: number) => Promise<Analytics | void>;
  updateAnalytics: (id: number, data: IUpdateAnalytics) => Promise<Analytics | void>;
  deleteAnalytics: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseAnalyticsProps {
  player: Player;
  meta?: IPartialPaginationMeta;
}

const useAnalytics = (props: IUseAnalyticsProps): UseAnalytics => {
  const { player } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<Analytics[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadAnalyticsByPlayer = async (props?: ILoadProps) => {
    setLoading(true);
    const { results, meta: metaRes } = await getAnalyticsByPlayer(player.id, { ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new Analytics(el));
      if (props?.reload) setAnalytics(data);
      else setAnalytics([...analytics, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getAnalyticsByPlayer(player.id, {
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new Analytics(el));
        if (props?.reload) setAnalytics(data);
        else setAnalytics([...analytics, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadAnalyticsById = async (id: number) => {
    setLoading(true);
    const data = await getAnalyticsById(id).catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    setLoading(false);
    if (data) return new Analytics(data);
  };

  const createAnalytics = async (data: ICreateAnalytics) => {
    setLoading(true);
    const result = await addAnalytics(player.id, data).catch((err) => {
      if (err.options.statusCode === HttpStatus.CONFLICT) {
        return notificationController.error({
          message: t('messages.analyticsStageUsed'),
        });
      } else {
        notificationController.error({ message: err.message });
      }
    });

    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successAdd'),
      });
      return new Analytics(result);
    }
  };

  const updateAnalytics = async (id: number, data: IUpdateAnalytics) => {
    setLoading(true);
    const result = await updateAnalyticsById(id, data).then((data) => {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      setLoading(false);
      return data;
    });
    return new Analytics(result);
  };

  const deleteAnalytics = async (id: number) => {
    setLoading(true);
    await deleteAnalyticsById(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  return {
    analytics,
    meta,
    loading,
    loadAnalyticsByPlayer,
    loadAnalyticsById,
    createAnalytics,
    updateAnalytics,
    deleteAnalytics,
  };
};

export default useAnalytics;
