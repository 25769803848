import React from 'react';
import { BaseSelect, BaseSelectProps, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { useTranslation } from 'react-i18next';

export enum FrequencyType {
  Weekly,
  Monthly,
}

type IFrequencySelectProps = BaseSelectProps;

const FrequencySelect: React.FC<IFrequencySelectProps> = (props) => {
  const { t } = useTranslation();

  const frequencyOptions = [
    {
      key: FrequencyType.Weekly,
      label: t('calendar.weekly'),
    },
    {
      key: FrequencyType.Monthly,
      label: t('calendar.monthly'),
    },
  ];

  return (
    <BaseSelect {...props}>
      {frequencyOptions.map((el) => (
        <Option key={el.key} value={el.key}>
          {el.label}
        </Option>
      ))}
    </BaseSelect>
  );
};

export default FrequencySelect;
