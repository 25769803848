import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import { PersonalTransfer } from '@app/infrastructure/classes/personal-transfer';
import usePersonalTransfers from '@app/infrastructure/hooks/use-personal-transfers';
import { IUpdatePersonalTransfer } from '@app/infrastructure/interfaces/i-personal-transfer';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { InputNumber } from 'antd';
import * as S from './styles';
import PurposePersonalTransferSelect from '@app/components/personal-transfers/purpose-select';
import { ImageUpload } from '@app/components/images/image-uploud-input';
import useImages from '@app/infrastructure/hooks/use-images';
import { PURPOSE_PERSONAL_TRANSFER } from '@app/infrastructure/enums/purpose-personal-transfer.enum';
import { UploadFile } from 'antd/es/upload/interface';

interface IPersonalTransfersUpdateFormProps {
  data: PersonalTransfer;
  onClose?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  image: UploadFile;
  amount: number;
  description: string;
  purpose: PURPOSE_PERSONAL_TRANSFER;
}

const PersonalTransfersUpdateForm: React.FC<IPersonalTransfersUpdateFormProps> = (props) => {
  const { data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { id } = data;

  const { updatePersonalTransfer, loading } = usePersonalTransfers({ player: undefined });
  const { createImage, loading: loadingImage } = useImages();

  const onFinish = async (values: IFormValues) => {
    if (values.image) {
      const imageData = new FormData();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      imageData.append('image', values.image as any);
      createImage(imageData).then((image) => {
        if (image) {
          const updatePersonalTransferValues: IUpdatePersonalTransfer = {
            amount: values.amount,
            description: values.description,
            purpose: values.purpose,
            image_id: image?.id,
          };
          updatePersonalTransfer(id, updatePersonalTransferValues, data).then(() => {
            if (onSave) onSave();
          });
        }
      });
    } else {
      const updatePersonalTransferValues: IUpdatePersonalTransfer = {
        amount: values.amount,
        description: values.description,
        purpose: values.purpose,
      };
      updatePersonalTransfer(id, updatePersonalTransferValues, data).then(() => {
        if (onSave) onSave();
      });
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading || loadingImage}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <S.HeaderDescription>{t('messages.createTransferNeedFillFields')}</S.HeaderDescription>
      <BaseRow justify="space-between">
        <S.NumberInput
          name="amount"
          initialValue={data.amount}
          label={t('labels.summ')}
          rules={[{ type: 'number', min: 0 }]}
        >
          <InputNumber min={0} />
        </S.NumberInput>
        <S.PurposeItem name="purpose" initialValue={data.purpose} label={t('labels.purpose')}>
          <PurposePersonalTransferSelect />
        </S.PurposeItem>
      </BaseRow>

      <BaseButtonsForm.Item name="description" initialValue={data.description} label={t('labels.comment')}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item label={t('labels.screen')}>
        <ImageUpload />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default PersonalTransfersUpdateForm;
