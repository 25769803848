import React from 'react';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { analyticsStagesDataSource } from '@app/infrastructure/data-sources/analytics-stages';

const StageSelect: React.FC = (props) => {
  const { ...args } = props;
  return (
    <BaseSelect {...args}>
      {analyticsStagesDataSource().map((stage) => (
        <Option key={stage.id} value={stage.id}>
          {stage.label}
        </Option>
      ))}
    </BaseSelect>
  );
};

export default StageSelect;
