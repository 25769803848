import styled from 'styled-components';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

export const HeaderDescription = styled(BaseTypography)`
  margin-bottom: 15px;
`;

export const PurposeItem = styled(BaseButtonsForm.Item)`
  width: 45%;

  .ant-select-selection-placeholder {
    font-size: 16px;
  }
`;

export const NumberInput = styled(BaseButtonsForm.Item)`
  .ant-input-number {
    width: 100%;
  }
`;
