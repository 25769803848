import { ICreateRank, IRank, IUpdateRank } from '@app/infrastructure/interfaces/i-rank';
import { httpApi } from './http.api';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const baseRoute = '/ranks';

export const getRanks = async (params: IPaginationMeta): Promise<IPaginatedData<IRank>> => {
  return await httpApi.get<IPaginatedData<IRank>>(baseRoute, { params }).then(({ data }) => data);
};

export async function getRankById(id: number): Promise<IRank> {
  const { data } = await httpApi.get(`${baseRoute}/${id}`);
  return data;
}

export async function createRanks(rank: ICreateRank): Promise<IRank> {
  const { data } = await httpApi.post(baseRoute, rank);
  return data;
}

export async function updateRanks(id: number, rank: IUpdateRank): Promise<IRank> {
  const { data } = await httpApi.put(`${baseRoute}/${id}`, rank);
  return data;
}

export async function deleteRanks(id: number): Promise<IRank> {
  const { data } = await httpApi.delete(`${baseRoute}/${id}`);
  return data;
}
