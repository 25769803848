import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { ROLES } from '@app/infrastructure/enums/roles';
import PlayerGamesInReviewTable from '@app/components/players/player-games/in-review-table';
import PlayerGamesWithStatusTable from '@app/components/players/player-games/with-status-table';
import PlayerGamesAdminInReviewTable from '@app/components/player-games/in-review-table';
import PlayerGamesAdminWithStatusTable from '@app/components/player-games/with-status-table';
import { useBadge } from '@app/infrastructure/providers/badge-provider';

const PlayerView: React.FC = () => {
  const player = useAppSelector((state) => state.user.user?.player);
  return (
    <>
      {player && (
        <>
          <PlayerGamesInReviewTable canCreate canDelete />
          <PlayerGamesWithStatusTable />
        </>
      )}
    </>
  );
};

const AdminView: React.FC = () => {
  const user = useAppSelector((state) => state.user?.user);

  const { rerenderBadge } = useBadge();

  useEffect(() => {
    rerenderBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BaseCol>
      <PlayerGamesAdminInReviewTable
        canEdit={user?.canUpdate('playerGamePermission')}
        canDelete={user?.canUpdate('playerGamePermission')}
      />
      <PlayerGamesAdminWithStatusTable />
    </BaseCol>
  );
};

const PlayerGamesPage: React.FC = () => {
  const { t } = useTranslation();
  const role = useAppSelector((state) => state.user.user?.role);

  return (
    <>
      <PageTitle>{t('navigation.playerGames')}</PageTitle>
      {role === ROLES.PLAYER ? <PlayerView /> : <AdminView />}
    </>
  );
};

export default PlayerGamesPage;
