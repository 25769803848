/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { flcraftPlayerStatisticTableColumns } from './cols';
import { TablePaginationConfig } from 'antd';
import { FilterValue, Key, RowSelectMethod, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import useFlcraftsStatistic from '@app/infrastructure/hooks/use-flcraft-statistic';
import { FlcraftPlayerStatistic } from '@app/infrastructure/classes/flcraft';
import FlcraftStatisticView from '../statistic';
import FlcraftPlayersTableFilters from './filters';
import * as S from './styles';
import {
  IFlcraftAverageStatisticFilters,
  IFlcraftFilters,
  IPlayerFlcraftFilters,
} from '@app/infrastructure/interfaces/i-flcraft';
import { getPlayerFlcraftFilters } from '@app/infrastructure/services/filters-storage.service';

interface IFlcraftPlayerStatisticsTableProps {
  flcraftFilters?: IFlcraftFilters;
  onSelect: (ids: number[]) => void;
}

const FlcraftPlayerStatisticsTable: React.FC<IFlcraftPlayerStatisticsTableProps> = (props) => {
  const { flcraftFilters = {}, onSelect } = props;
  const { flcraftStatistics, loadFlcraftStatistics, loadAverageFlcraftStatistics, meta, loading } =
    useFlcraftsStatistic();

  const [statistic, setStatistic] = useState<FlcraftPlayerStatistic | undefined>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [filters, setFilters] = useState<IPlayerFlcraftFilters>(getPlayerFlcraftFilters());

  const hasPagination = meta.total > meta.limit;

  const prepareStatisticFilters = (flcraftFilters: IFlcraftFilters, playerFilters: IPlayerFlcraftFilters) => {
    const statisticFilter = {
      ...playerFilters,
      ...flcraftFilters,
      tournamentName: flcraftFilters?.tournamentName,
      playerName: playerFilters.playerName,
    };
    return statisticFilter;
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<FlcraftPlayerStatistic> | SorterResult<FlcraftPlayerStatistic>[],
  ) => {
    await loadFlcraftStatistics({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<FlcraftPlayerStatistic>(sorter as IModifiedSorterResult<FlcraftPlayerStatistic>),
      reload: true,
      filters,
    });
  };

  const getStatistic = async (ids: number[], filters: IFlcraftAverageStatisticFilters) => {
    const statistic = await loadAverageFlcraftStatistics({
      ids,
      limit: 0,
      page: 0,
      filters,
    });
    setStatistic(new FlcraftPlayerStatistic({ statistic }));
  };

  const rowSelection = {
    onChange: (
      selectedKeys: Key[],
      _: any,
      info: {
        type: RowSelectMethod;
      },
    ) => {
      let ids = selectedKeys as number[];

      if (info.type === 'all') {
        ids = meta.ids as number[];
        if (selectedRowKeys.length === ids.length) ids = [];
      }

      setSelectedRowKeys(ids);
      onSelect(ids);
      getStatistic(ids.length ? ids : meta.ids ?? [], prepareStatisticFilters(flcraftFilters, filters));
    },
  };

  const onFilterChange = async (filters: IPlayerFlcraftFilters) => {
    setFilters(filters);
    setSelectedRowKeys([]);
    onSelect([]);
    await loadFlcraftStatistics({ ...meta, filters, ids: [], reload: true });
  };

  useEffect(() => {
    const storageFilters = getPlayerFlcraftFilters();
    onFilterChange(storageFilters);
  }, []);

  useEffect(() => {
    getStatistic(selectedRowKeys as number[], prepareStatisticFilters(flcraftFilters, filters));
  }, [selectedRowKeys, filters, flcraftFilters]);

  return (
    <S.StyledBaseTable
      size="small"
      rowKey="playerId"
      title={() => {
        return (
          <div>
            {statistic && (
              <div style={{ margin: '0 0 25px 0' }}>
                <FlcraftStatisticView data={statistic} />
              </div>
            )}
            <FlcraftPlayersTableFilters filters={filters} onFilterChange={onFilterChange} />
          </div>
        );
      }}
      columns={flcraftPlayerStatisticTableColumns()}
      dataSource={flcraftStatistics}
      pagination={
        !loading &&
        hasPagination && {
          current: meta.page,
          pageSize: meta.limit,
          total: meta.total,
          showSizeChanger: true,
        }
      }
      loading={loading}
      onChange={handleTableChange}
      scroll={{ x: 1000 }}
      rowSelection={{ ...rowSelection, selectedRowKeys, preserveSelectedRowKeys: true }}
      bordered
    />
  );
};

export default FlcraftPlayerStatisticsTable;
