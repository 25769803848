import { useState } from 'react';
import { Level } from '../classes/level';
import { getLevels, createLevels, deleteLevels, updateLevels } from '@app/api/levels';
import { ILoadProps } from './interfaces/i-load-props';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ICreateLevel } from '../interfaces/i-level';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HttpStatus } from '../enums/http-status';

type UseLevels = {
  levels: Level[];
  meta: IPaginationMeta;
  loadLevels: (props?: ILoadProps) => Promise<Level[] | undefined>;
  createLevel: (data: ICreateLevel) => Promise<Level | void>;
  updateLevel: (id: number, data: ICreateLevel) => Promise<Level | undefined>;
  deleteLevel: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseLevelsProps {
  meta?: IPartialPaginationMeta;
}

const useLevels = (props?: IUseLevelsProps): UseLevels => {
  const [loading, setLoading] = useState<boolean>(false);
  const [levels, setLevels] = useState<Level[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 0,
    limit: 0,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadLevels = async (props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { results, meta: metaRes } = await getLevels({ ...meta, ...props });
    const data = results.map((el) => new Level(el));
    if (props?.reload) setLevels(data);
    else setLevels([...levels, ...data]);
    setMeta({
      ...meta,
      ...metaRes,
    });
    setLoading(false);
    return data;
  };

  const createLevel = async (data: ICreateLevel) => {
    setLoading(true);
    const result = await createLevels(data).catch((error) => {
      if (error.options.statusCode === HttpStatus.CONFLICT) {
        notificationController.error({
          message: t('messages.roomUsed'),
        });
      }
    });
    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successAdd'),
      });
      return new Level(result);
    }
  };

  const updateLevel = async (id: number, data: ICreateLevel) => {
    setLoading(true);
    const result = await updateLevels(id, data);
    setLoading(false);
    notificationController.success({
      message: t('messages.successUpdate'),
    });
    return new Level(result);
  };

  const deleteLevel = async (id: number) => {
    setLoading(true);
    await deleteLevels(id);
    setLoading(false);
    notificationController.success({
      message: t('messages.successDelete'),
    });
  };

  return {
    levels,
    meta,
    loading,
    loadLevels,
    createLevel,
    updateLevel,
    deleteLevel,
  };
};

export default useLevels;
