import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { User } from '@app/infrastructure/classes/user';
import AccessRightCell from './access-right';

interface IUsersTableColumnsProps {
  user?: User;
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: User) => void;
  onDelete: (data: User) => void;
}

export const usersTableColumns = ({
  user,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}: IUsersTableColumnsProps): IModifiedTableColumn<User>[] => {
  const colums: IModifiedTableColumn<User>[] = [
    {
      title: `${i18next.t('labels.name')}`,
      dataIndex: 'login',
      sortField: 'login',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.role')}`,
      dataIndex: 'role',
      sortField: 'role',
      sorter: true,
      render: (_, record) => `${i18next.t(`roles.${record.role}`)}`,
    },
    {
      title: `${i18next.t('labels.accessRight')}`,
      dataIndex: 'accessRight',
      sorter: false,
      render: (_, record) => <AccessRightCell data={record} />,
    },
  ];

  if (canEdit && canDelete) {
    colums.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '150px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          canEdit={canEdit}
          canDelete={canDelete && user && user.login !== record.login && record.login !== 'superadmin'}
          onDelete={() => {
            onDelete(record);
          }}
          onEdit={() => {
            onEdit(record);
          }}
          size="small"
        />
      ),
    });
  }

  return colums;
};
