/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import useTournaments from '@app/infrastructure/hooks/use-tournaments';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { tournamentTableColumns } from './cols';
import { Tournament } from '@app/infrastructure/classes/tournament';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { TOURNAMENT_PERIOD } from '@app/infrastructure/enums/tournament-period';
import { ITournamentFilters } from '@app/infrastructure/interfaces/i-tournament';
import TournamentsCreateFromCopyForm from '../create-form';

interface ITournamentTableProps {
  periodType?: TOURNAMENT_PERIOD[];
  filters?: ITournamentFilters;
}

const TournamentTable: React.FC<ITournamentTableProps> = (props) => {
  const { periodType = [TOURNAMENT_PERIOD.EVENT, TOURNAMENT_PERIOD.REGULAR], filters = {} } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [copiedTournament, setCopiedTournament] = useState<Tournament | null>(null);
  const [action, setAction] = useState<'update' | 'copy' | undefined>();
  const { tournaments, meta, loading, loadTournaments, deleteTournament } = useTournaments({
    meta: {
      limit: 100,
      filters: {
        ...filters,
        periodType,
      },
    },
  });
  const { t } = useTranslation();

  const hasPagination = meta.total > meta.limit;

  const modalTitle =
    (copiedTournament && action === 'update' && `${t('labels.update')} ${copiedTournament.name}`) ||
    (action === 'copy' && t('controls.copy'));

  const user = useAppSelector((state) => state.user.user);

  const onReload = async () => {
    await loadTournaments({ reload: true });
  };

  const onCopy = async (record: Tournament) => {
    setAction('copy');
    setCopiedTournament(record);
    setModalOpened(true);
  };

  const onEdit = async (record: Tournament) => {
    setAction('update');
    setCopiedTournament(record);
    setModalOpened(true);
  };

  const onDelete = async (record: Tournament) => {
    await deleteTournament(record.groupId);
    await onReload();
  };

  const onClose = () => {
    setAction(undefined);
    setCopiedTournament(null);
    setModalOpened(false);
  };

  const onSave = async () => {
    setCopiedTournament(null);
    setModalOpened(false);
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Tournament> | SorterResult<Tournament>[],
  ) => {
    await loadTournaments({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<Tournament>(sorter as IModifiedSorterResult<Tournament>, 'start_date'),
      reload: true,
    });
  };

  useEffect(() => {
    loadTournaments();
  }, [filters]);

  return (
    <>
      <div>
        <S.StyledBaseTable
          rowKey="key"
          size="small"
          rowClassName={(record: Tournament) => record.color.replace('#', 'color-')}
          columns={tournamentTableColumns({
            canEdit: user?.canUpdate('accessPermission'),
            canDelete: user?.fullAccess('accessPermission'),
            onDelete,
            onCopy,
            onEdit,
            isPlayer: user?.isPlayer() ?? false,
          })}
          dataSource={tournaments}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: false,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 700, y: 800 }}
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} footer={null} destroyOnClose>
        {copiedTournament && (
          <TournamentsCreateFromCopyForm
            id={copiedTournament.id}
            data={copiedTournament.meta}
            bill={copiedTournament.bill}
            groupId={copiedTournament.groupId}
            action={action}
            onSave={onSave}
          />
        )}
      </BaseModal>
    </>
  );
};

export default TournamentTable;
