/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import DebounceSelect from '../../common/debounce-select';
import { useTranslation } from 'react-i18next';
import { IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { AutoCompleteProps } from 'antd/es/auto-complete';
import { Rank } from '@app/infrastructure/classes/rank';
import useRanks from '@app/infrastructure/hooks/use-ranks';

export interface IRankSelectBoxItemProps {
  key: any;
  label: React.ReactNode;
  value: any;
  record: Rank;
}

interface IRankSelectBoxProps extends AutoCompleteProps {
  width?: string;
  saveState?: boolean;
  placeholder?: string;
  onSelect?: (items: Rank[]) => void;
}

const RankSelectBox: React.FC<IRankSelectBoxProps> = (props) => {
  const { t } = useTranslation();

  const { onSelect, width = '100%', saveState = true, placeholder = t('labels.selectRank') } = props;

  const { loadRanks, meta } = useRanks({
    meta: {
      limit: 50,
    },
  });

  const [value, setValue] = useState<IRankSelectBoxItemProps | IRankSelectBoxItemProps[]>([]);

  const searchRanks = async (searchValue: string, meta: IPaginationMeta) => {
    const ranks = await loadRanks({
      ...meta,
      search: ['name', searchValue],
      reload: true,
    });
    if (ranks)
      return ranks.map((el) => {
        return {
          key: el.id,
          label: el.name,
          value: `${el.name}`,
          record: el,
        };
      });

    return [];
  };

  return (
    <DebounceSelect
      showSearch
      allowClear
      value={value}
      placeholder={placeholder}
      fetchOptions={searchRanks}
      onChange={(newValue) => {
        if (onSelect) {
          if (newValue) {
            const data = Array.isArray(newValue) ? newValue.map((el) => el.record) : [newValue.record];
            onSelect(data);
          } else {
            onSelect([]);
          }
          if (saveState) {
            setValue(newValue);
          }
        }
      }}
      meta={meta}
      style={{ width }}
      {...props}
    />
  );
};

export default RankSelectBox;
