import { useTranslation } from 'react-i18next';
import { Player } from '@app/infrastructure/classes/player';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { IUpdatePlayer } from '@app/infrastructure/interfaces/i-player';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useState } from 'react';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import AppBaseCard from '@app/components/common/base-card';

interface AnalyticsDescriptionProps {
  player: Player;
  canEdit?: boolean;
}

const { TextArea } = BaseInput;

const AnalyticsDescription: React.FC<AnalyticsDescriptionProps> = (props) => {
  const { player, canEdit = false } = props;

  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { updatePlayer, updatePlayerInfoById, loading } = usePlayers();

  const { analyticsDescription } = player;

  const onFinish = async (values: IUpdatePlayer) => {
    await updatePlayer(
      player.id,
      {
        analytics_description: values.analytics_description,
      },
      player,
    );
    await updatePlayerInfoById(player.id, player);
    onCancel();
  };

  const onCancel = () => {
    setIsEdit(false);
  };

  return (
    <AppBaseCard
      title={t('labels.comment')}
      loading={loading}
      size="small"
      canEdit={canEdit}
      onEdit={() => setIsEdit(true)}
    >
      <BaseButtonsForm
        style={{ width: '100%' }}
        isFieldsChanged={isEdit}
        onFinish={onFinish}
        onClose={onCancel}
        autoComplete="off"
        size="small"
      >
        <BaseButtonsForm.Item name="analytics_description" initialValue={analyticsDescription}>
          {isEdit ? <TextArea rows={3} maxLength={1000} /> : <BaseSpace>{player.analyticsDescription}</BaseSpace>}
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </AppBaseCard>
  );
};

export default AnalyticsDescription;
