import i18next from 'i18next';
import { ANALYTICS_STAGES } from '../enums/analytics-stages.enum';

export const analyticsStagesDataSource = (): {
  id: ANALYTICS_STAGES;
  label: string;
}[] => [
  {
    id: ANALYTICS_STAGES.early,
    label: i18next.t(`analyticsStages.${ANALYTICS_STAGES.early}`),
  },
  {
    id: ANALYTICS_STAGES.middle,
    label: i18next.t(`analyticsStages.${ANALYTICS_STAGES.middle}`),
  },
  {
    id: ANALYTICS_STAGES.late,
    label: i18next.t(`analyticsStages.${ANALYTICS_STAGES.late}`),
  },
  {
    id: ANALYTICS_STAGES.all,
    label: i18next.t(`analyticsStages.${ANALYTICS_STAGES.all}`),
  },
];
