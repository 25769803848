import styled from 'styled-components';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

export const BaseFormItem = styled(BaseForm.Item)`
  margin: 0;

  .ant-col.ant-form-item-label {
    text-align: left;
    .ant-form-item-required::before {
      display: none;
    }
  }
  .ant-form-item-explain {
    display: none;
  }
  .ant-table-cell {
    white-space: pre-wrap;
  }
`;

export const StyledTable = styled(BaseTable)`
  .ant-table-cell {
    white-space: pre-wrap;
  }
`;
