import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import styled from 'styled-components';

export const FilterRow = styled(BaseRow)`
  margin-top: 5px;

  .ant-select-selector {
    border-radius: 7px !important;
  }

  .ant-input-number-affix-wrapper {
    width: 120px;
  }

  .ant-picker {
    width: 330px;
  }
`;
