/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import DebounceSelect from '../../common/debounce-select';
import useBills from '../../../infrastructure/hooks/use-bills';
import { Bill } from '../../../infrastructure/classes/bill';
import { useTranslation } from 'react-i18next';
import { IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { BILL_TYPES } from '@app/infrastructure/enums/bill-types.enum';
import { SelectProps } from 'antd';

export interface IBillSelectBoxItemProps {
  key: any;
  label: React.ReactNode;
  value: any;
  record: Bill;
}

interface IBillSelectBoxProps extends SelectProps {
  type?: BILL_TYPES[];
  width?: string;
  placeholder?: string;
}

const BillSelectBox: React.FC<IBillSelectBoxProps> = (props) => {
  const { t } = useTranslation();

  const {
    width = '100%',
    placeholder = t('labels.selectBill'),
    type = [BILL_TYPES.BILL, BILL_TYPES.ROOM],
    ...args
  } = props;

  const { loadBills, meta } = useBills({
    meta: {
      limit: 50,
      filters: {
        type,
      },
    },
  });

  const searchBills = async (searchValue: string, meta: IPaginationMeta) => {
    const bills = await loadBills({
      ...meta,
      search: ['name', searchValue],
      reload: true,
      filters: {
        type,
      },
    });
    if (bills)
      return bills.map((el) => {
        return {
          key: el.id,
          label: `${el.name} ${el.currency.name}`,
          value: `${el.name} ${el.currency.name}`,
          record: el,
        };
      });

    return [];
  };

  return (
    <DebounceSelect
      showSearch
      allowClear
      placeholder={placeholder}
      fetchOptions={searchBills}
      meta={meta}
      style={{ width }}
      {...args}
    />
  );
};

export default BillSelectBox;
