import moment from 'moment-timezone';
import { STATUS_GAME_REQUEST } from '../enums/status-game-request.enum';
import { TYPE_OF_ENTRY } from '../enums/type-of-entry.enum';
import { IPlayerGame } from '../interfaces/i-player-game';
import { Bill } from './bill';
import { Image } from './image';
import { Player } from './player';

export class PlayerGame {
  id: number;
  bbQuantity: number;
  description: string;
  status: STATUS_GAME_REQUEST;
  typeOfEntry: TYPE_OF_ENTRY;
  tournamentName: string;
  startDate: moment.Moment;
  buyIn: number;
  bill: Bill;
  image: Image;
  player: Player;

  constructor(data: IPlayerGame) {
    this.id = data.id;
    this.bbQuantity = data.bb_quantity;
    this.description = data.description;
    this.status = data.status;
    this.typeOfEntry = data.type_of_entry;
    this.tournamentName = data.tournament_name;
    this.startDate = moment(data.start_date);
    this.buyIn = data.buy_in;
    this.image = new Image(data.image);
    this.bill = new Bill(data.bill);
    this.player = new Player(data.player);
  }
}
