import React from 'react';
import * as S from './styles';
import { EditOutlined } from '@ant-design/icons';
import { BaseRow } from '../BaseRow/BaseRow';
import { BaseCol } from '../BaseCol/BaseCol';
import { BaseTypography } from '../BaseTypography/BaseTypography';
import { CardSize } from 'antd/lib/card/Card';

interface IAppBaseCardProps {
  children?: React.ReactNode;
  title?: string;
  extra?: React.ReactNode;
  canEdit?: boolean;
  loading?: boolean;
  size?: CardSize;
  onEdit?: () => void;
}

const AppBaseCardTitle: React.FC<IAppBaseCardProps> = (props) => {
  const { title, extra, canEdit = false, onEdit } = props;
  return (
    <BaseRow align="middle" justify="space-between" gutter={10}>
      <BaseCol>
        <BaseTypography>{title}</BaseTypography>
      </BaseCol>
      <BaseCol>{canEdit && <S.IconBtn size="small" type="text" icon={<EditOutlined />} onClick={onEdit} />}</BaseCol>
      {extra && (
        <BaseCol>
          <BaseTypography>{extra}</BaseTypography>
        </BaseCol>
      )}
    </BaseRow>
  );
};

const AppBaseCard: React.FC<IAppBaseCardProps> = (props) => {
  const { children, loading = false, size = 'default' } = props;
  return (
    <S.Card loading={loading} size={size} title={<AppBaseCardTitle {...props} />}>
      {children}
    </S.Card>
  );
};

export default AppBaseCard;
