export enum MomentFormats {
  'DD.MM.YYYY' = 'DD.MM.YYYY',
  'dd DD.MM.YYYY' = 'dd DD.MM.YYYY',
  'MM.DD.YYYY' = 'MM.DD.YYYY',
  'MM.DD.YYYY HH:mm' = 'MM.DD.YYYY HH:mm',
  'DD.MM.YYYY HH:mm' = 'DD.MM.YYYY HH:mm',
  'DD.MM.YYYY hh:mm' = 'DD.MM.YYYY hh:mm',
  'hh:mm' = 'hh:mm',
  'HH:mm' = 'HH:mm',
}

export enum MomentTimezones {
  'UTC' = 'UTC',
}
