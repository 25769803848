import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FONT_FAMILY } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const TitleBalanceText = styled(BaseTypography.Title)`
  font-size: 30px;
  font-family: ${FONT_FAMILY.secondary};
`;

export const CardSpace = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const OnTheWayRow = styled(BaseRow)`
  width: 100%;
  margin-bottom: 10px;

  .ant-input-number-affix-wrapper {
    width: 150px;
    margin-left: 20px;
  }
`;
