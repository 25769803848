import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Player } from '@app/infrastructure/classes/player';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';

interface IProfitPackageCellProps {
  data: Player;
}

const ProfitPackageCell: React.FC<IProfitPackageCellProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const profitPackage = data.profitPackages?.find((profitPackage) => profitPackage.canceledDate === null);
  return (
    <div>
      <BaseTypography>
        {profitPackage ? `${CURRENCIES.DOLLAR} ${profitPackage.profitPackage}` : t('labels.noProfitPackage')}
      </BaseTypography>
    </div>
  );
};

export default ProfitPackageCell;
