import React from 'react';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { BaseRow } from '../../common/BaseRow/BaseRow';
import { BaseCol } from '../../common/BaseCol/BaseCol';
import { InputNumber } from '../../common/inputs/InputNumber/InputNumber';
import FrequencySelect, { FrequencyType } from './frequency-select';
import WeekDaysSelector from './week-days-selector';
import { TOURNAMENT_PERIOD } from '@app/infrastructure/enums/tournament-period';
import MonthDaysSelector from './month-days-selector';
import { FormInstance } from 'antd/es/form/Form';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';

interface IAppRecurrenceCalendarProps {
  form: FormInstance;
}

const TournamentRecurrenceCalendar: React.FC<IAppRecurrenceCalendarProps> = (props) => {
  const { form } = props;
  const { t } = useTranslation();

  useRerender();
  const periodType = form.getFieldValue('periodType');
  const frequencyType = form.getFieldValue('frequencyType');
  const bill = form.getFieldValue('bill');

  const startEndDate: [moment.Moment, moment.Moment] = form.getFieldValue('startEndDate') ?? [];

  return (
    <>
      {periodType === TOURNAMENT_PERIOD.REGULAR && (
        <S.BaseFormItem name="startEndDate" label={t('labels.startEndDate')} rules={[{ required: true }]}>
          <DatePicker.RangePicker size="small" />
        </S.BaseFormItem>
      )}
      {periodType === TOURNAMENT_PERIOD.EVENT && (
        <S.BaseFormItem name="startDate" label={t('labels.startDate')} rules={[{ required: true }]}>
          <DatePicker size="small" />
        </S.BaseFormItem>
      )}
      <BaseRow gutter={20}>
        <BaseCol>
          <S.BaseFormItem name="startTime" label={t('labels.startTime')} rules={[{ required: true }]}>
            <DatePicker.TimePicker
              format={MomentFormats['HH:mm']}
              showSecond={false}
              placeholder={t('labels.startTime')}
              size="small"
            />
          </S.BaseFormItem>
        </BaseCol>
        <BaseCol>
          <S.BaseFormItem name="endTime" label={t('labels.endTime')} rules={[{ required: true }]}>
            <DatePicker.TimePicker
              format={MomentFormats['HH:mm']}
              showSecond={false}
              placeholder={t('labels.endTime')}
              size="small"
            />
          </S.BaseFormItem>
        </BaseCol>
        {bill && bill.record && (
          <BaseCol>
            <S.BaseFormItem label={t('labels.timeShift')}>
              <BaseInput style={{ width: '100px' }} value={bill.record.timeShift} size="small" disabled readOnly />
            </S.BaseFormItem>
          </BaseCol>
        )}
      </BaseRow>
      {periodType === TOURNAMENT_PERIOD.REGULAR && !!startEndDate.length && (
        <>
          <BaseRow align="bottom">
            <BaseCol span={6}>
              <S.BaseFormItem
                name="repeatEvery"
                label={t('labels.repeatEvery')}
                rules={[
                  { required: true, message: t('messages.requiredField') },
                  { type: 'number', min: 1 },
                ]}
              >
                <InputNumber min={1} size="small" />
              </S.BaseFormItem>
            </BaseCol>
            <BaseCol span={18}>
              <S.BaseFormItem name="frequencyType" rules={[{ required: true, message: t('messages.requiredField') }]}>
                <FrequencySelect size="small" />
              </S.BaseFormItem>
            </BaseCol>
          </BaseRow>
          {frequencyType === FrequencyType.Weekly ? (
            <S.BaseFormItem name="weekDays" rules={[{ required: true, message: t('messages.requiredField') }]}>
              <WeekDaysSelector />
            </S.BaseFormItem>
          ) : (
            <S.BaseFormItem name="monthDays" rules={[{ required: true, message: t('messages.requiredField') }]}>
              <MonthDaysSelector date={startEndDate[0]} />
            </S.BaseFormItem>
          )}
        </>
      )}
    </>
  );
};

export default TournamentRecurrenceCalendar;
