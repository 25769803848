/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ApiError } from '@app/api/api-error';
import {
  readRefreshToken,
  readToken,
  persistToken,
  persistRefreshToken,
} from '@app/infrastructure/services/local-storage.service';
import { refreshAccessToken } from './auth.api';
import { ROUTES } from '@app/infrastructure/consts/routes';
import { HttpStatus } from '@app/infrastructure/enums/http-status';

export const createAbortController = () => {
  const controller = new AbortController();

  return { controller };
};

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

const redirectTo = (path: string) => {
  window.location.replace(path);
};

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi.interceptors.response.use(undefined, async (error: AxiosError) => {
  const originalRequest: AxiosRequestConfig = error.config;
  if (error.response && error.response.status === HttpStatus.FORBIDDEN) {
    redirectTo(ROUTES.ERRORS.FORBIDDEN);
  }
  if (error.response && error.response.status === HttpStatus.NOT_FOUND) {
    redirectTo(ROUTES.ERRORS.NOT_FOUND);
  }
  if (error.response && error.response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
    redirectTo(ROUTES.ERRORS.SERVER_ERROR);
  }
  if (error.response && error.response.status === HttpStatus.UNAUTHORIZED && error.config.url === '/auth/refresh') {
    redirectTo(ROUTES.LOGIN);
  }
  if (error.response && error.response.status === HttpStatus.UNAUTHORIZED && error.config.headers) {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const refreshToken = readRefreshToken();
        if (refreshToken) {
          const res = await refreshAccessToken(refreshToken)
            .then((data) => data)
            .catch(() => {
              redirectTo(ROUTES.LOGIN);
            });

          if (res) {
            persistToken(res.user.access_token);
            persistRefreshToken(res.user.refresh_token);
            error.config.headers['Authorization'] = `Bearer ${res.user.access_token}`;

            refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
              httpApi
                .request(config)
                .then((response) => resolve(response))
                .catch((err) => {
                  reject(err);
                });
            });

            refreshAndRetryQueue.length = 0;
            return httpApi(originalRequest);
          }
        } else {
          redirectTo(ROUTES.LOGIN);
        }
      } catch (refreshError: any) {
        throw refreshError;
      } finally {
        isRefreshing = false;
      }
    }

    return new Promise<void>((resolve, reject) => {
      refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
    });
  }
  throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
});

export interface ApiErrorData {
  message: string;
}
