import { STATUS_GAME_REQUEST } from '../enums/status-game-request.enum';

export const fontStatusColor = {
  [STATUS_GAME_REQUEST.IN_REVIEW]: '#082799',
  [STATUS_GAME_REQUEST.IN_PACKAGE]: '#06802b',
  [STATUS_GAME_REQUEST.PLAYER_GAME]: '#ed4049',
};

export const backgroundStatusColor = {
  [STATUS_GAME_REQUEST.IN_REVIEW]: '#FAFAFA',
  [STATUS_GAME_REQUEST.IN_PACKAGE]: '#E6F7FF',
  [STATUS_GAME_REQUEST.PLAYER_GAME]: '#FFF1F0',
};

export const borderStatusColor = {
  [STATUS_GAME_REQUEST.IN_REVIEW]: '#082799',
  [STATUS_GAME_REQUEST.IN_PACKAGE]: '#06802b',
  [STATUS_GAME_REQUEST.PLAYER_GAME]: '#ed4049',
};
