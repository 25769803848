/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import useRanks from '@app/infrastructure/hooks/use-ranks';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const RankCheckBox: React.FC<CheckboxGroupProps> = (props) => {
  const { onChange, value, ...arg } = props;

  const [ranksList, setRanksList] = useState<number[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const { ranks, loadRanks } = useRanks({
    meta: {
      limit: 0,
      page: 0,
    },
  });

  const { t } = useTranslation();

  useEffect(() => {
    loadRanks();
  }, []);

  useEffect(() => {
    if (ranks) {
      const list = ranks.map((rank) => rank.name);
      setRanksList(list);
      setIndeterminate(!!value?.length && value?.length < ranks.length);
      setCheckAll(value?.length === ranks.length);
    }
  }, [ranks]);

  const onListChange = (list: CheckboxValueType[]) => {
    if (ranksList) {
      setIndeterminate(!!list.length && list.length < ranksList.length);
      setCheckAll(list.length === ranksList.length);
    }
    if (onChange) {
      onChange(list);
    }
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (onChange && ranksList) {
      onChange(e.target.checked ? ranksList : []);
    }
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <BaseRow>
      <BaseCheckbox.Group value={value} onChange={onListChange} {...arg}>
        <BaseRow>
          {ranks.map((rank) => (
            <BaseCol key={rank.id}>
              <BaseCheckbox value={rank.id}>{rank.name}</BaseCheckbox>
            </BaseCol>
          ))}
        </BaseRow>
      </BaseCheckbox.Group>
      <BaseCol span={24}>
        <BaseCheckbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          {t('controls.chooseAll')}
        </BaseCheckbox>
      </BaseCol>
    </BaseRow>
  );
};

export default RankCheckBox;
