import i18next from 'i18next';
import { STATUS_INTERNAL_TRANSFER } from '../enums/status-internal-transfer.enum';

export const statusInternalTransferDataSource = (): {
  id: STATUS_INTERNAL_TRANSFER;
  label: string;
}[] => [
  {
    id: STATUS_INTERNAL_TRANSFER.IN_REVIEW,
    label: i18next.t('labels.new'),
  },
  {
    id: STATUS_INTERNAL_TRANSFER.APPROVED,
    label: i18next.t('labels.approved'),
  },
  {
    id: STATUS_INTERNAL_TRANSFER.REJECTED,
    label: i18next.t('labels.rejected'),
  },
  {
    id: STATUS_INTERNAL_TRANSFER.CLOSED,
    label: i18next.t('labels.closed'),
  },
];
