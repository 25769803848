import { useState } from 'react';
import { FlcraftPlayerStatistic } from '../classes/flcraft';
import { getFlcraftStatistics, getFlcraftsAverageStatistic } from '@app/api/flcraft';
import { ILoadProps } from './interfaces/i-load-props';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { IFlcraftStatistic } from '../interfaces/i-flcraft';

type UseFlcraftsStatistic = {
  flcraftStatistics: FlcraftPlayerStatistic[];
  meta: IPaginationMeta;
  loadFlcraftStatistics: (props?: ILoadProps) => Promise<FlcraftPlayerStatistic[] | undefined>;
  loadAverageFlcraftStatistics: (props?: ILoadProps) => Promise<IFlcraftStatistic>;
  loading: boolean;
};

interface IUseFlcraftsStatisticProps {
  meta?: IPartialPaginationMeta;
}

const useFlcraftsStatistic = (props?: IUseFlcraftsStatisticProps): UseFlcraftsStatistic => {
  const [loading, setLoading] = useState<boolean>(false);
  const [flcraftStatistics, setFlcraftStatistics] = useState<FlcraftPlayerStatistic[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 5,
    sort: ['player.id', 'ASC'],
    total: 0,
    totalPages: 0,
    ids: [],
    ...props?.meta,
  });

  const loadFlcraftStatistics = async (props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { results, meta: metaRes } = await getFlcraftStatistics({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new FlcraftPlayerStatistic(el));
      if (props?.reload) setFlcraftStatistics(data);
      else setFlcraftStatistics([...flcraftStatistics, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getFlcraftStatistics({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new FlcraftPlayerStatistic(el));
        if (props?.reload) setFlcraftStatistics(data);
        else setFlcraftStatistics([...flcraftStatistics, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const loadAverageFlcraftStatistics = async (props?: ILoadProps) => {
    setLoading(true);
    const data = await getFlcraftsAverageStatistic({ ...meta, ...props });
    setLoading(false);
    return data;
  };

  return {
    flcraftStatistics,
    meta,
    loading,
    loadFlcraftStatistics,
    loadAverageFlcraftStatistics,
  };
};

export default useFlcraftsStatistic;
