import React from 'react';
import { Permissions } from '@app/infrastructure/enums/permissions';
import { useTranslation } from 'react-i18next';

interface IAccessCollProps {
  data?: Permissions;
}

const PermissionCell: React.FC<IAccessCollProps> = ({ data }) => {
  const { t } = useTranslation();

  return <div>{data ? t(`permissions.${data}`) : t(`permissions.noPermissions`)}</div>;
};

export default PermissionCell;
