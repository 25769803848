/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { flcraftTableColumns } from './cols';
import { Flcraft } from '@app/infrastructure/classes/flcraft';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import useFlcrafts from '@app/infrastructure/hooks/use-flcraft';
import FlcraftsTableHeader from './header';
import { useTableColumnSearch } from '@app/infrastructure/antd/hooks/use-table-column-search';
import { IFlcraftFilters } from '@app/infrastructure/interfaces/i-flcraft';
import useFlcraftsStatistic from '@app/infrastructure/hooks/use-flcraft-statistic';
import * as S from './styles';
import { getFlcraftFilters } from '@app/infrastructure/services/filters-storage.service';

interface IFlcraftTableProps {
  ids: number[];
  showStatistic?: boolean;
  canUpload?: boolean;
  onFilterChange?: (filters: IFlcraftFilters) => void;
}

const FlcraftTable: React.FC<IFlcraftTableProps> = (props) => {
  const { ids, onFilterChange: onFilterChangeProps, canUpload = true, showStatistic = true } = props;

  const { flcrafts, meta, loading, loadFlcrafts } = useFlcrafts();
  const { flcraftStatistics, loadFlcraftStatistics, loading: loadingStatistic } = useFlcraftsStatistic();
  const [filters, setFilters] = useState<IFlcraftFilters>(getFlcraftFilters());

  const hasPagination = meta.total > meta.limit;

  const { columnSearchProps } = useTableColumnSearch();

  const onReload = async () => {
    await loadFlcrafts({ filters, ids, reload: true });
    if (showStatistic) await getStatistic();
  };

  const getStatistic = async () => {
    await loadFlcraftStatistics({ ids });
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<Flcraft> | SorterResult<Flcraft>[],
  ) => {
    let search: [string, string] | undefined = undefined;
    if (tableFilters['tournamentName']) {
      search = ['tournament_name', `${tableFilters['tournamentName'][0]}`];
    }

    await loadFlcrafts({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<Flcraft>(sorter as IModifiedSorterResult<Flcraft>),
      reload: true,
      search,
      ids,
      filters,
    });
  };

  const handleFilterChange = (filters: IFlcraftFilters) => {
    setFilters(filters);
    if (onFilterChangeProps) onFilterChangeProps(filters);
  };

  useEffect(() => {
    onReload();
  }, [ids, filters]);

  return (
    <>
      <S.StyledBaseTable
        size="small"
        rowKey="id"
        title={() => {
          return (
            <FlcraftsTableHeader
              filters={filters}
              canUpload={canUpload}
              loading={loadingStatistic}
              onUploaded={onReload}
              statistic={showStatistic ? flcraftStatistics[0] : undefined}
              onFilterChange={handleFilterChange}
            />
          );
        }}
        columns={flcraftTableColumns({
          columnSearchProps,
        })}
        dataSource={flcrafts}
        pagination={
          !loading &&
          hasPagination && {
            current: meta.page,
            pageSize: meta.limit,
            total: meta.total,
            showSizeChanger: true,
          }
        }
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 1000 }}
        bordered
      />
    </>
  );
};

export default FlcraftTable;
