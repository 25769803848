import React from 'react';
import { FlcraftPlayerStatistic } from '@app/infrastructure/classes/flcraft';
import { Link } from 'react-router-dom';
import { ROUTES } from '@app/infrastructure/consts/routes';

interface IPlayerLoginCellProps {
  data?: FlcraftPlayerStatistic;
}

const PlayerLoginCell: React.FC<IPlayerLoginCellProps> = ({ data }) => {
  return <>{data?.player && <Link to={`${ROUTES.FLCRAFT}/${data?.player.id}`}>{data?.player.user?.login}</Link>}</>;
};

export default PlayerLoginCell;
