import i18next from 'i18next';
import { TOURNAMENT_PERIOD } from '../enums/tournament-period';

export const tournamentPeriodsDataSource = (): {
  id: TOURNAMENT_PERIOD;
  label: string;
}[] => [
  {
    id: TOURNAMENT_PERIOD.EVENT,
    label: i18next.t(`tournamentPeriods.${TOURNAMENT_PERIOD.EVENT}`),
  },
  {
    id: TOURNAMENT_PERIOD.REGULAR,
    label: i18next.t(`tournamentPeriods.${TOURNAMENT_PERIOD.REGULAR}`),
  },
];
