/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import LimitsTableHeader from './header';
import useLimits from '@app/infrastructure/hooks/use-limits';
import { Limit } from '@app/infrastructure/classes/limit';
import { EditableCell } from './cols/editable-cell';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Rank } from '@app/infrastructure/classes/rank';
import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import BillCell from './cols/name-cell';
import { useTranslation } from 'react-i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import TitleCell from './cols/title-cell';
import ValueCell from './cols/value-cell';

interface ILimitsTableProps {
  rank: Rank;
}

const LimitsTable: React.FC<ILimitsTableProps> = ({ rank }) => {
  const user = useAppSelector((state) => state.user.user);

  const [editingId, setEditingId] = useState<number>(0);
  const [cols, setCols] = useState<IModifiedTableColumn<Limit>[]>();

  const {
    limits,
    dynamicLimits,
    loadLimitsByRank,
    loadDynamicLimitsForTable,
    deleteLimit,
    updateLimit,
    updateDynamicLimit,
    loading,
  } = useLimits();
  const [form] = BaseForm.useForm();

  const { t } = useTranslation();

  const isEditing = (data: Limit) => data.id === editingId;

  const canEdit = user?.canUpdate('limitPermission');
  const canDelete = user?.fullAccess('limitPermission');

  const columns: IModifiedTableColumn<Limit>[] = [
    {
      title: t('labels.room'),
      dataIndex: 'name',
      editable: false,
      width: '120px',
      render: (_, record) => <BillCell data={record} />,
    },
  ];

  const reload = async () => {
    await loadLimitsByRank(rank.id, { reload: true });
    await loadDynamicLimitsForTable();
    reloadTable();
  };

  const onDelete = async (record: Limit) => {
    await deleteLimit(record.id);
    reload();
  };

  const onClose = () => {
    setEditingId(0);
  };

  const onEdit = (record: Limit) => {
    if (!isEditing(record) && dynamicLimits) {
      const fieldsValue: { [key: string]: string } = {};

      Object.keys(dynamicLimits.col_names).map((name) => (fieldsValue[name] = record.limits[name]));
      form.setFieldsValue(fieldsValue);
      setEditingId(record.id);
    }
  };

  const onSaved = () => {
    reload();
  };

  const onSave = () => {
    form.submit();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (values: any) => {
    const limit = limits.find((el) => el.id === editingId);
    if (limit && dynamicLimits) {
      const limits: { [key: string]: string } = {};
      Object.keys(dynamicLimits.col_names).map((colName) => {
        limits[colName] = values[colName];
      });
      await updateLimit(editingId, {
        limits,
      });
      reload();
      setEditingId(0);
    }
  };

  const onColDelete = async (col: string) => {
    if (dynamicLimits) {
      const colsNamesFiltered = Object.keys(dynamicLimits.col_names).filter((data) => data !== col);
      const newColNames: { [key: string]: string } = {};
      colsNamesFiltered.map((name) => (newColNames[name] = name));
      await updateDynamicLimit({ col_names: newColNames });
      reload();
    }
  };

  const reloadTable = () => {
    if (dynamicLimits) {
      const colNames = Object.keys(dynamicLimits.col_names).sort();
      colNames.map((col) =>
        columns.push({
          title: (
            <TitleCell
              value={col}
              onDelete={() => {
                onColDelete(col);
              }}
              canDelete={canDelete}
            />
          ),
          dataIndex: col,
          editable: true,
          render: (_, record) => <ValueCell value={record.limits[col]} />,
        }),
      );

      if (canEdit || canDelete) {
        columns.push({
          title: t('labels.actions'),
          dataIndex: 'actions',
          sorter: false,
          width: '100px',
          align: 'center',
          render: (_, record) => (
            <BaseActionsCell
              isEditing={isEditing(record)}
              canEdit={canEdit}
              size="small"
              canDelete={canDelete}
              onDelete={() => {
                onDelete(record);
              }}
              onEdit={() => {
                onEdit(record);
              }}
              onSave={() => {
                onSave();
              }}
              onClose={() => {
                onClose();
              }}
            />
          ),
        });
      }
      setCols(columns);
    }
  };

  useEffect(() => {
    loadLimitsByRank(rank.id);
    loadDynamicLimitsForTable();
  }, []);

  useEffect(() => {
    if (dynamicLimits) {
      reloadTable();
    }
  }, [dynamicLimits, editingId]);

  const mergedColumns = cols
    ? cols.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (data: Limit) => ({
            record: data,
            dataIndex: col.dataIndex,
            title: data.limits[col.dataIndex as string],
            editing: isEditing(data),
          }),
        };
      })
    : columns;

  return (
    <BaseForm form={form} component={false} onFinish={onFinish} autoComplete="off">
      <BaseTable
        rowKey="id"
        size="small"
        title={() =>
          user?.canCreate('limitPermission') && dynamicLimits ? (
            <LimitsTableHeader colNames={dynamicLimits.col_names} rank={rank} onSave={onSaved} />
          ) : undefined
        }
        dataSource={limits}
        loading={loading}
        columns={mergedColumns}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        pagination={false}
      />
    </BaseForm>
  );
};

export default LimitsTable;
