import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import { AccessRight } from '@app/infrastructure/classes/access-right';
import i18next from 'i18next';
import PermissionCell from './permission';
import BaseActionsCell from '@app/components/common/base-actions-cell';

interface IAccessRightsTableColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: AccessRight) => void;
  onDelete: (data: AccessRight) => void;
}

export const accessRightsTableColumns = ({
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}: IAccessRightsTableColumnsProps): IModifiedTableColumn<AccessRight>[] => {
  const columns: IModifiedTableColumn<AccessRight>[] = [
    {
      title: `${i18next.t('labels.name')}`,
      dataIndex: 'name',
      sortField: 'name',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.accessRights')}`,
      dataIndex: 'accessPermission',
      sortField: 'access_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.accessPermission} />,
    },
    {
      title: `${i18next.t('labels.users')}`,
      dataIndex: 'userPermission',
      sortField: 'user_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.userPermission} />,
    },
    {
      title: `${i18next.t('labels.players')}`,
      dataIndex: 'playerPermission',
      sortField: 'player_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.playerPermission} />,
    },
    {
      title: `${i18next.t('labels.bills')}`,
      dataIndex: 'billPermission',
      sortField: 'bill_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.billPermission} />,
    },
    {
      title: `${i18next.t('labels.ranks')}`,
      dataIndex: 'rankPermission',
      sortField: 'rank_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.rankPermission} />,
    },
    {
      title: `${i18next.t('labels.flcraft')}`,
      dataIndex: 'flcraftPermission',
      sortField: 'flcraft_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.flcraftPermission} />,
    },
    {
      title: `${i18next.t('labels.personalTransfers')}`,
      dataIndex: 'personalTransferPermission',
      sortField: 'personal_transfer_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.personalTransferPermission} />,
    },
    {
      title: `${i18next.t('labels.internalTransfers')}`,
      dataIndex: 'internalTransferPermission',
      sortField: 'internal_transfer_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.internalTransferPermission} />,
    },
    {
      title: `${i18next.t('labels.analytics')}`,
      dataIndex: 'analyticsPermission',
      sortField: 'analytics_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.analyticsPermission} />,
    },
    {
      title: `${i18next.t('labels.playerGame')}`,
      dataIndex: 'playerGamePermission',
      sortField: 'player_game_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.playerGamePermission} />,
    },
    {
      title: `${i18next.t('labels.schedule')}`,
      dataIndex: 'tournamentPermission',
      sortField: 'tournament_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.tournamentPermission} />,
    },
    {
      title: `${i18next.t('labels.limits')}`,
      dataIndex: 'limitPermission',
      sortField: 'limit_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.limitPermission} />,
    },
    {
      title: `${i18next.t('labels.profitPackage')}`,
      dataIndex: 'profitPackagePermission',
      sortField: 'profit_package_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.profitPackagePermission} />,
    },
    {
      title: `${i18next.t('labels.overMoney')}`,
      dataIndex: 'overMoneyPermission',
      sortField: 'over_money_permission',
      sorter: true,
      render: (_, record) => <PermissionCell data={record.overMoneyPermission} />,
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '120px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          canEdit={canEdit}
          canDelete={canDelete}
          onDelete={() => {
            onDelete(record);
          }}
          onEdit={() => {
            onEdit(record);
          }}
          size="small"
        />
      ),
    });
  }

  return columns;
};
