/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { RangePickerProps } from 'antd/lib/date-picker';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import * as S from './styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { IInternalTransferFilters } from '@app/infrastructure/interfaces/i-internal-transfer';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import {
  getInternalTransferPlayerFilters,
  setInternalTransferPlayerFilters,
} from '@app/infrastructure/services/filters-storage.service';
import moment from 'moment-timezone';
import PlayersBillSelectBox, { IPlayersBillSelectBoxItemProps } from '../bills/select-box';
import { Player } from '@app/infrastructure/classes/player';
import BaseRangeNumberInput, { rangeValue } from '@app/components/common/base-range-number-input';

interface IInternalTransferTableFiltersProps {
  player: Player;
  onFilterChange: (filters: IInternalTransferFilters) => void;
}

const InternalTransferTablePlayerFilters: React.FC<IInternalTransferTableFiltersProps> = (props) => {
  const { player, onFilterChange } = props;
  const { t } = useTranslation();

  const [name, setName] = useState<string | undefined>();
  const [dateRange, setDateRange] = useState<RangePickerProps['value']>(undefined);
  const [bill, setBill] = useState<IPlayersBillSelectBoxItemProps | undefined>(undefined);
  const [amountRange, setAmountRange] = useState<rangeValue>([undefined, undefined]);
  const [filters, setFilters] = useState<IInternalTransferFilters>({});

  const changeFilters = useCallback(
    (filters: IInternalTransferFilters) => {
      setInternalTransferPlayerFilters({
        ...filters,
        bill,
      });
      setFilters(filters);
      onFilterChange(filters);
    },
    [amountRange, dateRange, bill?.key, name],
  );

  const handleInputChange = (inputValue: rangeValue) => {
    const [val1, val2] = inputValue;
    setAmountRange(inputValue);
    changeFilters({
      ...filters,
      fromAmount: val1,
      toAmount: val2,
    });
  };
  const debouncedInputChange = debounce(handleInputChange, 1000);

  const onDateChange: RangePickerProps['onChange'] = (values) => {
    setDateRange(values);
    let newFilters = {
      ...filters,
    };
    if (values) {
      const [fromDate, toDate] = values;
      newFilters = {
        ...filters,
        fromDate: fromDate?.format(MomentFormats['MM.DD.YYYY']),
        toDate: toDate?.format(MomentFormats['MM.DD.YYYY']),
      };
    } else {
      newFilters = {
        ...filters,
        fromDate: undefined,
        toDate: undefined,
      };
    }
    changeFilters(newFilters);
  };

  const onBillSelected = (bill: IPlayersBillSelectBoxItemProps) => {
    setBill(bill);
    changeFilters({
      ...filters,
      billId: bill?.record?.bill.id ?? undefined,
    });
    setInternalTransferPlayerFilters({
      ...filters,
      bill,
    });
  };

  const debouncedNameChange = useCallback(
    debounce((value: string | undefined) => {
      changeFilters({
        ...filters,
        name: value as string,
      });
    }, 500),
    [amountRange, dateRange, bill?.key],
  );

  const handleNameChange = (value: string | undefined) => {
    setName(value);
    debouncedNameChange(value);
  };

  useEffect(() => {
    const storageFilters = getInternalTransferPlayerFilters();
    if (storageFilters) {
      const { fromDate, toDate, name, fromAmount, toAmount, bill } = storageFilters;
      const localFilters = { ...storageFilters, billId: bill?.record ? bill.record.bill.id : undefined };
      setInternalTransferPlayerFilters({
        ...storageFilters,
        bill,
      });

      setFilters(localFilters);
      onFilterChange(localFilters);

      if (fromDate && toDate) {
        setDateRange([moment(fromDate), moment(toDate)]);
      }
      if (name) {
        setName(name);
      }
      if (fromAmount || toAmount) {
        setAmountRange([fromAmount, toAmount]);
      }
      if (bill) {
        setBill(bill);
      }
    }
  }, []);

  return (
    <S.FilterRow gutter={[20, 20]}>
      <BaseCol>
        <DatePicker.RangePicker size="small" value={dateRange} onChange={onDateChange} />
      </BaseCol>
      <BaseCol>
        <BaseInput
          size="small"
          placeholder={`${t('labels.sender')}/${t('labels.receiver')}`}
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
        />
      </BaseCol>
      <BaseCol>
        <PlayersBillSelectBox
          width="210px"
          size="small"
          placeholder={t('labels.check')}
          onChange={onBillSelected}
          player={player}
          value={bill}
        />
      </BaseCol>
      <BaseCol>
        <BaseRow gutter={10}>
          <BaseCol>
            <BaseRangeNumberInput
              value={amountRange}
              placeholder={t('labels.summ')}
              onChange={(inputValue) => debouncedInputChange(inputValue)}
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </S.FilterRow>
  );
};

export default InternalTransferTablePlayerFilters;
