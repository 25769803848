import React from 'react';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { BaseCol } from '../BaseCol/BaseCol';
import { BasePopconfirm } from '../BasePopconfirm/BasePopconfirm';
import { useTranslation } from 'react-i18next';
import { BasePopover } from '../BasePopover/BasePopover';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export interface IActionsCellProps {
  onInfo?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onSave?: () => void;
  onClose?: () => void;
  onCopy?: () => void;
  isEditing?: boolean;
  canRead?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  deleteMessage?: string;
  size?: SizeType;
}

const BaseActionsCell: React.FC<IActionsCellProps> = (props) => {
  const { t } = useTranslation();
  const {
    canRead,
    canEdit,
    canDelete,
    onInfo,
    onEdit,
    onDelete,
    onSave,
    onClose,
    onCopy,
    isEditing = false,
    deleteMessage = t('messages.deleteConfirm'),
    size = 'middle',
  } = props;
  return (
    <BaseRow align="middle" justify="center" gutter={[3, 3]}>
      {!isEditing && onInfo && canRead && (
        <BaseCol>
          <BasePopover content={t('controls.info')}>
            <BaseButton icon={<InfoCircleOutlined />} onClick={onInfo} size={size} />
          </BasePopover>
        </BaseCol>
      )}
      {onCopy && canEdit && (
        <BaseCol>
          <BasePopover content={t('controls.copy')}>
            <BaseButton icon={<CopyOutlined />} onClick={onCopy} size={size} />
          </BasePopover>
        </BaseCol>
      )}
      {!isEditing && onEdit && canEdit && (
        <BaseCol>
          <BasePopover content={t('controls.edit')}>
            <BaseButton icon={<EditOutlined />} onClick={onEdit} size={size} />
          </BasePopover>
        </BaseCol>
      )}
      {!isEditing && onDelete && canDelete && (
        <BaseCol>
          <BasePopconfirm title={deleteMessage} onConfirm={onDelete}>
            <BasePopover content={t('controls.delete')}>
              <BaseButton icon={<DeleteOutlined />} size={size} />
            </BasePopover>
          </BasePopconfirm>
        </BaseCol>
      )}
      {isEditing && (
        <BaseCol>
          <BasePopover content={t('controls.save')}>
            <BaseButton size="small" icon={<CheckOutlined />} onClick={onSave} />
          </BasePopover>
        </BaseCol>
      )}
      {isEditing && (
        <BaseCol>
          <BasePopover content={t('controls.close')}>
            <BaseButton size="small" icon={<CloseOutlined />} onClick={onClose} />
          </BasePopover>
        </BaseCol>
      )}
    </BaseRow>
  );
};

export default BaseActionsCell;
