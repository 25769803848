import React from 'react';
import SaveIconButton from '@app/components/common/buttons/save-icon-button';
import CancelIconButton from '@app/components/common/buttons/cancel-icon-button';
import DeleteIconButton from '@app/components/common/buttons/delete-icon-button';
import * as S from '../styles';
import { PlayerBill } from '@app/infrastructure/classes/player-bill';

interface ActionsCellProps {
  data: PlayerBill;
  editable: boolean;
  save: () => void;
  cancel: () => void;
  handleDeleteBill: (id: number) => void;
  canDelete?: boolean;
}

const ActionsCell: React.FC<ActionsCellProps> = ({
  data,
  editable,
  save,
  cancel,
  handleDeleteBill,
  canDelete = false,
}) => {
  return (
    <S.BaseSpace>
      {editable ? (
        <>
          <SaveIconButton save={save} />
          <CancelIconButton cancel={cancel} />
          {canDelete && <DeleteIconButton handleDeleteBill={handleDeleteBill} dataId={data.id} />}
        </>
      ) : (
        <></>
      )}
    </S.BaseSpace>
  );
};

export default ActionsCell;
