import React from 'react';
import * as S from './styles';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import useLimits from '@app/infrastructure/hooks/use-limits';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';

interface ILimitCreateColProps {
  colNames: { [key: string]: string };
  onClose: () => void;
  onSaved: () => void;
}

const LimitCreateCol: React.FC<ILimitCreateColProps> = (props) => {
  const { colNames, onClose, onSaved } = props;
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  const { updateDynamicLimit, loading } = useLimits();

  const onSave = async () => {
    form.submit();
  };

  const onFinish = async (values: { newColName: string }) => {
    if (colNames) {
      const newColsNames = colNames;
      newColsNames[values.newColName] = values.newColName;
      await updateDynamicLimit({ col_names: newColsNames });
      onClose();
      onSaved();
    }
  };

  return (
    <BaseForm name="add-limit-form" autoComplete="off" form={form} onFinish={onFinish} size="small">
      <BaseRow gutter={10} align="middle" justify={'space-between'}>
        <BaseCol span={4}>
          <S.BaseFormItem name="newColName" rules={[{ required: true, message: '' }]}>
            <BaseInput placeholder={t('labels.enterColName')} style={{ width: '300px' }} />
          </S.BaseFormItem>
        </BaseCol>

        <BaseCol span={4}>
          <BaseRow gutter={2} align="middle">
            <BaseCol>
              <BaseButton size="small" type="text" icon={<CheckOutlined />} onClick={onSave} loading={loading} />
            </BaseCol>
            <BaseCol>
              <BaseButton size="small" type="text" icon={<CloseOutlined />} onClick={onClose} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  );
};

export default LimitCreateCol;
