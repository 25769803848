import React from 'react';
import { weekDays } from './data';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

const WeekDaysSelector: React.FC<CheckboxGroupProps> = (props) => {
  return (
    <BaseCheckbox.Group {...props}>
      <BaseRow align="middle" justify="center">
        {weekDays().map((day) => (
          <BaseCol key={`${day.title}-${day.key}-col`}>
            <BaseTooltip title={day.title}>
              <BaseCheckbox value={day.key}>{day.symbol}</BaseCheckbox>
            </BaseTooltip>
          </BaseCol>
        ))}
      </BaseRow>
    </BaseCheckbox.Group>
  );
};

export default WeekDaysSelector;
