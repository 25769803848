import styled from 'styled-components';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

export const HeaderDescription = styled(BaseTypography)`
  margin-bottom: 10px;
`;

export const TypeOfEntryItem = styled(BaseButtonsForm.Item)`
  width: 200px;

  .ant-select-selection-placeholder {
    font-size: 16px;
  }
`;

export const NumberInput = styled(BaseButtonsForm.Item)`
  .ant-input-number {
    width: 100%;
  }
`;

export const FilterRow = styled(BaseRow)`
  width: 100%;
  margin-bottom: 10px;

  .ant-input-number {
    width: 200px;
  }
`;
