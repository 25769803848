/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';

interface IRerenderContext {
  key: number;
  rerender: () => void;
}

const rerenderContext = React.createContext<IRerenderContext>({
  key: Date.now(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  rerender: () => {},
});

export const useRerender = () => useContext(rerenderContext);

interface IRerenderProviderProps {
  children: React.ReactNode;
}

const RerenderProvider: React.FC<IRerenderProviderProps> = (props) => {
  const { children } = props;

  const [key, setKey] = useState<number>(Date.now());

  const rerender = () => {
    setKey(Date.now());
  };

  return (
    <rerenderContext.Provider
      value={{
        key,
        rerender,
      }}
    >
      {children}
    </rerenderContext.Provider>
  );
};

export default RerenderProvider;
