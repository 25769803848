import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Player } from '@app/infrastructure/classes/player';
import PlayersBillSelectBox, { IPlayersBillSelectBoxItemProps } from '../../bills/select-box';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import * as S from './styles';
import { ImageUpload } from '@app/components/images/image-uploud-input';
import useImages from '@app/infrastructure/hooks/use-images';
import { UploadFile } from 'antd/es/upload/interface';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import useInternalTransfers from '@app/infrastructure/hooks/use-internal-transfers';
import { ICreateInternalTransferToAdmin } from '@app/infrastructure/interfaces/i-internal-transfer';

interface IInternalTransferRequestFormProps {
  player: Player;
  onClose?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  amount: number;
  playersBill: IPlayersBillSelectBoxItemProps;
  image: UploadFile;
  commission: number;
  description: string;
}

const InternalTransferRequestsAdminCreateForm: React.FC<IInternalTransferRequestFormProps> = (props) => {
  const { player, onClose, onSave } = props;
  const { t } = useTranslation();

  const { createInternalTransferToAdmin, loading } = useInternalTransfers({ player });
  const { createImage, loading: loadingImage } = useImages();

  const onFinish = async (values: IFormValues) => {
    const imageData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    imageData.append('image', values.image as any);
    createImage(imageData).then((data) => {
      if (data) {
        const createInternalTransferValues: ICreateInternalTransferToAdmin = {
          amount: values.amount,
          commission: values.commission ? values.commission : 0,
          description: values.description,
          players_bill_id: values.playersBill.record.id,
          image_id: data?.id,
        };
        createInternalTransferToAdmin(createInternalTransferValues).then(async () => {
          if (onSave) onSave();
        });
      }
    });
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BaseButtonsForm
      size="small"
      loading={loading || loadingImage}
      isFieldsChanged={true}
      onFinish={onFinish}
      onClose={onCancel}
      autoComplete="off"
    >
      <BaseButtonsForm.Item
        name="playersBill"
        label={t('labels.check')}
        rules={[{ required: true, message: t('messages.requiredField') }]}
      >
        <PlayersBillSelectBox player={player} withSumm />
      </BaseButtonsForm.Item>
      <BaseRow justify="space-between">
        <S.NumberInput name="amount" label={t('labels.summ')} rules={[{ type: 'number', min: 0, required: true }]}>
          <InputNumber min={0} width="100%" />
        </S.NumberInput>
        <S.NumberInput name="commission" label={t('labels.commission')} rules={[{ type: 'number', min: 0 }]}>
          <InputNumber min={0} />
        </S.NumberInput>
      </BaseRow>
      <BaseButtonsForm.Item name="description" label={t('labels.comment')}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item label={t('labels.screen')} required>
        <ImageUpload rules={[{ required: true, message: t('messages.requiredField') }]} />
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};

export default InternalTransferRequestsAdminCreateForm;
