import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const AuthLayout = React.lazy(() => import('@app/components/layouts/auth-layout'));
const MainLayout = React.lazy(() => import('@app/components/layouts/main/'));

import LoginPage from '@app/pages/auth/login';
import LockPage from '@app/pages/auth/lock';
import AdminsPage from '@app/pages/admins';
import HomePage from '@app/pages/home';
import AccessRightsPage from '@app/pages/access-rights';
import FlcraftPage from '@app/pages/flcraft';

import { ROUTES } from '@app/infrastructure/consts/routes';
import { withLoading } from '@app/infrastructure/hocs/with-loading';

import RequireAuth from './require-auth';
import CanReadRoute from './can-read';
import PlayersPage from '@app/pages/players';
import BillsPage from '@app/pages/bills';
import RanksPage from '@app/pages/ranks';
import PlayerDetailPage from '@app/pages/players/id-admin';
import PersonalTransfersPage from '@app/pages/personal-transfers';
import Error404Page from '@app/pages/errors/404';
import ServerErrorPage from '@app/pages/errors/500';
import Error403Page from '@app/pages/errors/403';
import InternalTransfersPage from '@app/pages/internal-transfers';
import FlcraftDetailPage from '@app/pages/flcraft/id';
import TournamentsPage from '@app/pages/tournaments';
import InternalTransferRequestsForPlayerPage from '@app/pages/internal-transfer-requests/player';
import AnalyticsPage from '@app/pages/analytics';
import PlayerGamesPage from '@app/pages/player-games';
import ProfitPackagesPage from '@app/pages/profit-packages';

const Logout = React.lazy(() => import('./logout'));

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={protectedLayout}>
          <Route index element={<HomePage />} />
          <Route
            path={ROUTES.RANKS}
            element={
              <CanReadRoute accessField="rankPermission">
                <RanksPage />
              </CanReadRoute>
            }
          />
          <Route
            path={ROUTES.BILLS}
            element={
              <CanReadRoute accessField="billPermission">
                <BillsPage />
              </CanReadRoute>
            }
          />
          <Route
            path={ROUTES.PLAYERS}
            element={
              <CanReadRoute accessField="playerPermission">
                <PlayersPage />
              </CanReadRoute>
            }
          />
          <Route
            path={ROUTES.PLAYERS_DETAIL}
            element={
              <CanReadRoute accessField="playerPermission">
                <PlayerDetailPage />
              </CanReadRoute>
            }
          />
          <Route path={ROUTES.PERSONAL_TRANSFERS} element={<PersonalTransfersPage />} />
          <Route path={ROUTES.TOURNAMENTS} element={<TournamentsPage />} />
          <Route path={ROUTES.INTERNAL_TRANSFERS} element={<InternalTransfersPage />} />
          <Route path={ROUTES.INTERNAL_TRANSFER_REQUESTS_ME} element={<InternalTransferRequestsForPlayerPage />} />
          <Route
            path={ROUTES.ACCESS_RIGHTS}
            element={
              <CanReadRoute accessField="accessPermission">
                <AccessRightsPage />
              </CanReadRoute>
            }
          />
          <Route
            path={ROUTES.ADMINS}
            element={
              <CanReadRoute accessField="userPermission">
                <AdminsPage />
              </CanReadRoute>
            }
          />
          <Route path={ROUTES.PLAYER_GAMES} element={<PlayerGamesPage />} />
          <Route path={ROUTES.ANALYTICS} element={<AnalyticsPage />} />
          <Route path={ROUTES.FLCRAFT} element={<FlcraftPage />} />
          <Route
            path={ROUTES.FLCRAFT_DETAIL}
            element={
              <CanReadRoute accessField="flcraftPermission">
                <FlcraftDetailPage />
              </CanReadRoute>
            }
          />
          <Route
            path={ROUTES.PROFIT_PACKAGES}
            element={
              <CanReadRoute accessField="profitPackagePermission">
                <ProfitPackagesPage />
              </CanReadRoute>
            }
          />
          <Route path={ROUTES.ERRORS.FORBIDDEN} element={<Error403Page />} />
          <Route path={ROUTES.ERRORS.NOT_FOUND} element={<Error404Page />} />
          <Route path={ROUTES.ERRORS.SERVER_ERROR} element={<ServerErrorPage />} />
        </Route>

        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
