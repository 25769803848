import React from 'react';
import { BaseImage } from '../BaseImage/BaseImage';
import { Image } from '@app/infrastructure/classes/image';
import { BaseRow } from '../BaseRow/BaseRow';

interface ImageProps {
  data?: Image;
  width?: number;
  height?: number;
}

const ImageWithPreview: React.FC<ImageProps> = (props) => {
  const { data, width, height = 50 } = props;
  return (
    <BaseRow justify="center" align="middle">
      <BaseImage width={width} height={height} src={data ? data.originalUrl : '/noImage.png'} />
    </BaseRow>
  );
};

export default ImageWithPreview;
