import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import useProfitPackages from '@app/infrastructure/hooks/use-profit-packages';
import { ProfitPackage } from '@app/infrastructure/classes/profit-package';
import { PlayerBillImage } from './player-bill-image';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface CalculateAdminProps {
  profitPackage: ProfitPackage;
  isEdit?: boolean;
  onSave?: () => void;
}

export const CalculateAdmin: React.FC<CalculateAdminProps> = ({ profitPackage, isEdit = false, onSave }) => {
  const { t } = useTranslation();

  const { updateCalculateProfitPackage, cancelCalculateProfitPackage, loading } = useProfitPackages({
    player: undefined,
  });

  const onFinish = async () => {
    await updateCalculateProfitPackage(profitPackage.id).then(() => {
      if (onSave) onSave();
    });
  };

  const onCancelCalculate = async () => {
    await cancelCalculateProfitPackage(profitPackage.id).then(() => {
      if (onSave) onSave();
    });
  };

  return (
    <>
      <S.PackageInfo>{t('labels.profitPackageImages')}</S.PackageInfo>
      <BaseRow gutter={[10, 10]}>
        {profitPackage.profitPackageImages?.length &&
          profitPackage.profitPackageImages.map((profitPackageImage) => {
            return (
              <BaseCol key={profitPackageImage.id}>
                <PlayerBillImage profitPackageImage={profitPackageImage} />
              </BaseCol>
            );
          })}
      </BaseRow>
      <S.PackageDateInfo>
        {`${t('labels.startDateLastPackage')}: ${profitPackage.startDate.format(MomentFormats['DD.MM.YYYY'])}`}
      </S.PackageDateInfo>
      <S.PackageInfo>{`${t('labels.rank')}: ${profitPackage.player?.rank?.name}`}</S.PackageInfo>
      <S.PackageInfo>{`${t('labels.lastPackageTerms')}: ${profitPackage.packageTerms}`}</S.PackageInfo>
      <S.PackageInfo>{`${t('labels.profitPackage')}: ${CURRENCIES.DOLLAR} ${
        profitPackage.profitPackage
      }`}</S.PackageInfo>
      <S.PackageInfo>{`${t('labels.playersPart')}: ${CURRENCIES.DOLLAR} ${profitPackage.playersPart}`}</S.PackageInfo>
      <S.PackageInfo>{`${t('labels.fundsPart')}: ${CURRENCIES.DOLLAR} ${profitPackage.fundsPart}`}</S.PackageInfo>
      <S.PackageInfo>{`${t('labels.newPackageTerms')}: ${profitPackage.nextPackageTerms}`}</S.PackageInfo>
      {isEdit && (
        <BaseRow justify="space-between">
          <BaseButton severity="error" loading={loading} size="small" onClick={() => onCancelCalculate()}>
            {t('controls.cancelCalculate')}
          </BaseButton>
          <BaseButton type="primary" loading={loading} size="small" onClick={() => onFinish()}>
            {t('controls.calculate')}
          </BaseButton>
        </BaseRow>
      )}
    </>
  );
};
