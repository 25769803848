import { useState } from 'react';
import { AccessRight } from '../classes/access-right';
import { getAccessRights, createAccessRights, deleteAccessRights, updateAccessRights } from '@app/api/access-rights';
import { ILoadProps } from './interfaces/i-load-props';
import { IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';

type UseAccessRights = {
  accessRights: AccessRight[];
  meta: IPaginationMeta;
  loadAccessRights: (props?: ILoadProps) => Promise<AccessRight[] | undefined>;
  createAccessRight: (data: AccessRight) => Promise<AccessRight | undefined>;
  updateAccessRight: (id: number, data: AccessRight) => Promise<AccessRight | undefined>;
  deleteAccessRight: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUseAccessRightsProps {
  meta?: IPartialPaginationMeta;
}

const useAccessRights = (props?: IUseAccessRightsProps): UseAccessRights => {
  const [loading, setLoading] = useState<boolean>(false);
  const [accessRights, setAccessRights] = useState<AccessRight[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadAccessRights = async (props?: ILoadProps) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { results, meta: metaRes } = await getAccessRights({ ...meta, ...props });
    let data = undefined;
    if (results.length || metaRes.total === 0) {
      data = results.map((el) => new AccessRight(el));
      if (props?.reload) setAccessRights(data);
      else setAccessRights([...accessRights, ...data]);
      setMeta({
        ...meta,
        ...metaRes,
      });
    } else {
      let page = metaRes.page;
      if (metaRes.totalPages < page) page = metaRes.totalPages;
      const { results, meta: metaSecondReq } = await getAccessRights({
        ...meta,
        ...metaRes,
        page,
        ...props,
      });
      if (results) {
        data = results.map((el) => new AccessRight(el));
        if (props?.reload) setAccessRights(data);
        else setAccessRights([...accessRights, ...data]);
        setMeta({
          ...meta,
          ...metaSecondReq,
        });
      }
    }

    setLoading(false);
    return data;
  };

  const createAccessRight = async (data: AccessRight) => {
    setLoading(true);
    const result = await createAccessRights({
      name: data.name,
      access_permission: data.accessPermission,
      user_permission: data.userPermission,
      player_permission: data.playerPermission,
      bill_permission: data.billPermission,
      rank_permission: data.rankPermission,
      flcraft_permission: data.flcraftPermission,
      personal_transfer_permission: data.personalTransferPermission,
      internal_transfer_permission: data.internalTransferPermission,
      analytics_permission: data.analyticsPermission,
      player_game_permission: data.playerGamePermission,
      tournament_permission: data.tournamentPermission,
      limit_permission: data.limitPermission,
      profit_package_permission: data.profitPackagePermission,
      over_money_permission: data.overMoneyPermission,
    }).then((data) => {
      notificationController.success({
        message: t('messages.successAdd'),
      });
      return data;
    });
    setLoading(false);
    return new AccessRight(result);
  };

  const updateAccessRight = async (id: number, data: AccessRight) => {
    setLoading(true);
    const result = await updateAccessRights(id, {
      name: data.name,
      access_permission: data.accessPermission,
      user_permission: data.userPermission,
      player_permission: data.playerPermission,
      bill_permission: data.billPermission,
      rank_permission: data.rankPermission,
      flcraft_permission: data.flcraftPermission,
      personal_transfer_permission: data.personalTransferPermission,
      internal_transfer_permission: data.internalTransferPermission,
      analytics_permission: data.analyticsPermission,
      player_game_permission: data.playerGamePermission,
      tournament_permission: data.tournamentPermission,
      limit_permission: data.limitPermission,
      profit_package_permission: data.profitPackagePermission,
      over_money_permission: data.overMoneyPermission,
    }).then((data) => {
      notificationController.success({
        message: t('messages.successUpdate'),
      });
      return data;
    });
    setLoading(false);
    return new AccessRight(result);
  };

  const deleteAccessRight = async (id: number) => {
    setLoading(true);
    await deleteAccessRights(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
    });
    setLoading(false);
  };

  return {
    accessRights,
    meta,
    loading,
    loadAccessRights,
    createAccessRight,
    updateAccessRight,
    deleteAccessRight,
  };
};

export default useAccessRights;
