import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputNumber } from 'antd';
import SaveIconButton from '@app/components/common/buttons/save-icon-button';
import CancelIconButton from '@app/components/common/buttons/cancel-icon-button';
import { Player } from '@app/infrastructure/classes/player';
import BillSelectBox, { IBillSelectBoxItemProps } from '@app/components/bills/select-box';
import usePlayersBills from '@app/infrastructure/hooks/use-players-bills';

interface IPlayerBillCreateFormProps {
  player: Player;
  onCancel?: () => void;
  onSave?: () => void;
}

interface IFormValues {
  bill: IBillSelectBoxItemProps;
  summ: number;
  playerNickname: string;
}

const PlayerBillCreateForm: React.FC<IPlayerBillCreateFormProps> = (props) => {
  const { player, onCancel, onSave } = props;

  const [form] = BaseForm.useForm();
  const { t } = useTranslation();

  const { createPlayersBill } = usePlayersBills({ player });

  const save = () => {
    form.submit();
  };

  const cancel = () => {
    if (onCancel) onCancel();
  };

  const onFinish = async (values: IFormValues) => {
    await createPlayersBill({
      bill_id: values.bill.record.id,
      summ: values.summ,
      player_nickname: values.playerNickname,
    }).then((data) => {
      if (data) {
        if (onSave) onSave();
        if (onCancel) onCancel();
      }
    });
  };

  return (
    <BaseForm
      name="add-player-bill-form"
      size="small"
      layout="horizontal"
      autoComplete="off"
      form={form}
      onFinish={onFinish}
    >
      <S.AddPlayerBillSpace>
        <S.AddPlayerBillFormItem name="bill" rules={[{ required: true, message: t('messages.requiredField') }]}>
          <BillSelectBox size="small" />
        </S.AddPlayerBillFormItem>
        <S.AddPlayerBillFormItem
          name="summ"
          rules={[
            { type: 'number', min: 0, message: t('messages.enterPositiveNumber') },
            { required: true, message: t('messages.requiredField') },
          ]}
        >
          <InputNumber placeholder={t('labels.summ')} min={0} />
        </S.AddPlayerBillFormItem>
        <S.AddPlayerBillFormItem
          name="playerNickname"
          rules={[
            { required: true, message: t('messages.requiredField') },
            { min: 2, message: t('messages.minCharacters') },
          ]}
        >
          <BaseInput placeholder={t('labels.nickname')} />
        </S.AddPlayerBillFormItem>
        <SaveIconButton save={save} />
        <CancelIconButton cancel={cancel} />
      </S.AddPlayerBillSpace>
    </BaseForm>
  );
};

export default PlayerBillCreateForm;
