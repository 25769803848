import { IRank } from '../interfaces/i-rank';

export class Rank {
  id: number;
  name: number;
  fundLimit: number;
  reqLimit: number;

  constructor(data: IRank) {
    this.id = data.id;
    this.name = data.name;
    this.fundLimit = data.fund_limit;
    this.reqLimit = data.req_limit;
  }
}
