/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import DebounceSelect from '../../common/debounce-select';
import useAccessRights from '../../../infrastructure/hooks/use-access-rights';
import { AccessRight } from '../../../infrastructure/classes/access-right';
import { useTranslation } from 'react-i18next';
import { IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { AutoCompleteProps } from 'antd/es/auto-complete';

export interface IAccessRightSelectBoxItemProps {
  key: any;
  label: React.ReactNode;
  value: any;
  record: AccessRight;
}

interface IAccessRightSelectBoxProps extends AutoCompleteProps {
  width?: string;
  saveState?: boolean;
  placeholder?: string;
  onSelect?: (items: AccessRight[]) => void;
}

const AccessRightSelectBox: React.FC<IAccessRightSelectBoxProps> = (props) => {
  const { t } = useTranslation();

  const { onSelect, width = '100%', saveState = true, placeholder = t('labels.selectAccessRight') } = props;

  const { loadAccessRights, meta } = useAccessRights({
    meta: {
      limit: 10,
    },
  });

  const [value, setValue] = useState<IAccessRightSelectBoxItemProps | IAccessRightSelectBoxItemProps[]>([]);

  const searchAccessRights = async (searchValue: string, meta: IPaginationMeta) => {
    const accessRights = await loadAccessRights({
      ...meta,
      search: ['name', searchValue],
      reload: true,
    });
    if (accessRights)
      return accessRights.map((el) => {
        return {
          key: el.id,
          label: el.name,
          value: `${el.name}`,
          record: el,
        };
      });

    return [];
  };

  return (
    <DebounceSelect
      showSearch
      allowClear
      value={value}
      placeholder={placeholder}
      fetchOptions={searchAccessRights}
      onChange={(newValue) => {
        if (onSelect) {
          if (newValue) {
            const data = Array.isArray(newValue) ? newValue.map((el) => el.record) : [newValue.record];
            onSelect(data);
          } else {
            onSelect([]);
          }
          if (saveState) {
            setValue(newValue);
          }
        }
      }}
      meta={meta}
      style={{ width }}
      {...props}
    />
  );
};

export default AccessRightSelectBox;
