/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { Player } from '@app/infrastructure/classes/player';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import { internalTransferRequestTableColumns } from './cols';
import OverMoneyInfo from '../over-money-info';
import { STATUS_INTERNAL_TRANSFER } from '@app/infrastructure/enums/status-internal-transfer.enum';
import InternalTransferRequestsUpdateForm from '@app/components/internal-transfer-requests/update-form';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import InternalTransferSendAdminForm from '@app/components/internal-transfer-requests/send-form';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { useBadge } from '@app/infrastructure/providers/badge-provider';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';
import InternalTransferRequestsAdminCreateForm from '../create-admin-form';

interface InternalTransferRequestsTableProps {
  player: Player;
}

const InternalTransferRequestsPlayerTable: React.FC<InternalTransferRequestsTableProps> = (props) => {
  const { player } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedInternalTransferRequest, setSelectedInternalTransferRequest] =
    useState<InternalTransferRequest | null>(null);
  const [sendRequest, setSendRequest] = useState<InternalTransferRequest | null>(null);
  const [modalTitle, setModalTitle] = useState<string>();
  const {
    internalTransferRequests,
    meta,
    loading,
    stopTransfer,
    loadInternalTransferRequestsByPlayerForAdmin,
    deleteInternalTransferRequest,
  } = useInternalTransferRequests({
    player,
  });
  const { updatePlayerInfoById } = usePlayers();

  const { t } = useTranslation();
  const { key, rerender } = useRerender();
  const { rerenderBadge } = useBadge();

  const hasPagination = meta.total > meta.limit;

  const user = useAppSelector((state) => state.user?.user);

  const filterStatus = { statuses: [STATUS_INTERNAL_TRANSFER.APPROVED, STATUS_INTERNAL_TRANSFER.IN_REVIEW] };

  const onReload = async () => {
    await loadInternalTransferRequestsByPlayerForAdmin({
      filters: filterStatus,
      reload: true,
      sort: ['status', 'ASC'],
    });
  };

  const onCreate = () => {
    player.rank?.name
      ? (setModalTitle(t('labels.createAdminInternalTransferRequest')), setModalOpened(true))
      : notificationController.error({
          message: t('messages.rankNotDefined'),
        });
  };

  const onEdit = async (record: InternalTransferRequest) => {
    setSelectedInternalTransferRequest(record);
    setModalTitle(t('labels.updateStatusRequest'));
    setModalOpened(true);
  };

  const onDelete = async (record: InternalTransferRequest) => {
    await deleteInternalTransferRequest(record.id);
    await onReload();
  };

  const onClose = async () => {
    setSelectedInternalTransferRequest(null);
    setModalOpened(false);
    sendRequest && (await stopTransfer(sendRequest.id));
    setSendRequest(null);
    rerenderBadge();
  };

  const onSave = async () => {
    onClose();
    await updatePlayerInfoById(player.id, player);
    rerender();
    await onReload();
  };

  const onSend = async (record: InternalTransferRequest) => {
    setSendRequest(record);
    setModalTitle(t('controls.send'));
    setModalOpened(true);
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InternalTransferRequest> | SorterResult<InternalTransferRequest>[],
  ) => {
    await loadInternalTransferRequestsByPlayerForAdmin({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<InternalTransferRequest>(
        sorter as IModifiedSorterResult<InternalTransferRequest>,
        'status',
        'ASC',
      ),
      reload: true,
      filters: filterStatus,
    });
  };

  useEffect(() => {
    loadInternalTransferRequestsByPlayerForAdmin({ filters: filterStatus, reload: true, sort: ['status', 'ASC'] });
  }, [key]);

  return (
    <>
      <div>
        <BaseTable
          rowKey="id"
          title={() => {
            return (
              <BaseTableTitle
                canCreate={user?.canCreate('overMoneyPermission')}
                onReload={onReload}
                onCreate={onCreate}
                extra={<OverMoneyInfo player={player} />}
                size="small"
              />
            );
          }}
          columns={internalTransferRequestTableColumns({
            canEdit: user?.canUpdate('internalTransferPermission'),
            canDelete: user?.fullAccess('internalTransferPermission'),
            onDelete,
            onEdit,
            onSend,
            onSave,
          })}
          dataSource={internalTransferRequests}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          style={{ marginBottom: '20px' }}
          size="small"
          scroll={{ x: 1000 }}
          bordered
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} destroyOnClose footer={null}>
        {selectedInternalTransferRequest && (
          <InternalTransferRequestsUpdateForm
            data={selectedInternalTransferRequest}
            onClose={onClose}
            onSave={onSave}
          />
        )}
        {sendRequest && (
          <InternalTransferSendAdminForm internalTransferRequest={sendRequest} onClose={onClose} onSave={onSave} />
        )}
        {!selectedInternalTransferRequest && !sendRequest && (
          <InternalTransferRequestsAdminCreateForm player={player} onClose={onClose} onSave={onSave} />
        )}
      </BaseModal>
    </>
  );
};

export default InternalTransferRequestsPlayerTable;
