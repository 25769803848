import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import { PersonalTransfer } from '@app/infrastructure/classes/personal-transfer';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import ImageWithPreview from '@app/components/common/image';
import BillNameCell from '@app/components/personal-transfers/table/cols/bill-name-cell';
import PurposeCell from '@app/components/personal-transfers/table/cols/purpose-cell';
import BillDeletedCell from '@app/components/personal-transfers/table/cols/bill-deleted-cell';
import BaseActionsCell from '@app/components/common/base-actions-cell';

interface IPersonalTransferColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: PersonalTransfer) => void;
  onDelete: (data: PersonalTransfer) => void;
}

export const personalTransfersTableColumns = ({
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}: IPersonalTransferColumnsProps): IModifiedTableColumn<PersonalTransfer>[] => {
  const columns: IModifiedTableColumn<PersonalTransfer>[] = [
    {
      title: `${i18next.t('labels.check')}`,
      dataIndex: 'name',
      sortField: 'players_bill.bill.name',
      sorter: true,
      width: '100px',
      render: (_, data: PersonalTransfer) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.summ')}`,
      dataIndex: 'amount',
      sortField: 'amount',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.date')}`,
      dataIndex: 'createdAt',
      sortField: 'created_at',
      sorter: true,
      render: (_, record) => record.createdAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.purpose')}`,
      dataIndex: 'purpose',
      sortField: 'purpose',
      sorter: true,
      render: (_, data: PersonalTransfer) => {
        return <PurposeCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.comment')}`,
      dataIndex: 'description',
    },
    {
      title: `${i18next.t('labels.screen')}`,
      dataIndex: 'image',
      align: 'center',
      render: (_, data: PersonalTransfer) => {
        return <ImageWithPreview data={data.image} />;
      },
    },
    {
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '120px',
      align: 'center',
      render: (_, record) =>
        record.playersBill.deleted_at === null ? (
          (canEdit || canDelete) && (
            <BaseActionsCell
              canEdit={canEdit}
              canDelete={canDelete}
              onDelete={() => {
                onDelete(record);
              }}
              onEdit={() => {
                onEdit(record);
              }}
              size="small"
            />
          )
        ) : (
          <BillDeletedCell />
        ),
    },
  ];

  return columns;
};
