import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './button.styles';
import { BasePopconfirm } from '../BasePopconfirm/BasePopconfirm';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { DeleteOutlined } from '@ant-design/icons';

interface DeleteIconButtonProps {
  handleDeleteBill: (id: number) => void;
  dataId: number;
}

const DeleteIconButton: React.FC<DeleteIconButtonProps> = ({ handleDeleteBill, dataId }) => {
  const { t } = useTranslation();

  return (
    <BasePopconfirm title={t('messages.deleteConfirm')} onConfirm={() => handleDeleteBill(dataId)}>
      <BaseTooltip title={t('controls.delete')}>
        <S.ActivityButton size="small" type="default">
          <DeleteOutlined />
        </S.ActivityButton>
      </BaseTooltip>
    </BasePopconfirm>
  );
};

export default DeleteIconButton;
