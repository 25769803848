import styled from 'styled-components';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export const Description = styled(BaseTypography)`
  margin-bottom: 15px;
`;

export const NumberInput = styled(BaseButtonsForm.Item)`
  .ant-input-number {
    width: 100%;
  }
`;
