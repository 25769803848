import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Limit } from '@app/infrastructure/classes/limit';

interface IBillCellProps {
  data: Limit;
}

const BillCell: React.FC<IBillCellProps> = (props) => {
  const { data } = props;
  return <BaseTypography>{data.bill.name}</BaseTypography>;
};

export default BillCell;
