/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { STATUS_GAME_REQUEST } from '@app/infrastructure/enums/status-game-request.enum';
import usePlayerGames from '@app/infrastructure/hooks/use-player-games';
import { PlayerGame } from '@app/infrastructure/classes/player-game';
import PlayerGameCreateForm from '../create-form';
import { playerGameTableColumns } from './cols';

interface PlayerGamesTableProps {
  canCreate?: boolean;
  canDelete?: boolean;
}

const PlayerGamesInReviewTable: React.FC<PlayerGamesTableProps> = (props) => {
  const { canCreate = false, canDelete = false } = props;
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const { playerGames, meta, loading, loadPlayerGamesByPlayer, deletePlayerGame } = usePlayerGames();

  const { t } = useTranslation();

  const filterStatus = { statuses: [STATUS_GAME_REQUEST.IN_REVIEW] };

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadPlayerGamesByPlayer({ filters: filterStatus, reload: true });
  };

  const onCreate = () => {
    setModalOpened(true);
  };

  const onDelete = async (record: PlayerGame) => {
    await deletePlayerGame(record.id);
    await onReload();
  };

  const onClose = () => {
    setModalOpened(false);
  };

  const onSave = async () => {
    setModalOpened(false);
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PlayerGame> | SorterResult<PlayerGame>[],
  ) => {
    await loadPlayerGamesByPlayer({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<PlayerGame>(sorter as IModifiedSorterResult<PlayerGame>, 'updated_at', 'DESC'),
      reload: true,
      filters: filterStatus,
    });
  };

  useEffect(() => {
    onReload();
  }, []);

  return (
    <>
      <div>
        <BaseTable
          rowKey="id"
          title={() => {
            return <BaseTableTitle canCreate={canCreate} onCreate={onCreate} onReload={onReload} size="small" />;
          }}
          columns={playerGameTableColumns({
            canDelete,
            onDelete,
          })}
          dataSource={playerGames}
          pagination={
            !loading &&
            hasPagination && {
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          size="small"
          scroll={{ x: 1000 }}
          style={{ marginBottom: '20px' }}
          bordered
        />
      </div>
      <BaseModal
        title={t('labels.createPlayerGame')}
        open={modalOpened}
        onCancel={onClose}
        destroyOnClose
        footer={null}
      >
        <PlayerGameCreateForm onClose={onClose} onSave={onSave} />
      </BaseModal>
    </>
  );
};

export default PlayerGamesInReviewTable;
