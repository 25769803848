import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { httpApi } from './http.api';
import {
  ICalculatePackage,
  ICreateProfitPackage,
  IProfitPackage,
} from '@app/infrastructure/interfaces/i-profit-package';

const baseRoute = 'profit-packages';
const routeByPlayer = (id: number) => `players/${id}/profit-packages`;
const calculateRoute = '/calculate';

export const getProfitPackages = async (params: IPaginationMeta): Promise<IPaginatedData<IProfitPackage>> => {
  return await httpApi.get<IPaginatedData<IProfitPackage>>(baseRoute, { params }).then(({ data }) => data);
};

export async function getProfitPackageById(playerId: number): Promise<IProfitPackage> {
  return await httpApi.get(routeByPlayer(playerId)).then(({ data }) => data);
}

export async function getProfitPackageBadges(): Promise<number> {
  return await httpApi.get(`${baseRoute}/badge`).then(({ data }) => data);
}

export async function createProfitPackages(
  playerId: number,
  profitPackage: ICreateProfitPackage,
): Promise<IProfitPackage> {
  const { data } = await httpApi.post<IProfitPackage>(routeByPlayer(playerId), profitPackage);
  return data;
}

export async function calculateProfitPackages(
  playerId: number,
  profitPackageId: number,
  calculateProfitPackage: ICalculatePackage,
): Promise<IProfitPackage> {
  const { data } = await httpApi.post<IProfitPackage>(
    `${routeByPlayer(playerId)}/${profitPackageId}${calculateRoute}`,
    calculateProfitPackage,
  );
  return data;
}

export async function updateCalculateProfitPackages(profitPackageId: number): Promise<IProfitPackage> {
  const { data } = await httpApi.put<IProfitPackage>(`${baseRoute}/${profitPackageId}${calculateRoute}`);
  return data;
}

export async function cancelCalculateProfitPackages(profitPackageId: number): Promise<IProfitPackage> {
  const { data } = await httpApi.put<IProfitPackage>(`${baseRoute}/${profitPackageId}/cancel-calculate`);
  return data;
}
