import { LoadingOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import { STATUS_INTERNAL_TRANSFER } from '@app/infrastructure/enums/status-internal-transfer.enum';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import useInternalTransferRequests from '@app/infrastructure/hooks/use-internal-transfer-requests';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SendCellProps {
  data: InternalTransferRequest;
  onSend?: () => void;
}

const SendAdminCell: React.FC<SendCellProps> = (props) => {
  const { data, onSend } = props;
  const { t } = useTranslation();

  const userId = useAppSelector((state) => state.user.user?.id);

  const { getTransferInfo, startTransfer, loading } = useInternalTransferRequests({ userId });

  const getInfo = async () => {
    const res = await getTransferInfo(data.id);
    if (res && res.canTransfer && onSend) {
      onSend();
      if (res.time === 0) await startTransfer(data.id);
    }
  };

  const isDisabled =
    loading || data.status !== STATUS_INTERNAL_TRANSFER.APPROVED || data.playersBill?.deleted_at !== null;

  return (
    <BaseRow align="middle" justify="center">
      <BaseButton
        icon={loading ? <LoadingOutlined /> : undefined}
        size="small"
        severity="info"
        disabled={isDisabled}
        onClick={getInfo}
      >
        {t('controls.send')}
      </BaseButton>
    </BaseRow>
  );
};

export default SendAdminCell;
