import React from 'react';
import { PlayerGame } from '@app/infrastructure/classes/player-game';

interface TournamentBiCellProps {
  data: PlayerGame;
}

const TournamentBiCell: React.FC<TournamentBiCellProps> = ({ data }) => {
  return <span>{data.buyIn}</span>;
};

export default TournamentBiCell;
