import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import { Tournament } from '@app/infrastructure/classes/tournament';
import StartDateCol from './start-date';
import BillCol from './bill';
import StartTimeCol from './start-time';
import EndTimeCol from './end-time';

interface ITournamentTableColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  isPlayer: boolean;
  onCopy: (data: Tournament) => void;
  onEdit: (data: Tournament) => void;
  onDelete: (data: Tournament) => void;
}

export const tournamentTableColumns = ({
  canEdit,
  canDelete,
  onCopy,
  onEdit,
  onDelete,
  isPlayer,
}: ITournamentTableColumnsProps): IModifiedTableColumn<Tournament>[] => {
  const columns: IModifiedTableColumn<Tournament>[] = [
    {
      title: `${i18next.t('labels.date')}`,
      dataIndex: 'startDate',
      sortField: 'start_date',
      width: '115px',
      sorter: true,
      render(_, record) {
        return <StartDateCol data={record} isPlayer={isPlayer} />;
      },
    },
    {
      title: `${i18next.t('labels.time')}`,
      dataIndex: 'startTime',
      sortField: 'start_time',
      width: '60px',
      sorter: false,
      render(_, record) {
        return <StartTimeCol data={record} isPlayer={isPlayer} />;
      },
    },
    {
      title: `${i18next.t('labels.room')}`,
      dataIndex: 'bill',
      sortField: 'bill.id',
      sorter: false,
      width: '100px',
      render(_, record) {
        return <BillCol data={record} />;
      },
    },
    {
      title: `${i18next.t('labels.name')}`,
      dataIndex: 'name',
      sortField: 'name',
      sorter: false,
    },
    {
      title: `${i18next.t('labels.bi')}`,
      dataIndex: 'buyIn',
      sortField: 'buy_in',
      width: '55px',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.type')}`,
      dataIndex: 'type',
      sortField: 'type',
      width: '45px',
      sorter: false,
    },
    {
      title: `${i18next.t('labels.end')}`,
      dataIndex: 'endTime',
      sortField: 'end_time',
      width: '60px',
      sorter: false,
      render(_, record) {
        return <EndTimeCol data={record} isPlayer={isPlayer} />;
      },
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '120px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          size="small"
          canEdit={canEdit}
          canDelete={canDelete}
          onDelete={() => {
            onDelete(record);
          }}
          onEdit={() => onEdit(record)}
          onCopy={() => onCopy(record)}
        />
      ),
    });
  }

  return columns;
};
