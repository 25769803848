import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { UploadFile } from 'antd/es/upload/interface';
import { PACKAGE_TERMS_ENUM } from '@app/infrastructure/enums/package-terms.enum';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import * as S from './styles';
import { ImageUpload } from './image-upload';
import { ICalculatePackage } from '@app/infrastructure/interfaces/i-profit-package';
import { Player } from '@app/infrastructure/classes/player';
import { notificationController } from '@app/infrastructure/controllers/notification-controller';
import PackageTermsSelect from '@app/components/profit-packages/select-box';
import useImages from '@app/infrastructure/hooks/use-images';
import useProfitPackages from '@app/infrastructure/hooks/use-profit-packages';
import usePlayersBills from '@app/infrastructure/hooks/use-players-bills';
import { ProfitPackage } from '@app/infrastructure/classes/profit-package';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { ICreateProfitPackageImage } from '@app/infrastructure/interfaces/i-profit-package-image';

interface CalculateFormProps {
  player: Player;
  profitPackage: ProfitPackage;
  onSave: () => void;
}

interface CalculateFormValues {
  package_terms?: PACKAGE_TERMS_ENUM;
}

export const CalculateForm: React.FC<CalculateFormProps> = ({ player, profitPackage, onSave }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();

  const [imagesArray, setImagesArray] = useState<{ image: UploadFile; playerBillId: number }[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [calculateImagesData, setCalculateImagesData] = useState<ICreateProfitPackageImage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { createImage } = useImages();
  const { calculateProfitPackage } = useProfitPackages({ player });
  const { playersBills, loadPlayersBills } = usePlayersBills({ player });
  const { updatePlayerInfoById } = usePlayers();
  const { rerender } = useRerender();

  useEffect(() => {
    loadPlayersBills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = () => {
    if (playersBills.length === imagesArray.length) {
      setLoading(true);
      imagesArray.map(({ image, playerBillId }) => {
        const imageData = new FormData();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        imageData.append('image', image as any);
        createImage(imageData)
          .then((data) => {
            if (data) {
              const state = calculateImagesData;
              state.push({ image_id: data.id, players_bill_id: playerBillId });
              setCalculateImagesData(state);
            }
          })
          .finally(() => {
            if (imagesArray.length === calculateImagesData.length) {
              form.submit();
            }
          });
      });
    } else {
      notificationController.error({
        message: t('messages.errorScrinshotsLength'),
      });
    }
  };

  const onCancel = () => {
    if (onSave) onSave();
  };

  const onFinish = (values: CalculateFormValues) => {
    const calculateProfitPackageData: ICalculatePackage = {
      images: calculateImagesData,
      package_terms: values?.package_terms,
    };
    calculateProfitPackage(profitPackage.id, calculateProfitPackageData).then(async () => {
      setLoading(false);
      onCancel();
      await updatePlayerInfoById(player.id, player);
      rerender();
    });
  };
  return (
    <BaseButtonsForm
      size="small"
      form={form}
      isFieldsChanged={true}
      loading={loading}
      layout="vertical"
      name="calculateForm"
      onFinish={onFinish}
      autoComplete="off"
      footer={
        <BaseButtonsForm.Item>
          <S.CaculateFormButton type="primary" onClick={uploadImage} loading={loading}>
            {t('controls.calculate')}
          </S.CaculateFormButton>
        </BaseButtonsForm.Item>
      }
    >
      {playersBills.map((playerBill) => {
        return (
          <ImageUpload
            playerBill={playerBill}
            key={playerBill.id}
            imagesArray={imagesArray}
            setImagesArray={setImagesArray}
          />
        );
      })}
      <S.CaculateFormCheckbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
        {t('labels.changePackageTerms')}
      </S.CaculateFormCheckbox>
      {isChecked && (
        <BaseButtonsForm.Item name="package_terms">
          <PackageTermsSelect placeholder={t('labels.selectPackageTerms')} />
        </BaseButtonsForm.Item>
      )}
    </BaseButtonsForm>
  );
};
