import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { httpApi } from './http.api';
import {
  ICreatePersonalTransfer,
  IPersonalTransfer,
  IUpdatePersonalTransfer,
} from '@app/infrastructure/interfaces/i-personal-transfer';

const route = '/personal-transfers';
const routeByPlayer = (id: number) => `players/${id}/personal-transfers`;

export async function getAllPersonalTransfers(params: IPaginationMeta): Promise<IPaginatedData<IPersonalTransfer>> {
  return await httpApi.get<IPaginatedData<IPersonalTransfer>>(route, { params }).then(({ data }) => data);
}

export async function getPersonalTransfersByPlayer(
  playerId: number,
  params: IPaginationMeta,
): Promise<IPaginatedData<IPersonalTransfer>> {
  return await httpApi
    .get<IPaginatedData<IPersonalTransfer>>(routeByPlayer(playerId), { params })
    .then(({ data }) => data);
}

export async function getPersonalTransferById(personalTransferId: number): Promise<IPersonalTransfer> {
  return await httpApi.get<IPersonalTransfer>(`${route}/${personalTransferId}`).then(({ data }) => data);
}

export async function createPersonalTransfers(
  playerId: number,
  data: ICreatePersonalTransfer,
): Promise<IPersonalTransfer> {
  return await httpApi.post<IPersonalTransfer>(routeByPlayer(playerId), data).then(({ data }) => data);
}

export async function updatePersonalTransfers(
  personalTransferId: number,
  data: IUpdatePersonalTransfer,
): Promise<IPersonalTransfer> {
  return await httpApi.put<IPersonalTransfer>(`${route}/${personalTransferId}`, data).then(({ data }) => data);
}

export async function deletePersonalTransferById(personalTransferId: number): Promise<IPersonalTransfer> {
  return await httpApi.delete<IPersonalTransfer>(`${route}/${personalTransferId}`).then(({ data }) => data);
}
