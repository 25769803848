import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { httpApi } from './http.api';
import { ICreatePlayerBill, IPlayerBill, IUpdatePlayerBill } from '@app/infrastructure/interfaces/i-player-bill';

const baseRoute = (id: number) => `players/${id}/bills`;

export async function getPlayerBills(playerId: number, params: IPaginationMeta): Promise<IPaginatedData<IPlayerBill>> {
  return await httpApi.get<IPaginatedData<IPlayerBill>>(baseRoute(playerId), { params }).then(({ data }) => data);
}

export async function getPlayerBillsWithDeleted(
  playerId: number,
  params: IPaginationMeta,
): Promise<IPaginatedData<IPlayerBill>> {
  return await httpApi
    .get<IPaginatedData<IPlayerBill>>(`${baseRoute(playerId)}/with-deleted`, { params })
    .then(({ data }) => data);
}

export async function getPlayerBillById(playerId: number, billId: number): Promise<IPlayerBill> {
  return await httpApi.get<IPlayerBill>(`${baseRoute(playerId)}/${billId}`).then(({ data }) => data);
}

export async function createPlayerBill(playerId: number, data: ICreatePlayerBill): Promise<IPlayerBill> {
  return await httpApi.post<IPlayerBill>(baseRoute(playerId), data).then(({ data }) => data);
}

export async function updatePlayerBill(
  playerId: number,
  billId: number,
  data: IUpdatePlayerBill,
): Promise<IPlayerBill> {
  return await httpApi.put<IPlayerBill>(`${baseRoute(playerId)}/${billId}`, data).then(({ data }) => data);
}

export async function deletePlayerBillById(playerId: number, billId: number): Promise<IPlayerBill> {
  return await httpApi.delete<IPlayerBill>(`${baseRoute(playerId)}/${billId}`).then(({ data }) => data);
}
