import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import BillNameCell from '@app/components/internal-transfers/table/cols/bill-name-cell';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';
import ImageWithPreview from '@app/components/common/image';
import PurposeInternalTransferCell from '@app/components/internal-transfers/table/cols/purpose-cell';
import LoginSenderCell from '@app/components/internal-transfers/table/cols/login-sender-cell';
import LoginReceiverCell from '@app/components/internal-transfers/table/cols/login-receiver-cell';

export const internalTransferTableColumns = (): IModifiedTableColumn<InternalTransfer>[] => {
  const columns: IModifiedTableColumn<InternalTransfer>[] = [
    {
      title: `${i18next.t('labels.date')}`,
      dataIndex: 'createdAt',
      sortField: 'created_at',
      sorter: true,
      render: (_, record) => record.createdAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.sender')}`,
      dataIndex: 'login',
      sortField: 'user.login',
      sorter: true,
      render: (_, data: InternalTransfer) => {
        return <LoginSenderCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.receiver')}`,
      dataIndex: 'receiverLogin',
      sortField: 'internal_transfer_request.user.login',
      sorter: true,
      render: (_, data: InternalTransfer) => {
        return <LoginReceiverCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.check')}`,
      dataIndex: 'name',
      render: (_, data: InternalTransfer) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.summ')}`,
      dataIndex: 'amount',
      sortField: 'amount',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.commission')}`,
      dataIndex: 'commission',
      sortField: 'commission',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.purpose')}`,
      dataIndex: 'purpose',
      render: () => {
        return <PurposeInternalTransferCell />;
      },
    },
    {
      title: `${i18next.t('labels.comment')}`,
      dataIndex: 'description',
    },
    {
      title: `${i18next.t('labels.screen')}`,
      dataIndex: 'image',
      align: 'center',
      render: (_, data: InternalTransfer) => {
        return <ImageWithPreview data={data.image} />;
      },
    },
  ];

  return columns;
};
