/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import useAccessRights from '@app/infrastructure/hooks/use-access-rights';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import BaseTableTitle from '@app/components/common/base-table-title';
import { accessRightsTableColumns } from './cols';
import AccessRightsCreateForm from '../create-form';
import { AccessRight } from '@app/infrastructure/classes/access-right';
import AccessRightsUpdateForm from '../update-form';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { useAppSelector } from '@app/infrastructure/hooks/redux';

const AccessRightsTable: React.FC = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedAccessRight, setSelectedAccessRight] = useState<AccessRight | null>(null);
  const { accessRights, meta, loading, loadAccessRights, deleteAccessRight } = useAccessRights();
  const { t } = useTranslation();

  const modalTitle = selectedAccessRight
    ? `${t('labels.update')} ${selectedAccessRight.name}`
    : t('labels.createAccessRight');

  const hasPagination = meta.total > meta.limit;

  const user = useAppSelector((state) => state.user.user);

  const onReload = async () => {
    await loadAccessRights({ reload: true });
  };

  const onCreate = () => {
    setSelectedAccessRight(null);
    setModalOpened(true);
  };

  const onEdit = async (record: AccessRight) => {
    setSelectedAccessRight(record);
    setModalOpened(true);
  };

  const onDelete = async (record: AccessRight) => {
    await deleteAccessRight(record.id);
    await onReload();
  };

  const onClose = () => {
    setSelectedAccessRight(null);
    setModalOpened(false);
  };

  const onSave = async () => {
    setSelectedAccessRight(null);
    setModalOpened(false);
    await onReload();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AccessRight> | SorterResult<AccessRight>[],
  ) => {
    await loadAccessRights({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<AccessRight>(sorter as IModifiedSorterResult<AccessRight>),
      reload: true,
    });
  };

  useEffect(() => {
    loadAccessRights();
  }, []);

  return (
    <>
      <div>
        <BaseTable
          size="small"
          rowKey="id"
          title={() => {
            return (
              <BaseTableTitle
                title={t('navigation.accessRights')}
                canCreate={user?.canCreate('accessPermission')}
                onCreate={onCreate}
                onReload={onReload}
                size="small"
              />
            );
          }}
          columns={accessRightsTableColumns({
            canEdit: user?.canUpdate('accessPermission'),
            canDelete: user?.fullAccess('accessPermission'),
            onDelete,
            onEdit,
          })}
          dataSource={accessRights}
          pagination={
            !loading &&
            hasPagination && {
              showLessItems: true,
              current: meta.page,
              pageSize: meta.limit,
              total: meta.total,
              showSizeChanger: true,
            }
          }
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1800, y: 500 }}
          bordered
        />
      </div>
      <BaseModal title={modalTitle} open={modalOpened} onCancel={onClose} footer={null} destroyOnClose>
        {selectedAccessRight ? (
          <AccessRightsUpdateForm data={selectedAccessRight} onClose={onClose} onSave={onSave} />
        ) : (
          <AccessRightsCreateForm onClose={onClose} onSave={onSave} />
        )}
      </BaseModal>
    </>
  );
};

export default AccessRightsTable;
