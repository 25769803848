import { ICreatePlayerGame, IPlayerGame, IUpdatePlayerGame } from '@app/infrastructure/interfaces/i-player-game';
import { httpApi } from './http.api';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const baseRoute = '/player-games';
const routeByPlayer = `${baseRoute}/me`;

export async function getPlayerGameBadges(): Promise<number> {
  return await httpApi.get(`${baseRoute}/badge`).then(({ data }) => data);
}

export const getPlayerGames = async (params: IPaginationMeta): Promise<IPaginatedData<IPlayerGame>> => {
  return await httpApi.get<IPaginatedData<IPlayerGame>>(baseRoute, { params }).then(({ data }) => data);
};

export const getPlayerGamesByPlayer = async (params: IPaginationMeta): Promise<IPaginatedData<IPlayerGame>> => {
  return await httpApi.get<IPaginatedData<IPlayerGame>>(routeByPlayer, { params }).then(({ data }) => data);
};

export async function getPlayerGameById(id: number): Promise<IPlayerGame> {
  const { data } = await httpApi.get(`${baseRoute}/${id}`);
  return data;
}

export async function createPlayerGames(playerGame: ICreatePlayerGame): Promise<IPlayerGame> {
  const { data } = await httpApi.post(routeByPlayer, playerGame);
  return data;
}

export async function updatePlayerGameById(id: number, playerGame: IUpdatePlayerGame): Promise<IPlayerGame> {
  const { data } = await httpApi.put(`${baseRoute}/${id}`, playerGame);
  return data;
}

export async function deletePlayerGameById(id: number): Promise<IPlayerGame> {
  const { data } = await httpApi.delete(`${baseRoute}/${id}`);
  return data;
}
