/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import BaseTableTitle from '@app/components/common/base-table-title';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { createSortOrder } from '@app/infrastructure/antd/functions/create-sort-order';
import { IModifiedSorterResult } from '@app/infrastructure/antd/interfaces/i-modified-sorter-result';
import { STATUS_GAME_REQUEST } from '@app/infrastructure/enums/status-game-request.enum';
import usePlayerGames from '@app/infrastructure/hooks/use-player-games';
import { PlayerGame } from '@app/infrastructure/classes/player-game';
import { playerGameWithStatusesTableColumns } from './cols';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';

const PlayerGamesAdminWithStatusTable: React.FC = () => {
  const { playerGames, meta, loading, loadPlayerGames } = usePlayerGames();

  const { key } = useRerender();

  const filterStatus = { statuses: [STATUS_GAME_REQUEST.IN_PACKAGE, STATUS_GAME_REQUEST.PLAYER_GAME] };

  const hasPagination = meta.total > meta.limit;

  const onReload = async () => {
    await loadPlayerGames({ filters: filterStatus, reload: true });
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PlayerGame> | SorterResult<PlayerGame>[],
  ) => {
    await loadPlayerGames({
      ...meta,
      page: pagination.current || meta.page,
      limit: pagination.pageSize || meta.limit,
      sort: createSortOrder<PlayerGame>(sorter as IModifiedSorterResult<PlayerGame>, 'updated_at', 'DESC'),
      reload: true,
      filters: filterStatus,
    });
  };

  useEffect(() => {
    onReload();
  }, [key]);

  return (
    <BaseTable
      rowKey="id"
      title={() => {
        return <BaseTableTitle onReload={onReload} size="small" />;
      }}
      columns={playerGameWithStatusesTableColumns()}
      dataSource={playerGames}
      pagination={
        !loading &&
        hasPagination && {
          current: meta.page,
          pageSize: meta.limit,
          total: meta.total,
          showSizeChanger: true,
        }
      }
      loading={loading}
      onChange={handleTableChange}
      size="small"
      scroll={{ x: 1000 }}
      bordered
    />
  );
};

export default PlayerGamesAdminWithStatusTable;
