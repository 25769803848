import { CANCELED_PACKAGE_STATUS } from '../enums/canceled-package-status';

export const fontStatusColor = {
  [CANCELED_PACKAGE_STATUS.IN_REVIEW]: '#082799',
  [CANCELED_PACKAGE_STATUS.APPROVED]: '#06802b',
};

export const backgroundStatusColor = {
  [CANCELED_PACKAGE_STATUS.IN_REVIEW]: '#E6F7FF',
  [CANCELED_PACKAGE_STATUS.APPROVED]: '#F6FFED',
};

export const borderStatusColor = {
  [CANCELED_PACKAGE_STATUS.IN_REVIEW]: '#082799',
  [CANCELED_PACKAGE_STATUS.APPROVED]: '#06802b',
};
