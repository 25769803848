import React from 'react';
import { BaseSelect, BaseSelectProps, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { useTranslation } from 'react-i18next';
import { MonthDaysType } from '@app/infrastructure/enums/month-days-type';

interface IMonthDaysSelectorProps extends BaseSelectProps {
  date: moment.Moment;
}

const MonthDaysSelector: React.FC<IMonthDaysSelectorProps> = (props) => {
  const { date } = props;

  const { t } = useTranslation();

  const getWeekNumberInMonth = (date: moment.Moment): number => {
    const startOfMonth = date.clone().startOf('month');
    const weekDifference = date.diff(startOfMonth, 'weeks');
    return weekDifference + 1;
  };

  const weekNumber = getWeekNumberInMonth(date);
  const isLastWeek = weekNumber === 5;

  const isLastWeekdayOfMonth = (date: moment.Moment): boolean => {
    const currentMonth = date.month();
    const nextWeek = date.clone().add(1, 'week');

    return currentMonth !== nextWeek.month();
  };

  const getMonthDay = (date: moment.Moment): string => {
    const monthDay = date.format('D');

    return t('calendar.monthlyMonthDay', {
      monthDay,
    });
  };

  const getWeekdayAndWeek = (date: moment.Moment, isLast = false): string => {
    const weekday = date.format('dddd');

    return t('calendar.monthlyWeekDay', {
      weekNumber: isLastWeek || isLast ? t('calendar.last') : `${weekNumber}`,
      weekday,
    });
  };

  const options = [
    {
      key: MonthDaysType.MonthDay,
      label: getMonthDay(date),
      visible: true,
    },
    {
      key: MonthDaysType.WeekDay,
      label: getWeekdayAndWeek(date),
      visible: true,
    },
    {
      key: MonthDaysType.LastWeekDay,
      label: getWeekdayAndWeek(date, true),
      visible: !isLastWeek && isLastWeekdayOfMonth(date),
    },
  ];

  return (
    <BaseSelect {...props}>
      {options.map(
        (el) =>
          el.visible && (
            <Option key={el.key} value={el.key}>
              {el.label}
            </Option>
          ),
      )}
    </BaseSelect>
  );
};

export default MonthDaysSelector;
