import { IAnalytics, ICreateAnalytics, IUpdateAnalytics } from '@app/infrastructure/interfaces/i-analytics';
import { httpApi } from './http.api';
import { IPaginatedData, IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';

const baseRoute = '/analytics';
const routeByPlayer = (id: number) => `players/${id}${baseRoute}`;

export const getAnalyticsByPlayer = async (
  playerId: number,
  params: IPaginationMeta,
): Promise<IPaginatedData<IAnalytics>> => {
  return await httpApi.get<IPaginatedData<IAnalytics>>(routeByPlayer(playerId), { params }).then(({ data }) => data);
};

export async function getAnalyticsById(id: number): Promise<IAnalytics> {
  const { data } = await httpApi.get(`${baseRoute}/${id}`);
  return data;
}

export async function addAnalytics(playerId: number, analytics: ICreateAnalytics): Promise<IAnalytics> {
  const { data } = await httpApi.post(routeByPlayer(playerId), analytics);
  return data;
}

export async function updateAnalyticsById(id: number, analytics: IUpdateAnalytics): Promise<IAnalytics> {
  const { data } = await httpApi.put(`${baseRoute}/${id}`, analytics);
  return data;
}

export async function deleteAnalyticsById(id: number): Promise<IAnalytics> {
  const { data } = await httpApi.delete(`${baseRoute}/${id}`);
  return data;
}
