import React from 'react';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import useCurrencies from '@app/infrastructure/hooks/use-currencies';

const CurrenciesSelectBox: React.FC = (props) => {
  const { currencies, loadCurrencies, loading } = useCurrencies();

  return (
    <BaseSelect onFocus={() => loadCurrencies()} loading={loading} {...props}>
      {currencies.map((currency) => (
        <Option key={currency.id} value={currency.id}>
          {currency.name}
        </Option>
      ))}
    </BaseSelect>
  );
};

export default CurrenciesSelectBox;
