import React from 'react';
import { FlcraftPlayerStatistic } from '@app/infrastructure/classes/flcraft';

interface IPlayerLoginCellProps {
  data?: FlcraftPlayerStatistic;
}

const PlayerRankCell: React.FC<IPlayerLoginCellProps> = ({ data }) => {
  return <>{data?.player && <div>{data?.player.rank?.name}</div>}</>;
};

export default PlayerRankCell;
