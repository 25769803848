import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { WithChildrenProps } from '@app/types/general-types';
import { ROUTES } from '@app/infrastructure/consts/routes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  const user = useAppSelector((state) => state.user.user);
  return token && user ? <>{children}</> : <Navigate to={ROUTES.LOGIN} replace />;
};

export default RequireAuth;
