import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;

export const BillsSpace = styled.div`
  min-width: 428px;
  max-width: 428px;
`;

export const FoundSpace = styled.div`
  min-width: 320px;
  max-width: 320px;
`;

export const PlayerFundsSpace = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const AddPlayerBillFormItem = styled(BaseForm.Item)`
  margin-bottom: 0;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const AddPlayerBillButton = styled(BaseButton)`
  margin-bottom: 5px;
  width: 100%;
  justify-content: start;
`;

export const AddPlyerBillSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlayerDashboardCardSpace = styled(BaseSpace)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-space-item {
    width: 100%;
  }
`;
