/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { uniqBy } from 'lodash';
import type { SelectProps } from 'antd/es/select';
import { IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { BaseSpin } from '../BaseSpin/BaseSpin';
import { BaseAutoComplete } from '../BaseAutoComplete/BaseAutoComplete';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../BaseTypography/BaseTypography';

export interface DebounceSelectProps<ValueType>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string, meta: IPaginationMeta) => Promise<ValueType[]>;
  debounceTimeout?: number;
  meta: IPaginationMeta;
  children: React.ReactNode;
}

interface IDebounceSelectValueType {
  key?: string | number;
  label: React.ReactNode;
  value: any;
  record: any;
}

const DebounceInput = <ValueType extends IDebounceSelectValueType>(props: DebounceSelectProps<ValueType>) => {
  const { fetchOptions, children, meta, debounceTimeout = 400, ...settings } = props;

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [fetching, setFetching] = useState(false);
  const [allItems, setAllItems] = useState<ValueType[]>([]);
  const [items, setItems] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchValue: string, meta: IPaginationMeta, loadNextPage = false, focus = false) => {
      if (focus && allItems.length) {
        setItems(allItems);
        return;
      }

      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setFetching(true);
      fetchOptions(searchValue, meta).then((newItems) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setAllItems(uniqBy([...allItems, ...newItems], 'label'));
        if (loadNextPage) {
          setItems([...items, ...newItems]);
        } else {
          setItems(newItems);
        }
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onScroll = (event: any) => {
    const target = event.target;
    if (!fetching && items.length < meta.total && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      debounceFetcher(searchValue, { ...meta, page: meta.page + 1 }, true);
    }
  };

  return (
    <BaseAutoComplete
      labelInValue
      filterOption={false}
      onSearch={(value) => {
        setSearchValue(value);
        debounceFetcher(value, {
          ...meta,
          page: 1,
        });
      }}
      onFocus={() => {
        setSearchValue('');
        debounceFetcher('', meta, false, true);
      }}
      notFoundContent={<BaseTypography>{t('labels.noData')}</BaseTypography>}
      {...settings}
      options={items}
      onPopupScroll={onScroll}
      dropdownRender={(menu) => <BaseSpin spinning={fetching}>{menu}</BaseSpin>}
    >
      {children}
    </BaseAutoComplete>
  );
};

export default DebounceInput;
