/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';

interface IBadgeContext {
  key: number;
  rerenderBadge: () => void;
}

const badgeContext = React.createContext<IBadgeContext>({
  key: Date.now(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  rerenderBadge: () => {},
});

export const useBadge = () => useContext(badgeContext);

interface IBadgeProviderProps {
  children: React.ReactNode;
}

const BadgeProvider: React.FC<IBadgeProviderProps> = (props) => {
  const { children } = props;

  const [key, setKey] = useState<number>(Date.now());

  const rerenderBadge = () => {
    setKey(Date.now());
  };

  return (
    <badgeContext.Provider
      value={{
        key,
        rerenderBadge,
      }}
    >
      {children}
    </badgeContext.Provider>
  );
};

export default BadgeProvider;
