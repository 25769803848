import React from 'react';
import { PlayerGame } from '@app/infrastructure/classes/player-game';

interface BillNameCellProps {
  data: PlayerGame;
}

const BillNameCell: React.FC<BillNameCellProps> = ({ data }) => {
  return <span>{data.bill.name}</span>;
};

export default BillNameCell;
