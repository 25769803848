/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { levelTableColumns } from './cols';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import useLevels from '@app/infrastructure/hooks/use-levels';
import { Level } from '@app/infrastructure/classes/level';
import { EditableCell } from './cols/editable-cell';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './styles';
import TournamentLevelsTableHeader from './header';

const TournamentLevelsTable: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const [editingId, setEditingId] = useState<number>(0);
  const { levels, loadLevels, deleteLevel, updateLevel, loading } = useLevels();
  const [form] = BaseForm.useForm();

  const isEditing = (data: Level) => data.id === editingId;

  const reload = async () => {
    await loadLevels({ reload: true });
  };

  const onDelete = async (record: Level) => {
    await deleteLevel(record.id);
    reload();
  };

  const onClose = () => {
    setEditingId(0);
  };

  const onEdit = (record: Level) => {
    if (!isEditing(record)) {
      form.setFieldsValue(record);
      setEditingId(record.id);
    }
  };

  const onSaved = () => {
    reload();
  };

  const onSave = () => {
    form.submit();
  };
  const onFinish = async (values: Level) => {
    const level = levels.find((el) => el.id === editingId);
    if (level) {
      await updateLevel(editingId, {
        name: values.name,
        description: values.description,
      });
      reload();
      setEditingId(0);
    }
  };

  useEffect(() => {
    loadLevels();
  }, []);

  return (
    <BaseForm form={form} component={false} onFinish={onFinish} autoComplete="off">
      <S.StyledTable
        rowKey="id"
        size="small"
        dataSource={levels}
        title={() => user?.canCreate('limitPermission') && <TournamentLevelsTableHeader onSave={onSaved} />}
        loading={loading}
        columns={levelTableColumns({
          isEditing,
          canEdit: user?.canUpdate('limitPermission'),
          canDelete: user?.fullAccess('limitPermission'),
          onDelete,
          onEdit,
          onSave,
          onClose,
        })}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        pagination={false}
      />
    </BaseForm>
  );
};

export default TournamentLevelsTable;
