import React from 'react';
import { BaseSelect, BaseSelectProps, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { permissionsDataSource } from '@app/infrastructure/data-sources/permissions';
import { useTranslation } from 'react-i18next';

interface IPermissionSelectProps extends BaseSelectProps {
  title?: string;
}

const PermissionSelect: React.FC<IPermissionSelectProps> = (props) => {
  const { title } = props;
  const { t } = useTranslation();
  const permissions = permissionsDataSource();
  return (
    <div>
      {title && <p style={{ margin: '10px 0 5px 0' }}>{title}</p>}
      <BaseSelect placeholder={t('permissions.noPermissions')} width={`100%`} allowClear {...props}>
        <Option value={null}>{t(`permissions.noPermissions`)}</Option>
        {permissions.map((el) => (
          <Option key={el.id} value={el.id}>
            {el.label}
          </Option>
        ))}
      </BaseSelect>
    </div>
  );
};

export default PermissionSelect;
