/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { IPaginationMeta } from '@app/infrastructure/interfaces/i-pagination';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Tournament } from '@app/infrastructure/classes/tournament';
import useTournaments from '@app/infrastructure/hooks/use-tournaments';
import { ITournamentFilters } from '@app/infrastructure/interfaces/i-tournament';
import DebounceInput from '@app/components/common/debounce-input';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputProps } from 'antd';

export interface ITournamentSelectBoxItemProps {
  key: any;
  label: React.ReactNode;
  value: any;
  record: Tournament;
}

interface ITournamentSelectBoxProps extends InputProps {
  filters?: ITournamentFilters;
  width?: string;
  size?: SizeType;
  saveState?: boolean;
  placeholder?: string;
}

const TournamentAutocompleteInput: React.FC<ITournamentSelectBoxProps> = (props) => {
  const { filters = {} } = props;
  const { loadTournaments, meta } = useTournaments({
    meta: {
      limit: 10,
      filters,
    },
  });

  const inputRef = useRef<any>(null);

  const searchTournaments = async (searchValue: string, meta: IPaginationMeta) => {
    const tournaments = await loadTournaments({
      ...meta,
      search: ['name', searchValue],
      reload: true,
      filters,
    });
    if (tournaments)
      return tournaments.map((el) => {
        return {
          key: el.id,
          label: el.name,
          value: el.name,
          record: el,
        };
      });

    return [];
  };

  const onSelect = (value: any) => {
    if (props.onChange) {
      props.onChange({
        target: {
          value: value.label,
        },
      } as any);
    }
  };

  return (
    <DebounceInput
      key={JSON.stringify(filters)}
      fetchOptions={searchTournaments}
      meta={meta}
      onSelect={onSelect}
      value={
        {
          value: props.value,
        } as any
      }
    >
      <BaseInput ref={inputRef} {...props} allowClear />
    </DebounceInput>
  );
};

export default TournamentAutocompleteInput;
