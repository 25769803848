import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

interface IValueCellProps {
  value?: string;
}

const ValueCell: React.FC<IValueCellProps> = (props) => {
  const { value } = props;
  const title = value ? value : '-';
  return <BaseTypography title={title}>{title}</BaseTypography>;
};

export default ValueCell;
