import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Player } from '@app/infrastructure/classes/player';
import { useTranslation } from 'react-i18next';

interface IPackageTermsCellProps {
  data: Player;
}

const PackageTermsCell: React.FC<IPackageTermsCellProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const profitPackage = data.profitPackages?.find((profitPackage) => profitPackage.canceledDate === null);

  return (
    <div>
      <BaseTypography>{profitPackage ? profitPackage.packageTerms : t('labels.noProfitPackage')}</BaseTypography>
    </div>
  );
};

export default PackageTermsCell;
