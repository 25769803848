import React from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '@app/components/error';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import error403 from '@app/assets/images/403.svg';

const Error403Page: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.forbiddenError')}</PageTitle>
      <Error img={error403} msg={t('error403.forbidden')} />
    </>
  );
};

export default Error403Page;
