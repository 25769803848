/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export default function useEventListener(eventType: any, callback: any, element = window) {
  useEffect(() => {
    element.addEventListener(eventType, callback);

    return () => element.removeEventListener(eventType, callback);
  }, [eventType, element, callback]);
}
