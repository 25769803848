import React from 'react';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { useTranslation } from 'react-i18next';
import { statusInternalTransferDataSource } from '@app/infrastructure/data-sources/status-internal-transfer';
import { STATUS_INTERNAL_TRANSFER } from '@app/infrastructure/enums/status-internal-transfer.enum';
import { backgroundStatusColor, fontStatusColor } from '@app/infrastructure/consts/status-internal-transfer-colors';

interface StatusInternalTransferRequestSelectProps {
  disabledValues?: STATUS_INTERNAL_TRANSFER[];
  placeholder?: string;
}

const StatusInternalTransferRequestSelect: React.FC<StatusInternalTransferRequestSelectProps> = (props) => {
  const { t } = useTranslation();
  const { placeholder = t('labels.selectFromList'), disabledValues = [], ...args } = props;

  return (
    <BaseSelect placeholder={placeholder} {...args}>
      {statusInternalTransferDataSource().map((item) => {
        if (item.id !== STATUS_INTERNAL_TRANSFER.CLOSED) {
          return (
            <Option
              value={item.id}
              key={item.id}
              disabled={disabledValues.some((el) => el === item.id)}
              style={{
                color: fontStatusColor[item.id],
                backgroundColor: backgroundStatusColor[item.id],
              }}
            >
              {item.label}
            </Option>
          );
        }
      })}
    </BaseSelect>
  );
};

export default StatusInternalTransferRequestSelect;
