import { Bill } from '@app/infrastructure/classes/bill';
import React from 'react';

interface NameCellProps {
  data: Bill;
}

const NameCell: React.FC<NameCellProps> = ({ data }) => {
  return (
    <span>
      {data.currency.name} {data.name}
    </span>
  );
};

export default NameCell;
