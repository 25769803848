import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { PlayersStatistic } from '@app/infrastructure/classes/player';

const PlayerStatisticView: React.FC = () => {
  const [statistic, setStatistic] = useState<PlayersStatistic | undefined>();
  const { loading, loadPlayersStatistic } = usePlayers();
  const { t } = useTranslation();

  useEffect(() => {
    loadPlayersStatistic().then((data) => setStatistic(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseSpin spinning={loading}>
      <BaseRow gutter={[15, 10]} justify="space-around">
        <BaseCol>
          <BaseTypography.Text>{t('labels.fundsPart')}</BaseTypography.Text>
          <S.Title level={3}>
            {CURRENCIES.DOLLAR} {statistic?.fundsPartSumm}
          </S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.fundMoney')}</BaseTypography.Text>
          <S.Title level={3}>
            {CURRENCIES.DOLLAR} {statistic?.fundMoneySumm}
          </S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.overMoney')}</BaseTypography.Text>
          <S.Title level={3}>
            {CURRENCIES.DOLLAR} {statistic?.overMoneySumm}
          </S.Title>
        </BaseCol>
      </BaseRow>
    </BaseSpin>
  );
};

export default PlayerStatisticView;
