import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { Rank } from '@app/infrastructure/classes/rank';
import LimitsCell from './limits-cell';

interface IRankColumnsProps {
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: Rank) => void;
  onDelete: (data: Rank) => void;
  onSelectLimit: (data: Rank) => void;
}

export const ranksTableColumns = ({
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  onSelectLimit,
}: IRankColumnsProps): IModifiedTableColumn<Rank>[] => {
  const columns: IModifiedTableColumn<Rank>[] = [
    {
      title: `${i18next.t('labels.contentName')}`,
      dataIndex: 'name',
      sortField: 'name',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.fundLimit')}`,
      dataIndex: 'fundLimit',
      sortField: 'fund_limit',
      sorter: true,
    },
    {
      title: `${i18next.t('labels.reqLimit')}`,
      dataIndex: 'reqLimit',
      sortField: 'req_limit',
      sorter: true,
    },
    {
      title: `${i18next.t('controls.limits')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '100px',
      align: 'center',
      render: (_, data: Rank) => (
        <LimitsCell
          onClick={() => {
            onSelectLimit(data);
          }}
        />
      ),
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '150px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          canEdit={canEdit}
          canDelete={canDelete}
          onDelete={() => {
            onDelete(record);
          }}
          onEdit={() => {
            onEdit(record);
          }}
          size="small"
        />
      ),
    });
  }

  return columns;
};
