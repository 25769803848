import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BaseBreadcrumb } from '@app/components/common/BaseBreadcrumb/BaseBreadcrumb';
import * as S from './styles';
import { ROUTES } from '@app/infrastructure/consts/routes';

interface BreadcrumbProps {
  name: string;
  rank: number | undefined;
}

const BreadcrumbPlayerDashboard: React.FC<BreadcrumbProps> = ({ name, rank }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const rankName = rank ? `${t('labels.rank').toLowerCase()}: ${rank}` : t('labels.rankNotDefined');

  return (
    <BaseBreadcrumb>
      <S.PlayersBreadCrumbItem onClick={() => navigate(ROUTES.PLAYERS)}>
        {t('navigation.players')}
      </S.PlayersBreadCrumbItem>
      <BaseBreadcrumb.Item>{`${name} (${rankName})`}</BaseBreadcrumb.Item>
    </BaseBreadcrumb>
  );
};

export default BreadcrumbPlayerDashboard;
