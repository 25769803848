import { ICurrency } from '../interfaces/i-currency';

export class Currency {
  id: number;
  name: string;
  rate: number;
  code: string;

  constructor(data: ICurrency) {
    this.id = data.id;
    this.name = data.name;
    this.rate = data.rate;
    this.code = data.code;
  }
}
