import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TournamentLevelsTable from '../levels/table';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { ROLES } from '@app/infrastructure/enums/roles';
import LimitsTable from '@app/components/limits/table';

const TournamentLimits: React.FC = () => {
  const { t } = useTranslation();

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user.user);
  const rank = user?.role === ROLES.PLAYER ? user.player?.rank : undefined;

  const onClose = () => {
    setModalOpened(false);
  };

  return (
    <div>
      <BaseButton size="small" onClick={() => setModalOpened(true)}>
        {t('controls.limits')}
      </BaseButton>
      <BaseModal
        width={rank ? 1000 : 600}
        title={t('controls.limits')}
        open={modalOpened}
        onCancel={onClose}
        footer={null}
      >
        <BaseRow gutter={10} align={'top'}>
          {rank && (
            <BaseCol span={16}>
              <LimitsTable rank={rank} />
            </BaseCol>
          )}
          <BaseCol span={rank ? 8 : 24}>
            <TournamentLevelsTable />
          </BaseCol>
        </BaseRow>
      </BaseModal>
    </div>
  );
};

export default TournamentLimits;
