import React from 'react';
import * as S from './styles';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import useLevels from '@app/infrastructure/hooks/use-levels';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';

interface IFormValue {
  name: string;
  description: string;
}

interface ILevelCreateRowProps {
  onClose: () => void;
  onSaved: () => void;
}

const LevelCreateRow: React.FC<ILevelCreateRowProps> = (props) => {
  const { onClose, onSaved } = props;

  const [form] = BaseForm.useForm();
  const { createLevel } = useLevels();

  const onSave = async () => {
    form.submit();
  };

  const onFinish = async (values: IFormValue) => {
    await createLevel({
      name: values.name,
      description: values.description,
    });
    onSaved();
  };

  return (
    <BaseForm name="add-level-form" autoComplete="off" form={form} onFinish={onFinish} size="small">
      <BaseRow gutter={10} align="middle" justify={'space-between'}>
        <BaseCol span={9}>
          <S.BaseFormItem name="name">
            <BaseInput />
          </S.BaseFormItem>
        </BaseCol>
        <BaseCol span={9}>
          <S.BaseFormItem name="description">
            <BaseInput />
          </S.BaseFormItem>
        </BaseCol>
        <BaseCol span={4}>
          <BaseRow gutter={2} align="middle">
            <BaseCol>
              <BaseButton size="small" type="text" icon={<CheckOutlined />} onClick={onSave} />
            </BaseCol>
            <BaseCol>
              <BaseButton size="small" type="text" icon={<CloseOutlined />} onClick={onClose} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  );
};

export default LevelCreateRow;
