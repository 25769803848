import React from 'react';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import StatusButton from './status-button';
import { STATUS_GAME_REQUEST } from '@app/infrastructure/enums/status-game-request.enum';
import { PlayerGame } from '@app/infrastructure/classes/player-game';
import usePlayerGames from '@app/infrastructure/hooks/use-player-games';

interface StatusCellProps {
  data: PlayerGame;
  onSave: () => void;
}

const StatusCell: React.FC<StatusCellProps> = (props) => {
  const { data, onSave } = props;

  const { updatePlayerGame, loading } = usePlayerGames();

  const onFinish = async (status: STATUS_GAME_REQUEST) => {
    updatePlayerGame(data.id, { status }).then(() => {
      if (onSave) onSave();
    });
  };
  return (
    <BaseRow justify="center" align="middle" gutter={[0, 7]}>
      <StatusButton statusGameRequest={STATUS_GAME_REQUEST.IN_PACKAGE} onChangeStatus={onFinish} loading={loading} />
      <StatusButton statusGameRequest={STATUS_GAME_REQUEST.PLAYER_GAME} onChangeStatus={onFinish} loading={loading} />
    </BaseRow>
  );
};

export default StatusCell;
