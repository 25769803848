import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import i18next from 'i18next';
import { IUseTableColumnSearch } from '@app/infrastructure/antd/hooks/use-table-column-search';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import BillNameCell from '@app/components/internal-transfer-requests/table/cols/bill-name-cell';
import { InternalTransferRequest } from '@app/infrastructure/classes/internal-transfer-request';
import SendCell from './send-cell';
import { Player } from '@app/infrastructure/classes/player';
import LoginCell from './login-cell';

interface IApprovedInternalTransferRequestColumnsProps {
  onSend: (data: InternalTransferRequest) => void;
  player: Player;
  columnSearchProps?: IUseTableColumnSearch['columnSearchProps'];
}

export const approvedInternalTransferRequestTableColumns = ({
  onSend,
  player,
}: IApprovedInternalTransferRequestColumnsProps): IModifiedTableColumn<InternalTransferRequest>[] => {
  const columns: IModifiedTableColumn<InternalTransferRequest>[] = [
    {
      title: `${i18next.t('labels.login')}`,
      dataIndex: 'login',
      sortField: 'players_bill.player.user.login',
      sorter: true,
      render: (_, data: InternalTransferRequest) => {
        return <LoginCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.check')}`,
      dataIndex: 'name',
      sortField: 'players_bill.bill.name',
      sorter: true,
      render: (_, data: InternalTransferRequest) => {
        return <BillNameCell data={data} />;
      },
    },
    {
      title: `${i18next.t('labels.summ')}`,
      dataIndex: 'leftOver',
    },
    {
      title: `${i18next.t('labels.createDate')}`,
      dataIndex: 'createdAt',
      sortField: 'created_at',
      sorter: true,
      render: (_, record) => record.createdAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('labels.updateDate')}`,
      dataIndex: 'updatedAt',
      sortField: 'updated_at',
      sorter: true,
      render: (_, record) => record.updatedAt.format(MomentFormats['DD.MM.YYYY HH:mm']),
    },
    {
      title: `${i18next.t('controls.send')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '120px',
      align: 'center',
      render: (_, data: InternalTransferRequest) => (
        <SendCell
          data={data}
          player={player}
          onSend={() => {
            onSend(data);
          }}
        />
      ),
    },
  ];

  return columns;
};
