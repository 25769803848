import i18next from 'i18next';
import { Permissions } from '../enums/permissions';

export const permissionsDataSource = (): {
  id: Permissions;
  label: string;
}[] => [
  {
    id: Permissions.Read,
    label: i18next.t(`permissions.${Permissions.Read}`),
  },
  {
    id: Permissions.Create,
    label: i18next.t(`permissions.${Permissions.Create}`),
  },
  {
    id: Permissions.Update,
    label: i18next.t(`permissions.${Permissions.Update}`),
  },
  {
    id: Permissions.FullAccess,
    label: i18next.t(`permissions.${Permissions.FullAccess}`),
  },
];
