/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import TournamentPeriodSelect from '../../period-select-box';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInstance } from 'antd';
import TournamentRecurrenceCalendar from '../../calendar';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';

interface IStepProps {
  form: FormInstance;
  isTypeTournamentDisabled?: boolean;
}

export const EventTimeStep: React.FC<IStepProps> = (props) => {
  const { form, isTypeTournamentDisabled = false } = props;
  const { t } = useTranslation();
  const { rerender } = useRerender();

  const onTournamentTypeChange = () => {
    form.setFieldValue('startDate', undefined);
    form.setFieldValue('startEndDate', undefined);
    rerender();
  };

  useEffect(() => {
    rerender();
  }, []);

  return (
    <>
      <BaseForm.Item name="periodType" label={t('labels.tournamentType')}>
        <TournamentPeriodSelect onChange={onTournamentTypeChange} disabled={isTypeTournamentDisabled} />
      </BaseForm.Item>
      <TournamentRecurrenceCalendar form={form} />
    </>
  );
};

export default EventTimeStep;
