import React, { useState } from 'react';
import FlcraftPlayerStatisticsTable from '../players-table';
import FlcraftTable from '../table';
import { IFlcraftFilters } from '@app/infrastructure/interfaces/i-flcraft';
import { getFlcraftFilters } from '@app/infrastructure/services/filters-storage.service';

const FlcraftMergedTable: React.FC = () => {
  const [filters, setFilters] = useState<IFlcraftFilters>(getFlcraftFilters());

  const [ids, setIds] = useState<number[]>([]);

  const onIdsChange = (ids: number[]) => {
    setIds(ids);
  };

  const handleStatisticFilterChange = (data: IFlcraftFilters) => {
    setFilters(data);
  };

  return (
    <div>
      <FlcraftPlayerStatisticsTable flcraftFilters={filters} onSelect={onIdsChange} />
      <FlcraftTable ids={ids} showStatistic={false} canUpload={false} onFilterChange={handleStatisticFilterChange} />
    </div>
  );
};

export default FlcraftMergedTable;
