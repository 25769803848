import { useState } from 'react';
import { createImages } from '@app/api/images';
import { Image } from '../classes/image';
import { HTTP_STATUSES } from '../consts/http-statuses';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';

type UseImages = {
  createImage: (data: FormData) => Promise<Image | undefined>;
  loading: boolean;
};

const useImages = (): UseImages => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const createImage = async (data: FormData) => {
    setLoading(true);
    const result = await createImages(data).catch((err) => {
      if (err.options.statusCode === HTTP_STATUSES.UNPROCESSABLE_ENTITY) {
        return notificationController.error({
          message: t('messages.notPngJpegFile'),
        });
      } else {
        notificationController.error({ message: err.message });
      }
    });

    setLoading(false);
    if (result) {
      return new Image(result);
    }
  };

  return {
    loading,
    createImage,
  };
};

export default useImages;
