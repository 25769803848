import React from 'react';
import * as S from '../styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import PermissionSelect from '@app/components/permission/select';
import { useTranslation } from 'react-i18next';
import useAccessRights from '@app/infrastructure/hooks/use-access-rights';
import { AccessRight } from '@app/infrastructure/classes/access-right';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface IAccessRightsUpdateFormProps {
  data: AccessRight;
  onClose?: () => void;
  onSave?: () => void;
}

const AccessRightsUpdateForm: React.FC<IAccessRightsUpdateFormProps> = (props) => {
  const { data, onClose, onSave } = props;
  const { t } = useTranslation();

  const { updateAccessRight } = useAccessRights();

  const onFinish = async (values: AccessRight) => {
    if (values) {
      await updateAccessRight(data.id, values);
      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    if (onClose) onClose();
  };

  return (
    <BaseButtonsForm
      key={data.id}
      size="small"
      isFieldsChanged={true}
      initialValues={data}
      onFinish={onFinish}
      onClose={onCancel}
    >
      <BaseButtonsForm.Item name="name" label={t('labels.name')} rules={[{ required: true }]}>
        <BaseInput />
      </BaseButtonsForm.Item>
      <BaseRow justify="space-between" align="middle">
        <S.PermissionItem name="accessPermission" label={t('labels.accessRights')}>
          <PermissionSelect />
        </S.PermissionItem>
        <S.PermissionItem name="userPermission" label={t('labels.users')}>
          <PermissionSelect />
        </S.PermissionItem>
      </BaseRow>
      <BaseRow justify="space-between" align="middle">
        <S.PermissionItem name="playerPermission" label={t('labels.players')}>
          <PermissionSelect />
        </S.PermissionItem>
        <S.PermissionItem name="billPermission" label={t('labels.bills')}>
          <PermissionSelect />
        </S.PermissionItem>
      </BaseRow>
      <BaseRow justify="space-between" align="middle">
        <S.PermissionItem name="rankPermission" label={t('labels.ranks')}>
          <PermissionSelect />
        </S.PermissionItem>
        <S.PermissionItem name="flcraftPermission" label={t('labels.flcraft')}>
          <PermissionSelect />
        </S.PermissionItem>
      </BaseRow>
      <BaseRow justify="space-between" align="middle">
        <S.PermissionItem name="personalTransferPermission" label={t('labels.personalTransfers')}>
          <PermissionSelect />
        </S.PermissionItem>
        <S.PermissionItem name="internalTransferPermission" label={t('labels.internalTransfers')}>
          <PermissionSelect />
        </S.PermissionItem>
      </BaseRow>
      <BaseRow justify="space-between" align="middle">
        <S.PermissionItem name="analyticsPermission" label={t('labels.analytics')}>
          <PermissionSelect />
        </S.PermissionItem>
        <S.PermissionItem name="playerGamePermission" label={t('labels.playerGame')}>
          <PermissionSelect />
        </S.PermissionItem>
      </BaseRow>
      <BaseRow justify="space-between" align="middle">
        <S.PermissionItem name="tournamentPermission" label={t('labels.schedule')}>
          <PermissionSelect />
        </S.PermissionItem>
        <S.PermissionItem name="limitPermission" label={t('labels.limits')}>
          <PermissionSelect />
        </S.PermissionItem>
      </BaseRow>
      <BaseRow justify="space-between" align="middle">
        <S.PermissionItem name="profitPackagePermission" label={t('labels.profitPackage')}>
          <PermissionSelect />
        </S.PermissionItem>
        <S.PermissionItem name="overMoneyPermission" label={t('labels.overMoney')}>
          <PermissionSelect />
        </S.PermissionItem>
      </BaseRow>
    </BaseButtonsForm>
  );
};

export default AccessRightsUpdateForm;
