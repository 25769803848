import i18next from 'i18next';
import BaseActionsCell from '@app/components/common/base-actions-cell';
import { IModifiedTableColumn } from '@app/infrastructure/antd/interfaces/i-modified-table-column';
import { Level } from '@app/infrastructure/classes/level';

interface ILevelTableColumnsProps {
  isEditing: (data: Level) => boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  onEdit: (data: Level) => void;
  onDelete: (data: Level) => void;
  onSave: () => void;
  onClose: () => void;
}

export const levelTableColumns = ({
  isEditing,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  onSave,
  onClose,
}: ILevelTableColumnsProps): IModifiedTableColumn<Level>[] => {
  const columns: IModifiedTableColumn<Level>[] = [
    {
      title: `${i18next.t('labels.type')}`,
      dataIndex: 'name',
      editable: false,
      width: '50px',
    },
    {
      title: `${i18next.t('labels.description')}`,
      dataIndex: 'description',
      editable: true,
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: `${i18next.t('labels.actions')}`,
      dataIndex: 'actions',
      sorter: false,
      width: '100px',
      align: 'center',
      render: (_, record) => (
        <BaseActionsCell
          isEditing={isEditing(record)}
          canEdit={canEdit}
          canDelete={canDelete}
          size="small"
          onDelete={() => {
            onDelete(record);
          }}
          onEdit={() => {
            onEdit(record);
          }}
          onSave={() => {
            onSave();
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (data: Level) => ({
        record: data,
        dataIndex: col.dataIndex,
        title: `${col.title}`,
        editing: isEditing(data),
      }),
    };
  });

  return mergedColumns;
};
