import { IImage } from '../interfaces/i-image';

export class Image {
  id: number;
  folder: string;
  originalUrl: string;
  optimizedUrl: string;

  constructor(data: IImage) {
    this.id = data.id;
    this.folder = data.folder;
    this.originalUrl = data.original_url;
    this.optimizedUrl = data.optimized_url;
  }
}
