import { useState } from 'react';
import { getCurrencies } from '@app/api/currencies';
import { ILoadProps } from './interfaces/i-load-props';
import { Currency } from '../classes/currency';

type UseCurrencies = {
  currencies: Currency[];
  loadCurrencies: (props?: ILoadProps) => Promise<Currency[] | undefined>;
  loading: boolean;
};

const useCurrencies = (): UseCurrencies => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const loadCurrencies = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const results = await getCurrencies();
    let data = undefined;
    if (results) {
      data = results.map((el) => new Currency(el));
      setCurrencies(data);
    }
    setLoading(false);
    return data;
  };

  return {
    currencies,
    loading,
    loadCurrencies,
  };
};

export default useCurrencies;
