import React from 'react';
import * as S from './styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FlcraftPlayerStatistic } from '@app/infrastructure/classes/flcraft';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';

interface IFlcraftStatisticViewProps {
  data: FlcraftPlayerStatistic;
  loading?: boolean;
}

const FlcraftStatisticView: React.FC<IFlcraftStatisticViewProps> = (props) => {
  const { data, loading = false } = props;
  const { t } = useTranslation();

  return (
    <BaseSpin spinning={loading}>
      <BaseRow gutter={25} justify="space-around">
        <BaseCol>
          <BaseTypography.Text>{t('labels.totalEntries')}</BaseTypography.Text>
          <S.Title level={3}> {data.totalEntries}</S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.uniqueEntries')}</BaseTypography.Text>
          <S.Title level={3}> {data.uniqueEntries}</S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.profit')}</BaseTypography.Text>
          <S.Title level={3}>
            {CURRENCIES.DOLLAR} {data.profit}
          </S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.abi')}</BaseTypography.Text>
          <S.Title level={3}>
            {CURRENCIES.DOLLAR} {data.abi}
          </S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.roi')}</BaseTypography.Text>
          <S.Title level={3}>{data.roi} %</S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.totalBuyin')}</BaseTypography.Text>
          <S.Title level={3}>
            {CURRENCIES.DOLLAR} {data.totalBuyin}
          </S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.reentry')}</BaseTypography.Text>
          <S.Title level={3}> {data.totalReentry}</S.Title>
        </BaseCol>
        <BaseCol>
          <BaseTypography.Text>{t('labels.reentry')} %</BaseTypography.Text>
          <S.Title level={3}> {data.reentryPercent} %</S.Title>
        </BaseCol>
      </BaseRow>
    </BaseSpin>
  );
};

export default FlcraftStatisticView;
