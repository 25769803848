import styled from 'styled-components';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export const BaseFormItem = styled(BaseButtonsForm.Item)`
  .ant-col.ant-form-item-label {
    .ant-form-item-required::before {
      display: none;
    }
  }
`;
