import React from 'react';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { purposePersonalTransferDataSource } from '@app/infrastructure/data-sources/purpose-personal-transfer';
import { PURPOSE_PERSONAL_TRANSFER } from '@app/infrastructure/enums/purpose-personal-transfer.enum';
import { useTranslation } from 'react-i18next';

interface PurposePersonalTransferSelectProps {
  disabledValues?: PURPOSE_PERSONAL_TRANSFER[];
  placeholder?: string;
}

const PurposePersonalTransferSelect: React.FC<PurposePersonalTransferSelectProps> = (props) => {
  const { t } = useTranslation();
  const { placeholder = t('labels.selectFromList'), disabledValues = [], ...args } = props;

  return (
    <BaseSelect placeholder={placeholder} {...args}>
      {purposePersonalTransferDataSource().map((item) => {
        return (
          <Option value={item.id} key={item.id} disabled={disabledValues.some((el) => el === item.id)}>
            {item.label}
          </Option>
        );
      })}
    </BaseSelect>
  );
};

export default PurposePersonalTransferSelect;
