import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ROLES } from '@app/infrastructure/enums/roles';
import PlayerDashboard from '@app/components/players/dashboard';
import usePlayers from '@app/infrastructure/hooks/use-players';
import { useEffect, useState } from 'react';
import { Player } from '@app/infrastructure/classes/player';
import { Loading } from '@app/components/common/Loading/Loading';

const PlayerView: React.FC = () => {
  const playerId = useAppSelector((state) => state.user.user?.player?.id);
  const [player, setPlayer] = useState<Player>();
  const { loadPlayerById, loading } = usePlayers();

  useEffect(() => {
    if (playerId) {
      loadPlayerById(playerId).then((player) => {
        setPlayer(player);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  return <BaseCol>{(loading && <Loading />) || (player && <PlayerDashboard player={player} canEdit />)}</BaseCol>;
};

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const role = useAppSelector((state) => state.user.user?.role);
  return (
    <>
      <PageTitle>{t('navigation.home')}</PageTitle>
      {role === ROLES.PLAYER && <PlayerView />}
    </>
  );
};

export default HomePage;
