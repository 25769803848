import { useState } from 'react';
import { IPaginatedData, IPaginationMeta, IPartialPaginationMeta } from '../interfaces/i-pagination';
import { ILoadProps } from './interfaces/i-load-props';
import { Player, PlayersStatistic } from '../classes/player';
import { ICreatePlayer, IPlayer, IUpdatePlayer } from '../interfaces/i-player';
import {
  createPlayers,
  deletePlayers,
  getPlayers,
  getPlayerById,
  updatePlayers,
  getPlayersStatistics,
} from '@app/api/players.api';
import { ROLES } from '../enums/roles';
import { notificationController } from '../controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import { HTTP_STATUSES } from '../consts/http-statuses';

type UsePlayers = {
  players: Player[];
  meta: IPaginationMeta;
  loadPlayers: (props?: ILoadProps) => Promise<Player[] | void>;
  loadPlayersStatistic: () => Promise<PlayersStatistic | undefined>;
  createPlayer: (data: ICreatePlayer) => Promise<Player | void>;
  loadPlayerById: (id: number) => Promise<Player | undefined>;
  updatePlayerInfoById: (id: number, player: Player) => Promise<Player>;
  updatePlayer: (id: number, data: IUpdatePlayer, player: Player) => Promise<Player | void>;
  deletePlayer: (id: number) => Promise<void>;
  loading: boolean;
};

interface IUsePlayersProps {
  meta?: IPartialPaginationMeta;
}

const usePlayers = (props?: IUsePlayersProps): UsePlayers => {
  const [loading, setLoading] = useState<boolean>(false);
  const [players, setPlayers] = useState<Player[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    page: 1,
    limit: 100,
    sort: ['id', 'ASC'],
    total: 0,
    totalPages: 0,
    ...props?.meta,
  });

  const { t } = useTranslation();

  const loadPlayers = async (props?: ILoadProps) => {
    setLoading(true);

    const fetchPlayers = async (page: number): Promise<IPaginatedData<IPlayer>> => {
      const { results, meta: metaRes } = await getPlayers({ ...meta, ...props, page });
      return { results, meta: metaRes };
    };

    const handleResults = (results: IPlayer[], metaRes: IPaginationMeta) => {
      if (results.length || metaRes.total === 0) {
        const data = results.map((el) => new Player(el));
        if (props?.reload) {
          setPlayers(data);
        } else {
          setPlayers((prevPlayers) => [...prevPlayers, ...data]);
        }
        setMeta((prevMeta) => ({ ...prevMeta, ...metaRes }));
      }
      return results;
    };

    const { results, meta: metaRes } = await fetchPlayers(props?.page ?? meta.page);

    if (!handleResults(results, metaRes).length && metaRes.total !== 0) {
      const { results: secondResults, meta: metaSecondReq } = await fetchPlayers(
        metaRes.totalPages < metaRes.page ? metaRes.totalPages : metaRes.page,
      );
      handleResults(secondResults, metaSecondReq);
    }

    setLoading(false);
  };

  const loadPlayersStatistic = async () => {
    setLoading(true);
    const data = await getPlayersStatistics().catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    setLoading(false);
    if (data) return new PlayersStatistic(data);
  };

  const loadPlayerById = async (id: number) => {
    setLoading(true);
    const data = await getPlayerById(id).catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    setLoading(false);
    if (data) return new Player(data);
  };

  const updatePlayerInfoById = async (id: number, player: Player) => {
    setLoading(true);
    const data = await getPlayerById(id).catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    if (data) player.updateInfo(data);
    setLoading(false);
    return player;
  };

  const createPlayer = async (data: ICreatePlayer) => {
    setLoading(true);
    const result = await createPlayers({ ...data, role: ROLES.PLAYER }).catch((err) => {
      if (err.options.statusCode === HTTP_STATUSES.CONFLICT) {
        return notificationController.error({
          message: t('messages.loginUsed'),
        });
      } else {
        notificationController.error({ message: err.message });
      }
      setLoading(false);
    });

    setLoading(false);
    if (result) {
      notificationController.success({
        message: t('messages.successAddPlayer'),
      });
      return new Player(result);
    }
  };

  const updatePlayer = async (id: number, data: IUpdatePlayer, player: Player) => {
    setLoading(true);
    await updatePlayers(id, data)
      .then((data) => {
        notificationController.success({
          message: t('messages.successUpdate'),
        });
        setLoading(false);
        player.updateInfo(data);
        return new Player(data);
      })
      .catch(({ options }) => {
        if (options.statusCode === HTTP_STATUSES.CONFLICT) {
          return notificationController.error({
            message: t('messages.loginUsed'),
          });
        }
      });
  };

  const deletePlayer = async (id: number) => {
    setLoading(true);
    await deletePlayers(id).then(() => {
      notificationController.success({
        message: t('messages.successDelete'),
      });
      setLoading(false);
    });
  };

  return {
    players,
    meta,
    loading,
    loadPlayers,
    loadPlayersStatistic,
    loadPlayerById,
    updatePlayerInfoById,
    createPlayer,
    updatePlayer,
    deletePlayer,
  };
};

export default usePlayers;
