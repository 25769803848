import React, { useEffect, useState } from 'react';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { TOURNAMENT_PERIOD } from '@app/infrastructure/enums/tournament-period';
import TournamentsTable from './index';
import TournamentTableHeader from './header';
import { ITournamentFilters } from '@app/infrastructure/interfaces/i-tournament';
import moment from 'moment-timezone';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface IMergedTournamentTable {
  near?: boolean;
  ranks?: number[];
}

const MergedTournamentTable: React.FC<IMergedTournamentTable> = (props) => {
  const { ranks, near = false } = props;

  const user = useAppSelector((state) => state.user.user);
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [key, setKey] = useState<number>(Date.now());
  const date = moment();

  if (user && user.isPlayer()) {
    date.utc();
  }

  const [filters, setFilters] = useState<ITournamentFilters>({
    fromDate: date.format(MomentFormats['MM.DD.YYYY']),
    fromTime: date.format(MomentFormats['HH:mm']),
  });

  const onCreated = () => {
    setKey(Date.now());
  };

  const onFilterChange = (newFilters: ITournamentFilters) => {
    setFilters({
      ...newFilters,
      fromDate: newFilters.fromDate ? newFilters.fromDate : moment().format(MomentFormats['MM.DD.YYYY']),
    });
    setKey(Date.now());
  };

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TournamentTableHeader onCreated={onCreated} onFilterChange={onFilterChange} />
      {!isInitial && (
        <BaseRow gutter={[10, 20]}>
          <BaseCol xxl={{ span: 12 }}>
            <TournamentsTable
              key={key}
              periodType={[TOURNAMENT_PERIOD.REGULAR]}
              filters={{
                ...filters,
                near,
                ranks,
              }}
            />
          </BaseCol>
          <BaseCol xxl={{ span: 12 }}>
            <TournamentsTable
              key={key}
              periodType={[TOURNAMENT_PERIOD.EVENT]}
              filters={{
                ...filters,
                near,
                ranks,
              }}
            />
          </BaseCol>
        </BaseRow>
      )}
    </div>
  );
};

export default MergedTournamentTable;
