import React from 'react';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { PACKAGE_TERMS_ENUM } from '@app/infrastructure/enums/package-terms.enum';

export const packageTermsList: string[] = Object.values(PACKAGE_TERMS_ENUM).map((value) => {
  return value;
});

interface PackageTermsSelectProps {
  placeholder?: string;
}

const PackageTermsSelect: React.FC<PackageTermsSelectProps> = (props) => {
  const { placeholder, ...args } = props;
  return (
    <BaseSelect placeholder={placeholder} {...args}>
      {packageTermsList.map((item) => {
        return (
          <Option value={item} key={item}>
            {item}
          </Option>
        );
      })}
    </BaseSelect>
  );
};

export default PackageTermsSelect;
