/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as S from './styles';
import { CURRENCIES } from '@app/infrastructure/consts/currencies';
import AppBaseCard from '@app/components/common/base-card';
import { Player } from '@app/infrastructure/classes/player';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import { BaseDatePicker, BaseDatePickerProps } from '@app/components/common/pickers/BaseDatePicker';
import PackageTermsSelect from '@app/components/profit-packages/select-box';
import useProfitPackages from '@app/infrastructure/hooks/use-profit-packages';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { CalculateForm } from '../calculate-form';
import { CANCELED_PACKAGE_STATUS } from '@app/infrastructure/enums/canceled-package-status';
import { useRerender } from '@app/infrastructure/providers/rerender-provider';
import { useAppSelector } from '@app/infrastructure/hooks/redux';
import { ICreateProfitPackage } from '@app/infrastructure/interfaces/i-profit-package';
import { MomentFormats } from '@app/infrastructure/enums/moment-formats';
import { ProfitPackage } from '@app/infrastructure/classes/profit-package';
import { ROLES } from '@app/infrastructure/enums/roles';

interface IPlayerProfitPackageProps {
  player: Player;
}

const PlayerProfitPackage: React.FC<IPlayerProfitPackageProps> = (props) => {
  const { player } = props;
  const user = useAppSelector((state) => state.user?.user);
  const canEdit = user?.role === ROLES.PLAYER ? true : user?.canUpdate('profitPackagePermission');

  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [profitPackage, setProfitPackage] = useState<ProfitPackage>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const isNoProfitPackage = !profitPackage;

  const { loadProfitPackageByPlayer, createProfitPackage, loading } = useProfitPackages({ player });
  const { key, rerender } = useRerender();

  const getProfitPackage = () => {
    loadProfitPackageByPlayer().then((profitPackage) =>
      profitPackage ? setProfitPackage(profitPackage) : setProfitPackage(undefined),
    );
  };

  useEffect(() => {
    getProfitPackage();
  }, [key]);

  useEffect(() => {
    setIsDisabled(profitPackage?.canceledPackageStatus === CANCELED_PACKAGE_STATUS.IN_REVIEW);
  }, [profitPackage]);

  const onFinish = async (values: ICreateProfitPackage) => {
    await createProfitPackage({
      start_date: values.start_date,
      package_terms: values.package_terms,
    }).then(async () => {
      setIsEdit(false);
      getProfitPackage();
      rerender();
    });
  };

  const onOpen = () => {
    rerender();
    setIsModalOpen(true);
  };

  const onCancel = () => {
    setIsEdit(false);
  };

  const onSave = () => {
    getProfitPackage();
    setIsModalOpen(false);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const disabledDate: BaseDatePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <AppBaseCard
      title={t('labels.profitPackage')}
      loading={loading}
      canEdit={isNoProfitPackage && user?.canUpdate('profitPackagePermission')}
      onEdit={() => setIsEdit(true)}
    >
      <BaseButtonsForm
        isFieldsChanged={isEdit}
        key={key}
        onFinish={onFinish}
        onClose={onCancel}
        size="small"
        layout="horizontal"
      >
        <S.BaseButtonsFormItem name="start_date" label={t('labels.startDate')} rules={[{ required: true }]}>
          {isEdit ? (
            <BaseDatePicker disabledDate={disabledDate} placeholder="" style={{ width: '125px' }} />
          ) : (
            <BaseSpace>{profitPackage?.startDate.format(MomentFormats['DD.MM.YYYY'])}</BaseSpace>
          )}
        </S.BaseButtonsFormItem>
        <S.BaseButtonsFormItem
          name="package_terms"
          label={t('labels.packageTerms')}
          rules={[{ required: true }]}
          initialValue={profitPackage?.packageTerms}
        >
          {isEdit ? <PackageTermsSelect /> : <BaseSpace>{profitPackage?.packageTerms}</BaseSpace>}
        </S.BaseButtonsFormItem>
        <S.BaseButtonsFormItem
          name="profitPackage"
          label={t('labels.profitPackage')}
          initialValue={profitPackage?.profitPackage}
        >
          <InputNumber bordered={false} readOnly={true} prefix={CURRENCIES.DOLLAR} />
        </S.BaseButtonsFormItem>
        <S.BaseButtonsFormItem name="fundsPart" label={t('labels.fundsPart')} initialValue={profitPackage?.fundsPart}>
          <InputNumber bordered={false} readOnly={true} prefix={CURRENCIES.DOLLAR} />
        </S.BaseButtonsFormItem>
        <S.BaseButtonsFormItem
          name="playersPart"
          label={t('labels.playersPart')}
          initialValue={profitPackage?.playersPart}
        >
          <InputNumber bordered={false} readOnly={true} prefix={CURRENCIES.DOLLAR} />
        </S.BaseButtonsFormItem>
        {!isNoProfitPackage && (
          <BaseButton type="primary" onClick={onOpen} disabled={isDisabled || !canEdit} size="small">
            {t('controls.calculate')}
          </BaseButton>
        )}
      </BaseButtonsForm>

      <BaseModal
        title={t('labels.calculateProfitPackage')}
        open={isModalOpen}
        footer={false}
        onCancel={onClose}
        size="large"
        centered
      >
        <BaseTypography>{t('messages.descriptionNeedScreenshots')}</BaseTypography>
        {profitPackage && <CalculateForm player={player} profitPackage={profitPackage} onSave={onSave} />}
      </BaseModal>
    </AppBaseCard>
  );
};

export default PlayerProfitPackage;
