import { SortType } from '../../types/sort-type';
import { IModifiedSorterResult } from '../interfaces/i-modified-sorter-result';

export const createSortOrder = <ColumnType>(
  sorterField: IModifiedSorterResult<ColumnType> | IModifiedSorterResult<ColumnType>[],
  defaultField = 'id',
  defaultSortType: SortType = 'ASC',
): [string, SortType] => {
  let sortType: SortType = defaultSortType;

  if (!Array.isArray(sorterField) && sorterField.order && sorterField.column) {
    sortType = sorterField.order === 'descend' ? 'DESC' : 'ASC';
    return [`${sorterField.column.sortField}`, sortType];
  }
  return [defaultField, sortType];
};
