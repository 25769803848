import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { ProfitPackage } from '@app/infrastructure/classes/profit-package';
import { ROUTES } from '@app/infrastructure/consts/routes';
import { Link } from 'react-router-dom';

interface ILoginCellProps {
  data: ProfitPackage;
}

const LoginCell: React.FC<ILoginCellProps> = (props) => {
  const { data } = props;
  return (
    <BaseTypography>
      {data.player && (
        <Link to={`${ROUTES.PLAYERS}/${data.player.id}`}>{data.player.user && data.player.user.login}</Link>
      )}
    </BaseTypography>
  );
};

export default LoginCell;
