import { PersonalTransfer } from '@app/infrastructure/classes/personal-transfer';
import { purposePersonalTransferDataSource } from '@app/infrastructure/data-sources/purpose-personal-transfer';
import React from 'react';

interface PurposeCellProps {
  data: PersonalTransfer;
}

const PurposeCell: React.FC<PurposeCellProps> = ({ data }) => {
  const name = purposePersonalTransferDataSource().find((item) => item.id === data.purpose);
  return <span>{name?.label}</span>;
};

export default PurposeCell;
