import React from 'react';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { InternalTransfer } from '@app/infrastructure/classes/internal-transfer';

interface ILoginReceiverCellProps {
  data: InternalTransfer;
}

const LoginReceiverCell: React.FC<ILoginReceiverCellProps> = (props) => {
  const { data } = props;

  return <BaseTypography>{data.internalTransferRequest.user.login}</BaseTypography>;
};

export default LoginReceiverCell;
