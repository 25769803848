import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import AccessRightsTable from '@app/components/access-rights/table';

const AccessRightsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('navigation.accessRights')}</PageTitle>
      <BaseCol>
        <AccessRightsTable />
      </BaseCol>
    </>
  );
};

export default AccessRightsPage;
